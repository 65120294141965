import "./CustomCarousel.css";
import Carousel from "react-bootstrap/Carousel";
import ProgressBar from "react-bootstrap/ProgressBar";
import { AiOutlineClockCircle } from "react-icons/ai";

function CustomCarousel(props) {
  function getProgress(_props) {
    if (_props.index === 0) return 3;
    else if (_props.index === _props.elements.length - 1) return 96;
    return Math.round((_props.index / (_props.elements.length - 1)) * 100);
  }

  const renderCarouselMainConent = (element, i) => {
    return (
      <>
        <h3 className="carousel-title">{element.title}</h3>
        <div
          className="d-flex align-items-center my-3"
          style={{ color: "#002994" }}
        >
          <AiOutlineClockCircle style={{ marginRight: "6px" }} />
          <div>
            {element.duration} min{element.duration > 1 ? "s" : ""}
          </div>
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            maxWidth: element.subtitleWidth ? element.subtitleWidth : "449px",
          }}
          className="mt-4 subtitle"
        >
          {element.subtitle}
        </div>
        {props.hasHr && <hr />}
        <div
          style={{ width: element.contentWidth ?? "504px" }}
          className={`mt-4 ${element.contentClassName ?? ""}`}
        >
          {element.content}
        </div>
      </>
    );
  };

  return (
    <div>
      <div
        className="d-flex justify-content-between w-100"
        style={{ marginBottom: "28px" }}
      >
        {props.customHeaders && (
          <>
            {props.customHeaders.map((element, i) => (
              <span
                style={
                  element.index <= props.customHeadersIndex
                    ? {
                        color: "black",
                        fontWeight: "900",
                      }
                    : {
                        color: "gray",
                        fontWeight: "500",
                      }
                }
                key={i}
              >
                {element.stepName}
              </span>
            ))}
          </>
        )}

        {!props.customHeaders && (
          <>
            {props.elements.map((element, i) => (
              <>
                <span
                  style={
                    (
                      props.isStepActive ??
                      ((_props, idx) => {
                        return _props.index === idx;
                      })
                    )(props, i)
                      ? {
                          color: "black",
                          fontWeight: "900",
                        }
                      : {
                          color: "gray",
                          fontWeight: "500",
                        }
                  }
                  key={i}
                >
                  {element.stepName}
                  {element.stepSubName && (
                    <span className="fc-grey fs-16">
                      <br></br>
                      {element.stepSubName}
                    </span>
                  )}
                </span>
              </>
            ))}
          </>
        )}
      </div>
      <ProgressBar
        now={(props.getProgress ?? getProgress)(props)}
        className="progress"
      />
      <Carousel indicators={false} activeIndex={props.index} controls={false}>
        {props.elements.map((element, i) => (
          <Carousel.Item
            key={i}
            className={`${element?.className ?? ""} ${element?.sidebar ? "has-sidebar" : ""}`}
          >
            {!!element?.sidebar && (
              <>
                <div className="content-main">
                  {renderCarouselMainConent(element, i)}
                </div>
                <div className="sidebar-main">{element?.sidebar}</div>
              </>
            )}
            {!element?.sidebar && renderCarouselMainConent(element, i)}
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

CustomCarousel.defaultProps = {
  hasHr: false,
};

export default CustomCarousel;
