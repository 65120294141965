import { buildChartTheme } from "@visx/xychart";

export default buildChartTheme({
  backgroundColor: "white",
  colors: ["#2A00FF"],
  gridColor: "#263238",
  gridColorDark: "#263238",
  svgLabelBig: { fill: "#1d1b38" },
  tickLength: 0,
});
