import React, { useState, useEffect, useRef } from "react";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { FiArrowLeft } from "react-icons/fi";
import lightbulbImg from "../../assets/project-lightbulb-vector.png";
import locationImg from "../../assets/project-location-vector.png";
import reworkImg from "../../assets/project-rework-vector.png";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { modifyDropdowns, removeDuplicates, getLabel } from "../../helpers";
import Modal from "react-modal";
import { BsXCircle } from "react-icons/bs";
import { Spinner } from "react-bootstrap";
import { SelectedProjectContext } from "../../contexts";
import "./Report.css";
import CustomCollapse from "../CustomCollapse";
import arrowRight from "../../assets/arrow-right.svg";

export function Report() {
  const defaultFilter = { value: "All", label: "All" };
  const selectedProject = React.useContext(SelectedProjectContext);

  const [isTimelineMenuOpen, setIsTimelineMenuOpen] = useState(false);
  const [isInsightsMenuOpen, setIsInsightsMenuOpen] = useState(false);
  const [isPageMenuOpen, setIsPageMenuOpen] = useState(false);
  const [isEaseToFixMenuOpen, setIsEaseToFixMenuOpen] = useState(false);
  const [isSeverityMenuOpen, setIsSeverityMenuOpen] = useState(false);

  const [selectedTimeline, setSelectedTimeline] = useState(defaultFilter);
  const [selectedInsight, setSelectedInsight] = useState(defaultFilter);
  const [selectedPage, setSelectedPage] = useState(defaultFilter);
  const [selectedEaseToFix, setSelectedEaseToFix] = useState(defaultFilter);
  const [selectedSeverity, setSelectedSeverity] = useState(defaultFilter);

  const [actionsModalOpen, setActionsModalOpen] = useState(false);
  const [actionsModalText, setActionsModalText] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [imagesLoading, setImagesLoading] = useState(true);
  const [insightsLoading, setInisghtsLoading] = useState(true);
  const [totalImages, setTotalImages] = useState(0);
  const [loadedImages, setLoadedImages] = useState(0);

  const ref = useRef(null);
  const navigate = useNavigate();

  const convertDateToActionsFormat = (date) => {
    if (date === undefined) {
      return "";
    } else if (typeof date === "string") {
      return date;
    } else if (date && typeof date === "object" && "seconds" in date) {
      const jsDate = new Date(date.seconds * 1000);
      const month = jsDate.toLocaleString("default", { month: "long" });
      return `${jsDate.getDate()} ${month} ${jsDate.getFullYear()}`;
    }
    return "Invalid date";
  };

  /**
   * Ensure that the user is on the correct project, otherwise redirect them
   */
  useEffect(() => {
    async function ensureProject() {
      if (
        selectedProject?.value &&
        selectedProject.value !==
          (
            await getDoc(
              doc(
                db,
                "reports",
                window.location.pathname.split("/report/").pop(),
              ),
            )
          ).data().projectId
      ) {
        navigate("/reports/insights");
      }
    }
    ensureProject();
  }, [selectedProject]);

  const onSelect = (option, menuType) => {
    switch (menuType) {
      case "Timeline":
        setSelectedTimeline(option);
        modifyDropdowns(0, "value", option, setDropdowns);
        break;
      case "Insights":
        setSelectedInsight(option);
        modifyDropdowns(1, "value", option, setDropdowns);
        break;
      case "Page":
        setSelectedPage(option);
        modifyDropdowns(2, "value", option, setDropdowns);
        break;
      case "Ease to fix":
        setSelectedEaseToFix(option);
        modifyDropdowns(3, "value", option, setDropdowns);
        break;
      case "Severity":
        setSelectedSeverity(option);
        modifyDropdowns(4, "value", option, setDropdowns);
        break;
      default:
        break;
    }
  };

  const tooltipLabels = {
    1: "Navigation & Structure",
    2: "Design & Typography",
    3: "Accessibility & Conformity",
    4: "Content & Engagement",
    5: "Mobile & Responsive",
  };

  const ratingIcons = [
    <i class="fa-solid fa-block-brick"></i>,
    <i class="fa-solid fa-brush"></i>,
    <i class="fa-brands fa-accessible-icon"></i>,
    <i class="fa-solid fa-book"></i>,
    <i class="fa-solid fa-mobile"></i>,
  ];

  const [projectCards, setProjectCards] = useState([]);

  const fixEaseOptions = [
    { value: "All", label: "All" },
    { value: "very-easy", label: "Very easy to fix" },
    { value: "easy", label: "Easy to fix" },
    { value: "challenging", label: "Challenging to fix" },
    { value: "very-challenging", label: "Very challenging to fix" },
    { value: "unfixable", label: "Unfixable" },
  ];

  const severityOptions = [
    { value: "All", label: "All" },
    { value: "not-usability", label: "Not a usability problem" },
    { value: "cosmetic", label: "Cosmetic" },
    { value: "minor", label: "Minor" },
    { value: "major", label: "Major" },
    { value: "critical", label: "Critical" },
  ];

  const actionTypeOptions = [
    { value: "user-tests", label: "User tests" },
    { value: "expert-review", label: "Expert review" },
    { value: "heuristic-evaluation", label: "Heuristic evaluation" },
    { value: "analytics", label: "Analytics" },
    { value: "ab-testing", label: "A/B testing" },
    { value: "other", label: "Other" },
  ];

  const [dropdowns, setDropdowns] = useState([
    {
      name: "Timeline",
      options: [],
      value: selectedTimeline,
      isMenuOpen: isTimelineMenuOpen,
      menuOpenHandler: () => setIsTimelineMenuOpen(true),
      menuCloseHandler: () => setIsTimelineMenuOpen(false),
    },
    {
      name: "Insights",
      options: [],
      value: selectedInsight,
      isMenuOpen: isInsightsMenuOpen,
      menuOpenHandler: () => setIsInsightsMenuOpen(true),
      menuCloseHandler: () => setIsInsightsMenuOpen(false),
    },
    {
      name: "Page",
      options: [],
      value: selectedPage,
      isMenuOpen: isPageMenuOpen,
      menuOpenHandler: () => setIsPageMenuOpen(true),
      menuCloseHandler: () => setIsPageMenuOpen(false),
    },
    {
      name: "Ease to fix",
      options: fixEaseOptions,
      value: selectedEaseToFix,
      isMenuOpen: isEaseToFixMenuOpen,
      menuOpenHandler: () => setIsEaseToFixMenuOpen(true),
      menuCloseHandler: () => setIsEaseToFixMenuOpen(false),
    },
    {
      name: "Severity",
      options: severityOptions,
      value: selectedSeverity,
      isMenuOpen: isSeverityMenuOpen,
      menuOpenHandler: () => setIsSeverityMenuOpen(true),
      menuCloseHandler: () => setIsSeverityMenuOpen(false),
    },
  ]);

  const onToggleMenu = (state, menuType) => {
    switch (menuType) {
      case "Timeline":
        modifyDropdowns(0, "isMenuOpen", state, setDropdowns);
        break;
      case "Insights":
        modifyDropdowns(1, "isMenuOpen", state, setDropdowns);
        break;
      case "Page":
        modifyDropdowns(2, "isMenuOpen", state, setDropdowns);
        break;
      case "Ease to fix":
        modifyDropdowns(3, "isMenuOpen", state, setDropdowns);
        break;
      case "Severity":
        modifyDropdowns(4, "isMenuOpen", state, setDropdowns);
        break;
      default:
        break;
    }
  };

  const filteredProjectCards = projectCards.filter((project) => {
    const timelineFilter = // Use label here because the value is unformatted and needed for ordering
      selectedTimeline.value === "All" || // This groups and filters by "Month Year"
      project.timeline.label === selectedTimeline.value;
    const insightFilter =
      selectedInsight.value === "All" ||
      project.insight === selectedInsight.value;
    const pageFilter =
      selectedPage.value === "All" ||
      project.locationResults.text === selectedPage.value;
    const easeToFixFilter =
      selectedEaseToFix.value === "All" ||
      project.easeToFixResults.value === selectedEaseToFix.value;
    const severityResultsFilter =
      selectedSeverity.value === "All" ||
      project.severityResults.value === selectedSeverity.value;

    return (
      timelineFilter &&
      insightFilter &&
      pageFilter &&
      easeToFixFilter &&
      severityResultsFilter
    );
  });

  function getRatingArray(errorType) {
    const result = [];
    if (!errorType) return [];
    errorType.forEach((e) => {
      switch (e.value) {
        case "navigationAndStructure":
          result.push(1);
          break;
        case "designAndTypography":
          result.push(2);
          break;
        case "accessibilityAndConformity":
          result.push(3);
          break;
        case "contentAndEngagement":
          result.push(4);
          break;
        case "mobileAndResponsive":
          result.push(5);
          break;
        default:
          break;
      }
    });
    return result;
  }

  function setProgressButtonShowHandler(index) {
    setActionsModalText((prevState) => {
      const newState = { ...prevState };
      newState[index].progressButton.show = true;
      return newState;
    });
  }

  function setActionsModalTextHandler(index, action) {
    setActionsModalText((prevState) => {
      const newState = { ...prevState };
      newState[index] = action;
      return newState;
    });
  }

  function setActionsModalOpenHandler(index, opened) {
    setActionsModalOpen((prevState) => {
      const newState = { ...prevState };
      newState[index] = opened;
      return newState;
    });
  }

  const updateActionStatus = async (reportId, insightId, actionId) => {
    const docRef = doc(
      db,
      `reports/${reportId}/insights/${insightId}/actions/${actionId}`,
    );

    await updateDoc(docRef, {
      status: "in-progress",
    });
  };

  /**
   * Fetches the insights from the database and populates the page
   * @returns {Promise<void>} - A promise that resolves when the insights have been fetched
   */
  const getInsightsAsync = async () => {
    // 0 is the reportid, 1 is the insight number
    const reportId = window.location.pathname.split("/report/").pop();
    const insightNum = window.location.hash.substring(1);

    const report = doc(db, "reports", reportId);

    const querySnapshot = await getDocs(collection(report, "insights"));
    const projectCardsTemp = [];
    const projectCardsPagesDropdowns = [{ value: "All", label: "All" }];
    const asyncOperations = querySnapshot.docs.map(async (doc) => {
      const rawDate = new Date(doc.data().createdAt.toDate().getTime());
      projectCardsPagesDropdowns.push({
        value: doc.data().pageName,
        label: doc.data().pageName,
      });

      // Get actions from documents & push to array.
      const actions = [];
      const actionDocs = await getDocs(collection(doc.ref, "actions"));
      actionDocs.forEach((actionDoc) => {
        actions.push({
          text: getLabel(actionDoc.data().actionType),
          offerButton: {
            show: true,
            buttonText: "See offer",
          },
          progressButton: {
            show: actionDoc.data()?.status === "in-progress",
            buttonText: "In progress",
          },
          info: {
            ...actionDoc.data(),
            id: actionDoc.id,
            insightId: doc.ref.id,
            reportId: reportId,
          },
        });
      });

      // Push project card to array.
      projectCardsTemp.push({
        timeline: { value: rawDate }, // Attach label below
        suggestionTitle: doc.data().title,
        suggestionText: doc.data().description,
        rating: getRatingArray(doc.data().uxErrorType),
        screenshotUrl: doc.data().screenshotUrl,
        severityResults: {
          title: "severity",
          text: getLabel(doc.data().severity),
          value: doc.data().severity,
        },
        easeToFixResults: {
          title: "ease to fix",
          text: getLabel(doc.data().fixEase),
          value: doc.data().fixEase,
        },
        locationResults: {
          title: "location",
          text: doc.data().pageName,
        },
        recommendation: {
          title: "recommendations",
          text: doc.data().recommendation,
        },
        rework: actions,
      });
    });

    await Promise.all(asyncOperations);
    projectCardsTemp.sort((a, b) => a.timeline.value - b.timeline.value); // Order by raw date

    // Populate insight details and dropdowns that require date ordering
    const insightNumberDropdowns = [{ value: "All", label: "All" }];
    const projectCardsTimelineDropdowns = [{ value: "All", label: "All" }];

    projectCardsTemp.forEach((item, index) => {
      const date =
        // Turn raw date into formatted date
        `${item.timeline.value.toLocaleString("en-US", { month: "long" })}
       ${item.timeline.value.getFullYear()} `;
      projectCardsTimelineDropdowns.push({ value: date, label: date });
      item.timeline.label = date;

      const insightCount = `Insight #${index + 1}`;
      insightNumberDropdowns.push({ value: insightCount, label: insightCount });
      item.insight = insightCount;
      item.idx = index + 1;

      if (insightNum && index + 1 == insightNum) {
        item.focus = true;
      }
    });

    modifyDropdowns(
      0,
      "options",
      removeDuplicates(projectCardsTimelineDropdowns),
      setDropdowns,
    );
    modifyDropdowns(1, "options", insightNumberDropdowns, setDropdowns);
    modifyDropdowns(
      2,
      "options",
      removeDuplicates(projectCardsPagesDropdowns),
      setDropdowns,
    );
    setProjectCards(projectCardsTemp);
    setTotalImages(
      projectCardsTemp.filter((card) => card.screenshotUrl != null).length,
    );
    setInisghtsLoading(false);
  };

  useEffect(() => {
    getInsightsAsync();
  }, []);

  useEffect(() => {
    const imageUrls = projectCards
      .filter((card) => card.screenshotUrl)
      .map((card) => card.screenshotUrl);

    const preloadImage = (src) => {
      const img = new Image();
      img.onload = () => setLoadedImages((prev) => prev + 1);
      img.src = src;
    };

    imageUrls.forEach(preloadImage);
  }, [projectCards]);

  useEffect(() => {
    if (totalImages !== 0 && loadedImages === totalImages) {
      setImagesLoading(false);
    }
  }, [loadedImages]);

  useEffect(() => {
    if (!isLoading) ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(imagesLoading || insightsLoading);
  }, [imagesLoading, insightsLoading]);

  const ActionInfoRow = ({ lText, rText }) => {
    return (
      <div className="row mt-1">
        <div className="col fs-16">
          <b>{lText}</b>
        </div>
        <div className="col fs-16" style={{ textAlign: "right" }}>
          {rText}
        </div>
        <div className="mt-1 d-flex align-items-center justify-content-center">
          <hr className="w-100" />
        </div>
      </div>
    );
  };

  return (
    <div>
      <Link
        to="/reports/insights"
        className="nav-back d-flex align-items-center"
      >
        <FiArrowLeft color="#000" size="24px" />
        <div className="nav-back-title">Back to Reports & Insights</div>
      </Link>
      <div className="line"></div>

      {isLoading ? (
        <div className="spinner-parent">
          <Spinner style={{ width: "50px", height: "50px" }} />
        </div>
      ) : projectCards.length === 0 ? (
        <h5>
          No insights to show yet. You'll find your insights here when they are
          created!
        </h5>
      ) : (
        <div>
          <div className="filter-section">
            <div className="filter-section-title">Filters</div>
            <div className="d-flex">
              {dropdowns.map((dropdown, i) => (
                <div className="me-4 dropdown-filter" key={i}>
                  <CustomDropdown
                    options={dropdown.options}
                    onChange={(option) => onSelect(option, dropdown.name)}
                    placeholder={dropdown.name}
                    value={dropdown.value}
                    unstyled
                    menuOpenState={dropdown.isMenuOpen}
                    setMenuOpenState={(res) => onToggleMenu(res, dropdown.name)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="timeline">{selectedTimeline.value}</div>
          <div>
            {filteredProjectCards.length === 0 ? (
              <h5>No insights found using selected filters.</h5>
            ) : (
              filteredProjectCards.map((card, i) => (
                <div
                  className="col-12 container project-card mb-4"
                  key={i}
                  ref={card.focus ? ref : null}
                >
                  <div className="suggestion">
                    <div className="row">
                      <div className="col">
                        <div id={card.idx} className="insight mb-5">
                          {card.insight}
                        </div>
                        <div className="suggestion-title">
                          {card.suggestionTitle}
                        </div>
                        <div className="suggestion-text mb-4">
                          {card.suggestionText}
                        </div>
                        <div className="row mb-4">
                          <div>
                            <div className="col-5 d-flex results-img">
                              {card.rating.map((value, i) => (
                                <div className="custom-tooltip" key={i}>
                                  {ratingIcons[value - 1]}
                                  <span className="tooltip-text">
                                    {tooltipLabels[value]}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="row results-title">
                          <div className="col">
                            {card.severityResults.title}
                          </div>
                          <div className="col">
                            {card.easeToFixResults.title}
                          </div>
                          <div className="col location-title">
                            <div>{card.locationResults.title}</div>
                            <div>
                              <img
                                src={locationImg}
                                alt="project-location-vector"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row results-text">
                          <div className="col">{card.severityResults.text}</div>
                          <div className="col">
                            {card.easeToFixResults.text}
                          </div>
                          <div className="col">{card.locationResults.text}</div>
                        </div>
                      </div>
                      {card.screenshotUrl && (
                        <div
                          style={{
                            width: "50%",
                          }}
                          className="col"
                        >
                          <div className="row">
                            <div className="project-img">
                              <img
                                src={card.screenshotUrl}
                                alt="screenshot"
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  border: "4.9px solid black",
                                  borderRadius: "9.8px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/*The div below is the recommendations section */}
                  <div className="recommendations">
                    <div className="row">
                      <div className="d-flex">
                        <div>
                          <img
                            src={lightbulbImg}
                            alt="project-lightbulb-vector"
                          />
                        </div>
                        <div className="recommendation-title">
                          {card.recommendation.title}
                        </div>
                      </div>
                      <div className="recommendation-body">
                        <div
                          style={{
                            overflowWrap: "anywhere",
                          }}
                        >
                          {card.recommendation.text}
                        </div>
                        {card.rework
                          .filter((action) => action.text !== "N/A")
                          .map((action, j) => (
                            <div className="rework" key={j}>
                              <div>
                                <img
                                  src={reworkImg}
                                  alt="project-rework-vector"
                                />
                              </div>
                              <div style={{ width: "144px" }}>
                                {action.text}
                              </div>
                              <div className="d-flex">
                                {action.offerButton.show ? (
                                  <button
                                    className="btn1"
                                    style={{ marginRight: "0" }}
                                    onClick={() => {
                                      setActionsModalOpenHandler(i, true);
                                      setActionsModalTextHandler(i, action);
                                    }}
                                  >
                                    {action.offerButton.buttonText}
                                  </button>
                                ) : null}
                                {action.progressButton.show ? (
                                  <button
                                    className="btn2"
                                    style={{ marginLeft: "12px" }}
                                  >
                                    {action.progressButton.buttonText}
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          ))}
                        <Modal
                          isOpen={actionsModalOpen[i]}
                          onRequestClose={() =>
                            setActionsModalOpenHandler(i, false)
                          }
                          contentLabel="Action modal"
                          className="custom-modal modal-shape modal-shape-2"
                          appElement={document.getElementById("root")}
                          style={{
                            overlay: {
                              backgroundColor: "rgba(42, 0, 255, 0.7)",
                              backdropFilter: "blur(8px)",
                              zIndex: 10,
                            },
                          }}
                        >
                          <button
                            onClick={() => setActionsModalOpenHandler(i, false)}
                            className="icon-button"
                          >
                            <BsXCircle
                              alt="X button"
                              size="30px"
                              color="#1F1F24"
                            />
                          </button>
                          <div>
                            <div className="left-half fl">
                              <div className="fw-500 fs-24">
                                {getLabel(actionsModalText[i]?.info.actionType)}
                              </div>
                              <div
                                className="fw-400 fs-16"
                                style={{
                                  width: "402px",
                                  marginTop: "20px",
                                  marginBottom: "22px",
                                }}
                              >
                                {actionsModalText[i]?.info.description}
                              </div>
                              <iframe
                                className="rounded-iframe"
                                src="https://www.youtube.com/embed/38leY3mTk7U"
                                title="YouTube Video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                              <div
                                style={{ width: "604px", marginTop: "25px" }}
                              >
                                <CustomCollapse
                                  isUxInsightsPage
                                  title="Method"
                                  content={
                                    <ol>
                                      <li>Dummy text</li>
                                      <li>Dummy text</li>
                                      <li>Dummy text</li>
                                      <li>Dummy text</li>
                                      <li>Dummy text</li>
                                      <li>Dummy text</li>
                                      <li>Dummy text</li>
                                      <li>Dummy text</li>
                                      <li>Dummy text</li>
                                      <li>Dummy text</li>
                                    </ol>
                                  }
                                />
                                <hr className="w-100" />
                                <CustomCollapse
                                  isUxInsightsPage
                                  title="How many people do I have to recruit ?"
                                  content={
                                    <ol style={{ margin: 0 }}>
                                      <li>Dummy text</li>
                                    </ol>
                                  }
                                />
                                <hr className="w-100" />
                                <CustomCollapse
                                  isUxInsightsPage
                                  title="Do you recruit people ?"
                                  content={
                                    <ol>
                                      <li>Dummy text</li>
                                    </ol>
                                  }
                                />
                                <hr className="w-100" />
                              </div>
                            </div>
                            <div className="right-half fr">
                              <button className="black-btn mb-5">OFFER</button>
                              <ActionInfoRow
                                lText="Duration"
                                rText={
                                  actionsModalText[i]?.info.hoursEstimation +
                                  " hours"
                                }
                              />
                              <ActionInfoRow
                                lText="Rate"
                                rText={actionsModalText[i]?.info.rate + "€"}
                              />
                              <ActionInfoRow
                                lText="Delivery Date"
                                rText={convertDateToActionsFormat(
                                  actionsModalText[i]?.info.deliveryDate,
                                )}
                              />

                              <div className="mt-5">
                                <b className="fs-16">Price</b>
                                <br></br>
                                <b className="fs-24">
                                  {actionsModalText[i]?.info.rate +
                                    "€ vat. excluded"}
                                </b>
                              </div>

                              <div className="offerbtn-container mt-4">
                                <button className="btn1">
                                  Will do it later
                                </button>
                                <button
                                  className="btn2"
                                  onClick={() => {
                                    updateActionStatus(
                                      actionsModalText[i]?.info.reportId,
                                      actionsModalText[i]?.info.insightId,
                                      actionsModalText[i]?.info.id,
                                    );
                                    setProgressButtonShowHandler(i);
                                  }}
                                >
                                  I agree
                                  <img
                                    src={arrowRight}
                                    alt="->"
                                    width="17.8"
                                    height="17.8"
                                  />
                                </button>
                              </div>

                              <div className="mt-4">
                                If you agree, an invoice will be generated to
                                your Company.
                              </div>
                            </div>
                            <div className="clear-fix"></div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}
