import React, { useState, useCallback, useEffect } from "react";
import { GoBell } from "react-icons/go";
import "./NotificationPopup.css";
import { auth, db } from "../../firebase";
import { formatDateMDY, getUserNotifications } from "../../helpers";
import luxi from "../../assets/black-logo.png";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const NotificationPopup = (props) => {
  const [showNotification, setShowNotification] = useState(false);
  const [showNewNotifications, setShowNewNotifications] = useState(false);

  const handleButtonClick = useCallback(
    (event) => {
      if (event) {
        event.preventDefault();
      }

      if (showNotification) {
        const notificationsRef = collection(db, "notifications");
        const q = query(
          notificationsRef,
          where("clientId", "==", auth?.currentUser?.uid),
        );

        getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const notificationRef = collection(doc.ref, "notification");
            getDocs(notificationRef).then((subQuerySnapshot) => {
              subQuerySnapshot.forEach((subDoc) => {
                updateDoc(subDoc.ref, { seen: true });
              });
            });
          });
        });
      }

      setShowNewNotifications(false);
      setShowNotification(!showNotification);
    },
    [showNotification],
  );

  const [notificationsToday, setNotificationsToday] = useState([]);
  const [notificationsYesterday, setNotificationsYesterday] = useState([]);
  const [notificationsOlder, setNotificationsOlder] = useState([]);

  async function getUserNotificationsHelper() {
    return getUserNotifications(auth?.currentUser?.uid, (data) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const grouped = data.reduce(
        (acc, item) => {
          const createdAtDate = item.createdAt.toDate();
          createdAtDate.setHours(0, 0, 0, 0);

          if (!item.seen) {
            setShowNewNotifications(true);
          }

          if (+createdAtDate === +today) {
            acc.notificationsToday.push(item);
          } else if (+createdAtDate === +today - 86400000) {
            // - 1 day
            acc.notificationsYesterday.push(item);
          } else {
            acc.notificationsOlder.push(item);
          }

          return acc;
        },
        {
          notificationsToday: [],
          notificationsYesterday: [],
          notificationsOlder: [],
        },
      );

      for (let group in grouped) {
        grouped[group].sort(
          (a, b) => b.createdAt.seconds - a.createdAt.seconds,
        );
      }

      setNotificationsToday(grouped.notificationsToday);
      setNotificationsYesterday(grouped.notificationsYesterday);
      setNotificationsOlder(grouped.notificationsOlder);
    });
  }

  useEffect(() => {
    let unsubscribeNotifications;

    if (auth?.currentUser) {
      getUserNotificationsHelper().then((unsub) => {
        unsubscribeNotifications = unsub;
      });
    }

    // Cleanup function
    return () => {
      unsubscribeNotifications && unsubscribeNotifications();
    };
  }, [auth]);

  function formatTimeAgo(date) {
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)}min ago`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)}hr ago`;
    } else {
      return `${Math.floor(diffInSeconds / 86400)} days ago`;
    }
  }

  const navigate = useNavigate();

  const notificationGroup = (group, groupName) => {
    return (
      <div>
        {group.length > 0 && (
          <>
            <div
              className="fs-16 fw-500 lh-192"
              style={{
                paddingLeft: "24px",
                paddingBottom: "24px",
                paddingTop: "23px",
              }}
            >
              {groupName}
            </div>

            {group?.map((n, i) => (
              <div
                key={i}
                className={
                  n.seen ? "notification-item-white" : "notification-item-grey"
                }
              >
                <img
                  src={luxi}
                  alt="luxi"
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "16.29px",
                  }}
                />
                <div
                  className="fw-500 fc-black fs-16 lh-192 wrap"
                  style={{ width: "253px" }}
                >
                  {n.body}{" "}
                  {n.url && (
                    <span
                      className="styled-link fc-blue"
                      onClick={() => {
                        handleButtonClick();
                        props.setActiveMenuItem("insights");
                        navigate(n.url);
                      }}
                    >
                      {n?.urlText}{" "}
                    </span>
                  )}
                  {!n.seen && <span className="fc-blue fs-24">•</span>}
                  <div
                    className="fc-grey lh-168 fs-14 fw-500"
                    style={{ marginTop: "6px" }}
                  >
                    {groupName === "Today"
                      ? formatTimeAgo(n.createdAt.toDate())
                      : formatDateMDY(n.createdAt.toDate())}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <button
        style={{ background: "none", padding: "0px" }}
        onClick={(event) => handleButtonClick(event)}
      >
        <GoBell alt="bell" size="24px" color="#1F1F24" />
        {showNewNotifications && <div className="new-notifications"></div>}
      </button>

      {showNotification && (
        <div className="notification-popup-list">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "24px 24px 0",
            }}
          >
            <div className="fs-18 fw-700 fc-black">Notifications</div>
            <a className="fs-14 fw-700">See All</a>
          </div>
          {notificationGroup(notificationsToday, "Today", "grey")}
          {notificationGroup(notificationsYesterday, "Yesterday")}
          {notificationGroup(notificationsOlder, "Older")}
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;
