import CustomCarousel from "../../components/CustomCarousel/CustomCarousel";
import MainLayout from "../MainLayout";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { FiArrowRight, FiPlus, FiX } from "react-icons/fi";
import check from "../../assets/check.png";
import Twemoji from "react-twemoji";
import "./OnboardDesigner.css";
import CustomInput from "../CustomInput/CustomInput";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import {
  getIndustryTags,
  uploadFileToFirestore,
  userExists,
} from "../../helpers";
import { MdCheckCircle } from "react-icons/md";
import { GiCircle } from "react-icons/gi";
import { components } from "react-select";
import PasswordStrengthBar from "react-password-strength-bar";
import { auth, db } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";

import grayPfp from "../../assets/gray.PNG";
import Modal from "react-modal";
import googleLoginIcon from "../../assets/google-login.png";

function OnboardDesigner() {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  // Join as designer - 1 & 10 - user info
  const [isNationalityOpen, setIsNationalityOpen] = useState(false);
  const [isPhoneCountryOpen, setIsPhoneCountryOpen] = useState(false);
  const [applyingAs, setApplyingAs] = useState("company"); // "individual" or "company"
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isCityOpen, setIsCityOpen] = useState(false);
  const [isBankCountryOpen, setIsBankCountryOpen] = useState(false);
  const [name, setName] = useState(""); // Individual only
  const [firstName, setFirstName] = useState(""); // Individual only
  const [nationality, setNationality] = useState(null); // Individual only

  const [companyName, setCompanyName] = useState(""); // Company only
  const [vatNum, setVatNum] = useState(""); // Company only
  const [website, setWebsite] = useState(""); // Company only
  const [companyCountry, setCompanyCountry] = useState(null); // Company only
  const [companyCity, setCompanyCity] = useState(null); // Company only
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPostCode, setCompanyPostCode] = useState("");
  const [companyContactEmail, setCompanyContactEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyPhoneCountry, setCompanyPhoneCountry] = useState(null);

  const [vatValid, setVatValid] = useState(false);
  const [websiteValid, setWebsiteValid] = useState(false);
  const [contactEmail, setContactEmail] = useState(""); // Email used for identification
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [phoneCountry, setPhoneCountry] = useState(null);
  const [phone, setPhone] = useState("");
  const [userInDb, setUserInDb] = useState(false);

  const [validContactEmail, setValidContactEmail] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [creationValid, setCreationValid] = useState(false);
  const [validCompanyContactEmail, setValidCompanyContactEmail] =
    useState(false);
  const [validCompanyPhone, setValidCompanyPhone] = useState(false);

  const [validAddress, setValidAddress] = useState(false);

  const [countryErrMsg, setCountryErrMsg] = useState("");
  const [cityErrMsg, setCityErrMsg] = useState("");
  const [addressErrMsg, setAddressErrMsg] = useState("");
  const [emailError, setEmailError] = useState("");

  const [companyPersonFormValid, setCompanyPersonFormValid] = useState(false);
  const [individualFormValid, setIndividualFormValid] = useState(false);

  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [validLinkedinUrl, setValidLinkedinUrl] = useState(false);
  const [linkedinVerified, setLinkedinVerified] = useState(false);

  // Join as designer - 15 - languages further below
  const [languagesValid, setLanguagesValid] = useState(false);

  // Join as designer - 16 - skills and experience
  const [selectedIndustryTags, setSelectedIndustryTags] = useState(null);
  const [industryTagsOptions, setIndustryTagsOptions] = useState([]);
  const [isIndustryTagsMenuOpen, setIsIndustryTagsMenuOpen] = useState(false);

  const [selectedProjectTags, setSelectedProjectTags] = useState([]);
  const [projectTagsOptions, setProjectTagsOptions] = useState([
    { label: "Landing Page", value: "Landing Page" },
    { label: "Advertising", value: "Advertising" },
    { label: "Saas", value: "Saas" },
    { label: "Website", value: "Website" },
    { label: "E commerce", value: "E commerce" },
    {
      label: "Microsoft Power Platform (Power BI, Power Apps,...)",
      value: "Microsoft Power Platform (Power BI, Power Apps,...)",
    },
    { label: "Form", value: "Form" },
    { label: "Mobile App", value: "Mobile App" },
    { label: "Software", value: "Software" },
    { label: "Interaction Design", value: "Interaction Design" },
    { label: "Email", value: "Email" },
    { label: "Game design", value: "Game design" },
    { label: "Webflow", value: "Webflow" },
    { label: "WordPress", value: "WordPress" },
    { label: "Wix", value: "Wix" },
  ]);
  const [isIndustryProjectMenuOpen, setIsIndustryProjectMenuOpen] =
    useState(false);

  const [selectedSkillTags, setSelectedSkillTags] = useState([]);
  const [skillTagsOptions, setSkillTagsOptions] = useState([
    { label: "UX Research", value: "UX Research" },
    { label: "User Flow", value: "User Flow" },
    { label: "Graphic Design", value: "Graphic Design" },
    { label: "Prototyping", value: "Prototyping" },
    { label: "Content Writing", value: "Content Writing" },
    { label: "Usability Test", value: "Usability Test" },
    { label: "Interaction Design", value: "Interaction Design" },
    { label: "Responsive Design", value: "Responsive Design" },
    { label: "Wireframing", value: "Wireframing" },
    { label: "UI Style Guide", value: "UI Style Guide" },
    { label: "Design System", value: "Design System" },
    { label: "User Interviews & Tests", value: "User Interviews & Tests" },
    { label: "Heuristic Evaluation", value: "Heuristic Evaluation" },
    { label: "Information Architecture", value: "Information Architecture" },
    { label: "Journey Mapping", value: "Journey Mapping" },
    { label: "Competitive Analysis", value: "Competitive Analysis" },
  ]);
  const [isIndustrySkillMenuOpen, setIsIndustrySkillMenuOpen] = useState(false);

  const [selectedToolTags, setSelectedToolTags] = useState([]);
  const [toolTagsOptions, setToolTagsOptions] = useState([
    { label: "Figma", value: "Figma" },
    { label: "Figjam", value: "Figjam" },
    { label: "Adobe Xd", value: "Adobe Xd" },
    { label: "Proto.io", value: "Proto.io" },
    { label: "ProtoPie", value: "ProtoPie" },
    { label: "Kameleoon", value: "Kameleoon" },
    { label: "AB Testing", value: "AB Testing" },
    { label: "Google Analytics", value: "Google Analytics" },
    { label: "Piano", value: "Piano" },
    { label: "Matomo", value: "Matomo" },
    { label: "Webflow", value: "Webflow" },
    { label: "FlowMapp", value: "FlowMapp" },
    { label: "Miro", value: "Miro" },
    { label: "Balsamiq", value: "Balsamiq" },
    { label: "Hotjar", value: "Hotjar" },
    { label: "Crazzyegg", value: "Crazzyegg" },
    { label: "Inspectlet", value: "Inspectlet" },
    { label: "Loop11", value: "Loop11" },
    { label: "SmartLook", value: "SmartLook" },
    { label: "Optimizely", value: "Optimizely" },
  ]);
  const [isToolMenuOpen, setIsToolMenuOpen] = useState(false);

  const [portfolio, setPortfolio] = useState("");
  const [validPortfolio, setValidPortfolio] = useState(false);
  const [presentationURL, setPresentationURL] = useState("");
  const [validPresentationURL, setValidPresentationURL] = useState(false);
  const [isFaceToFaceChecked, setIsFaceToFaceChecked] = useState(false);

  // Join as designer - 17 - banking and account info
  const [bankCountry, setBankCountry] = useState(null);
  const [iban, setIban] = useState("");
  const [email, setEmail] = useState(""); // Email used for sign in
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [ibanValid, setIbanValid] = useState(false);
  const [validPostcode, setValidPostcode] = useState(false);

  const [postCodeErrMsg, setPostcodeErrMsg] = useState("");
  const [passwordErrMsg, setPasswordErrMsg] = useState("");
  const [ibanErrMsg, setIbanErrMsg] = useState("");

  const [awaitingCreation, setAwaitingCreation] = useState(false);
  const [congratsPfpDisplay, setCongratsPfpDisplay] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [ssoError, setSsoError] = useState(false);
  const [idForm, setIdForm] = useState("idcard");

  const [customHeadersIndex, setCustomHeadersIndex] = useState(0);

  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const [showWaitForValidate, setShowWaitForValidate] = useState(false);

  const gotoDashboard = () => {
    navigate("/");
  };

  /*
  const linkedinSignIn = async () => {
    try {
      const auth = getAuth();
      const linkedinProvider = new OAuthProvider("oidc.linkedin");
      const result = await signInWithPopup(auth, linkedinProvider);
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;
    } catch (e) {
      console.error(e);
    }
  };*/

  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      const user = auth.currentUser;
      if (user && (await userExists())) {
        // User exists, notify them
        setSsoError(`User ${auth.currentUser.email} already registered.`);
        auth.signOut();
      } else {
        setSsoError("");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const userInDBFunc = async () => {
    if (!auth.currentUser) return;
    setUserInDb(await userExists());
  };

  const onPortfolioChange = (value) => {
    const websitePattern =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

    setPortfolio(value);
    setValidPortfolio(value !== "" && websitePattern.test(value));
  };

  const onPresentationChange = (value) => {
    const websitePattern = /^https:\/\/www\.loom\.com\/share\/.*/;

    setPresentationURL(value);
    setValidPresentationURL(value !== "" && websitePattern.test(value));
  };

  useEffect(() => {
    auth.signOut().then(() => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (!user) {
          setIsSSO(false);
          setUserInDb(false);
          return;
        }

        if (await userInDBFunc()) navigate("/");
        else setIsSSO(true);
      });

      return () => unsubscribe();
    });
  }, []);

  useEffect(() => {
    const linkedinPattern = /^https:\/\/www\.linkedin\.com\/in\/.*/;
    setValidLinkedinUrl(linkedinPattern.test(linkedinUrl));
  }, [linkedinUrl]);

  // Set any of these to false when changed. Validate only on Continue click
  useEffect(() => {
    setValidAddress(false);
    setAddressErrMsg("");
  }, [address]);

  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [cityCompanyOptions, setCityCompanyOptions] = useState([]);
  const [countryCitiesMap, setCountryCitiesMap] = useState({});

  function countryCodeToFlag(countryCode) {
    return (
      countryCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        ) + "  "
    );
  }

  const getCountriesAndCities = async () => {
    const country_info = await fetch(
      "https://countriesnow.space/api/v0.1/countries",
    );

    const country_data = await country_info.json();

    setCountryOptions(
      country_data.data.map((country) => {
        return {
          label: (
            <Twemoji options={{ className: "twemoji" }}>
              {countryCodeToFlag(country.iso2) + country.country}
            </Twemoji>
          ),
          countryCode: country.iso2,
          value: country.country,
        };
      }),
    );

    const countryCitiesMapTemp = {};
    for (const { country, cities } of country_data.data) {
      countryCitiesMapTemp[country] = cities.map((city) => {
        return { label: city, value: city };
      });
    }

    setCountryCitiesMap(countryCitiesMapTemp);
  };

  useEffect(() => {
    getCountriesAndCities();
  }, []);

  const validateLocation = async (locationType, location, biasString = "") => {
    if (!location || /^\s*$/.test(location)) {
      return false; // Skip the API call to save quota
    }

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          location,
        )}&key=${GOOGLE_API_KEY}${biasString}`,
      );
      const data = await response.json();

      if (locationType === "address") {
        return data.status === "OK";
      } else {
        if (data.status !== "OK") return false; // Invalid location
        const locationComponent = data.results?.[0]?.address_components?.find(
          (component) => component.types.includes(locationType),
        );

        return location.includes(locationComponent?.long_name);
      }
    } catch (error) {
      console.error(`Error validating ${locationType}: ${location}`);
      return false;
    }
  };

  // Validates Address in "Tell us who you are"
  const validateCompanyOrPerson = async () => {
    /*const [validatedAddress, validatedPostcode] = await Promise.all([
      validateLocation(
        "address",
        address,
        country?.value ? `&components=${"country:" + country.countryCode}` : "",
      ),
      validateLocation(
        "postal_code",
        postcode,
        country?.value ? `&components=${"country:" + country.countryCode}` : "",
      ),
    ]);
    setValidAddress(validatedAddress);
    setValidPostcode(validatedPostcode);
    if (validatedAddress && validatedPostcode) {
      setIndex(index + 1);
    } else {
      setAddressErrMsg(validatedAddress ? "" : "Invalid Address");
      setPostcodeErrMsg(validatedPostcode ? "" : "Invalid Postcode");
    }*/
    const validatedAddress = address !== "";
    const validatedPostcode = postcode !== "";
    setValidAddress(validatedAddress);
    setValidPostcode(validatedPostcode);

    const validatedCompanyAddress = companyAddress !== "";
    const validatedCompanyPostcode = companyPostCode !== "";
    //setValidCompanyAddress(validatedCompanyAddress);
    //setValidCompanyPostCode(validatedCompanyPostcode);

    if (applyingAs === "company") {
      if (validatedCompanyAddress && validatedCompanyPostcode) {
        setIndex(index + 1);
        setCompanyPersonFormValid(false);
      }
    }

    if (applyingAs === "individual")
      if (validatedAddress && validatedPostcode) setIndex(index + 2);
  };

  // Validate on iban state change
  useEffect(() => {
    const cleanedIban = iban.replace(/ /g, "");
    const ibanRegex = /^([A-Z]{2})(\d{2})([A-Z\d]+)$/;
    if (!iban || !ibanRegex.test(cleanedIban)) {
      setIbanValid(false);
      setIbanErrMsg(iban !== "" ? "Invalid IBAN" : "");
      return;
    }

    const match = cleanedIban.match(ibanRegex);

    if (!match || match.length !== 4) {
      setIbanErrMsg("Invalid IBAN");
      return;
    }

    const rearranged = match[3] + match[1] + match[2];
    const numeric = rearranged.replace(/[A-Z]/g, function (letter) {
      return letter.charCodeAt(0) - 55;
    });

    let remainder = 0;
    for (let i = 0; i < numeric.length; i++) {
      remainder = (remainder * 10 + parseInt(numeric[i], 10)) % 97;
    }

    remainder === 1 ? setIbanErrMsg("") : setIbanErrMsg("Invalid IBAN");
    setIbanValid(remainder === 1);
  }, [iban]);

  const autofillPostcode = async (loc) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        loc,
      )}&key=${GOOGLE_API_KEY}${
        country?.value ? `&components=${"country:" + country.countryCode}` : ""
      }`,
    );
    const data = await response.json();

    if (data.status === "OK") {
      const postalCodeComponent = data.results[0].address_components.find(
        (component) => component.types.includes("postal_code"),
      );

      const cityComponent = data.results[0].address_components.find(
        (component) => component.types.includes("locality"),
      );

      if (data.results[0]?.postcode_localities) {
        const cityOption = cityOptions.find(
          (c) => c.value === data.results[0].postcode_localities[0],
        );
        if (cityOption) setCity(cityOption);
        setPostcodeErrMsg("");
      }

      const countryComponent = data.results[0].address_components.find(
        (component) => component.types.includes("country"),
      );

      if (countryComponent)
        setCountry(
          countryOptions.find((c) => c.value === countryComponent.long_name),
        );

      if (cityComponent) {
        setCityOptions(countryCitiesMap[countryComponent.long_name]);
        setCity(
          countryCitiesMap[countryComponent.long_name].find(
            (c) => c.value === cityComponent.long_name,
          ),
        );
      }

      if (postalCodeComponent) {
        setPostcode(postalCodeComponent.long_name);
        setPostcodeErrMsg("");
      }
    }
  };

  function isIndividualFormValid() {
    return (
      !/^\s*$/.test(firstName) &&
      !/^\s*$/.test(name) &&
      nationality &&
      validContactEmail &&
      [address, postcode].every((field) => !/^\s*$/.test(field)) &&
      country &&
      city &&
      !!phoneCountry &&
      validPhone &&
      validPostcode
    );
  }

  function isCompanyFormValid() {
    return (
      !/^\s*$/.test(companyName) &&
      websiteValid &&
      vatNum &&
      [companyAddress, companyPostCode].every(
        (field) => !/^\s*$/.test(field),
      ) &&
      companyCountry &&
      companyCity &&
      !!companyPhoneCountry &&
      companyPhone !== "" &&
      companyContactEmail !== "" &&
      validCompanyContactEmail &&
      validCompanyPhone
    );
  }

  useEffect(() => {
    setIndividualFormValid(isIndividualFormValid());
  }, [
    name,
    firstName,
    nationality,
    country,
    contactEmail,
    city,
    address,
    postcode,
    phoneCountry,
    validPhone,
    validPostcode,
    validContactEmail,
    applyingAs,
  ]);

  useEffect(() => {
    let fieldsValid = false;
    if (applyingAs === "individual") {
      fieldsValid = isIndividualFormValid();
    } else if (applyingAs === "company") {
      fieldsValid = isCompanyFormValid();
    }
    setCompanyPersonFormValid(fieldsValid);

    if (fieldsValid) setEmail(contactEmail);
  }, [
    name,
    firstName,
    nationality,
    country,
    contactEmail,
    websiteValid,
    city,
    address,
    postcode,
    phoneCountry,
    vatValid,
    validPhone,
    companyName,
    validPostcode,
    vatNum,
    website,
    validContactEmail,
    applyingAs,
    companyAddress,
    companyPostCode,
    companyCountry,
    companyCity,
    companyPhoneCountry,
    companyPhone,
    companyContactEmail,
  ]);

  const isStepActive = (_props, idx) => {
    switch (_props.index) {
      case 1:
      case 2:
      case 3:
      case 4:
        return (
          idx === 1 || idx === 2 || idx === 3 || idx === 4 || idx < _props.index
        );
      case 5:
      case 6:
        return idx === 5 || idx === 6 || idx < _props.index;
      default:
        return _props.index === idx || idx < _props.index;
    }
  };

  const getProgress = (_props) => {
    switch (_props.index) {
      case 0:
        setCustomHeadersIndex(0);
        return 6.7;
      case 1:
        setCustomHeadersIndex(1);
        return 36.3;
      case 1:
        setCustomHeadersIndex(1);
        return 40.9;
      case 3:
        setCustomHeadersIndex(1);
        return 44.6;
      case 4:
        setCustomHeadersIndex(2);
        return 68.4;
      case 5:
        setCustomHeadersIndex(2);
        return 79.4;
      case 6:
        setCustomHeadersIndex(3);
        return 100;
      /*case 5:
        return 72;
      case 6:
        return 80;
      case _props.elements.length - 1:
        return 96; */
      default:
        return Math.round((_props.index / (_props.elements.length - 1)) * 100);
    }
  };
  // Options on the Individual tell us who you are page
  const nationalityOptions = [
    {
      value: "Afghan",
      countryCode: "AF",
    },
    {
      value: "Åland Island",
      countryCode: "AX",
    },
    {
      value: "Albanian",
      countryCode: "AL",
    },
    {
      value: "Algerian",
      countryCode: "DZ",
    },
    {
      value: "American Samoan",
      countryCode: "AS",
    },
    {
      value: "Andorran",
      countryCode: "AD",
    },
    {
      value: "Angolan",
      countryCode: "AO",
    },
    {
      value: "Anguillan",
      countryCode: "AI",
    },
    {
      value: "Antarctic",
      countryCode: "AQ",
    },
    {
      value: "Antiguan or Barbudan",
      countryCode: "AG",
    },
    {
      value: "Argentine",
      countryCode: "AR",
    },
    {
      value: "Armenian",
      countryCode: "AM",
    },
    {
      value: "Aruban",
      countryCode: "AW",
    },
    {
      value: "Australian",
      countryCode: "AU",
    },
    {
      value: "Austrian",
      countryCode: "AT",
    },
    {
      value: "Azerbaijani, Azeri",
      countryCode: "AZ",
    },
    {
      value: "Bahamian",
      countryCode: "BS",
    },
    {
      value: "Bahraini",
      countryCode: "BH",
    },
    {
      value: "Bangladeshi",
      countryCode: "BD",
    },
    {
      value: "Barbadian",
      countryCode: "BB",
    },
    {
      value: "Belarusian",
      countryCode: "BY",
    },
    {
      value: "Belgian",
      countryCode: "BE",
    },
    {
      value: "Belizean",
      countryCode: "BZ",
    },
    {
      value: "Beninese, Beninois",
      countryCode: "BJ",
    },
    {
      value: "Bermudian, Bermudan",
      countryCode: "BM",
    },
    {
      value: "Bhutanese",
      countryCode: "BT",
    },
    {
      value: "Bolivian",
      countryCode: "BO",
    },
    {
      value: "Bonaire",
      countryCode: "BQ",
    },
    {
      value: "Bosnian or Herzegovinian",
      countryCode: "BA",
    },
    {
      value: "Motswana, Botswanan",
      countryCode: "BW",
    },
    {
      value: "Bouvet Island",
      countryCode: "BV",
    },
    {
      value: "Brazilian",
      countryCode: "BR",
    },
    {
      value: "BIOT",
      countryCode: "IO",
    },
    {
      value: "Bruneian",
      countryCode: "BN",
    },
    {
      value: "Bulgarian",
      countryCode: "BG",
    },
    {
      value: "Burkinabé",
      countryCode: "BF",
    },
    {
      value: "Burundian",
      countryCode: "BI",
    },
    {
      value: "Cabo Verdean",
      countryCode: "CV",
    },
    {
      value: "Cambodian",
      countryCode: "KH",
    },
    {
      value: "Cameroonian",
      countryCode: "CM",
    },
    {
      value: "Canadian",
      countryCode: "CA",
    },
    {
      value: "Caymanian",
      countryCode: "KY",
    },
    {
      value: "Central African",
      countryCode: "CF",
    },
    {
      value: "Chadian",
      countryCode: "TD",
    },
    {
      value: "Chilean",
      countryCode: "CL",
    },
    {
      value: "Chinese",
      countryCode: "CN",
    },
    {
      value: "Christmas Island",
      countryCode: "CX",
    },
    {
      value: "Cocos Island",
      countryCode: "CC",
    },
    {
      value: "Colombian",
      countryCode: "CO",
    },
    {
      value: "Comoran, Comorian",
      countryCode: "KM",
    },
    {
      value: "Congolese",
      countryCode: "CG",
    },
    {
      value: "Congolese",
      countryCode: "CD",
    },
    {
      value: "Cook Island",
      countryCode: "CK",
    },
    {
      value: "Costa Rican",
      countryCode: "CR",
    },
    {
      value: "Ivorian",
      countryCode: "CI",
    },
    {
      value: "Croatian",
      countryCode: "HR",
    },
    {
      value: "Cuban",
      countryCode: "CU",
    },
    {
      value: "Curaçaoan",
      countryCode: "CW",
    },
    {
      value: "Cypriot",
      countryCode: "CY",
    },
    {
      value: "Czech",
      countryCode: "CZ",
    },
    {
      value: "Danish",
      countryCode: "DK",
    },
    {
      value: "Djiboutian",
      countryCode: "DJ",
    },
    {
      value: "Dominican",
      countryCode: "DM",
    },
    {
      value: "Dominican",
      countryCode: "DO",
    },
    {
      value: "Ecuadorian",
      countryCode: "EC",
    },
    {
      value: "Egyptian",
      countryCode: "EG",
    },
    {
      value: "Salvadoran",
      countryCode: "SV",
    },
    {
      value: "Equatorial Guinean, Equatoguinean",
      countryCode: "GQ",
    },
    {
      value: "Eritrean",
      countryCode: "ER",
    },
    {
      value: "Estonian",
      countryCode: "EE",
    },
    {
      value: "Ethiopian",
      countryCode: "ET",
    },
    {
      value: "Falkland Island",
      countryCode: "FK",
    },
    {
      value: "Faroese",
      countryCode: "FO",
    },
    {
      value: "Fijian",
      countryCode: "FJ",
    },
    {
      value: "Finnish",
      countryCode: "FI",
    },
    {
      value: "French",
      countryCode: "FR",
    },
    {
      value: "French Guianese",
      countryCode: "GF",
    },
    {
      value: "French Polynesian",
      countryCode: "PF",
    },
    {
      value: "French Southern Territories",
      countryCode: "TF",
    },
    {
      value: "Gabonese",
      countryCode: "GA",
    },
    {
      value: "Gambian",
      countryCode: "GM",
    },
    {
      value: "Georgian",
      countryCode: "GE",
    },
    {
      value: "German",
      countryCode: "DE",
    },
    {
      value: "Ghanaian",
      countryCode: "GH",
    },
    {
      value: "Gibraltar",
      countryCode: "GI",
    },
    {
      value: "Greek, Hellenic",
      countryCode: "GR",
    },
    {
      value: "Greenlandic",
      countryCode: "GL",
    },
    {
      value: "Grenadian",
      countryCode: "GD",
    },
    {
      value: "Guadeloupe",
      countryCode: "GP",
    },
    {
      value: "Guamanian, Guambat",
      countryCode: "GU",
    },
    {
      value: "Guatemalan",
      countryCode: "GT",
    },
    {
      value: "Channel Island",
      countryCode: "GG",
    },
    {
      value: "Guinean",
      countryCode: "GN",
    },
    {
      value: "Bissau-Guinean",
      countryCode: "GW",
    },
    {
      value: "Guyanese",
      countryCode: "GY",
    },
    {
      value: "Haitian",
      countryCode: "HT",
    },
    {
      value: "Heard Island or McDonald Islands",
      countryCode: "HM",
    },
    {
      value: "Vatican",
      countryCode: "VA",
    },
    {
      value: "Honduran",
      countryCode: "HN",
    },
    {
      value: "Hong Kong, Hong Kongese",
      countryCode: "HK",
    },
    {
      value: "Hungarian, Magyar",
      countryCode: "HU",
    },
    {
      value: "Icelandic",
      countryCode: "IS",
    },
    {
      value: "Indian",
      countryCode: "IN",
    },
    {
      value: "Indonesian",
      countryCode: "ID",
    },
    {
      value: "Iranian, Persian",
      countryCode: "IR",
    },
    {
      value: "Iraqi",
      countryCode: "IQ",
    },
    {
      value: "Irish",
      countryCode: "IE",
    },
    {
      value: "Manx",
      countryCode: "IM",
    },
    {
      value: "Israeli",
      countryCode: "IL",
    },
    {
      value: "Italian",
      countryCode: "IT",
    },
    {
      value: "Jamaican",
      countryCode: "JM",
    },
    {
      value: "Japanese",
      countryCode: "JP",
    },
    {
      value: "Channel Island",
      countryCode: "JE",
    },
    {
      value: "Jordanian",
      countryCode: "JO",
    },
    {
      value: "Kazakhstani, Kazakh",
      countryCode: "KZ",
    },
    {
      value: "Kenyan",
      countryCode: "KE",
    },
    {
      value: "I-Kiribati",
      countryCode: "KI",
    },
    {
      value: "North Korean",
      countryCode: "KP",
    },
    {
      value: "South Korean",
      countryCode: "KR",
    },
    {
      value: "Kuwaiti",
      countryCode: "KW",
    },
    {
      value: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
      countryCode: "KG",
    },
    {
      value: "Lao, Laotian",
      countryCode: "LA",
    },
    {
      value: "Latvian",
      countryCode: "LV",
    },
    {
      value: "Lebanese",
      countryCode: "LB",
    },
    {
      value: "Basotho",
      countryCode: "LS",
    },
    {
      value: "Liberian",
      countryCode: "LR",
    },
    {
      value: "Libyan",
      countryCode: "LY",
    },
    {
      value: "Liechtenstein",
      countryCode: "LI",
    },
    {
      value: "Lithuanian",
      countryCode: "LT",
    },
    {
      value: "Luxembourg, Luxembourgish",
      countryCode: "LU",
    },
    {
      value: "Macanese, Chinese",
      countryCode: "MO",
    },
    {
      value: "Macedonian",
      countryCode: "MK",
    },
    {
      value: "Malagasy",
      countryCode: "MG",
    },
    {
      value: "Malawian",
      countryCode: "MW",
    },
    {
      value: "Malaysian",
      countryCode: "MY",
    },
    {
      value: "Maldivian",
      countryCode: "MV",
    },
    {
      value: "Malian, Malinese",
      countryCode: "ML",
    },
    {
      value: "Maltese",
      countryCode: "MT",
    },
    {
      value: "Marshallese",
      countryCode: "MH",
    },
    {
      value: "Martiniquais, Martinican",
      countryCode: "MQ",
    },
    {
      value: "Mauritanian",
      countryCode: "MR",
    },
    {
      value: "Mauritian",
      countryCode: "MU",
    },
    {
      value: "Mahoran",
      countryCode: "YT",
    },
    {
      value: "Mexican",
      countryCode: "MX",
    },
    {
      value: "Micronesian",
      countryCode: "FM",
    },
    {
      value: "Moldovan",
      countryCode: "MD",
    },
    {
      value: "Monégasque, Monacan",
      countryCode: "MC",
    },
    {
      value: "Mongolian",
      countryCode: "MN",
    },
    {
      value: "Montenegrin",
      countryCode: "ME",
    },
    {
      value: "Montserratian",
      countryCode: "MS",
    },
    {
      value: "Moroccan",
      countryCode: "MA",
    },
    {
      value: "Mozambican",
      countryCode: "MZ",
    },
    {
      value: "Burmese",
      countryCode: "MM",
    },
    {
      value: "Namibian",
      countryCode: "NA",
    },
    {
      value: "Nauruan",
      countryCode: "NR",
    },
    {
      value: "Nepali, Nepalese",
      countryCode: "NP",
    },
    {
      value: "Dutch, Netherlandic",
      countryCode: "NL",
    },
    {
      value: "New Caledonian",
      countryCode: "NC",
    },
    {
      value: "New Zealand, NZ",
      countryCode: "NZ",
    },
    {
      value: "Nicaraguan",
      countryCode: "NI",
    },
    {
      value: "Nigerien",
      countryCode: "NE",
    },
    {
      value: "Nigerian",
      countryCode: "NG",
    },
    {
      value: "Niuean",
      countryCode: "NU",
    },
    {
      value: "Norfolk Island",
      countryCode: "NF",
    },
    {
      value: "Northern Marianan",
      countryCode: "MP",
    },
    {
      value: "Norwegian",
      countryCode: "NO",
    },
    {
      value: "Omani",
      countryCode: "OM",
    },
    {
      value: "Pakistani",
      countryCode: "PK",
    },
    {
      value: "Palauan",
      countryCode: "PW",
    },
    {
      value: "Palestinian",
      countryCode: "PS",
    },
    {
      value: "Panamanian",
      countryCode: "PA",
    },
    {
      value: "Papua New Guinean, Papuan",
      countryCode: "PG",
    },
    {
      value: "Paraguayan",
      countryCode: "PY",
    },
    {
      value: "Peruvian",
      countryCode: "PE",
    },
    {
      value: "Philippine, Filipino",
      countryCode: "PH",
    },
    {
      value: "Pitcairn Island",
      countryCode: "PN",
    },
    {
      value: "Polish",
      countryCode: "PL",
    },
    {
      value: "Portuguese",
      countryCode: "PT",
    },
    {
      value: "Puerto Rican",
      countryCode: "PR",
    },
    {
      value: "Qatari",
      countryCode: "QA",
    },
    {
      value: "Réunionese, Réunionnais",
      countryCode: "RE",
    },
    {
      value: "Romanian",
      countryCode: "RO",
    },
    {
      value: "Russian",
      countryCode: "RU",
    },
    {
      value: "Rwandan",
      countryCode: "RW",
    },
    {
      value: "Barthélemois",
      countryCode: "BL",
    },
    {
      value: "Saint Helenian",
      countryCode: "SH",
    },
    {
      value: "Kittitian or Nevisian",
      countryCode: "KN",
    },
    {
      value: "Saint Lucian",
      countryCode: "LC",
    },
    {
      value: "Saint-Martinoise",
      countryCode: "MF",
    },
    {
      value: "Saint-Pierrais or Miquelonnais",
      countryCode: "PM",
    },
    {
      value: "Saint Vincentian, Vincentian",
      countryCode: "VC",
    },
    {
      value: "Samoan",
      countryCode: "WS",
    },
    {
      value: "Sammarinese",
      countryCode: "SM",
    },
    {
      value: "São Toméan",
      countryCode: "ST",
    },
    {
      value: "Saudi, Saudi Arabian",
      countryCode: "SA",
    },
    {
      value: "Senegalese",
      countryCode: "SN",
    },
    {
      value: "Serbian",
      countryCode: "RS",
    },
    {
      value: "Seychellois",
      countryCode: "SC",
    },
    {
      value: "Sierra Leonean",
      countryCode: "SL",
    },
    {
      value: "Singaporean",
      countryCode: "SG",
    },
    {
      value: "Sint Maarten",
      countryCode: "SX",
    },
    {
      value: "Slovak",
      countryCode: "SK",
    },
    {
      value: "Slovenian, Slovene",
      countryCode: "SI",
    },
    {
      value: "Solomon Island",
      countryCode: "SB",
    },
    {
      value: "Somali, Somalian",
      countryCode: "SO",
    },
    {
      value: "South African",
      countryCode: "ZA",
    },
    {
      value: "South Georgia or South Sandwich Islands",
      countryCode: "GS",
    },
    {
      value: "South Sudanese",
      countryCode: "SS",
    },
    {
      value: "Spanish",
      countryCode: "ES",
    },
    {
      value: "Sri Lankan",
      countryCode: "LK",
    },
    {
      value: "Sudanese",
      countryCode: "SD",
    },
    {
      value: "Surinamese",
      countryCode: "SR",
    },
    {
      value: "Svalbard",
      countryCode: "SJ",
    },
    {
      value: "Swazi",
      countryCode: "SZ",
    },
    {
      value: "Swedish",
      countryCode: "SE",
    },
    {
      value: "Swiss",
      countryCode: "CH",
    },
    {
      value: "Syrian",
      countryCode: "SY",
    },
    {
      value: "Chinese, Taiwanese",
      countryCode: "TW",
    },
    {
      value: "Tajikistani",
      countryCode: "TJ",
    },
    {
      value: "Tanzanian",
      countryCode: "TZ",
    },
    {
      value: "Thai",
      countryCode: "TH",
    },
    {
      value: "Timorese",
      countryCode: "TL",
    },
    {
      value: "Togolese",
      countryCode: "TG",
    },
    {
      value: "Tokelauan",
      countryCode: "TK",
    },
    {
      value: "Tongan",
      countryCode: "TO",
    },
    {
      value: "Trinidadian or Tobagonian",
      countryCode: "TT",
    },
    {
      value: "Tunisian",
      countryCode: "TN",
    },
    {
      value: "Turkish",
      countryCode: "TR",
    },
    {
      value: "Turkmen",
      countryCode: "TM",
    },
    {
      value: "Turks and Caicos Island",
      countryCode: "TC",
    },
    {
      value: "Tuvaluan",
      countryCode: "TV",
    },
    {
      value: "Ugandan",
      countryCode: "UG",
    },
    {
      value: "Ukrainian",
      countryCode: "UA",
    },
    {
      value: "Emirati, Emirian, Emiri",
      countryCode: "AE",
    },
    {
      value: "British, UK",
      countryCode: "GB",
    },
    {
      value: "American",
      countryCode: "US",
    },
    {
      value: "Uruguayan",
      countryCode: "UY",
    },
    {
      value: "Uzbekistani, Uzbek",
      countryCode: "UZ",
    },
    {
      value: "Ni-Vanuatu, Vanuatuan",
      countryCode: "VU",
    },
    {
      value: "Venezuelan",
      countryCode: "VE",
    },
    {
      value: "Vietnamese",
      countryCode: "VN",
    },
    {
      value: "British Virgin Island",
      countryCode: "VG",
    },
    {
      value: "U.S. Virgin Island",
      countryCode: "VI",
    },
    {
      value: "Wallis and Futuna, Wallisian or Futunan",
      countryCode: "WF",
    },
    {
      value: "Sahrawi, Sahrawian, Sahraouian",
      countryCode: "EH",
    },
    {
      value: "Yemeni",
      countryCode: "YE",
    },
    {
      value: "Zambian",
      countryCode: "ZM",
    },
    {
      value: "Zimbabwean",
      countryCode: "ZW",
    },
  ].map((nat) => {
    return {
      label: (
        <Twemoji options={{ className: "twemoji" }}>
          {countryCodeToFlag(nat.countryCode) + " " + nat.value}
        </Twemoji>
      ),
      value: nat.value,
    };
  });

  const phoneCountryOptions = [
    {
      value: "+1",
      label: "🇨🇦 +1",
    },
    {
      value: "+880",
      label: "🇧🇩 +880",
    },
    {
      value: "+32",
      label: "🇧🇪 +32",
    },
    {
      value: "+226",
      label: "🇧🇫 +226",
    },
    {
      value: "+359",
      label: "🇧🇬 +359",
    },
    {
      value: "+387",
      label: "🇧🇦 +387",
    },
    {
      value: "+1-246",
      label: "🇧🇧 +1-246",
    },
    {
      value: "+681",
      label: "🇼🇫 +681",
    },
    {
      value: "+590",
      label: "🇧🇱 +590",
    },
    {
      value: "+1-441",
      label: "🇧🇲 +1-441",
    },
    {
      value: "+673",
      label: "🇧🇳 +673",
    },
    {
      value: "+591",
      label: "🇧🇴 +591",
    },
    {
      value: "+973",
      label: "🇧🇭 +973",
    },
    {
      value: "+257",
      label: "🇧🇮 +257",
    },
    {
      value: "+229",
      label: "🇧🇯 +229",
    },
    {
      value: "+975",
      label: "🇧🇹 +975",
    },
    {
      value: "+1-876",
      label: "🇯🇲 +1-876",
    },
    {
      value: "+",
      label: "🇧🇻 +",
    },
    {
      value: "+267",
      label: "🇧🇼 +267",
    },
    {
      value: "+685",
      label: "🇼🇸 +685",
    },
    {
      value: "+599",
      label: "🇧🇶 +599",
    },
    {
      value: "+55",
      label: "🇧🇷 +55",
    },
    {
      value: "+1-242",
      label: "🇧🇸 +1-242",
    },
    {
      value: "+44-1534",
      label: "🇯🇪 +44-1534",
    },
    {
      value: "+375",
      label: "🇧🇾 +375",
    },
    {
      value: "+501",
      label: "🇧🇿 +501",
    },
    {
      value: "+7",
      label: "🇷🇺 +7",
    },
    {
      value: "+250",
      label: "🇷🇼 +250",
    },
    {
      value: "+381",
      label: "🇷🇸 +381",
    },
    {
      value: "+670",
      label: "🇹🇱 +670",
    },
    {
      value: "+262",
      label: "🇷🇪 +262",
    },
    {
      value: "+993",
      label: "🇹🇲 +993",
    },
    {
      value: "+992",
      label: "🇹🇯 +992",
    },
    {
      value: "+40",
      label: "🇷🇴 +40",
    },
    {
      value: "+690",
      label: "🇹🇰 +690",
    },
    {
      value: "+245",
      label: "🇬🇼 +245",
    },
    {
      value: "+1-671",
      label: "🇬🇺 +1-671",
    },
    {
      value: "+502",
      label: "🇬🇹 +502",
    },
    {
      value: "+",
      label: "🇬🇸 +",
    },
    {
      value: "+30",
      label: "🇬🇷 +30",
    },
    {
      value: "+240",
      label: "🇬🇶 +240",
    },
    {
      value: "+590",
      label: "🇬🇵 +590",
    },
    {
      value: "+81",
      label: "🇯🇵 +81",
    },
    {
      value: "+592",
      label: "🇬🇾 +592",
    },
    {
      value: "+44-1481",
      label: "🇬🇬 +44-1481",
    },
    {
      value: "+594",
      label: "🇬🇫 +594",
    },
    {
      value: "+995",
      label: "🇬🇪 +995",
    },
    {
      value: "+1-473",
      label: "🇬🇩 +1-473",
    },
    {
      value: "+44",
      label: "🇬🇧 +44",
    },
    {
      value: "+241",
      label: "🇬🇦 +241",
    },
    {
      value: "+503",
      label: "🇸🇻 +503",
    },
    {
      value: "+224",
      label: "🇬🇳 +224",
    },
    {
      value: "+220",
      label: "🇬🇲 +220",
    },
    {
      value: "+299",
      label: "🇬🇱 +299",
    },
    {
      value: "+350",
      label: "🇬🇮 +350",
    },
    {
      value: "+233",
      label: "🇬🇭 +233",
    },
    {
      value: "+968",
      label: "🇴🇲 +968",
    },
    {
      value: "+216",
      label: "🇹🇳 +216",
    },
    {
      value: "+962",
      label: "🇯🇴 +962",
    },
    {
      value: "+385",
      label: "🇭🇷 +385",
    },
    {
      value: "+509",
      label: "🇭🇹 +509",
    },
    {
      value: "+36",
      label: "🇭🇺 +36",
    },
    {
      value: "+852",
      label: "🇭🇰 +852",
    },
    {
      value: "+504",
      label: "🇭🇳 +504",
    },
    {
      value: "+ ",
      label: "🇭🇲 + ",
    },
    {
      value: "+58",
      label: "🇻🇪 +58",
    },
    {
      value: "+1-787 and 1-939",
      label: "🇵🇷 +1-787 and 1-939",
    },
    {
      value: "+970",
      label: "🇵🇸 +970",
    },
    {
      value: "+680",
      label: "🇵🇼 +680",
    },
    {
      value: "+351",
      label: "🇵🇹 +351",
    },
    {
      value: "+47",
      label: "🇸🇯 +47",
    },
    {
      value: "+595",
      label: "🇵🇾 +595",
    },
    {
      value: "+964",
      label: "🇮🇶 +964",
    },
    {
      value: "+507",
      label: "🇵🇦 +507",
    },
    {
      value: "+689",
      label: "🇵🇫 +689",
    },
    {
      value: "+675",
      label: "🇵🇬 +675",
    },
    {
      value: "+51",
      label: "🇵🇪 +51",
    },
    {
      value: "+92",
      label: "🇵🇰 +92",
    },
    {
      value: "+63",
      label: "🇵🇭 +63",
    },
    {
      value: "+870",
      label: "🇵🇳 +870",
    },
    {
      value: "+48",
      label: "🇵🇱 +48",
    },
    {
      value: "+508",
      label: "🇵🇲 +508",
    },
    {
      value: "+260",
      label: "🇿🇲 +260",
    },
    {
      value: "+212",
      label: "🇪🇭 +212",
    },
    {
      value: "+372",
      label: "🇪🇪 +372",
    },
    {
      value: "+20",
      label: "🇪🇬 +20",
    },
    {
      value: "+27",
      label: "🇿🇦 +27",
    },
    {
      value: "+593",
      label: "🇪🇨 +593",
    },
    {
      value: "+39",
      label: "🇮🇹 +39",
    },
    {
      value: "+84",
      label: "🇻🇳 +84",
    },
    {
      value: "+677",
      label: "🇸🇧 +677",
    },
    {
      value: "+251",
      label: "🇪🇹 +251",
    },
    {
      value: "+252",
      label: "🇸🇴 +252",
    },
    {
      value: "+263",
      label: "🇿🇼 +263",
    },
    {
      value: "+966",
      label: "🇸🇦 +966",
    },
    {
      value: "+34",
      label: "🇪🇸 +34",
    },
    {
      value: "+291",
      label: "🇪🇷 +291",
    },
    {
      value: "+382",
      label: "🇲🇪 +382",
    },
    {
      value: "+373",
      label: "🇲🇩 +373",
    },
    {
      value: "+261",
      label: "🇲🇬 +261",
    },
    {
      value: "+590",
      label: "🇲🇫 +590",
    },
    {
      value: "+212",
      label: "🇲🇦 +212",
    },
    {
      value: "+377",
      label: "🇲🇨 +377",
    },
    {
      value: "+998",
      label: "🇺🇿 +998",
    },
    {
      value: "+95",
      label: "🇲🇲 +95",
    },
    {
      value: "+223",
      label: "🇲🇱 +223",
    },
    {
      value: "+853",
      label: "🇲🇴 +853",
    },
    {
      value: "+976",
      label: "🇲🇳 +976",
    },
    {
      value: "+692",
      label: "🇲🇭 +692",
    },
    {
      value: "+389",
      label: "🇲🇰 +389",
    },
    {
      value: "+230",
      label: "🇲🇺 +230",
    },
    {
      value: "+356",
      label: "🇲🇹 +356",
    },
    {
      value: "+265",
      label: "🇲🇼 +265",
    },
    {
      value: "+960",
      label: "🇲🇻 +960",
    },
    {
      value: "+596",
      label: "🇲🇶 +596",
    },
    {
      value: "+1-670",
      label: "🇲🇵 +1-670",
    },
    {
      value: "+1-664",
      label: "🇲🇸 +1-664",
    },
    {
      value: "+222",
      label: "🇲🇷 +222",
    },
    {
      value: "+44-1624",
      label: "🇮🇲 +44-1624",
    },
    {
      value: "+256",
      label: "🇺🇬 +256",
    },
    {
      value: "+255",
      label: "🇹🇿 +255",
    },
    {
      value: "+60",
      label: "🇲🇾 +60",
    },
    {
      value: "+52",
      label: "🇲🇽 +52",
    },
    {
      value: "+972",
      label: "🇮🇱 +972",
    },
    {
      value: "+33",
      label: "🇫🇷 +33",
    },
    {
      value: "+246",
      label: "🇮🇴 +246",
    },
    {
      value: "+290",
      label: "🇸🇭 +290",
    },
    {
      value: "+358",
      label: "🇫🇮 +358",
    },
    {
      value: "+679",
      label: "🇫🇯 +679",
    },
    {
      value: "+500",
      label: "🇫🇰 +500",
    },
    {
      value: "+691",
      label: "🇫🇲 +691",
    },
    {
      value: "+298",
      label: "🇫🇴 +298",
    },
    {
      value: "+505",
      label: "🇳🇮 +505",
    },
    {
      value: "+31",
      label: "🇳🇱 +31",
    },
    {
      value: "+47",
      label: "🇳🇴 +47",
    },
    {
      value: "+264",
      label: "🇳🇦 +264",
    },
    {
      value: "+678",
      label: "🇻🇺 +678",
    },
    {
      value: "+687",
      label: "🇳🇨 +687",
    },
    {
      value: "+227",
      label: "🇳🇪 +227",
    },
    {
      value: "+672",
      label: "🇳🇫 +672",
    },
    {
      value: "+234",
      label: "🇳🇬 +234",
    },
    {
      value: "+64",
      label: "🇳🇿 +64",
    },
    {
      value: "+977",
      label: "🇳🇵 +977",
    },
    {
      value: "+674",
      label: "🇳🇷 +674",
    },
    {
      value: "+683",
      label: "🇳🇺 +683",
    },
    {
      value: "+682",
      label: "🇨🇰 +682",
    },
    {
      value: "+",
      label: "🇽🇰 +",
    },
    {
      value: "+225",
      label: "🇨🇮 +225",
    },
    {
      value: "+41",
      label: "🇨🇭 +41",
    },
    {
      value: "+57",
      label: "🇨🇴 +57",
    },
    {
      value: "+86",
      label: "🇨🇳 +86",
    },
    {
      value: "+237",
      label: "🇨🇲 +237",
    },
    {
      value: "+56",
      label: "🇨🇱 +56",
    },
    {
      value: "+61",
      label: "🇨🇨 +61",
    },
    {
      value: "+242",
      label: "🇨🇬 +242",
    },
    {
      value: "+236",
      label: "🇨🇫 +236",
    },
    {
      value: "+243",
      label: "🇨🇩 +243",
    },
    {
      value: "+420",
      label: "🇨🇿 +420",
    },
    {
      value: "+357",
      label: "🇨🇾 +357",
    },
    {
      value: "+61",
      label: "🇨🇽 +61",
    },
    {
      value: "+506",
      label: "🇨🇷 +506",
    },
    {
      value: "+599",
      label: "🇨🇼 +599",
    },
    {
      value: "+238",
      label: "🇨🇻 +238",
    },
    {
      value: "+53",
      label: "🇨🇺 +53",
    },
    {
      value: "+268",
      label: "🇸🇿 +268",
    },
    {
      value: "+963",
      label: "🇸🇾 +963",
    },
    {
      value: "+599",
      label: "🇸🇽 +599",
    },
    {
      value: "+996",
      label: "🇰🇬 +996",
    },
    {
      value: "+254",
      label: "🇰🇪 +254",
    },
    {
      value: "+211",
      label: "🇸🇸 +211",
    },
    {
      value: "+597",
      label: "🇸🇷 +597",
    },
    {
      value: "+686",
      label: "🇰🇮 +686",
    },
    {
      value: "+855",
      label: "🇰🇭 +855",
    },
    {
      value: "+1-869",
      label: "🇰🇳 +1-869",
    },
    {
      value: "+269",
      label: "🇰🇲 +269",
    },
    {
      value: "+239",
      label: "🇸🇹 +239",
    },
    {
      value: "+421",
      label: "🇸🇰 +421",
    },
    {
      value: "+82",
      label: "🇰🇷 +82",
    },
    {
      value: "+386",
      label: "🇸🇮 +386",
    },
    {
      value: "+850",
      label: "🇰🇵 +850",
    },
    {
      value: "+965",
      label: "🇰🇼 +965",
    },
    {
      value: "+221",
      label: "🇸🇳 +221",
    },
    {
      value: "+378",
      label: "🇸🇲 +378",
    },
    {
      value: "+232",
      label: "🇸🇱 +232",
    },
    {
      value: "+248",
      label: "🇸🇨 +248",
    },
    {
      value: "+7",
      label: "🇰🇿 +7",
    },
    {
      value: "+1-345",
      label: "🇰🇾 +1-345",
    },
    {
      value: "+65",
      label: "🇸🇬 +65",
    },
    {
      value: "+46",
      label: "🇸🇪 +46",
    },
    {
      value: "+249",
      label: "🇸🇩 +249",
    },
    {
      value: "+1-809 and 1-829",
      label: "🇩🇴 +1-809 and 1-829",
    },
    {
      value: "+1-767",
      label: "🇩🇲 +1-767",
    },
    {
      value: "+253",
      label: "🇩🇯 +253",
    },
    {
      value: "+45",
      label: "🇩🇰 +45",
    },
    {
      value: "+1-284",
      label: "🇻🇬 +1-284",
    },
    {
      value: "+49",
      label: "🇩🇪 +49",
    },
    {
      value: "+967",
      label: "🇾🇪 +967",
    },
    {
      value: "+213",
      label: "🇩🇿 +213",
    },
    {
      value: "+1",
      label: "🇺🇸 +1",
    },
    {
      value: "+598",
      label: "🇺🇾 +598",
    },
    {
      value: "+262",
      label: "🇾🇹 +262",
    },
    {
      value: "+1",
      label: "🇺🇲 +1",
    },
    {
      value: "+961",
      label: "🇱🇧 +961",
    },
    {
      value: "+1-758",
      label: "🇱🇨 +1-758",
    },
    {
      value: "+856",
      label: "🇱🇦 +856",
    },
    {
      value: "+688",
      label: "🇹🇻 +688",
    },
    {
      value: "+886",
      label: "🇹🇼 +886",
    },
    {
      value: "+1-868",
      label: "🇹🇹 +1-868",
    },
    {
      value: "+90",
      label: "🇹🇷 +90",
    },
    {
      value: "+94",
      label: "🇱🇰 +94",
    },
    {
      value: "+423",
      label: "🇱🇮 +423",
    },
    {
      value: "+371",
      label: "🇱🇻 +371",
    },
    {
      value: "+676",
      label: "🇹🇴 +676",
    },
    {
      value: "+370",
      label: "🇱🇹 +370",
    },
    {
      value: "+352",
      label: "🇱🇺 +352",
    },
    {
      value: "+231",
      label: "🇱🇷 +231",
    },
    {
      value: "+266",
      label: "🇱🇸 +266",
    },
    {
      value: "+66",
      label: "🇹🇭 +66",
    },
    {
      value: "+",
      label: "🇹🇫 +",
    },
    {
      value: "+228",
      label: "🇹🇬 +228",
    },
    {
      value: "+235",
      label: "🇹🇩 +235",
    },
    {
      value: "+1-649",
      label: "🇹🇨 +1-649",
    },
    {
      value: "+218",
      label: "🇱🇾 +218",
    },
    {
      value: "+379",
      label: "🇻🇦 +379",
    },
    {
      value: "+1-784",
      label: "🇻🇨 +1-784",
    },
    {
      value: "+971",
      label: "🇦🇪 +971",
    },
    {
      value: "+376",
      label: "🇦🇩 +376",
    },
    {
      value: "+1-268",
      label: "🇦🇬 +1-268",
    },
    {
      value: "+93",
      label: "🇦🇫 +93",
    },
    {
      value: "+1-264",
      label: "🇦🇮 +1-264",
    },
    {
      value: "+1-340",
      label: "🇻🇮 +1-340",
    },
    {
      value: "+354",
      label: "🇮🇸 +354",
    },
    {
      value: "+98",
      label: "🇮🇷 +98",
    },
    {
      value: "+374",
      label: "🇦🇲 +374",
    },
    {
      value: "+355",
      label: "🇦🇱 +355",
    },
    {
      value: "+244",
      label: "🇦🇴 +244",
    },
    {
      value: "+",
      label: "🇦🇶 +",
    },
    {
      value: "+1-684",
      label: "🇦🇸 +1-684",
    },
    {
      value: "+54",
      label: "🇦🇷 +54",
    },
    {
      value: "+61",
      label: "🇦🇺 +61",
    },
    {
      value: "+43",
      label: "🇦🇹 +43",
    },
    {
      value: "+297",
      label: "🇦🇼 +297",
    },
    {
      value: "+91",
      label: "🇮🇳 +91",
    },
    {
      value: "+358-18",
      label: "🇦🇽 +358-18",
    },
    {
      value: "+994",
      label: "🇦🇿 +994",
    },
    {
      value: "+353",
      label: "🇮🇪 +353",
    },
    {
      value: "+62",
      label: "🇮🇩 +62",
    },
    {
      value: "+380",
      label: "🇺🇦 +380",
    },
    {
      value: "+974",
      label: "🇶🇦 +974",
    },
    {
      value: "+258",
      label: "🇲🇿 +258",
    },
  ];

  // Options on the Your magical skills language page
  const languageOptions = [
    {
      value: "aa",
      label: "Afar (aa)",
    },
    {
      value: "ab",
      label: "Abkhazian (ab)",
    },
    {
      value: "ae",
      label: "Avestan (ae)",
    },
    {
      value: "af",
      label: "Afrikaans (af)",
    },
    {
      value: "ak",
      label: "Akan (ak)",
    },
    {
      value: "am",
      label: "Amharic (am)",
    },
    {
      value: "an",
      label: "Aragonese (an)",
    },
    {
      value: "ar",
      label: "Arabic (ar)",
    },
    {
      value: "as",
      label: "Assamese (as)",
    },
    {
      value: "av",
      label: "Avaric (av)",
    },
    {
      value: "ay",
      label: "Aymara (ay)",
    },
    {
      value: "az",
      label: "Azerbaijani (az)",
    },
    {
      value: "ba",
      label: "Bashkir (ba)",
    },
    {
      value: "be",
      label: "Belarusian (be)",
    },
    {
      value: "bg",
      label: "Bulgarian (bg)",
    },
    {
      value: "bh",
      label: "Bihari languages (bh)",
    },
    {
      value: "bi",
      label: "Bislama (bi)",
    },
    {
      value: "bm",
      label: "Bambara (bm)",
    },
    {
      value: "bn",
      label: "Bengali (bn)",
    },
    {
      value: "bo",
      label: "Tibetan (bo)",
    },
    {
      value: "br",
      label: "Breton (br)",
    },
    {
      value: "bs",
      label: "Bosnian (bs)",
    },
    {
      value: "ca",
      label: "Catalan; Valencian (ca)",
    },
    {
      value: "ce",
      label: "Chechen (ce)",
    },
    {
      value: "ch",
      label: "Chamorro (ch)",
    },
    {
      value: "co",
      label: "Corsican (co)",
    },
    {
      value: "cr",
      label: "Cree (cr)",
    },
    {
      value: "cs",
      label: "Czech (cs)",
    },
    {
      value: "cu",
      label:
        "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic (cu)",
    },
    {
      value: "cv",
      label: "Chuvash (cv)",
    },
    {
      value: "cy",
      label: "Welsh (cy)",
    },
    {
      value: "da",
      label: "Danish (da)",
    },
    {
      value: "de",
      label: "German (de)",
    },
    {
      value: "dv",
      label: "Divehi; Dhivehi; Maldivian (dv)",
    },
    {
      value: "dz",
      label: "Dzongkha (dz)",
    },
    {
      value: "ee",
      label: "Ewe (ee)",
    },
    {
      value: "el",
      label: "Greek, Modern (1453-) (el)",
    },
    {
      value: "en",
      label: "English (en)",
    },
    {
      value: "eo",
      label: "Esperanto (eo)",
    },
    {
      value: "es",
      label: "Spanish; Castilian (es)",
    },
    {
      value: "et",
      label: "Estonian (et)",
    },
    {
      value: "eu",
      label: "Basque (eu)",
    },
    {
      value: "fa",
      label: "Persian (fa)",
    },
    {
      value: "ff",
      label: "Fulah (ff)",
    },
    {
      value: "fi",
      label: "Finnish (fi)",
    },
    {
      value: "fj",
      label: "Fijian (fj)",
    },
    {
      value: "fo",
      label: "Faroese (fo)",
    },
    {
      value: "fr",
      label: "French (fr)",
    },
    {
      value: "fy",
      label: "Western Frisian (fy)",
    },
    {
      value: "ga",
      label: "Irish (ga)",
    },
    {
      value: "gd",
      label: "Gaelic; Scomttish Gaelic (gd)",
    },
    {
      value: "gl",
      label: "Galician (gl)",
    },
    {
      value: "gn",
      label: "Guarani (gn)",
    },
    {
      value: "gu",
      label: "Gujarati (gu)",
    },
    {
      value: "gv",
      label: "Manx (gv)",
    },
    {
      value: "ha",
      label: "Hausa (ha)",
    },
    {
      value: "he",
      label: "Hebrew (he)",
    },
    {
      value: "hi",
      label: "Hindi (hi)",
    },
    {
      value: "ho",
      label: "Hiri Motu (ho)",
    },
    {
      value: "hr",
      label: "Croatian (hr)",
    },
    {
      value: "ht",
      label: "Haitian; Haitian Creole (ht)",
    },
    {
      value: "hu",
      label: "Hungarian (hu)",
    },
    {
      value: "hy",
      label: "Armenian (hy)",
    },
    {
      value: "hz",
      label: "Herero (hz)",
    },
    {
      value: "ia",
      label: "Interlingua (International Auxiliary Language Association) (ia)",
    },
    {
      value: "id",
      label: "Indonesian (id)",
    },
    {
      value: "ie",
      label: "Interlingue; Occidental (ie)",
    },
    {
      value: "ig",
      label: "Igbo (ig)",
    },
    {
      value: "ii",
      label: "Sichuan Yi; Nuosu (ii)",
    },
    {
      value: "ik",
      label: "Inupiaq (ik)",
    },
    {
      value: "io",
      label: "Ido (io)",
    },
    {
      value: "is",
      label: "Icelandic (is)",
    },
    {
      value: "it",
      label: "Italian (it)",
    },
    {
      value: "iu",
      label: "Inuktitut (iu)",
    },
    {
      value: "ja",
      label: "Japanese (ja)",
    },
    {
      value: "jv",
      label: "Javanese (jv)",
    },
    {
      value: "ka",
      label: "Georgian (ka)",
    },
    {
      value: "kg",
      label: "Kongo (kg)",
    },
    {
      value: "ki",
      label: "Kikuyu; Gikuyu (ki)",
    },
    {
      value: "kj",
      label: "Kuanyama; Kwanyama (kj)",
    },
    {
      value: "kk",
      label: "Kazakh (kk)",
    },
    {
      value: "kl",
      label: "Kalaallisut; Greenlandic (kl)",
    },
    {
      value: "km",
      label: "Central Khmer (km)",
    },
    {
      value: "kn",
      label: "Kannada (kn)",
    },
    {
      value: "ko",
      label: "Korean (ko)",
    },
    {
      value: "kr",
      label: "Kanuri (kr)",
    },
    {
      value: "ks",
      label: "Kashmiri (ks)",
    },
    {
      value: "ku",
      label: "Kurdish (ku)",
    },
    {
      value: "kv",
      label: "Komi (kv)",
    },
    {
      value: "kw",
      label: "Cornish (kw)",
    },
    {
      value: "ky",
      label: "Kirghiz; Kyrgyz (ky)",
    },
    {
      value: "la",
      label: "Latin (la)",
    },
    {
      value: "lb",
      label: "Luxembourgish; Letzeburgesch (lb)",
    },
    {
      value: "lg",
      label: "Ganda (lg)",
    },
    {
      value: "li",
      label: "Limburgan; Limburger; Limburgish (li)",
    },
    {
      value: "ln",
      label: "Lingala (ln)",
    },
    {
      value: "lo",
      label: "Lao (lo)",
    },
    {
      value: "lt",
      label: "Lithuanian (lt)",
    },
    {
      value: "lu",
      label: "Luba-Katanga (lu)",
    },
    {
      value: "lv",
      label: "Latvian (lv)",
    },
    {
      value: "mg",
      label: "Malagasy (mg)",
    },
    {
      value: "mh",
      label: "Marshallese (mh)",
    },
    {
      value: "mi",
      label: "Maori (mi)",
    },
    {
      value: "mk",
      label: "Macedonian (mk)",
    },
    {
      value: "ml",
      label: "Malayalam (ml)",
    },
    {
      value: "mn",
      label: "Mongolian (mn)",
    },
    {
      value: "mr",
      label: "Marathi (mr)",
    },
    {
      value: "ms",
      label: "Malay (ms)",
    },
    {
      value: "mt",
      label: "Maltese (mt)",
    },
    {
      value: "my",
      label: "Burmese (my)",
    },
    {
      value: "na",
      label: "Nauru (na)",
    },
    {
      value: "nb",
      label: "Bokmål, Norwegian; Norwegian Bokmål (nb)",
    },
    {
      value: "nd",
      label: "Ndebele, North; North Ndebele (nd)",
    },
    {
      value: "ne",
      label: "Nepali (ne)",
    },
    {
      value: "ng",
      label: "Ndonga (ng)",
    },
    {
      value: "nl",
      label: "Dutch; Flemish (nl)",
    },
    {
      value: "nn",
      label: "Norwegian Nynorsk; Nynorsk, Norwegian (nn)",
    },
    {
      value: "no",
      label: "Norwegian (no)",
    },
    {
      value: "nr",
      label: "Ndebele, South; South Ndebele (nr)",
    },
    {
      value: "nv",
      label: "Navajo; Navaho (nv)",
    },
    {
      value: "ny",
      label: "Chichewa; Chewa; Nyanja (ny)",
    },
    {
      value: "oc",
      label: "Occitan (post 1500) (oc)",
    },
    {
      value: "oj",
      label: "Ojibwa (oj)",
    },
    {
      value: "om",
      label: "Oromo (om)",
    },
    {
      value: "or",
      label: "Oriya (or)",
    },
    {
      value: "os",
      label: "Ossetian; Ossetic (os)",
    },
    {
      value: "pa",
      label: "Panjabi; Punjabi (pa)",
    },
    {
      value: "pi",
      label: "Pali (pi)",
    },
    {
      value: "pl",
      label: "Polish (pl)",
    },
    {
      value: "ps",
      label: "Pushto; Pashto (ps)",
    },
    {
      value: "pt",
      label: "Portuguese (pt)",
    },
    {
      value: "qu",
      label: "Quechua (qu)",
    },
    {
      value: "rm",
      label: "Romansh (rm)",
    },
    {
      value: "rn",
      label: "Rundi (rn)",
    },
    {
      value: "ro",
      label: "Romanian; Moldavian; Moldovan (ro)",
    },
    {
      value: "ru",
      label: "Russian (ru)",
    },
    {
      value: "rw",
      label: "Kinyarwanda (rw)",
    },
    {
      value: "sa",
      label: "Sanskrit (sa)",
    },
    {
      value: "sc",
      label: "Sardinian (sc)",
    },
    {
      value: "sd",
      label: "Sindhi (sd)",
    },
    {
      value: "se",
      label: "Northern Sami (se)",
    },
    {
      value: "sg",
      label: "Sango (sg)",
    },
    {
      value: "si",
      label: "Sinhala; Sinhalese (si)",
    },
    {
      value: "sk",
      label: "Slovak (sk)",
    },
    {
      value: "sl",
      label: "Slovenian (sl)",
    },
    {
      value: "sm",
      label: "Samoan (sm)",
    },
    {
      value: "sn",
      label: "Shona (sn)",
    },
    {
      value: "so",
      label: "Somali (so)",
    },
    {
      value: "sq",
      label: "Albanian (sq)",
    },
    {
      value: "sr",
      label: "Serbian (sr)",
    },
    {
      value: "ss",
      label: "Swati (ss)",
    },
    {
      value: "st",
      label: "Sotho, Southern (st)",
    },
    {
      value: "su",
      label: "Sundanese (su)",
    },
    {
      value: "sv",
      label: "Swedish (sv)",
    },
    {
      value: "sw",
      label: "Swahili (sw)",
    },
    {
      value: "ta",
      label: "Tamil (ta)",
    },
    {
      value: "te",
      label: "Telugu (te)",
    },
    {
      value: "tg",
      label: "Tajik (tg)",
    },
    {
      value: "th",
      label: "Thai (th)",
    },
    {
      value: "ti",
      label: "Tigrinya (ti)",
    },
    {
      value: "tk",
      label: "Turkmen (tk)",
    },
    {
      value: "tl",
      label: "Tagalog (tl)",
    },
    {
      value: "tn",
      label: "Tswana (tn)",
    },
    {
      value: "to",
      label: "Tonga (Tonga Islands) (to)",
    },
    {
      value: "tr",
      label: "Turkish (tr)",
    },
    {
      value: "ts",
      label: "Tsonga (ts)",
    },
    {
      value: "tt",
      label: "Tatar (tt)",
    },
    {
      value: "tw",
      label: "Twi (tw)",
    },
    {
      value: "ty",
      label: "Tahitian (ty)",
    },
    {
      value: "ug",
      label: "Uighur; Uyghur (ug)",
    },
    {
      value: "uk",
      label: "Ukrainian (uk)",
    },
    {
      value: "ur",
      label: "Urdu (ur)",
    },
    {
      value: "uz",
      label: "Uzbek (uz)",
    },
    {
      value: "ve",
      label: "Venda (ve)",
    },
    {
      value: "vi",
      label: "Vietnamese (vi)",
    },
    {
      value: "vo",
      label: "Volapük (vo)",
    },
    {
      value: "wa",
      label: "Walloon (wa)",
    },
    {
      value: "wo",
      label: "Wolof (wo)",
    },
    {
      value: "xh",
      label: "Xhosa (xh)",
    },
    {
      value: "yi",
      label: "Yiddish (yi)",
    },
    {
      value: "yo",
      label: "Yoruba (yo)",
    },
    {
      value: "za",
      label: "Zhuang; Chuang (za)",
    },
    {
      value: "zh",
      label: "Chinese (zh)",
    },
    {
      value: "zu",
      label: "Zulu (zu)",
    },
  ];

  const levelOptions = [
    { label: "Beginner (A1)", value: "Beginner" },
    { label: "Intermediate (A2 - B1)", value: "Intermediate" },
    { label: "Advanced (B2 - C1)", value: "Advanced" },
    { label: "Fluent (C2)", value: "Fluent" },
  ];

  const onSelect = async (option, menuType) => {
    switch (menuType) {
      case "nationality":
        setNationality(option);
        break;
      case "phoneCountry":
        setPhoneCountry(option);
        break;
      case "companyPhoneCountry":
        setCompanyPhoneCountry(option);
        break;
      case "industryTags":
        setSelectedIndustryTags(option);
        break;
      case "projectTags":
        setSelectedProjectTags(option);
        break;
      case "skillTags":
        setSelectedSkillTags(option);
        break;
      case "toolTags":
        setSelectedToolTags(option);
        break;
      case "addressCountry":
        setCountry(option);
        setCityOptions(countryCitiesMap[option.value]);
        setCity(null);
        /*if (postCodeErrMsg !== "") {
          setPostcodeErrMsg("");
          setValidPostcode(
            await validateLocation(
              "postal_code",
              postcode,
              option.value
                ? `&components=${"country:" + option.countryCode}`
                : "",
            ),
          );
        }*/
        break;
      case "addressCity":
        setCity(option);
        break;
      case "addressCompanyCountry":
        setCompanyCountry(option);
        setCityCompanyOptions(countryCitiesMap[option.value]);
        setCompanyCity(null);
        /*if (postCodeErrMsg !== "") {
            setPostcodeErrMsg("");
            setValidPostcode(
              await validateLocation(
                "postal_code",
                postcode,
                option.value
                  ? `&components=${"country:" + option.countryCode}`
                  : "",
              ),
            );
          }*/
        break;
      case "addressCompanyCity":
        setCompanyCity(option);
        break;
      case "bankCountry":
        setBankCountry(option);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (password.length < 6 || password !== confirmPassword) {
      setValidPassword(false);
      setPasswordErrMsg(
        password.length === 0
          ? ""
          : password.length < 6
            ? "Password must be 6 characters or longer"
            : "Passwords do not match",
      );
    } else {
      setValidPassword(true);
      setPasswordErrMsg("");
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setValidEmail(emailPattern.test(email));
  }, [email]);

  useEffect(() => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setValidContactEmail(emailPattern.test(contactEmail));
  }, [contactEmail]);

  const handleCompanyContactEmailChange = (value) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setCompanyContactEmail(value);
    setValidCompanyContactEmail(emailPattern.test(value));
  };

  const handleCompanyPhoneChange = (value) => {
    const phonePattern =
      /^\s*?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3,5})(?: *x(\d+))?\s*$/;
    setCompanyPhone(value);
    setValidCompanyPhone(phonePattern.test(value));
  };

  useEffect(() => {
    const phonePattern =
      /^\s*?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3,5})(?: *x(\d+))?\s*$/;
    setValidPhone(phonePattern.test(phone));
  }, [phone]);

  useEffect(() => {
    const vatNumPattern = /^[A-Z]{2}\d{8,12}/;
    setVatValid(vatNumPattern.test(vatNum));
  }, [vatNum]);

  useEffect(() => {
    const websitePattern =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    setWebsiteValid(websitePattern.test(website));
  }, [website]);

  const [languageDropdowns, setLanguageDropdowns] = useState([
    {
      options: languageOptions,
      value: null,
      placeholder: "Language",
      isOpen: false,
    },
    {
      options: levelOptions,
      value: null,
      placeholder: "Level",
      isOpen: false,
    },
  ]);

  // Check if languages are valid
  useEffect(() => {
    const isAnyPairFilled = languageDropdowns.some((_, index) => {
      if (index % 2 === 0) {
        // check only for even indices
        const pair = languageDropdowns.slice(index, index + 2);
        return pair.every((dropdown) => dropdown.value !== null);
      }
      return false;
    });

    setLanguagesValid(isAnyPairFilled);
  }, [languageDropdowns]);

  const modifyDropdowns = (num, property, newValue, setDropdowns) => {
    setDropdowns((prevDropdowns) => {
      const updatedDropdowns = [...prevDropdowns];
      updatedDropdowns[num] = {
        ...updatedDropdowns[num],
        [property]: newValue,
      };
      return updatedDropdowns;
    });
  };

  const addLanguageDropdowns = () => {
    setLanguageDropdowns((prevDropdowns) => {
      const newDropdown1 = {
        options: languageOptions,
        value: null,
        placeholder: "Language",
        isOpen: false,
      };
      const newDropdown2 = {
        options: levelOptions,
        value: null,
        placeholder: "Level",
        isOpen: false,
      };
      return [...prevDropdowns, newDropdown1, newDropdown2];
    });
  };

  const checkCircle = (props) => (
    <components.Option {...props}>
      {props.isSelected ? (
        <MdCheckCircle size={24} color="var(--main-blue)" />
      ) : (
        <GiCircle size={24} color="var(--main-blue)" />
      )}{" "}
      <h5
        style={{
          marginBottom: "0",
          marginLeft: "10px",
        }}
      >
        {props.data.label}
      </h5>
    </components.Option>
  );

  const CustomMultiValueIndustryTags = ({ data }) => {
    const additionalSelected = selectedIndustryTags.length - 2;
    return (
      selectedIndustryTags[0].value === data.value && (
        <>
          {selectedIndustryTags.slice(0, 2).map((option, index) => (
            <div key={option.value} className="industry-tag-chip">
              <span>
                {option.label}
                {index < selectedIndustryTags.length - 1 ? " " : null}
              </span>
            </div>
          ))}
          {selectedIndustryTags.length > 2 ? (
            <div className="industry-tag-chip">{`+ ${additionalSelected}`}</div>
          ) : null}
        </>
      )
    );
  };

  const CustomMultiValueSkillTags = ({ data }) => {
    const additionalSelected = selectedSkillTags.length - 2;
    return (
      selectedSkillTags[0].value === data.value && (
        <>
          {selectedSkillTags.slice(0, 2).map((option, index) => (
            <div key={option.value} className="industry-tag-chip">
              <span>
                {option.label}
                {index < selectedSkillTags.length - 1 ? " " : null}
              </span>
            </div>
          ))}
          {selectedSkillTags.length > 2 ? (
            <div className="industry-tag-chip">{`+ ${additionalSelected}`}</div>
          ) : null}
        </>
      )
    );
  };

  const CustomMultiValueProjectTags = ({ data }) => {
    const additionalSelected = selectedProjectTags.length - 2;
    return (
      selectedProjectTags[0].value === data.value && (
        <>
          {selectedProjectTags.slice(0, 2).map((option, index) => (
            <div key={option.value} className="industry-tag-chip">
              <span>
                {option.label}
                {index < selectedProjectTags.length - 1 ? " " : null}
              </span>
            </div>
          ))}
          {selectedProjectTags.length > 2 ? (
            <div className="industry-tag-chip">{`+ ${additionalSelected}`}</div>
          ) : null}
        </>
      )
    );
  };

  const CustomMultiValueToolTags = ({ data }) => {
    const additionalSelected = selectedToolTags.length - 2;
    return (
      selectedToolTags[0].value === data.value && (
        <>
          {selectedToolTags.slice(0, 2).map((option, index) => (
            <div key={option.value} className="industry-tag-chip">
              <span>
                {option.label}
                {index < selectedToolTags.length - 1 ? " " : null}
              </span>
            </div>
          ))}
          {selectedToolTags.length > 2 ? (
            <div className="industry-tag-chip">{`+ ${additionalSelected}`}</div>
          ) : null}
        </>
      )
    );
  };

  const getStateData = () => {
    const languages = [];

    for (let i = 0; i < languageDropdowns.length; i += 2) {
      if (languageDropdowns[i].value && languageDropdowns[i + 1].value) {
        languages.push({
          language: languageDropdowns[i].value.value,
          level: languageDropdowns[i + 1].value.value,
        });
      }
    }

    const stateData = {
      awaitingApproval: true,
      role: "designer",
      contactEmail,
      linkedin: linkedinUrl,
      email: isSSO ? auth.currentUser.email : email,
      phone: {
        phoneNumber: phone,
        phoneCountry: phoneCountry ? phoneCountry.value : "",
      },
      postalAddress: {
        country: country.value,
        city: city.value,
        postcode,
        address,
      },
      personData: {
        lastName: name,
        firstName,
        nationality: nationality ? nationality.value : "",
      },
      languages,
      projectTags: selectedProjectTags?.map((tag) => tag.value) ?? [],
      industryTags: selectedIndustryTags?.map((tag) => tag.value) ?? [],
      skillTags: selectedSkillTags?.map((tag) => tag.value) ?? [],
      toolTags: selectedToolTags?.map((tag) => tag.value) ?? [],
      portfolio: portfolio,
      presentation: isFaceToFaceChecked ? "" : presentationURL,
      billingInfo: {
        bankCountry: bankCountry.value,
        iban,
      },
    };

    if (applyingAs === "company") {
      stateData.companyData = {
        country: companyCountry.value,
        city: companyCity.value,
        name: companyName,
        address: companyAddress,
        postcode: companyPostCode,
        contactEmail: companyContactEmail,
        phone: {
          phoneNumber: companyPhone,
          phoneCountry: companyPhoneCountry ? companyPhoneCountry.value : "",
        },
        vatNum,
        website,
      };
    }
    return stateData;
  };

  useEffect(() => {
    const industryTags = getIndustryTags();
    const dropdownOptions = [];
    for (let key in industryTags) {
      dropdownOptions.push({
        value: key,
        label: (
          <>
            {industryTags[key].icon} {key}
          </>
        ),
      });
    }
    setIndustryTagsOptions(dropdownOptions.slice(1));
  }, []);

  const [isSSO, setIsSSO] = useState(false);

  const createUserInFirestore = () => {
    return new Promise(async (resolve, reject) => {
      let uid;
      try {
        uid = isSSO
          ? auth.currentUser.uid
          : (await createUserWithEmailAndPassword(auth, email, password)).user
              .uid;
      } catch (e) {
        setEmailError("Email is already in use");
        reject(e);
        return;
      }

      const newDocRef = doc(collection(db, "users"), uid);
      const stateData = getStateData();

      try {
        await setDoc(newDocRef, stateData);
        resolve(newDocRef);
      } catch (e) {
        reject(e);
      }
    });
  };

  const createImageSelector =
    (setImage, setPreview = null) =>
    (e) => {
      if (!e.target.files || e.target.files.length === 0) {
        setImage(undefined);
        return;
      }

      setImage(e.target.files[0]);
      if (setPreview) setPreview(URL.createObjectURL(e.target.files[0]));
    };

  const [selectedPfp, setSelectedPfp] = useState();
  const [selectedPfpPreview, setSelectedPfpPreview] = useState();

  const [selectedIdFront, setSelectedIdFront] = useState();
  const [selectedIdBack, setSelectedIdBack] = useState();
  const [selectedProofOfResidency, setSelectedProofOfResidency] = useState();

  const onSelectPfp = createImageSelector(
    setSelectedPfp,
    setSelectedPfpPreview,
  );
  const onSelectIdFront = createImageSelector(setSelectedIdFront);
  const onSelectIdBack = createImageSelector(setSelectedIdBack);
  const onSelectProofOfResidency = createImageSelector(
    setSelectedProofOfResidency,
  );

  const sendInfoEmail = async () => {
    const stateData = getStateData();
    stateData.presentation =
      stateData.presentation == ""
        ? "I want to present face to face"
        : stateData.presentation;
    let prettyContent = JSON.stringify(stateData, null, 2);

    const newDocRef = doc(collection(db, "mail"));
    await setDoc(newDocRef, {
      to: ["hello@digitalrise.be"],
      message: {
        subject: "New designer onboarded!",
        text: "New designer",
        html: `<pre>${prettyContent}</pre>`,
      },
    });
  };

  const createDesigner = async () => {
    let newDocRef;
    try {
      newDocRef = await createUserInFirestore();
    } catch (e) {
      setAwaitingCreation(false);
      return false;
    }
    const files = [
      selectedPfp,
      //  selectedProofOfResidency,
      //  selectedIdFront,
      //  ...(idForm === "passport" ? [] : [selectedIdBack]),
    ];
    const uploadPromises = files.map((file) =>
      uploadFileToFirestore("users/" + auth.currentUser.uid, file),
    );
    const [
      selectedPfpLink,
      //  selectedProofOfResidencyLink,
      //   selectedIdFrontLink,
      ...restLinks
    ] = await Promise.all(uploadPromises);
    await updateDoc(newDocRef, {
      pfp: selectedPfpLink,
      /*  idDocuments: {
        idFront: selectedIdFrontLink,
        idBack: idForm === "passport" ? "" : restLinks[0],
        proofOfResidency: selectedProofOfResidencyLink,
      },*/
    });
    setCongratsPfpDisplay(selectedPfpLink);
    //sendInfoEmail();
    return true;
  };

  useEffect(() => {
    setValidPostcode(postcode !== "" && /^[A-Za-z0-9 -]{3,10}$/.test(postcode));
  }, [postcode]);

  useEffect(() => {
    setCreationValid(
      [
        ibanValid,
        bankCountry,
        (validEmail && validPassword) || isSSO,
        selectedPfp,
        !awaitingCreation,
      ].every((field) => field),
    );
  }, [
    ibanValid,
    validPassword,
    bankCountry,
    isSSO,
    validEmail,
    selectedPfp,
    awaitingCreation,
  ]);

  const customHeaders = [
    { stepName: "Start here", index: 0 },
    { stepName: "Identity / Linkedin", index: 1 },
    { stepName: "Your magical skills", index: 2 },
    { stepName: "Bank & Profile info", index: 3 },
  ];

  const carouselElements = [
    {
      title: "Pass the test",
      stepName: "Start here",
      duration: 15,
      content: (
        <>
          <div className="txt-b fw-500 fs-18 mb-3">
            Because the quality is our best asset, take the online test to see
            if you master what we need as UX/UI Designer.
          </div>
          <div className="txt-g fw-500 fs-16 mb-5">
            As UX/UI Designer you will need to master knoweldges in Google
            Analytics, Hotjar (or other behavorial tools), best practices, web
            ergonomy, user research and design. Sure you are ready ?
          </div>

          <div className="w-100 d-flex justify-content-end my-4">
            <button
              type="button"
              onClick={() => setIndex(index + 1)}
              className="btn-primary d-flex align-items-center"
            >
              Start the test <FiArrowRight className="ms-1" />
            </button>
          </div>
        </>
      ),
    },
    {
      title: "Tell us who you are", // Company/Individual
      stepName: "",
      duration: 2,
      content: (
        <div className="no-margins-or-padding">
          <div className="row ms-1 mt-4 mb-4 fw-500 fs-18">
            I'm applying as...
          </div>
          <div>
            <div>
              <input
                type="radio"
                id="company"
                name="applyingAs"
                value="company"
                checked={applyingAs === "company"}
                onChange={(e) => setApplyingAs(e.target.value)}
              />
              <label htmlFor="company" className="ms-3 label-hover">
                A company. I have a VAT number.
              </label>
            </div>
            <div className="mt-3">
              <input
                type="radio"
                id="individual"
                name="applyingAs"
                value="individual"
                checked={applyingAs === "individual"}
                onChange={(e) => setApplyingAs(e.target.value)}
              />
              <label htmlFor="individual" className="ms-3 label-hover">
                A person. I don’t have VAT number.
              </label>
            </div>
          </div>
          {applyingAs === "individual" && (
            <>
              <div className="row ms-1 my-3">
                <div className="col half-width">
                  <CustomInput
                    placeholder="Name"
                    containerClassName="observation-text-input-container half-width"
                    inputClassNames="half-width"
                    className={name === "" ? "active" : ""}
                    valueState={name}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  <div style={{ position: "relative" }}>
                    {!/^\s*$/.test(name) && (
                      <img
                        alt="✓"
                        src={check}
                        className="check check-half-width-offset"
                      />
                    )}
                  </div>
                </div>
                <div className="col half-width">
                  <CustomInput
                    placeholder="Firstname"
                    containerClassName="observation-text-input-container half-width"
                    inputClassNames="half-width"
                    className={firstName === "" ? "active" : ""}
                    valueState={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                  <div style={{ position: "relative" }}>
                    {!/^\s*$/.test(firstName) && (
                      <img
                        alt="✓"
                        src={check}
                        className="check check-half-width-offset"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="row ms-1 mb-3"
                style={{
                  paddingTop: "10px",
                }}
              >
                <div className="col half-width mt--10px">
                  <CustomDropdown
                    options={countryOptions}
                    onChange={(option) => onSelect(option, "addressCountry")}
                    value={country}
                    placeholder="Country"
                    unstyled
                    menuOpenState={isCountryOpen}
                    setMenuOpenState={setIsCountryOpen}
                  />
                  <div className="password-mismatch-text-on">
                    {countryErrMsg}
                  </div>
                </div>
                <div className="col half-width mt--10px">
                  <CustomDropdown
                    options={cityOptions}
                    onChange={(option) => onSelect(option, "addressCity")}
                    value={city}
                    placeholder="City"
                    unstyled
                    menuOpenState={isCityOpen}
                    setMenuOpenState={setIsCityOpen}
                  />
                  <div className="password-mismatch-text-on">{cityErrMsg}</div>
                </div>
              </div>
              <div
                className="row ms-1"
                style={{
                  marginBottom: "10px",
                }}
              >
                <CustomDropdown
                  options={nationalityOptions}
                  onChange={(option) => onSelect(option, "nationality")}
                  value={nationality}
                  placeholder="Nationality"
                  unstyled
                  menuOpenState={isNationalityOpen}
                  setMenuOpenState={setIsNationalityOpen}
                />
              </div>
              <div className="row ms-1 mb-1 mt--8px">
                <CustomInput
                  placeholder={"Address"}
                  containerClassName="observation-text-input-container full-width"
                  className={address === "" ? "active" : ""}
                  valueState={address}
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  //onBlur={() => autofillPostcode(address)}
                />
                <div style={{ position: "relative" }}>
                  {
                    //validAddress
                    address && <img alt="✓" src={check} className="check" />
                  }
                </div>
                <div className="password-mismatch-text-on">{addressErrMsg}</div>
              </div>
              <div className="row ms-1 mb-1">
                <CustomInput
                  placeholder="Postcode"
                  containerClassName="observation-text-input-container full-width"
                  className={postcode === "" ? "active" : ""}
                  valueState={postcode}
                  onChange={(e) => setPostcode(e.target.value)}
                  value={postcode}
                  /* onBlur={() =>
                /^[A-Za-z0-9 -]{3,10}$/.test(postcode)
                  ? autofillPostcode(postcode)
                  : null
              }*/
                />
                <div style={{ position: "relative" }}>
                  {validPostcode && (
                    <img alt="✓" src={check} className="check" />
                  )}
                </div>
                <div className="password-mismatch-text-on">
                  {postCodeErrMsg}
                </div>
              </div>
              <div className="row ms-1 ms-1 mb-3">
                <CustomInput
                  placeholder={"Professional email address"}
                  containerClassName="observation-text-input-container full-width"
                  className={contactEmail === "" ? "active" : ""}
                  valueState={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  value={contactEmail}
                />
                <div style={{ position: "relative" }}>
                  {validContactEmail && (
                    <img alt="✓" src={check} className="check" />
                  )}
                </div>
                <div className="password-mismatch-text-on">
                  {!validContactEmail && contactEmail !== "" && "Invalid Email"}
                </div>
              </div>
              <div className="row ms-1 mt-2">
                <div
                  className={`col w-122px mb-3
            ${
              phone === ""
                ? "phone-country-dynamic-box-expanded"
                : "phone-country-dynamic-box"
            }`}
                >
                  <CustomDropdown
                    options={phoneCountryOptions.map((option) => {
                      return {
                        label: (
                          <Twemoji options={{ className: "twemoji" }}>
                            {option.label}
                          </Twemoji>
                        ),
                        value: option.value,
                      };
                    })}
                    onChange={(option) => onSelect(option, "phoneCountry")}
                    value={phoneCountry}
                    placeholder="Country"
                    unstyled
                    menuOpenState={isPhoneCountryOpen}
                    setMenuOpenState={setIsPhoneCountryOpen}
                  />
                </div>
                <div className="col mt--8px mb-3 input-365px">
                  <CustomInput
                    placeholder={"Mobile phone number"}
                    containerClassName="observation-text-input-container input-365px"
                    inputClassNames="input-365px"
                    className={phone === "" ? "active" : ""}
                    valueState={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                  <div style={{ position: "relative" }}>
                    {validPhone && (
                      <img alt="✓" src={check} className="check check-phone" />
                    )}
                  </div>
                  <div className="password-mismatch-text-on">
                    {!validPhone && phone !== "" && "Invalid Phone Number"}
                  </div>
                </div>
              </div>
            </>
          )}
          {applyingAs === "company" && (
            <>
              <div className="row ms-1 mt-3 mb-2">
                <div className="col half-width" style={{ marginTop: "10px" }}>
                  <CustomDropdown
                    options={countryOptions}
                    onChange={(option) =>
                      onSelect(option, "addressCompanyCountry")
                    }
                    value={companyCountry}
                    placeholder="Country"
                    unstyled
                    menuOpenState={isCountryOpen}
                    setMenuOpenState={setIsCountryOpen}
                  />
                  <div className="password-mismatch-text-on">
                    {countryErrMsg}
                  </div>
                </div>
                <div className="col half-width">
                  <CustomInput
                    placeholder="VAT number"
                    containerClassName="observation-text-input-container half-width"
                    inputClassNames="half-width"
                    className={vatNum === "" ? "active" : ""}
                    valueState={vatNum}
                    onChange={(e) => setVatNum(e.target.value)}
                    value={vatNum}
                  />
                  <div style={{ position: "relative" }}>
                    {
                      //vatValid
                      vatNum && (
                        <img
                          alt="✓"
                          src={check}
                          className="check check-half-width-offset"
                        />
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="row ms-1 mb-3">
                <div className="col half-width">
                  <CustomInput
                    placeholder="Company Legal Name"
                    containerClassName="observation-text-input-container half-width"
                    inputClassNames="half-width"
                    className={companyName === "" ? "active" : ""}
                    valueState={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                  />
                  <div style={{ position: "relative" }}>
                    {companyName && (
                      <img
                        alt="✓"
                        src={check}
                        className="check check-half-width-offset"
                      />
                    )}
                  </div>
                </div>
                <div className="col half-width" style={{ marginTop: "10px" }}>
                  <CustomDropdown
                    options={cityCompanyOptions}
                    onChange={(option) =>
                      onSelect(option, "addressCompanyCity")
                    }
                    value={companyCity}
                    placeholder="City"
                    unstyled
                    menuOpenState={isCityOpen}
                    setMenuOpenState={setIsCityOpen}
                  />
                  <div className="password-mismatch-text-on">{cityErrMsg}</div>
                </div>
              </div>
              <div className="row ms-1 mb-1 mt--8px">
                <CustomInput
                  placeholder={"Company address"}
                  containerClassName="observation-text-input-container full-width"
                  className={companyAddress === "" ? "active" : ""}
                  valueState={companyAddress}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                  value={companyAddress}
                />
                <div style={{ position: "relative" }}>
                  {companyAddress && (
                    <img alt="✓" src={check} className="check" />
                  )}
                </div>
                <div className="password-mismatch-text-on">{addressErrMsg}</div>
              </div>
              <div className="row ms-1 mb-1">
                <CustomInput
                  placeholder="Postcode"
                  containerClassName="observation-text-input-container full-width"
                  className={companyPostCode === "" ? "active" : ""}
                  valueState={companyPostCode}
                  onChange={(e) => setCompanyPostCode(e.target.value)}
                  value={companyPostCode}
                />
                <div style={{ position: "relative" }}>
                  {companyPostCode && (
                    <img alt="✓" src={check} className="check" />
                  )}
                </div>
                <div className="password-mismatch-text-on">
                  {postCodeErrMsg}
                </div>
              </div>
              <div className="row ms-1 ms-1 mb-3">
                <CustomInput
                  placeholder={"Company email address"}
                  containerClassName="observation-text-input-container full-width"
                  className={companyContactEmail === "" ? "active" : ""}
                  valueState={companyContactEmail}
                  onChange={(e) =>
                    handleCompanyContactEmailChange(e.target.value)
                  }
                  value={companyContactEmail}
                />
                <div style={{ position: "relative" }}>
                  {validCompanyContactEmail && (
                    <img alt="✓" src={check} className="check" />
                  )}
                </div>
                <div className="password-mismatch-text-on">
                  {!validCompanyContactEmail &&
                    companyContactEmail !== "" &&
                    "Invalid Email"}
                </div>
              </div>
              <div className="row ms-1 mt-2">
                <div
                  className={`col w-122px mb-3
            ${
              phone === ""
                ? "phone-country-dynamic-box-expanded"
                : "phone-country-dynamic-box"
            }`}
                >
                  <CustomDropdown
                    options={phoneCountryOptions.map((option) => {
                      return {
                        label: (
                          <Twemoji options={{ className: "twemoji" }}>
                            {option.label}
                          </Twemoji>
                        ),
                        value: option.value,
                      };
                    })}
                    onChange={(option) =>
                      onSelect(option, "companyPhoneCountry")
                    }
                    value={companyPhoneCountry}
                    placeholder="Country"
                    unstyled
                    menuOpenState={isPhoneCountryOpen}
                    setMenuOpenState={setIsPhoneCountryOpen}
                  />
                </div>
                <div className="col mt--8px mb-3 input-365px">
                  <CustomInput
                    placeholder={"Phone number"}
                    containerClassName="observation-text-input-container input-365px"
                    inputClassNames="input-365px"
                    className={companyPhone === "" ? "active" : ""}
                    valueState={companyPhone}
                    onChange={(e) => handleCompanyPhoneChange(e.target.value)}
                    value={companyPhone}
                  />
                  <div style={{ position: "relative" }}>
                    {validCompanyPhone && (
                      <img alt="✓" src={check} className="check check-phone" />
                    )}
                  </div>
                  <div className="password-mismatch-text-on">
                    {!validCompanyPhone &&
                      companyPhone !== "" &&
                      "Invalid Phone Number"}
                  </div>
                </div>
              </div>
              <div className="row ms-1 mt--8px ms-1 mb-5">
                <CustomInput
                  placeholder="Website"
                  containerClassName="observation-text-input-container full-width"
                  className={website === "" ? "active" : ""}
                  valueState={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  value={website}
                />
                <div style={{ position: "relative" }}>
                  {websiteValid && (
                    <img alt="✓" src={check} className="check" />
                  )}
                </div>
                <div className="password-mismatch-text-on">
                  {!websiteValid &&
                    website !== "" &&
                    "Enter website in the format https://www.luxifer.app"}
                </div>
              </div>
            </>
          )}

          <div className="w-100 d-flex justify-content-end my-4">
            <div className="me-3">
              <button
                type="button"
                className="btn-secondary"
                onClick={() => setIndex(index - 1)}
              >
                Back
              </button>
            </div>
            <button
              type="button"
              onClick={
                () =>
                  applyingAs === "company"
                    ? setIndex(index + 1)
                    : setIndex(index + 2)

                //validateCompanyOrPerson()
              }
              disabled={!companyPersonFormValid}
              className="btn-primary d-flex align-items-center"
            >
              Continue <FiArrowRight className="ms-1" />
            </button>
          </div>
        </div>
      ),
    },
    {
      title: "Tell us who you are", // Individual
      stepName: "",
      duration: 2,
      content: (
        <div className="no-margins-or-padding">
          <div className="row ms-1 mt-4 mb-4 fw-500 fs-18">
            Identify yourself.
          </div>
          <>
            <div className="row ms-1 my-3">
              <div className="col half-width">
                <CustomInput
                  placeholder="Name"
                  containerClassName="observation-text-input-container half-width"
                  inputClassNames="half-width"
                  className={name === "" ? "active" : ""}
                  valueState={name}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <div style={{ position: "relative" }}>
                  {!/^\s*$/.test(name) && (
                    <img
                      alt="✓"
                      src={check}
                      className="check check-half-width-offset"
                    />
                  )}
                </div>
              </div>
              <div className="col half-width">
                <CustomInput
                  placeholder="Firstname"
                  containerClassName="observation-text-input-container half-width"
                  inputClassNames="half-width"
                  className={firstName === "" ? "active" : ""}
                  valueState={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
                <div style={{ position: "relative" }}>
                  {!/^\s*$/.test(firstName) && (
                    <img
                      alt="✓"
                      src={check}
                      className="check check-half-width-offset"
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="row ms-1 mb-3"
              style={{
                paddingTop: "10px",
              }}
            >
              <div className="col half-width mt--10px">
                <CustomDropdown
                  options={countryOptions}
                  onChange={(option) => onSelect(option, "addressCountry")}
                  value={country}
                  placeholder="Country"
                  unstyled
                  menuOpenState={isCountryOpen}
                  setMenuOpenState={setIsCountryOpen}
                />
                <div className="password-mismatch-text-on">{countryErrMsg}</div>
              </div>
              <div className="col half-width mt--10px">
                <CustomDropdown
                  options={cityOptions}
                  onChange={(option) => onSelect(option, "addressCity")}
                  value={city}
                  placeholder="City"
                  unstyled
                  menuOpenState={isCityOpen}
                  setMenuOpenState={setIsCityOpen}
                />
                <div className="password-mismatch-text-on">{cityErrMsg}</div>
              </div>
            </div>
            <div
              className="row ms-1"
              style={{
                marginBottom: "10px",
              }}
            >
              <CustomDropdown
                options={nationalityOptions}
                onChange={(option) => onSelect(option, "nationality")}
                value={nationality}
                placeholder="Nationality"
                unstyled
                menuOpenState={isNationalityOpen}
                setMenuOpenState={setIsNationalityOpen}
              />
            </div>
          </>

          <div className="row ms-1 mb-1 mt--8px">
            <CustomInput
              placeholder={"Address"}
              containerClassName="observation-text-input-container full-width"
              className={address === "" ? "active" : ""}
              valueState={address}
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              //onBlur={() => autofillPostcode(address)}
            />
            <div style={{ position: "relative" }}>
              {
                //validAddress
                address && <img alt="✓" src={check} className="check" />
              }
            </div>
            <div className="password-mismatch-text-on">{addressErrMsg}</div>
          </div>
          <div className="row ms-1 mb-1">
            <CustomInput
              placeholder="Postcode"
              containerClassName="observation-text-input-container full-width"
              className={postcode === "" ? "active" : ""}
              valueState={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              value={postcode}
              /* onBlur={() =>
                /^[A-Za-z0-9 -]{3,10}$/.test(postcode)
                  ? autofillPostcode(postcode)
                  : null
              }*/
            />
            <div style={{ position: "relative" }}>
              {validPostcode && <img alt="✓" src={check} className="check" />}
            </div>
            <div className="password-mismatch-text-on">{postCodeErrMsg}</div>
          </div>
          <div className="row ms-1 ms-1 mb-3">
            <CustomInput
              placeholder={"Professional email address"}
              containerClassName="observation-text-input-container full-width"
              className={contactEmail === "" ? "active" : ""}
              valueState={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              value={contactEmail}
            />
            <div style={{ position: "relative" }}>
              {validContactEmail && (
                <img alt="✓" src={check} className="check" />
              )}
            </div>
            <div className="password-mismatch-text-on">
              {!validContactEmail && contactEmail !== "" && "Invalid Email"}
            </div>
          </div>
          <div className="row ms-1 mt-2">
            <div
              className={`col w-122px mb-3
            ${
              phone === ""
                ? "phone-country-dynamic-box-expanded"
                : "phone-country-dynamic-box"
            }`}
            >
              <CustomDropdown
                options={phoneCountryOptions.map((option) => {
                  return {
                    label: (
                      <Twemoji options={{ className: "twemoji" }}>
                        {option.label}
                      </Twemoji>
                    ),
                    value: option.value,
                  };
                })}
                onChange={(option) => onSelect(option, "phoneCountry")}
                value={phoneCountry}
                placeholder="Country"
                unstyled
                menuOpenState={isPhoneCountryOpen}
                setMenuOpenState={setIsPhoneCountryOpen}
              />
            </div>
            <div className="col mt--8px mb-3 input-365px">
              <CustomInput
                placeholder={"Mobile phone number"}
                containerClassName="observation-text-input-container input-365px"
                inputClassNames="input-365px"
                className={phone === "" ? "active" : ""}
                valueState={phone}
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
              <div style={{ position: "relative" }}>
                {validPhone && (
                  <img alt="✓" src={check} className="check check-phone" />
                )}
              </div>
              <div className="password-mismatch-text-on">
                {!validPhone && phone !== "" && "Invalid Phone Number"}
              </div>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end my-4">
            <div className="me-3">
              <button
                type="button"
                className="btn-secondary"
                onClick={() => setIndex(index - 1)}
              >
                Back
              </button>
            </div>
            <button
              type="button"
              onClick={
                () => setIndex(index + 1)
                //validateCompanyOrPerson()
              }
              disabled={!individualFormValid}
              className="btn-primary d-flex align-items-center"
            >
              Continue <FiArrowRight className="ms-1" />
            </button>
          </div>
        </div>
      ),
    },
    {
      title: "Tell us who you are", // Linkedin
      stepName: "",
      duration: 1,
      content: (
        <div style={{ minHeight: "750px" }}>
          <div className="txt-b fw-500 fs-18 mt-3">ID verification.</div>
          <div className="txt-b fw-500 fs-18 mb-3">
            You will need to do the Linkedin ID verification to be accepted.
          </div>
          <div className="row mb-4">
            <CustomInput
              placeholder="Url to your Linkedin account"
              containerClassName="observation-text-input-container full-width"
              className={linkedinUrl === "" ? "active" : ""}
              valueState={linkedinUrl}
              onChange={(e) => setLinkedinUrl(e.target.value)}
              value={linkedinUrl}
            />
            <div style={{ position: "relative" }}>
              {validLinkedinUrl && (
                <img alt="✓" src={check} className="check" />
              )}
            </div>
            <div className="password-mismatch-text-on">
              {!validLinkedinUrl &&
                linkedinUrl !== "" &&
                "Enter LinkedIn in the format https://www.linkedin.com/in/yourname"}
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
            className="row mb-5"
          >
            <label className="d-flex" style={{ cursor: "pointer" }}>
              <input
                type="checkbox"
                checked={linkedinVerified}
                onChange={() => setLinkedinVerified(!linkedinVerified)}
                className="checkbox-input"
              />

              <span
                style={{ marginLeft: "15.88px", fontWeight: "500" }}
                className="lh-189"
              >
                I have <span className="fw-700">verified</span> my LinkedIn
                account
              </span>
            </label>
          </div>
          <div className="w-100 d-flex justify-content-end my-4">
            <div className="me-3">
              <button
                type="button"
                className="btn-secondary"
                onClick={() =>
                  setIndex(applyingAs === "individual" ? index - 2 : index - 1)
                }
              >
                Back
              </button>
            </div>
            <button
              type="button"
              disabled={!validLinkedinUrl || !linkedinVerified}
              onClick={() => setIndex(index + 1)}
              className="btn-primary d-flex align-items-center"
            >
              Continue <FiArrowRight className="ms-1" />
            </button>
          </div>
        </div>
      ),
    },
    /*  {
      title: "Tell us who you are", // Scan front
      stepName: "Identity / KYC",
      duration: 1,
      content: (
        <>
          <div className="txt-b fw-500 fs-18 mt-3">ID verification.</div>
          <div className="txt-b fw-500 fs-18 mb-3">
            What form of identification would you like to use?
          </div>
          <div>
            <div>
              <input
                type="radio"
                id="passport"
                name="idForm"
                value="passport"
                checked={idForm === "passport"}
                onChange={(e) => setIdForm(e.target.value)}
              />
              <label htmlFor="passport" className="ms-3 label-hover">
                Passport
              </label>
            </div>
            <div className="mt-3">
              <input
                type="radio"
                id="idcard"
                name="idForm"
                value="idcard"
                checked={idForm === "idcard"}
                onChange={(e) => setIdForm(e.target.value)}
              />
              <label htmlFor="idcard" className="ms-3 label-hover">
                Government-Issued ID Card
              </label>
            </div>
          </div>
          <button
            className="btn-secondary id-scan-upload-box d-flex flex-column justify-content-center align-items-center my-5"
            type="button"
            onClick={() => document.getElementById("attachFileIdFront").click()}
          >
            <div>
              Scan front of{" "}
              {idForm === "passport"
                ? "your passport."
                : "your government-issued ID card."}
            </div>
            <div>Or click to upload</div>
            <input
              type="file"
              id="attachFileIdFront"
              style={{ display: "none" }}
              accept="image/*,application/pdf"
              onChange={onSelectIdFront}
            />
          </button>
          {selectedIdFront && (
            <a
              href={URL.createObjectURL(selectedIdFront)}
              target="_blank"
              rel="noreferrer"
            >
              View uploaded ID image
            </a>
          )}
          <div className="w-100 d-flex justify-content-end my-4">
            <div className="me-3">
              <button
                type="button"
                className="btn-secondary"
                onClick={() => setIndex(index - 1)}
              >
                Back
              </button>
            </div>
            <button
              type="button"
              onClick={() => setIndex(index + (idForm === "passport" ? 2 : 1))}
              className="btn-primary d-flex align-items-center"
              disabled={!selectedIdFront}
            >
              Continue <FiArrowRight className="ms-1" />
            </button>
          </div>
        </>
      ),
    },
    {
      title: "Tell us who you are", // Scan back
      stepName: "",
      duration: 1,
      content: (
        <>
          <div className="txt-b fw-500 fs-18 mt-3">ID verification.</div>
          <div className="txt-b fw-500 fs-18 mb-3">
            Scan the back of your Government-issued ID card.
          </div>
          <button
            className="btn-secondary id-scan-upload-box d-flex flex-column justify-content-center align-items-center my-5"
            type="button"
            onClick={() => document.getElementById("attachFileIdBack").click()}
          >
            <div>Scan back of Government-issued ID card</div>
            <div>Or click to upload</div>
            <input
              type="file"
              id="attachFileIdBack"
              style={{ display: "none" }}
              accept="image/*"
              onChange={onSelectIdBack}
            />
          </button>
          {selectedIdBack && (
            <a
              href={URL.createObjectURL(selectedIdBack)}
              target="_blank"
              rel="noreferrer"
            >
              View uploaded ID image
            </a>
          )}
          <div className="w-100 d-flex justify-content-end my-4">
            <div className="me-3">
              <button
                type="button"
                className="btn-secondary"
                onClick={() => setIndex(index - 1)}
              >
                Back
              </button>
            </div>
            <button
              type="button"
              onClick={() => setIndex(index + 1)}
              className="btn-primary d-flex align-items-center"
              disabled={!selectedIdBack}
            >
              Continue <FiArrowRight className="ms-1" />
            </button>
          </div>
        </>
      ),
    },
    {
      title: "Tell us who you are", // ID upload
      stepName: "",
      duration: 2,
      content: (
        <>
          <div className="txt-b fw-500 fs-18 mt-3">ID verification.</div>
          <div className="txt-b fw-500 fs-18 mb-3">
            Download an official document proving that you are living at{" "}
            {address}. Gas/Electricity bill, invoice, checkpay,...
          </div>
          <div className="w-100 d-flex justify-content-start my-4">
            <div className="me-3">
              <button
                className="btn-secondary"
                type="button"
                onClick={() => document.getElementById("attachFilePOR").click()}
              >
                Upload
                <input
                  type="file"
                  id="attachFilePOR"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={onSelectProofOfResidency}
                />
              </button>
            </div>
          </div>
          {selectedProofOfResidency && (
            <a
              href={URL.createObjectURL(selectedProofOfResidency)}
              target="_blank"
              rel="noreferrer"
            >
              View uploaded proof of residency
            </a>
          )}

          <div className="w-100 d-flex justify-content-end my-4">
            <div className="me-3">
              <button
                type="button"
                className="btn-secondary"
                onClick={() =>
                  setIndex(index - (idForm === "passport" ? 2 : 1))
                }
              >
                Back
              </button>
            </div>
            <button
              type="button"
              onClick={() => setIndex(index + 1)}
              className="btn-primary d-flex align-items-center"
              disabled={!selectedProofOfResidency}
            >
              Continue <FiArrowRight className="ms-1" />
            </button>
          </div>
        </>
      ),
    },*/
    {
      title: "Your magical skills.", // Languages
      stepName: "Your magical skills",
      duration: 1,
      content: (
        <div className="no-margins-or-padding">
          <div>
            <div className="row ms-1 fs-18 fw-500 txt-b mt-5 mb-4">
              Select languages you are speaking.
            </div>
            <div className="row">
              {languageDropdowns.map((dropdown, i) => (
                <div className="col-6" key={i} style={{ marginTop: "10px" }}>
                  <CustomDropdown
                    options={dropdown.options}
                    onChange={(option) =>
                      modifyDropdowns(i, "value", option, setLanguageDropdowns)
                    }
                    value={dropdown.value}
                    placeholder={dropdown.placeholder}
                    unstyled
                    menuOpenState={dropdown.isOpen}
                    setMenuOpenState={(res) =>
                      modifyDropdowns(i, "isOpen", res, setLanguageDropdowns)
                    }
                  />
                </div>
              ))}
              <button
                type="button"
                className="btn-secondary mt-3"
                style={{ width: "245px" }}
                onClick={addLanguageDropdowns}
              >
                <FiPlus className="me-3" size="18px" />
                Add another language
              </button>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end my-4">
            <div className="me-3">
              <button
                type="button"
                className="btn-secondary"
                onClick={() => setIndex(index - 1)}
              >
                Back
              </button>
            </div>
            <button
              type="button"
              onClick={() => setIndex(index + 1)}
              className="btn-primary d-flex align-items-center"
              disabled={!languagesValid}
            >
              Continue <FiArrowRight className="ms-1" />
            </button>
          </div>
        </div>
      ),
    },
    {
      title: "Your magical skills.", // Tags
      stepName: "",
      duration: 2,
      content: (
        <div className="skills-custom-height">
          <div className="row fw-500 fs-18 txt-b" style={{ marginTop: "63px" }}>
            Select tags.
          </div>
          <div className="row fw-500 fs-16 txt-g">
            Tags are the way we are matching client requests and you. Complete
            as many as possible until it is accurate.
          </div>

          <div className="row mt-5 mb-1 fw-700 fs-16 txt-b">Project tags</div>
          <div className="row fw-500 fs-16 txt-g mb-4">
            Select what kind of project you already worked on.
          </div>
          <div className="onboard-custom-dropdown-offset-side dropdown-section create-project-dropdown-section2">
            <CustomDropdown
              isMulti
              hideSelectedOptions={false}
              options={projectTagsOptions}
              onChange={(newSelectedOptions) => {
                onSelect(newSelectedOptions, "projectTags");
              }}
              value={selectedProjectTags}
              placeholder={
                !selectedProjectTags || selectedProjectTags.length === 0
                  ? "Select project tags"
                  : ""
              }
              unstyled
              closeMenuOnSelect={false}
              components={{
                MultiValue: CustomMultiValueProjectTags,
                Option: checkCircle,
              }}
              menuOpenState={isIndustryProjectMenuOpen}
              setMenuOpenState={setIsIndustryProjectMenuOpen}
              isClearable={false}
              controlChips={true}
            />
          </div>

          <div className="row mt-5 fw-700 fs-16 txt-b mb-1">
            Industries where you have experience
          </div>
          <div className="row fw-500 fs-16 txt-g">
            Be complete to find more projetcs.
          </div>
          <div className="row fw-500 fs-16 mb-4 txt-g">
            Be sure you have real experience before submitting.
          </div>
          <div className="onboard-custom-dropdown-offset-side dropdown-section create-project-dropdown-section2">
            <CustomDropdown
              isMulti
              hideSelectedOptions={false}
              options={industryTagsOptions}
              onChange={(option) => onSelect(option, "industryTags")}
              value={selectedIndustryTags}
              placeholder={
                !selectedIndustryTags || selectedIndustryTags.length === 0
                  ? "Select industries"
                  : ""
              }
              unstyled
              closeMenuOnSelect={false}
              components={{
                MultiValue: CustomMultiValueIndustryTags,
                Option: checkCircle,
              }}
              menuOpenState={isIndustryTagsMenuOpen}
              setMenuOpenState={setIsIndustryTagsMenuOpen}
              isClearable={false}
              controlChips={true}
            />
          </div>

          <div className="row mt-5 mb-1 fw-700 fs-16 txt-b">Skills</div>
          <div className="row fw-500 fs-16 txt-g mb-4">
            Select all the skills you have as UX Designer
          </div>
          <div className="onboard-custom-dropdown-offset-side dropdown-section create-project-dropdown-section2">
            <CustomDropdown
              isMulti
              hideSelectedOptions={false}
              options={skillTagsOptions}
              onChange={(newSelectedOptions) => {
                onSelect(newSelectedOptions, "skillTags");
              }}
              value={selectedSkillTags}
              placeholder={
                !selectedSkillTags || selectedSkillTags.length === 0
                  ? "Select your skills"
                  : ""
              }
              unstyled
              closeMenuOnSelect={false}
              components={{
                MultiValue: CustomMultiValueSkillTags,
                Option: checkCircle,
              }}
              menuOpenState={isIndustrySkillMenuOpen}
              setMenuOpenState={setIsIndustrySkillMenuOpen}
              isClearable={false}
              controlChips={true}
            />
          </div>

          <div className="row mt-5 mb-1 fw-700 fs-16 txt-b">Tools</div>
          <div className="fw-500 fs-16 txt-g mb-3 onboard-custom-text-offset">
            Select all the tools you can{" "}
            <span className="fw-700">perfectly use</span>
          </div>
          <div className="onboard-custom-dropdown-offset-side dropdown-section create-project-dropdown-section2">
            <CustomDropdown
              isMulti
              hideSelectedOptions={false}
              options={toolTagsOptions}
              onChange={(newSelectedOptions) => {
                onSelect(newSelectedOptions, "toolTags");
              }}
              value={selectedToolTags}
              placeholder={
                !selectedToolTags || selectedToolTags.length === 0
                  ? "Select your tools"
                  : ""
              }
              unstyled
              closeMenuOnSelect={false}
              components={{
                MultiValue: CustomMultiValueToolTags,
                Option: checkCircle,
              }}
              menuOpenState={isToolMenuOpen}
              setMenuOpenState={setIsToolMenuOpen}
              isClearable={false}
              controlChips={true}
            />
          </div>

          <div className="row mt-5 fw-700 fs-16 txt-b">Show case</div>
          <div className="row fw-500 fs-16 txt-g mb-3">
            Where can we find your best work ?
          </div>
          <div className="row mb-2 onboard-custom-input-offset">
            <CustomInput
              placeholder="Link to Behance profile or other work (Drive, etc.)"
              containerClassName="observation-text-input-container full-width"
              className={portfolio === "" ? "active" : ""}
              valueState={portfolio}
              onChange={(e) => onPortfolioChange(e.target.value)}
              value={portfolio}
            />
            <div style={{ position: "relative" }}>
              {validPortfolio && <img alt="✓" src={check} className="check" />}
            </div>
            <div className="password-mismatch-text-on">
              {!validPortfolio &&
                portfolio !== "" &&
                "Your showcase must be a valid website in the format https://www.luxifer.app."}
            </div>
          </div>

          <div className="row mt-4 mb-1 fw-700 fs-16 txt-b">
            Present yourself
          </div>
          <div className="row fw-500 fs-16 txt-g">
            You can put a free Loom video to explain us the best things
          </div>
          <div className="row fw-500 fs-16 txt-g mb-4">
            you achieved or you can ask to schedule a meeting with us.
          </div>
          <div className="onboard-custom-dropdown-offset-side dropdown-section create-project-dropdown-section2"></div>
          <div
            className={`row mb-4 onboard-custom-input-offset ${
              isFaceToFaceChecked ? "d-none" : ""
            }`}
          >
            <CustomInput
              placeholder="Loom's link"
              containerClassName="observation-text-input-container full-width"
              className={presentationURL === "" ? "active" : ""}
              valueState={isFaceToFaceChecked ? "" : presentationURL}
              onChange={(e) => onPresentationChange(e.target.value)}
              value={isFaceToFaceChecked ? "" : presentationURL}
            />
            <div style={{ position: "relative" }}>
              {validPresentationURL && (
                <img alt="✓" src={check} className="check" />
              )}
            </div>
            <div className="password-mismatch-text-on">
              {!isFaceToFaceChecked &&
                !validPresentationURL &&
                presentationURL !== "" &&
                "Your loom must be in the format https://www.loom.com/share/yourvideo."}
            </div>
          </div>
          <div
            className={`row mt-3 mb-5 fw-700 fs-16 txt-b onboard-custom-dropdown-offset-side ${
              isFaceToFaceChecked ? "d-none" : ""
            }`}
          >
            <a
              href="https://www.loom.com/"
              target="_blank"
              className="styled-link onboard-custom-text-offset loom-url-width-fix"
            >
              Go to loom
            </a>
          </div>

          <label
            className="d-flex onboard-custom-text-offset"
            style={{ cursor: "pointer" }}
          >
            <input
              type="checkbox"
              checked={isFaceToFaceChecked}
              onChange={() => setIsFaceToFaceChecked(!isFaceToFaceChecked)}
              className="checkbox-input"
            />

            <span
              style={{ marginLeft: "15.88px", fontWeight: "500" }}
              className="lh-189"
            >
              I prefer to have a face to face meeting with LUXIFER 😈
            </span>
          </label>

          <div className="w-100 d-flex justify-content-end my-4 mt-5">
            <div className="me-3">
              <button
                type="button"
                className="btn-secondary"
                onClick={() => setIndex(index - 1)}
              >
                Back
              </button>
            </div>
            <button
              type="button"
              disabled={
                !validPortfolio ||
                (!validPresentationURL && !isFaceToFaceChecked)
              }
              onClick={() => setIndex(index + 1)}
              className="btn-primary d-flex align-items-center"
            >
              Continue <FiArrowRight className="ms-1" />
            </button>
          </div>
        </div>
      ),
    },
    {
      title: "Set up your profile", // Bank account + photo
      stepName: "Bank & Profile info",
      duration: 5,
      content: (
        <div className="no-margins-or-padding">
          <div className="row ms-1 txt-b fs-16 fw-700 mt-5">
            Where do we send your money?
          </div>
          <div
            className="row ms-1 txt-g fs-16 fw-500 mb-3"
            style={{ width: "450px" }}
          >
            Money will be automatically sent each month on your bank account.
          </div>
          <div className="row mb-3">
            <div
              className="col half-width"
              style={{
                marginTop: "10px",
              }}
            >
              <CustomDropdown
                options={countryOptions}
                onChange={(option) => onSelect(option, "bankCountry")}
                value={bankCountry}
                placeholder="Country"
                disabled={awaitingCreation}
                unstyled
                menuOpenState={isBankCountryOpen}
                setMenuOpenState={setIsBankCountryOpen}
              />
            </div>
            <div className="col half-width">
              <CustomInput
                placeholder="IBAN"
                containerClassName="observation-text-input-container half-width"
                inputClassNames="half-width"
                className={iban === "" ? "active" : ""}
                valueState={iban}
                disabled={awaitingCreation}
                onChange={(e) => setIban(e.target.value)}
                value={iban}
              />
              <div style={{ position: "relative" }}>
                {ibanValid && (
                  <img
                    alt="✓"
                    src={check}
                    className="check check-half-width-offset"
                  />
                )}
              </div>
              <div className="password-mismatch-text-on">{ibanErrMsg}</div>
            </div>
          </div>

          {!isSSO ? (
            <>
              <div className="row ms-1 txt-b fs-16 fw-700 mt-4 mb-2">
                Create your account
              </div>
              {ssoError}
              {/* GOOGLE - re-add based on client design
              <Button
                className="google-sign-in mt-3"
                disabled={awaitingCreation}
                onClick={() => googleSignIn()}
              >
                <div className="google-sign-in-text">
                  <img src={googleLoginIcon} alt="G" width="20" height="20" />
                  Sign up with Google
                </div>
              </Button>
              <div
                style={{
                  marginBottom: !validEmail && email !== "" ? "0px" : "17px",
                  marginTop: "25px",
                }}
                className="d-flex justify-content-center align-items-center flex-column or-container"
              >
                <div className="line" />
                <div className="or-text">or</div>
                <div className="line" />
              </div>
              */}

              <h4 className="invalid-email-text">
                {!validEmail && email !== "" && "Invalid Email"}
              </h4>
              <div style={{ marginLeft: "1px" }}>
                <CustomInput
                  valueState={email}
                  disabled={awaitingCreation}
                  type="text"
                  placeholder="Enter your professional email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div style={{ position: "relative" }}>
                  {validEmail && <img alt="✓" src={check} className="check" />}
                </div>
                {emailError !== "" && (
                  <label className={"password-mismatch-text-on"}>
                    {emailError}
                  </label>
                )}
                <CustomInput
                  type="password"
                  placeholder="Password"
                  valueState={password}
                  disabled={awaitingCreation}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div style={{ position: "relative" }}>
                  {validPassword && (
                    <img alt="✓" src={check} className="check" />
                  )}
                </div>

                <CustomInput
                  inputClassNames={
                    password === confirmPassword ? "" : "password-mismatch-box"
                  }
                  type="password"
                  placeholder="Confirm the password"
                  valueState={confirmPassword}
                  disabled={awaitingCreation}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

                <div style={{ position: "relative" }}>
                  {password === confirmPassword && confirmPassword !== "" && (
                    <img alt="✓" src={check} className="check" />
                  )}
                </div>
                <div className="mt-0">
                  <label
                    className={
                      validPassword
                        ? "password-mismatch-text-off"
                        : "password-mismatch-text-on"
                    }
                  >
                    {passwordErrMsg}
                  </label>
                  <PasswordStrengthBar password={password} />
                </div>
              </div>
            </>
          ) : (
            <div>
              <h4 className="auth-success-label s16-medium">
                Currently signed in as {auth.currentUser?.email}.{" "}
                <a
                  href=""
                  onClick={async (e) => {
                    if (awaitingCreation) return;
                    e.preventDefault();
                    await auth.signOut();
                  }}
                >
                  Change Account?
                </a>
              </h4>
            </div>
          )}
          <div className="row ms-1 mt-3 fw-700 fs-16">Show your best shoot</div>
          <div className="row ms-1 mt-2 mb-2 fw-500 fs-14">Profile picture</div>
          {selectedPfp ? (
            <>
              <button
                type="button"
                className="btn-tertiary d-flex align-items-center my-3"
              >
                <img
                  src={selectedPfpPreview}
                  alt="Project"
                  className="project-image-preview me-3"
                />
                <div className="project-image-name">{selectedPfp.name}</div>
                <FiX
                  className="ms-3"
                  size="18px"
                  onClick={() => {
                    if (!awaitingCreation) {
                      setSelectedPfp();
                      setSelectedPfpPreview();
                    }
                  }}
                />
              </button>
            </>
          ) : (
            <div className="row ms-1">
              <input
                type="file"
                id="attachFilePfp"
                style={{ display: "none" }}
                accept="image/*"
                onChange={onSelectPfp}
              />
              <button
                type="button"
                className="btn-secondary w-100"
                onClick={() => document.getElementById("attachFilePfp").click()}
              >
                <FiPlus className="me-3" size="18px" />
                Add photo
              </button>
            </div>
          )}
          <div className="w-100 d-flex justify-content-end my-4 buttons-offset">
            <div className="me-3">
              <button
                type="button"
                className="btn-secondary"
                disabled={awaitingCreation}
                onClick={() => setIndex(index - 1)}
              >
                Back
              </button>
            </div>
            <button
              disabled={!creationValid}
              type="button"
              onClick={async () => {
                setAwaitingCreation(true);
                createDesigner().then(() => {
                  navigate("/");
                });
              }}
              className="btn-primary d-flex align-items-center"
            >
              {awaitingCreation ? (
                <Spinner style={{ width: "25px", height: "25px" }} />
              ) : (
                <>
                  Continue <FiArrowRight className="ms-1" />
                </>
              )}
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <MainLayout isEmptyBar>
      <Form>
        <CustomCarousel
          isStepActive={isStepActive}
          getProgress={getProgress}
          elements={carouselElements}
          index={index}
          hasHr
          customHeaders={customHeaders}
          customHeadersIndex={customHeadersIndex}
        />
      </Form>
    </MainLayout>
  );
}

export default OnboardDesigner;
