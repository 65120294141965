import React from "react";
import "./UxChecklist.css";
export default function UxChecklist({
  activeUxChecklist,
  activeUxChecklistPoint,
}) {
  if (activeUxChecklistPoint) {
    activeUxChecklist.point = activeUxChecklistPoint;
  }

  return (
    <div className="checklist-body">
      <h3>{activeUxChecklist.title}</h3>
      {activeUxChecklist.point && (
        <div>
          <h4>{activeUxChecklist.point.title}</h4>
          <h5>{activeUxChecklist.point.description}</h5>
          <h4>Check Rules</h4>
          {activeUxChecklist.point.subpoints &&
            Object.keys(activeUxChecklist.point.subpoints).map(
              (subpoint, i) => {
                return (
                  <div key={i}>
                    <h2>{subpoint}</h2>{" "}
                    <ul>
                      {activeUxChecklist.point.subpoints[subpoint].map(
                        (subpointData, i2) => {
                          return <li key={i2}>{subpointData}</li>;
                        },
                      )}
                    </ul>
                  </div>
                );
              },
            )}
        </div>
      )}
    </div>
  );
}
