import React, { useState, useContext, useEffect } from "react";
import ReportsTable from "../ReportsTable/ReportsTable";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import lightbulb from "../../assets/lightbulb.svg";
import signature from "../../assets/signature.svg";
import mask from "../../assets/mask.svg";
import binder from "../../assets/binder.svg";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  getReportData,
  modifyDropdowns,
  removeDuplicates,
} from "../../helpers";
import { Spinner } from "react-bootstrap";

import { UserInfoContext } from "../../contexts";

export function DesignerReports() {
  const userInfo = useContext(UserInfoContext);

  const [reports, setReports] = useState([]); // Filtered reports
  const [allReports, setAllReports] = useState([]); // Unfiltered reports
  const [completedReports, setCompletedReports] = useState(0);
  const [inProgressReports, setInProgressReports] = useState(0);

  const headerIcons = [binder, signature, mask, lightbulb];

  const tableHeaders = [
    { title: "Report date", icon: binder },
    { title: "Project Name", icon: signature },
    { title: "Projects", icon: mask },
    { title: "Insights", icon: lightbulb },
    { title: "", icon: "" },
  ];

  const dataKeys = [
    { key: "date", width: "15%" },
    {
      key: "projectName",
      width: "27%",
      containerStyle: {
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      key: "projects",
      width: "20%",
      render: (value) => (
        <img
          alt=""
          src={value}
          style={{
            maxWidth: "44px",
            maxHeight: "44px",
            width: "auto",
            height: "auto",
          }}
        />
      ),
    },
    { key: "insights", width: "15%" },
    { key: "", width: "20%" },
  ];

  const defaultFilter = { value: "All", label: "All" };

  const [isClientMenuOpen, setIsClientMenuOpen] = useState(false);
  const [isProjectMenuOpen, setIsProjectMenuOpen] = useState(false);
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(defaultFilter);
  const [selectedProject, setSelectedProject] = useState(defaultFilter);
  const [selectedDate, setSelectedDate] = useState(defaultFilter);
  const [isLoading, setIsLoading] = useState(true);

  const onSelect = (option, menuType) => {
    switch (menuType) {
      case "Client":
        setSelectedClient(option); // Updates the selected state to filter with
        modifyDropdowns(0, "value", option, setDropdowns); // Updates the dropdown to reflect the state
        break;
      case "Project":
        setSelectedProject(option);
        modifyDropdowns(1, "value", option, setDropdowns);
        break;
      case "Date":
        setSelectedDate(option);
        modifyDropdowns(2, "value", option, setDropdowns);
        break;
      default:
        break;
    }
  };

  const onToggleMenu = (state, menuType) => {
    switch (menuType) {
      case "Client":
        modifyDropdowns(0, "isMenuOpen", state, setDropdowns);
        break;
      case "Project":
        modifyDropdowns(1, "isMenuOpen", state, setDropdowns);
        break;
      case "Date":
        modifyDropdowns(2, "isMenuOpen", state, setDropdowns);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Filter the table on change of filter selection
    const filteredReports = allReports.filter((r) => {
      const clientFilter =
        selectedClient.value === "All" || r.clientId === selectedClient.value;
      const projectFilter =
        selectedProject.value === "All" ||
        r.projectId === selectedProject.value;
      const dateFilter =
        selectedDate.value === "All" || r.date === selectedDate.value;
      return clientFilter && projectFilter && dateFilter;
    });

    setReports(filteredReports);
  }, [selectedClient.value, selectedProject.value, selectedDate.value]);

  const [dropdowns, setDropdowns] = useState([
    {
      name: "Client",
      options: [defaultFilter],
      value: selectedClient, // This value is not being watched so have to update dropdowns via updateSelection()
      isMenuOpen: isClientMenuOpen,
      menuOpenHandler: () => setIsClientMenuOpen(true),
      menuCloseHandler: () => setIsClientMenuOpen(false),
    },
    {
      name: "Project",
      options: [defaultFilter],
      value: selectedProject,
      isMenuOpen: isProjectMenuOpen,
      menuOpenHandler: () => setIsProjectMenuOpen(true),
      menuCloseHandler: () => setIsProjectMenuOpen(false),
    },
    {
      name: "Date",
      options: [defaultFilter],
      value: selectedDate,
      isMenuOpen: isDateMenuOpen,
      menuOpenHandler: () => setIsDateMenuOpen(true),
      menuCloseHandler: () => setIsDateMenuOpen(false),
    },
  ]);

  const populateReports = async () => {
    // Builds data on initial page load
    const querySnapshot = await getDocs(
      query(collection(db, "reports"), where("designerId", "==", userInfo.uid)),
    );

    const reportsList = [];
    const reportsClients = [];
    const reportsProjects = [];
    const reportsDates = [];

    const asyncOperations = querySnapshot.docs.map(async (doc) => {
      const report = await getReportData(doc);
      if (!report.projectDeleted) {
        reportsList.push({
          date: report.date,
          insights: report.insights,
          id: report.reportId,
          projectId: doc.data().projectId,
          projectName: report.projectName,
          projects: report.projects,
          clientId: report.clientId,
          submitted: report.submitted,
        });

        reportsClients.push({
          value: report.clientId,
          label: report.clientFName + " " + report.clientLName,
        });
        reportsProjects.push({
          value: report.projectId,
          label: report.projectName,
        });
        reportsDates.push({
          value: report.date,
          label: report.date,
        });
      }
    });

    await Promise.all(asyncOperations);

    setCompletedReports(
      reportsList.filter((report) => report.submitted).length,
    );
    setInProgressReports(
      reportsList.filter((report) => !report.submitted).length,
    );

    setDropdowns((prevDropdowns) => [
      {
        ...prevDropdowns[0],
        options: [defaultFilter, ...removeDuplicates(reportsClients)],
      },
      {
        ...prevDropdowns[1],
        options: [defaultFilter, ...removeDuplicates(reportsProjects)],
      },
      {
        ...prevDropdowns[2],
        options: [defaultFilter, ...removeDuplicates(reportsDates)],
      },
    ]);

    setReports(reportsList);
    setAllReports(reportsList);
  };

  const populateReportsAsync = async () => {
    await populateReports();
    setIsLoading(false);
  };

  useEffect(() => {
    if (!userInfo.uid) return;

    populateReportsAsync();
  }, [userInfo.uid]);

  function strippedReports(reports) {
    // Remove fields not shown in the table ex. client id or client names
    return reports.map((report) => {
      // which are used for filtering
      report.isDesigner = true;
      const { clientId, ...result } = report;
      return result;
    });
  }

  return (
    <div>
      {isLoading ? (
        <div className="spinner-parent">
          <Spinner style={{ width: "50px", height: "50px" }} />
        </div>
      ) : allReports.length === 0 ? (
        <h5>
          No reports to show yet. You'll find your reports here when you've
          created some!
        </h5>
      ) : (
        <div>
          <div style={{ marginTop: "40px" }}>
            <h4>
              Nice {userInfo?.firstName ?? "User"},<br></br>
              You already made{" "}
              <strong>{completedReports + inProgressReports} reports</strong> 🔥
            </h4>
          </div>
          <div>
            <div className="filter-section">
              <div className="filter-section-title">Filters</div>
              <div className="d-flex">
                {dropdowns.map((dropdown, i) => (
                  <div key={i} className="dropdown-filter-width-127">
                    <CustomDropdown
                      options={dropdown.options}
                      onChange={(option) => onSelect(option, dropdown.name)}
                      placeholder={dropdown.name}
                      value={dropdown.value}
                      unstyled
                      setMenuOpenState={(res) =>
                        onToggleMenu(res, dropdown.name)
                      }
                      menuOpenState={dropdown.isMenuOpen}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              {reports.length === 0 ? (
                <h5>No reports found using the selected filters.</h5>
              ) : (
                <ReportsTable
                  reports={strippedReports(reports)}
                  headerIcons={headerIcons}
                  tableHeaders={tableHeaders}
                  dataKeys={dataKeys}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
