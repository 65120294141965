import React, { useEffect, useState } from "react";
import "./FunnelAnalysisSidebarContent.css";
import { FiPlus, FiMinus } from "react-icons/fi";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { MdCheckCircle } from "react-icons/md";
import { GiCircle } from "react-icons/gi";
import { components } from "react-select";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import CustomCalendar from "../CustomCalendar/CustomCalendar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function FunnelAnalysisSidebarContent(props) {
  const [selectedDateText, setSelectedDateText] = useState("7 day range");
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const [dateRange, setDateRange] = useState([sevenDaysAgo, today]);
  const [startDate, endDate] = dateRange;

  const [selectedDate, setSelectedDate] = useState(null);
  const [datepickerOpen, setDatepickerOpen] = useState(false);

  const [goalOptions, setGoalOptions] = useState([
    { label: "Conversion", value: "c" },
    { label: "Purchase", value: "c" },
  ]);
  const [tagOptions, setTagOptions] = useState([]);

  const [goal, setGoal] = useState(null);
  const [tag, setTag] = useState(null);
  const [pathType, setPathType] = useState("success");
  const [heatmap, setHeatmap] = useState(false);
  const [clicks, setClicks] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [hesitations, setHesitations] = useState(false);
  const [insights, setInsights] = useState(false);
  const [frustrations, setFrustrations] = useState(false);
  const [phone, setPhone] = useState(false);
  const [desktop, setDesktop] = useState(true);
  const [tablet, setTablet] = useState(false);
  const [unknownDevice, setUnknownDevice] = useState(false);
  const [country, setCountry] = useState(null);

  const [goalCollapsed, setGoalCollapsed] = useState(false);
  const [quickLearnCollapsed, setQuickLearnCollapsed] = useState(false);
  const [segmentsCollapsed, setSegmentsCollapsed] = useState(false);

  const [goalOptionsOpen, setGoalOptionsOpen] = useState(false);
  const [tagOptionsOpen, setTagOptionsOpen] = useState(false);

  const [countryOptions, setCountryOptions] = useState([
    { label: "Belgium", value: "Belgium" },
    { label: "France", value: "France" },
    { label: "Canada", value: "Canada" },
    { label: "Germany", value: "Germany" },
  ]);
  const [countryOptionsOpen, setCountryOptionsOpen] = useState(false);

  const populateCountryOptions = async () => {
    const country_info = await fetch(
      "https://countriesnow.space/api/v0.1/countries",
    );
    const country_data = await country_info.json();
    setCountryOptions(
      country_data.data.map((country) => {
        return {
          value: country.iso2,
          label: country.country,
        };
      }),
    );
  };

  useEffect(() => {
    populateCountryOptions();
  }, []);

  useEffect(() => {
    setTagOptions(props.selectedProject?.goals);
    setTag(props.selectedProject?.goals?.[0] ?? null);
  }, [props.selectedProject]);

  useEffect(() => {
    props.setHeatmapFunnelFilters({
      startDate,
      endDate,
      goal,
      tag,
      pathType,
      heatmap,
      clicks,
      scroll,
      hesitations,
      insights,
      frustrations,
      phone,
      desktop,
      tablet,
      country,
      unknownDevice,
    });
  }, [
    startDate,
    endDate,
    goal,
    tag,
    pathType,
    heatmap,
    clicks,
    scroll,
    hesitations,
    insights,
    frustrations,
    phone,
    desktop,
    tablet,
    country,
    unknownDevice,
  ]);

  const CustomMultiValue = ({ data }) => {
    const additionalSelected = country.length - 2;
    return (
      country[0]?.value === data.value && (
        <div>
          {country.slice(0, 2).map((option, index) => (
            <span key={option.value}>
              {option.label}
              {index < country.length - 1 ? ", " : null}
            </span>
          ))}
          {country.length > 2 ? `+ ${additionalSelected} others` : null}
        </div>
      )
    );
  };

  const checkCircle = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex" }}>
        {props.isSelected ? (
          <MdCheckCircle size={24} color="var(--main-blue)" />
        ) : (
          <GiCircle size={24} color="var(--main-blue)" />
        )}
        <h5
          style={{
            marginBottom: "0",
            marginLeft: "10px",
          }}
          className="vhc"
        >
          {props.data.label}
        </h5>
      </div>
    </components.Option>
  );

  const behaviors = [
    {
      label: "Heatmap & clicks",
      state: heatmap,
      setState: setHeatmap,
      color: "#2A00FF",
    },
    // {
    //   label: "Clicks / Touchs",
    //   state: clicks,
    //   setState: setClicks,
    //   color: "#0f0c22",
    // },
    {
      label: "Frustrations",
      state: frustrations,
      setState: setFrustrations,
      color: "#27DAB7",
    },
    {
      label: "Scroll",
      state: scroll,
      setState: setScroll,
      color: "#2A00FF",
    },
    {
      label: "Hesitations",
      state: hesitations,
      setState: setHesitations,
      color: "#EB0D35",
    },
    {
      label: "Insights",
      state: insights,
      setState: setInsights,
      color: "#FFC900",
    },
  ];

  const content = [
    {
      title: "Goals",
      content: (
        <div className="custom-funnel-dropdown-colours">
          <div style={{ marginTop: "20px" }}>
            <CustomDropdown
              unstyled
              placeholder="Tag"
              options={tagOptions}
              onChange={(e) => {
                setTag(e);
              }}
              value={tag}
              menuOpenState={tagOptionsOpen}
              setMenuOpenState={setTagOptionsOpen}
            />
          </div>
        </div>
      ),
      collapsible: true,
      collapsed: goalCollapsed,
      setCollapsed: setGoalCollapsed,
    },
    {
      title: "Quick learn",
      content: (
        <div>
          <div className="w-100">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="v4-tooltip-tall">
                  Represents the path where the highest quantity of users
                  achieved the goal in the least clicks and the least time.
                </Tooltip>
              }
            >
              <button
                className={`fw-500
              ${pathType === "success" ? "btn-primary" : "btn-quaternary"}`}
                style={{
                  marginTop: "20px",
                  height: "44px",
                  border: "2px solid #2a00ff",
                }}
                onClick={() => setPathType("success")}
              >
                Most successful path
              </button>
            </OverlayTrigger>
          </div>
          <div className="w-100">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="v4-tooltip-tall">
                  Represents the path where the highest quantity of users
                  reached the goal but with a lot of clicks and in a lot of
                  time.
                </Tooltip>
              }
            >
              <button
                className={`fw-500
              ${pathType === "failure" ? "btn-primary" : "btn-quaternary"}`}
                style={{
                  marginTop: "10px",
                  height: "44px",
                  border: "2px solid #2a00ff",
                }}
                onClick={() => setPathType("failure")}
              >
                Most failure path
              </button>
            </OverlayTrigger>
          </div>
          <div className="w-100">
            <button
              className={`fw-500
              ${pathType === "ROI" ? "btn-primary" : "btn-quaternary"}`}
              style={{
                marginTop: "10px",
                height: "44px",
                border: "2px solid #2a00ff",
              }}
              onClick={() => setPathType("ROI")}
            >
              Best ROI path
            </button>
          </div>
        </div>
      ),
      collapsible: true,
      collapsed: quickLearnCollapsed,
      setCollapsed: setQuickLearnCollapsed,
    },
    {
      title: "Behaviors",
      content: (
        <>
          {behaviors.map((b, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <div className="fs-16 fw-500 lh-216">{b.label}</div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    b.setState(!b.state);
                  }}
                  className="behavior-toggle align-items-center fw-700"
                  style={{ backgroundColor: b.state ? b.color : "#707070" }}
                >
                  <div
                    className={
                      b.state
                        ? "behavior-toggle-circle-on"
                        : "behavior-toggle-circle-off"
                    }
                  ></div>
                </button>
              </div>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Devices",
      content: (
        <>
          <div style={{ display: "flex", marginTop: "17px" }}>
            <button
              className={`vhc funnel-filter-toggle-btn-${
                phone ? "active" : "inactive"
              }`}
              onClick={() => {
                if (!phone) {
                  setPhone(true);
                  setDesktop(false);
                  setTablet(false);
                }
              }}
            >
              <i className="fa-regular fa-mobile fs-20"></i>
            </button>
            <button
              className={`vhc funnel-filter-toggle-btn-${
                desktop ? "active" : "inactive"
              }`}
              onClick={() => {
                if (!desktop) {
                  setPhone(false);
                  setDesktop(true);
                  setTablet(false);
                }
              }}
            >
              <i className="fa-regular fa-laptop fs-20"></i>
            </button>
            <button
              className={`vhc funnel-filter-toggle-btn-${
                tablet ? "active" : "inactive"
              }`}
              onClick={() => {
                if (!tablet) {
                  setPhone(false);
                  setDesktop(false);
                  setTablet(true);
                }
              }}
            >
              <i className="fa-regular fa-tablet fs-20"></i>
            </button>
            {/* <button
              className={`vhc funnel-filter-toggle-btn-${
                unknownDevice ? "active" : "inactive"
              }`}
              onClick={() => setUnknownDevice(!unknownDevice)}
            >
              <i className="fa-regular fa-message-question fs-20"></i>
            </button> */}
          </div>
        </>
      ),
    },
    {
      title: "Segments",
      content: (
        <>
          <div className="segment-funnel-dropdown-colours">
            <div style={{ marginTop: "20px" }}>
              <CustomDropdown
                isMulti
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                isClearable={false}
                unstyled
                placeholder="Country"
                options={countryOptions}
                onChange={(e) => {
                  setCountry(e);
                }}
                value={country ?? null}
                menuOpenState={countryOptionsOpen}
                setMenuOpenState={setCountryOptionsOpen}
                components={{
                  MultiValue: CustomMultiValue,
                  Option: checkCircle,
                }}
              />
            </div>
          </div>
        </>
      ),
      collapsible: true,
      collapsed: segmentsCollapsed,
      setCollapsed: setSegmentsCollapsed,
    },
  ];

  function formatDateRangeForFunnel(startDate, endDate) {
    if (!startDate || !endDate) return "Selecting...";
    const start = new Date(startDate);
    const end = new Date(endDate);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const startFormatted = start.toLocaleDateString("en-EN", options);
    const endFormatted = end.toLocaleDateString("en-EN", options);
    let [startMonth, startDay] = startFormatted.split(" ");
    let [endMonth, endDay, endYear] = endFormatted.split(" ");
    startMonth =
      startMonth.length > 4 ? startMonth.slice(0, 3) + "." : startMonth;
    endMonth = endMonth.length > 4 ? endMonth.slice(0, 3) + "." : endMonth;

    const sameYear = start.getFullYear() === end.getFullYear();
    if (sameYear) {
      return `${startMonth} ${startDay} - ${endMonth} ${endDay} ${endYear}`;
    } else {
      return `${startMonth} ${startDay} ${start.getFullYear()} - ${endMonth} ${endDay} ${endYear}`;
    }
  }

  return (
    <div style={{ overflow: "visible" }}>
      <div className="d-flex">
        <div className="funnel-daterange fs-14 fw-500 ls-20 fc-black word-wrapper">
          {selectedDateText}
        </div>
      </div>

      <div style={{ overflow: "visible" }}>
        <CustomCalendar
          maxDate={new Date()}
          setIsDatePickerOpen={setDatepickerOpen}
          datePickerOpen={datepickerOpen}
          startDate={startDate}
          endDate={endDate}
          customInput={
            <div
              className="funnel-datepicker-btn fs-16 fw-700 fc-blue lh-20"
              style={{ minWidth: "273px" }}
            >
              {formatDateRangeForFunnel(startDate, endDate)}
              {datepickerOpen ? (
                <FiChevronUp alt="chevron up" color="#2A00FF" size="24px" />
              ) : (
                <FiChevronDown alt="chevron up" color="#2A00FF" size="24px" />
              )}
            </div>
          }
          onChange={(update) => {
            setDateRange(update);
            const days =
              Math.floor((update[1] - update[0]) / (1000 * 60 * 60 * 24)) + 1;
            if (days > 0) {
              setSelectedDateText(`${days} day range`);
            }
          }}
        ></CustomCalendar>
      </div>

      {content.map((item, index) => (
        <div key={index}>
          <div className="FA-container">
            <button
              className="unstyled fc-black fs-16 fw-700 fc-black lh-216"
              onClick={() => {
                if (item.collapsible) {
                  item.setCollapsed(!item.collapsed);
                }
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div> {item.title}</div>
              <div>
                {item.collapsible ? (
                  item.collapsed ? (
                    <i className="fa-solid fa-plus fs-20"></i>
                  ) : (
                    <i className="fa-solid fa-minus fs-20"></i>
                  )
                ) : null}
              </div>
            </button>
            {!item.collapsed && <>{item.content}</>}
          </div>
        </div>
      ))}
    </div>
  );
}
