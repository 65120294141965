import React, { useContext, useState } from "react";
import { MetricData } from "./metricData.ts";
import MetricsChartControls from "./MetricsChartControls.tsx";
import "./chartStyles.css";
import { GridColumns, GridRows } from "@visx/grid";
import { DataContext } from "@visx/xychart";
import { ParentSize } from "@visx/responsive";
import {
  formatPercentageDiff,
  isFlippedMetric,
  isPercentagedMetric,
} from "../../helpers";
import { Timestamp } from "firebase/firestore";
import { ChartRangeContext } from "../../contexts.js";

export type XYChartProps = {
  width: number;
  height: number;
};
export type AnnotationProps = {
  width: number;
  height: number;
  compact?: boolean;
};

type Point = "data";

function CustomGrid(range) {
  const { xScale, yScale, innerHeight, innerWidth, margin } =
    React.useContext(DataContext);
  if (!xScale || !yScale) {
    return null;
  }
  return (
    <>
      <GridRows
        left={margin?.left ?? 0}
        width={innerWidth ?? 0}
        scale={yScale}
        numTicks={11}
      />
      <GridColumns
        top={margin?.top ?? 0}
        height={innerHeight ?? 0}
        scale={xScale}
        numTicks={range.range === 24 ? 100 : 30}
      />
    </>
  );
}

export default function MetricsChart({
  height,
  currentChart,
  numCharts,
  setCurrentChart,
  data,
  chartInsights,
  setActiveMenuItem,
  selectedChart,
}: XYChartProps & {
  numTicksY: number;
  currentChart: number;
  numCharts: number;
  setCurrentChart: (chart: any) => void;
  data: any;
  chartInsights: any;
  setActiveMenuItem: (item: any) => void;
  selectedChart: any;
}) {
  const [reportId, setReportId] = useState(null);
  const [ninReport, setNinReport] = useState(null);

  const [isHoveringInsight, setIsHoveringInsightState] = useState(false);
  const range = useContext(ChartRangeContext);

  interface SelectedInsight {
    status: string | null;
    date: Timestamp | null;
    fixEase: string | null;
    num: number | null;
    originalDate: Timestamp | null;
    reportId: string | null;
    reportnumber: number | null;
    severity: string | null;
    title: string | null;
  }

  if (data.length === 0) return <></>;

  // Decide whether or not to display x100% on y-axis
  let isPercentage = false;
  const dataValues = data.map((d) => d.data);
  const maxValue = Math.max(...dataValues);
  const isEmptyChart = dataValues.some(
    (value) => value !== 0 && value !== null && value > 0,
  );

  if (isPercentagedMetric(selectedChart?.metricType) && isEmptyChart) {
    isPercentage = true;
  }

  function formatMinimumDecimals(num) {
    const getDecimalPlaces = (value) => {
      const parts = value.toString().split(".");
      return parts.length > 1 ? parts[1].length : 0;
    };
    const cleanedNum = num.toString().replace(/,/g, "");
    const val = parseFloat(cleanedNum);
    if (getDecimalPlaces(cleanedNum) > 0) {
      const decimalPlaces = Math.min(getDecimalPlaces(cleanedNum), 2);
      return val.toFixed(decimalPlaces);
    }
    return cleanedNum;
  }

  function getDateWithoutTime(date: Date) {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  const sameTime = (d1, d2) => {
    if (!d1 || !d2) {
      return false;
    }
    return (
      getDateWithoutTime(d1).getTime() === getDateWithoutTime(d2).getTime()
    );
  };

  const period =
    range === 2
      ? "week"
      : range === 8
        ? "month"
        : range === 24
          ? "3 months"
          : "year";

  return (
    <div className="metrics-chart">
      {numCharts > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "50px",
          }}
        ></div>
      ) : (
        <div
          className="fw-500 fs-18 lh-324"
          style={{ marginLeft: "50px", marginTop: "75px" }}
        >
          To view charts, speak to your designer to set up performance trackers.
        </div>
      )}

      <MetricsChartControls>
        {({
          AnnotationComponent,
          accessors,
          animationTrajectory,
          annotationDataKey,
          annotationLabelPosition,
          colorAccessorFactory,
          config,
          curve,
          renderAreaSeries,
          renderGlyph,
          renderGlyphSeries,
          renderHorizontally,
          setAnnotationDataIndex,
          annotationDataIndex,
          setAnnotationDataKey,
          sharedTooltip,
          showHorizontalCrosshair,
          showTooltip,
          showVerticalCrosshair,
          snapTooltipToDatumX,
          snapTooltipToDatumY,
          theme,

          // components are animated or not depending on selection
          AreaSeries,
          Axis,
          GlyphSeries,
          Tooltip,
          XYChart,
        }) => (
          <ParentSize>
            {({ width }) => {
              if (width <= 0 || numCharts === 0) {
                return null; // or return a placeholder component
              }
              // Calculate min and max dates and values
              const minDate = Math.min(
                ...data.map((d) => new Date(d.date).getTime()),
              );
              const maxDate = Math.max(
                ...data.map((d) => new Date(d.date).getTime()),
              );

              // Calculate datePercentage and valuePercentage

              const annotationDatum = data[annotationDataIndex];
              const dateRange = maxDate - minDate;
              const datePosition = annotationDatum
                ? new Date(annotationDatum.date).getTime() - minDate
                : 0;
              const datePercentage = datePosition / dateRange;

              let selected = "";

              const yMax = Math.max(...data.map((d) => d.data));

              function getDateWithoutTime(date: Date) {
                const newDate = new Date(date);
                newDate.setHours(0, 0, 0, 0);
                return newDate;
              }

              const sameTime = (d1, d2) => {
                return (
                  getDateWithoutTime(d1).getTime() ===
                  getDateWithoutTime(d2).getTime()
                );
              };

              let selectedInsight: SelectedInsight | null = null;
              let displayDate: Date = new Date();

              /*
              if (annotationDataKey && annotationDatum && chartInsights) {
                const match = chartInsights.find((insight) =>
                  sameTime(new Date(annotationDatum.date), insight.date),
                );
                if (match) {
                  selectedInsight = match;
                  selected = annotationDatum?.[annotationDataKey] ?? 0;
                  displayDate = match.originalDate
                    ? new Date(match.originalDate)
                    : new Date(match.date);
                  setReportId(match.id);
                  setNinReport(match.num);
                } else {
                  setReportId(null);
                  setNinReport(null);
                }
              }*/

              const chartUpperPadding =
                yMax <= 1 ? (!yMax || yMax == 0 ? 0 : 0.1) : 1;

              return (
                <XYChart
                  width={width}
                  theme={theme}
                  xScale={{ type: "time", padding: 0 }}
                  yScale={{
                    type: "linear",
                    domain: [0, yMax + chartUpperPadding],
                  }}
                  height={Math.min(400, height)}
                  captureEvents={true}
                  onPointerUp={(d) => {
                    setAnnotationDataKey(d.key as "data");
                    setAnnotationDataIndex(d.index);
                  }}
                >
                  <CustomGrid range={range} />
                  {renderAreaSeries && (
                    <>
                      <AreaSeries
                        dataKey="data"
                        data={data}
                        xAccessor={accessors.x["data"]}
                        yAccessor={accessors.y["data"]}
                        fillOpacity={0.24}
                        curve={curve}
                      />
                    </>
                  )}
                  {renderGlyphSeries && (
                    <GlyphSeries
                      dataKey="data"
                      data={data}
                      xAccessor={accessors.x["data"]}
                      yAccessor={accessors.y["data"]}
                      renderGlyph={renderGlyph}
                      colorAccessor={colorAccessorFactory("data")}
                    />
                  )}
                  <Axis
                    hideAxisLine={true}
                    hideTicks={true}
                    key={`time-axis-${animationTrajectory}-${renderHorizontally}`}
                    orientation={"bottom"}
                    numTicks={
                      range === 2
                        ? 7
                        : range === 8
                          ? 30
                          : range === 24
                            ? 20
                            : 12
                    }
                    tickLabelProps={() => ({
                      dy: 33,
                      fill: "#78909C",
                      fontWeight: 400,
                      fontSize: 12,
                    })}
                    tickFormat={(d) => {
                      const date = new Date(d);

                      const weekday = date.toLocaleString("en-US", {
                        weekday: "short",
                      });
                      const month = date.toLocaleString("en-US", {
                        month: "short",
                      });
                      const day = date.getDate().toString().padStart(2, "0");

                      if (range === 96) {
                        return `${month}`;
                      }
                      if (range === 24) {
                        return `${month} ${day}`;
                      }
                      return `${weekday} ${day}`;
                    }}
                    tickComponent={({ formattedValue, ...tickProps }) => {
                      const day = formattedValue?.slice(
                        0,
                        range === 96 || range === 24 ? 3 : 2,
                      );
                      const dateNumber = formattedValue?.slice(3, 6);

                      return (
                        <>
                          <text {...tickProps} dy={16}>
                            {day}
                          </text>
                          <text {...tickProps}>{dateNumber}</text>
                        </>
                      );
                    }}
                  />
                  <Axis
                    hideAxisLine={true}
                    hideTicks={true}
                    key={`temp-axis-${animationTrajectory}-${renderHorizontally}`}
                    label={""}
                    orientation={"left"}
                    numTicks={10}
                    tickLabelProps={() => ({
                      dx: -20,
                      fill: "#78909C",
                      fontWeight: 400,
                      fontSize: 12,
                    })}
                    tickComponent={({ formattedValue, ...tickProps }) => (
                      <>
                        {isPercentage ? (
                          formattedValue > 1 ? (
                            <></>
                          ) : (
                            <text {...tickProps}>
                              {(formattedValue * 100).toFixed(0)}%
                            </text>
                          )
                        ) : (
                          <text {...tickProps}>
                            {formatMinimumDecimals(formattedValue)}
                          </text>
                        )}
                      </>
                    )}
                  />

                  {showTooltip && ( // ON HOVER TOOLTIP,
                    //  selected &&
                    //  selectedInsight &&
                    <Tooltip<MetricData>
                      className={
                        isHoveringInsight
                          ? "chart-insight-container f-satoshi"
                          : "insight-tooltip f-satoshi"
                      }
                      showHorizontalCrosshair={showHorizontalCrosshair}
                      showVerticalCrosshair={showVerticalCrosshair}
                      snapTooltipToDatumX={snapTooltipToDatumX}
                      snapTooltipToDatumY={snapTooltipToDatumY}
                      renderTooltip={({ tooltipData, colorScale }) => (
                        <div
                          className={
                            isHoveringInsight
                              ? "chart-insight"
                              : "insight-tooltip"
                          }
                        >
                          <div
                            className="fc-lg fs-12 fw-400 lh-16 f-ibm-plex-sans ls-04 lh-16 "
                            style={{
                              marginBottom: "18px",
                            }}
                          >
                            {(tooltipData?.nearestDatum?.datum &&
                              new Date(
                                accessors.date(
                                  tooltipData?.nearestDatum?.datum,
                                ),
                              )
                                .toLocaleString("en-US", {
                                  weekday: "long",
                                  day: "2-digit",
                                  month: "long",
                                })
                                .replace(",", "")
                                .split(" ")
                                .map((part, i, arr) =>
                                  i === 0
                                    ? [part, React.createElement("br")]
                                    : i === 1
                                      ? [arr[i + 1], " ", part]
                                      : "",
                                )) ||
                              "No date"}
                          </div>
                          {(
                            (sharedTooltip
                              ? Object.keys(tooltipData?.datumByKey ?? {})
                              : [tooltipData?.nearestDatum?.key]
                            ).filter((point) => point) as Point[]
                          ).map((point, i) => {
                            const hoverValue =
                              tooltipData?.nearestDatum?.datum &&
                              accessors[renderHorizontally ? "x" : "y"][point](
                                tooltipData?.nearestDatum?.datum,
                              );

                            const implementations = chartInsights.filter(
                              (i) => {
                                return i.status === "complete";
                              },
                            );

                            const hoverDate = tooltipData?.nearestDatum?.datum;
                            let latestImplementation = null;
                            if (hoverDate) {
                              const pointDate = new Date(hoverDate.date);
                              const validImplementations =
                                implementations.filter(
                                  (implementation) =>
                                    new Date(implementation.date) <= pointDate,
                                );

                              if (validImplementations.length > 0) {
                                latestImplementation =
                                  validImplementations.reduce((a, b) =>
                                    new Date(a.date) > new Date(b.date) ? a : b,
                                  );
                              }
                            }

                            let comparisonValue = 0;

                            if (latestImplementation) {
                              comparisonValue =
                                data.find(
                                  (datum) =>
                                    new Date(datum.date).setHours(
                                      0,
                                      0,
                                      0,
                                      0,
                                    ) ===
                                    new Date(
                                      latestImplementation.date,
                                    ).setHours(0, 0, 0, 0),
                                )?.data ?? data[0].data;
                            } else {
                              // Compare to the earliest point on the graph
                              comparisonValue = data[0].data;
                            }

                            const { firstPart, secondPart, sign } =
                              formatPercentageDiff(
                                hoverValue || 0,
                                comparisonValue || 0,
                              );

                            let res = false;
                            chartInsights.map(
                              (insight: SelectedInsight, index: number) => {
                                if (
                                  sameTime(
                                    new Date(
                                      tooltipData?.nearestDatum?.datum.date,
                                    ),
                                    insight.date,
                                  )
                                ) {
                                  res = true;
                                }
                              },
                            );
                            setIsHoveringInsightState(res);

                            if (hoverDate && chartInsights) {
                              const match = chartInsights.find((insight) =>
                                sameTime(
                                  new Date(hoverDate.date),
                                  insight.date,
                                ),
                              );
                              if (match) {
                                selectedInsight = match;
                                displayDate = match.originalDate
                                  ? new Date(match.originalDate)
                                  : new Date(match.date);
                                setReportId(match.id);
                                setNinReport(match.num);
                              } else {
                                setReportId(null);
                                setNinReport(null);
                              }
                            }

                            if (isHoveringInsight) {
                              return (
                                <div style={{ width: "100%" }}>
                                  {selectedInsight && (
                                    <>
                                      {/* report.submitted? && */}
                                      <div className="fw-700 fs-20 lh-27 fc-black mt-3 mb-4">
                                        {selectedInsight.status === "complete"
                                          ? "Implementation of"
                                          : ""}
                                      </div>
                                      <div className="fw-700 fs-16 lh-216 fc-black">
                                        Insight #{selectedInsight.num}
                                      </div>
                                      {displayDate && (
                                        <div className="fw-500 fs-14 lh-189 fc-grey">
                                          {`${displayDate.toLocaleString(
                                            "default",
                                            {
                                              month: "short",
                                            },
                                          )}. ${displayDate.getFullYear()}`}
                                        </div>
                                      )}
                                      <div className="mt-3 fw-700 fs-16 lh-216">
                                        {selectedInsight.title}
                                      </div>
                                      {selectedInsight.status ===
                                        "complete" && (
                                        <>
                                          <div
                                            className="fw-700 fs-24 lh-324 f-satoshi fc-green"
                                            style={{ marginTop: "20px" }}
                                          >
                                            {isPercentage
                                              ? Number(
                                                  hoverValue * 100 ?? 0,
                                                ).toFixed(0) + "%"
                                              : Number(hoverValue ?? 0).toFixed(
                                                  2,
                                                )}
                                          </div>
                                          <div className="fw-500 fs-14 fc-black">
                                            Current value
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              );
                            } else {
                              let fontColor;

                              const getFontColor = (sign, isFlipped) => {
                                if (sign === "+")
                                  return isFlipped ? "fc-red" : "fc-green";
                                if (sign === "-")
                                  return isFlipped ? "fc-green" : "fc-red";
                                return "fc-yellow";
                              };

                              fontColor = getFontColor(
                                sign,
                                isFlippedMetric(selectedChart?.metricType),
                              );

                              return (
                                <div key={i}>
                                  <div
                                    className={`fw-700 fs-24 lh-243 f-satoshi ${fontColor}`}
                                  >
                                    <>
                                      {firstPart !== "N/A" ? (
                                        <>
                                          {firstPart},
                                          <span className="fs-18">
                                            {secondPart}%
                                          </span>
                                        </>
                                      ) : (
                                        <>{firstPart}</>
                                      )}
                                      <div
                                        className="fc-black fs-14 fw-500 f-satoshi"
                                        style={{ marginTop: "5px" }}
                                      >
                                        Since{" "}
                                        {latestImplementation
                                          ? "the last implementation"
                                          : "last " + period}
                                      </div>
                                    </>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      )}
                    />
                  )}
                </XYChart>
              );
            }}
          </ParentSize>
        )}
      </MetricsChartControls>
    </div>
  );
}
