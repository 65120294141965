import { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { IoIosArrowDown } from "react-icons/io";

export default function CustomCollapse({ isUxInsightsPage, ...props }) {
  const [open, setOpen] = useState(props.open ?? false);

  return (
    <div
      style={{
        backgroundColor: isUxInsightsPage ? "" : "#FCFCFC",
        padding: isUxInsightsPage ? "" : "18px",
        border: isUxInsightsPage ? "none" : "solid 1px #E4E4E7",
      }}
      className="my-3"
    >
      <div
        role="button"
        onClick={() =>
          props.openIsParentManaged ? props.setOpen() : setOpen(!open)
        }
        aria-controls="example-collapse-text"
        aria-expanded={props.openIsParentManaged ? props.parentOpen : open}
        className="d-flex justify-content-between w-100"
      >
        <div>{props.title}</div>
        <IoIosArrowDown
          style={{
            transition: "300ms",
            rotate: `${
              (props.openIsParentManaged ? props.parentOpen : open)
                ? "180deg"
                : "0deg"
            }`,
          }}
        />
      </div>
      <Collapse in={props.openIsParentManaged ? props.parentOpen : open}>
        <div id="example-collapse-text" style={{ color: "#707070" }}>
          <hr className="w-100" />
          {props.content}
        </div>
      </Collapse>
    </div>
  );
}
