import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyDvbA106XFLG1wDw6E2XZ4ky5njcPp0dO0",
  authDomain: "ux-pro.firebaseapp.com",
  projectId: "ux-pro",
  storageBucket: "ux-pro.appspot.com",
  messagingSenderId: "573194387152",
  appId: "1:573194387152:web:4006a34ee5fd21d2a7a8d5",
  measurementId: "G-CLJTKMLNNG",
  databaseURL: "https://ux-pro-default-rtdb.europe-west1.firebasedatabase.app/",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west1");
const storage = getStorage(app);
const RTDB = getDatabase(app);

export { storage, auth, db, functions, RTDB };
