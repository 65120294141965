import { useEffect, useState } from "react";
import { auth, db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  setDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import Modal from "react-modal";
import { BsXCircle } from "react-icons/bs";
import CustomInput from "../CustomInput/CustomInput";
import { fetchGTMData } from "../../helpers";
import "./TagManager.css";

export default function TagManager(props) {
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersMap, setUsersMap] = useState({});

  const [showTags, setShowTags] = useState([]);

  const [showTagsModalOpen, setShowTagsModalOpen] = useState(false);
  const [addTagsModalOpen, setAddTagsModalOpen] = useState(false);
  const [projectFilter, setProjectFilter] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [pid, setPid] = useState("No id");

  const [newTagName, setNewTagName] = useState("");
  const [newTagTitle, setNewTagTitle] = useState("");
  const [newTagText, setNewTagText] = useState("");

  const handleFilterChange = (value) => {
    setProjectFilter(value);
    setFilteredProjects(
      projects.filter((p) =>
        p.name.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  const addGTM = async (projectId, newCard) => {
    const projectDoc = doc(db, "projects", projectId);
    await updateDoc(projectDoc, {
      tagManagerCards: arrayUnion(newCard),
    }).then(() => {
      updateData();
    });
  };

  const updateData = async () => {
    if (auth) {
      const getProjects = async () => {
        const arr = [];
        const projectSnapshot = await getDocs(collection(db, "projects"));
        projectSnapshot.forEach((doc) => {
          if (!doc?.data()?.deleted) {
            arr.push({ id: doc.id, ...doc.data() });
          }
        });
        return arr;
      };

      const getUsers = async () => {
        const arr = [];
        const projectSnapshot = await getDocs(collection(db, "users"));
        projectSnapshot.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        return arr;
      };

      getProjects()
        .then((arr) => {
          setProjects(arr);
          setFilteredProjects(arr);
        })
        .catch((error) => {});

      getUsers()
        .then((arr) => {
          setUsers(arr);
          const usersMap = arr.reduce((map, user) => {
            map[user.id] = user;
            return map;
          }, {});
          setUsersMap(usersMap);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    updateData();
  }, []);

  return (
    <div>
      <div
        className="d-flex"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          className="fs-24 fc-black fw-700 mr-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          Google Tag Manager
        </div>

        <div style={{ position: "absolute", right: "100px" }}>
          <CustomInput
            placeholder="Filter by project name"
            containerClassName="observation-text-input-container half-width"
            inputClassNames="w-100"
            className={projectFilter === "" ? "active" : ""}
            valueState={projectFilter}
            onChange={(e) => handleFilterChange(e.target.value)}
            value={projectFilter}
          />
        </div>
      </div>
      <div>Projects without google analytics will still appear.</div>
      <div>
        You can still add tags, but no data will be queried as cards do not show
        for them.
      </div>
      <div style={{ marginTop: "50px" }}>
        {filteredProjects?.map((p, i) => (
          <div key={i}>
            <div
              className="row"
              style={{
                margin: "0",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <img
                src={p.imageLink}
                className="img-keep-ratio"
                style={{
                  width: "38px",
                  height: "38px",
                  objectFit: "contain",
                  display: "block",
                  maxWidth: "100%",
                  padding: "0",
                  marginRight: "20.29px",
                }}
              />

              <div
                className="col fw-700 fs-18 lh-243"
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "100%",
                  flexWrap: "nowrap",
                  overflow: "auto",
                  padding: "0px",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {p.name}
                </div>
                <div className="col">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {p?.firstName !== "No Designer" && (
                      <button
                        type="button"
                        onClick={() => {
                          setShowTags(p.tagManagerCards ?? []);
                          setShowTagsModalOpen(true);
                        }}
                        style={{
                          marginRight: "16.36px",
                          height: "44px",
                          width: "175px",
                          float: "right",
                          padding: "none",
                        }}
                        className="btn-secondary align-items-center fw-700"
                      >
                        <div className="mp-btn-fix fs-1334">
                          Show {p?.tagManagerCards?.length ?? 0} Tags
                        </div>
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => {
                        setPid(p.id);
                        setAddTagsModalOpen(true);
                      }}
                      style={{
                        marginRight: "25px",
                        height: "44px",
                        width: "130px",
                      }}
                      className="btn-secondary align-items-center fw-700"
                    >
                      <div className="mp-btn-fix fs-1334">Add New Tag</div>
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "31.91px",
                  padding: "0",
                }}
              >
                <div className="col">
                  <div
                    className="fw-500 fs-18 lh-24"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {usersMap[p?.clientId]?.pfp && (
                      <img
                        src={usersMap[p?.clientId]?.pfp}
                        alt="profile picture"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "100%",
                          marginRight: "18.29px",
                        }}
                      />
                    )}

                    {usersMap[p?.clientId]?.firstName ??
                      usersMap[p?.clientId]?.personData?.firstName ??
                      "No Client"}
                    <span className="fc-grey">
                      {(usersMap[p?.clientId]?.firstName ||
                        usersMap[p?.clientId]?.personData?.firstName) && (
                        <>, Client</>
                      )}
                    </span>
                  </div>
                  <div
                    className="fw-500 fs-18 lh-24 mt-2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {usersMap[p?.designerId]?.pfp && (
                      <img
                        src={usersMap[p?.designerId]?.pfp}
                        alt="profile picture"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "100%",
                          marginRight: "18.29px",
                        }}
                      />
                    )}

                    {usersMap[p?.designerId]?.firstName ??
                      usersMap[p?.designerId]?.personData?.firstName ??
                      "No Designer"}
                    <span className="fc-grey">
                      {(usersMap[p?.designerId]?.firstName ||
                        usersMap[p?.designerId]?.personData?.firstName) && (
                        <>, Designer</>
                      )}
                    </span>
                  </div>
                </div>
                <div className="fw-500 fs-18 lh-24 mt-2">
                  {p?.googleAnalyticsId ? "" : "No Analytics"}
                </div>
              </div>
            </div>

            <hr
              className="row"
              style={{
                width: "95%",
                marginTop: "32px",
                marginBottom: "32px",
              }}
            ></hr>
          </div>
        ))}
      </div>

      <Modal
        isOpen={showTagsModalOpen}
        onRequestClose={() => setShowTagsModalOpen(false)}
        contentLabel="Example Modal"
        className="custom-modal modal-shape3"
        appElement={document.getElementById("root")}
        style={{
          overlay: {
            background: "rgb(42, 0, 255, 0.7)",
            backdropFilter: "blur(8px)",
            zIndex: 10,
          },
        }}
      >
        <div>
          <button
            className="x-button d-flex justify-content-center align-items-center"
            onClick={() => setShowTagsModalOpen(false)}
          >
            <BsXCircle alt="X button" size="32px" color="#0F0C22" />
          </button>
        </div>
        <div style={{ paddingTop: "50px" }} className="fc-black fs-16 fw-500">
          {showTags.length === 0 ? (
            <div className="text-center">This project has no GTM tags</div>
          ) : (
            <pre>{JSON.stringify(showTags, null, 2)}</pre>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={addTagsModalOpen}
        onRequestClose={() => {
          setNewTagName("");
          setNewTagTitle("");
          setNewTagText("");
          setAddTagsModalOpen(false);
        }}
        contentLabel="Example Modal"
        className="custom-modal modal-shape3 modal-shape-noscroll"
        appElement={document.getElementById("root")}
        style={{
          overlay: {
            background: "rgb(42, 0, 255, 0.7)",
            backdropFilter: "blur(8px)",
            zIndex: 10,
          },
        }}
      >
        <div>
          <button
            className="x-button d-flex justify-content-center align-items-center"
            onClick={() => {
              setNewTagName("");
              setNewTagTitle("");
              setNewTagText("");
              setAddTagsModalOpen(false);
            }}
          >
            <BsXCircle alt="X button" size="32px" color="#0F0C22" />
          </button>
        </div>
        <div
          style={{ padding: "100px 60px 60px 60px" }}
          className="fc-black fs-16 fw-500 d-flex justify-content-center align-items-center"
        >
          <div>
            <div className="fs-21 fc-black mb-3">
              Add Tag from Google Tag Manager{" "}
            </div>
            <div className="fc-grey">
              &lt;&gt; is needed to show 30 day % change.
              <br></br> ex. You have +5.9% monthly purple btn clicks
            </div>
            <CustomInput
              placeholder="Tag name for query"
              containerClassName="observation-text-input-container half-width"
              inputClassNames="w-100"
              className={newTagName === "" ? "active" : ""}
              valueState={newTagName}
              onChange={(e) => setNewTagName(e.target.value)}
              value={newTagName}
            />
            <CustomInput
              placeholder="Tag name to show client"
              containerClassName="observation-text-input-container half-width"
              inputClassNames="w-100"
              className={newTagTitle === "" ? "active" : ""}
              valueState={newTagTitle}
              onChange={(e) => setNewTagTitle(e.target.value)}
              value={newTagTitle}
            />
            <CustomInput
              placeholder="Template ex: You have <> monthly purple btn clicks"
              containerClassName="observation-text-input-container half-width"
              inputClassNames="w-100"
              className={newTagText === "" ? "active" : ""}
              valueState={newTagText}
              onChange={(e) => setNewTagText(e.target.value)}
              value={newTagText}
            />
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "31px" }}
            >
              <button
                type="button"
                className="btn-primary float-right"
                disabled={!newTagName || !newTagText || !newTagTitle}
                onClick={() => {
                  addGTM(pid, {
                    name: newTagName,
                    template: newTagText,
                    title: newTagTitle,
                  });
                  setNewTagName("");
                  setNewTagText("");
                  setNewTagTitle("");
                  setAddTagsModalOpen(false);
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
