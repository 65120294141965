import { useEffect } from "react";

import cursor_luxi_cur from "./assets/cursor_luxi.cur";
import cursor_red_luxi_cur from "./assets/cursor_red_luxi.cur";
import cursor_nothentity_cur from "./assets/cursor_nothentity.cur";
import cursor_change_cur from "./assets/cursor_change.cur";
import cursor_magic_cur from "./assets/cursor_magic.cur";

const cursorMap = {
  cursor_luxi: cursor_luxi_cur,
  cursor_red_luxi: cursor_red_luxi_cur,
  cursor_nothentity: cursor_nothentity_cur,
  cursor_change: cursor_change_cur,
  cursor_magic: cursor_magic_cur,
};

export default function GlobalStyleCursor() {
  const savedCursor =
    localStorage.getItem("luxiferSelectedCursor") || "cursor_luxi";

  useEffect(() => {
    const cursorUrl = cursorMap[savedCursor];

    const styleElement = document.createElement("style");
    styleElement.innerHTML = `* { cursor: url(${cursorUrl}), auto !important; }`;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [savedCursor]);

  return null;
}
