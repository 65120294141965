import { useContext, useState, useEffect } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { getProjectsOfDesigner, calcDaysLeft } from "../../helpers";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import grayPfp from "../../assets/gray.PNG";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  deleteField,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

import "./Todo.css";
import { UserInfoContext } from "../../contexts";

export function Todo() {
  const userInfo = useContext(UserInfoContext);
  const [todos, setTodos] = useState([]);
  const [ongoing, setOngoing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [justApprovedDesignerModalOpen, setJustApprovedDesignerModalOpen] =
    useState(false);

  const closeJustApprovedDesignerModal = async () => {
    const userRef = doc(db, "users", userInfo.uid);
    await updateDoc(userRef, {
      justApproved: deleteField(),
    }).then(() => {
      setJustApprovedDesignerModalOpen(false);
      window.location.reload();
    });
  };

  useEffect(() => {
    if (userInfo.justApproved) {
      setJustApprovedDesignerModalOpen(true);
    }
  }, [userInfo]);

  const tabItems = [
    {
      name: "todo",
      label: "To Do",
      link: "/",
    },
    {
      name: "opportunities",
      label: "Opportunities",
      link: "/opportunities",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabItems[0].name);

  /**
   * Populates the todos state with the projects the designer is assigned to.
   */
  useEffect(() => {
    const path = window.location.pathname;
    setActiveTab(path === "/" ? "todo" : "opportunities");

    // Get reports in progress
    getDocs(
      query(
        collection(db, "reports"),
        where("designerId", "==", userInfo.uid),
        where("submitted", "==", false),
      ),
    ).then((querySnapshot) => {
      const reportsInProgress = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        reportId: doc.id,
      }));
      getProjectsOfDesigner(userInfo.uid).then((projects) => {
        setTodos(
          projects
            .filter(
              // Filter out projects that already have a report in progress
              (project) =>
                !reportsInProgress.some(
                  (report) => report.projectId === project.id,
                ), // && project.googleAnalyticsId
            )
            .filter((project) => !project.deleted) // Filter out deleted projects
            .map((project) => {
              // Add one month to the registration date
              const daysLeft = calcDaysLeft(project.registrationDate);
              return {
                icon: project.imageLink,
                task: project.name,
                dueDate: `In ${daysLeft} days. ${
                  daysLeft >= 7 ? "You are good!" : ""
                }`,
                projectId: project.id,
              };
            }),
        );
        setOngoing(
          projects
            .filter((project) => !project.deleted) // Filter out deleted projects
            .map((project) => {
              // Find the report in progress for this project
              const report = reportsInProgress.find(
                (report) =>
                  report.projectId === project.id &&
                  report.designerId === userInfo.uid,
              );

              // If there's no report in progress for this project, return null
              if (!report) return null;
              // Add one month to the registration date
              const daysLeft = calcDaysLeft(project.registrationDate);
              // Calculate difference in days between today and project creation
              const differenceInDays =
                (new Date() -
                  new Date(project.registrationDate.seconds * 1000)) /
                (1000 * 60 * 60 * 24);

              // If it has a google analytics id, it is not disabled.
              // If it does not, and is older than 5 days, then it is not disabled
              // otherwise, disable.
              const isDisabled =
                project.googleAnalyticsId || differenceInDays > 5
                  ? false
                  : true;
              return {
                icon: project.imageLink,
                task: project.name,
                dueDate: `In ${daysLeft} days. ${
                  daysLeft >= 7 ? "You are good!" : ""
                }`,
                projectId: project.id,
                disabled: isDisabled,
                reportId: report.reportId,
              };
            })
            // Filter out any null values from the array
            .filter((project) => project !== null),
        );
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <div>
      <Modal
        isOpen={justApprovedDesignerModalOpen}
        onRequestClose={closeJustApprovedDesignerModal}
        contentLabel="Example Modal"
        className="onboard-modal custom-modal modal-shape2"
        appElement={document.getElementById("root")}
        style={{
          overlay: {
            background: "rgb(42, 0, 255, 0.7)",
            backdropFilter: "blur(8px)",
            zIndex: 10,
          },
        }}
      >
        <div className="text-center">
          <img
            src={userInfo.pfp ?? grayPfp}
            alt="profile pic"
            className="onboard-modal-image modal-image my-4"
          />
          <h3 className="mb-4">
            Congrats{" "}
            {userInfo.firstName || userInfo.personData.firstName || "Designer"},
            You made it !!! 🥳
          </h3>
          <h5 className="mb-2">
            Now, sit and wait or check how to make UX Report 👇
          </h5>
        </div>
        <div className="row justify-content-center">
          <button className="onboard-btn1 ux-btn col-5">
            <a href="/" style={{ color: "white" }}>
              <span style={{ color: "white" }}>
                Check how to make UX Report
              </span>
            </a>
          </button>
          <button
            className="onboard-btn2  to-dashboard-btn col-5"
            onClick={closeJustApprovedDesignerModal}
          >
            Go to my dashboard
          </button>
        </div>
      </Modal>
      <div className="nav-section d-flex">
        {tabItems.map((item, i) => {
          return (
            <div key={i}>
              <Button className="todo-tab-item" key={i}>
                <Link
                  onClick={() => setActiveTab(item.name)}
                  className={activeTab === item.name ? "active-tab" : ""}
                  to={item.link}
                >
                  {item.label}
                </Link>
              </Button>
              <div
                className="todo-tab-bar"
                style={{
                  background:
                    activeTab === item.name ? "var(--main-blue)" : "none",
                  width:
                    activeTab === item.name && activeTab === "todo"
                      ? "42px"
                      : "99px",
                }}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="nav-line" />
      {activeTab === "todo" ? (
        <div>
          <div className="user">
            <div>
              <h3>Hello {userInfo?.firstName ?? "User"}</h3>
            </div>
            <div>
              <h4>You have some UX stuff to cook 🍪</h4>
            </div>
          </div>
          {isLoading ? (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Spinner />
            </div>
          ) : todos.length == 0 ? (
            <h5>
              Nothing to do yet. You'll find projects here when they are ready!
            </h5>
          ) : (
            <div style={{ marginLeft: "10px" }}>
              <Row className="fs-20 lh-30 fw-500">New Project</Row>
              <Row
                className="fs-14 lh-20 fw-500 fc-grey"
                style={{ marginBottom: "24.32px" }}
              >
                <p className="p-m0 p-p0">
                  Amazing !!! You match with a new client 🥳
                </p>
                <p className="p-m0 p-p0">
                  Check the project and the contract, analyze and decide if you
                  want to take it or not.
                </p>
                <p className="p-m0 p-p0">
                  You have 72 hours to take a decision. Otherwise the project
                  will go to another UX Designer.
                </p>
              </Row>
              <Row>
                {todos.map((todo, i) => (
                  <Col className="p-0 custom-column" key={i}>
                    <div className="todo-card">
                      <div className="todo-card-first">
                        <img
                          src={todo.icon}
                          alt="todo-icon"
                          className="todo-card-img"
                        />
                        <div className="todo-card-title-holder">
                          <div className="todo-card-title">{todo.task}</div>
                        </div>
                      </div>
                      <hr className="todo-card-line" />
                      <div className="todo-card-second">
                        <div className="due-date">
                          <div className="due-date-details">
                            Go to the contract
                          </div>
                        </div>
                        <div>
                          <Link to={"/contract/" + todo.projectId}>
                            <button>
                              <FiArrowRight size="17px" />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <Row
                style={{ marginTop: "49px", marginBottom: "25px" }}
                className="fs-20 fw-500 lh-30"
              >
                Ongoing projects
              </Row>
              <Row>
                {ongoing.map((todo, i) => (
                  <Col className="p-0 custom-column" key={i}>
                    <div className="todo-card">
                      <div className="todo-card-first">
                        <img
                          src={todo.icon}
                          alt="todo-icon"
                          className="todo-card-img"
                        />
                        <div className="todo-card-title-holder">
                          <div className="todo-card-title">{todo.task}</div>
                        </div>
                      </div>
                      <hr className="todo-card-line" />
                      <div className="todo-card-second">
                        <div className="due-date">
                          <div>Due Date</div>
                          <div className="due-date-details">{todo.dueDate}</div>
                        </div>
                        <div>
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              todo.disabled ? (
                                <Tooltip id={`tooltip-top`}>
                                  You can’t access the report right now because
                                  we are still collecting qualitative data. You
                                  will be able to access the report 5 days after
                                  you got the contract
                                </Tooltip>
                              ) : (
                                <></>
                              )
                            }
                          >
                            <button
                              disabled={todo.disabled}
                              className={
                                todo.disabled ? "disabled-contract" : ""
                              }
                              onClick={() => {
                                navigate(`/create-report/${todo.reportId}`);
                              }}
                            >
                              <FiArrowRight size="17px" />
                            </button>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </div>
      ) : (
        <h4>
          This page is being built... You will find opportunities here once we
          build it!
        </h4>
      )}
    </div>
  );
}
