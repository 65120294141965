import { Row, Spinner } from "react-bootstrap";
import { UserInfoContext } from "../../contexts";
import { useContext, useEffect, useState } from "react";
import "./Summary.css";
import { useParams } from "react-router-dom";
import { collection, doc, getDocs, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { formatDate, calcDaysLeft } from "../../helpers";
import { FiArrowRight } from "react-icons/fi";

export default function Summary(props) {
  const userInfo = useContext(UserInfoContext);
  const { reportId } = useParams();

  const [insights, setInsights] = useState([]);
  const [report, setReport] = useState({});
  const [reportMonth, setReportMonth] = useState("");
  const [reportStart, setReportStart] = useState("");
  const [reportEnd, setReportEnd] = useState("");
  const [reportCard, setReportCard] = useState({});
  const [openInsights, setOpenInsights] = useState(0);
  const [openActions, setOpenActions] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const getReportDateRange = (report) => {
    const date = report?.createdAt;
    if (date) {
      setReportMonth(formatDate(date.toDate()).slice(0, -5));

      const dateObj = date.toDate();
      const day = String(dateObj.getDate()).padStart(2, "0");
      const month = dateObj.toLocaleString("default", { month: "short" });
      dateObj.setMonth(dateObj.getMonth() + 1);
      const monthAddOne = dateObj.toLocaleString("default", { month: "short" });
      const year = String(dateObj.getFullYear()).substr(-2);

      setReportStart(`${day} ${month} ${year}`);
      setReportEnd(`${day} ${monthAddOne} ${year}`);
    }
  };

  const getInsightData = async (reportDoc) => {
    let totalOpenActions = 0;
    getDocs(collection(reportDoc.ref, "insights")).then((snapshot) => {
      // Count total number of insights
      setOpenInsights(snapshot.docs.length);
      if (snapshot.docs.length === 0) setIsLoading(false);
      snapshot.docs.map((doc) => {
        getDocs(collection(doc.ref, "actions")).then((actions) => {
          const filteredActions = actions.docs.filter((action) => {
            const data = action.data();
            // Count actions that do not have status of complete
            return !data.status || data.status === "in-progress";
          });
          totalOpenActions = totalOpenActions + filteredActions.length;
          setOpenActions(totalOpenActions);
          setIsLoading(false);
        });
      });
    });
  };

  const getReportCardData = async (reportDoc) => {
    getDoc(doc(db, "projects", reportDoc.data().projectId)).then((p) => {
      const daysLeft = calcDaysLeft(p.data().registrationDate);
      setReportCard({
        icon: p.data().imageLink,
        task: p.data().name,
        dueDate: `In ${daysLeft} days. ${daysLeft >= 7 ? "You are good!" : ""}`,
      });
    });
  };

  useEffect(() => {
    async function fetchReport() {
      const reportDoc = await getDoc(doc(db, "reports", reportId));
      if (reportDoc.exists()) {
        getReportDateRange(reportDoc.data());
        getInsightData(reportDoc);
        getReportCardData(reportDoc);
      }
    }
    if (userInfo?.role !== "admin") {
      fetchReport();
    }
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          paddingRight: "50px",
        }}
      >
        <div
          className="info-greeting"
          style={{
            marginTop: "69px",
          }}
        >
          <h4 className="fw-700">Hello {userInfo?.firstName ?? "User"},</h4>
          <h5 className="fw-400">
            Global information about the current situation
          </h5>
        </div>
        <div>
          <h6
            className="fw-700 fs-20"
            style={{ marginTop: "100px", marginBottom: "0px" }}
          >
            Report {reportMonth}
          </h6>
          <p className="fs-16 fw-500 fc-grey">
            {reportStart} to {reportEnd}
          </p>
        </div>
        <Row style={{ marginTop: "31px" }}>
          <div
            className="todo-card summary-size-fix"
            style={{ marginRight: "24px" }}
          >
            <div className="todo-card-first">
              <img
                src={reportCard.icon}
                alt="todo-icon"
                className="todo-card-img"
              />
              <div className="todo-card-title-holder">
                <div className="todo-card-title fc-black">
                  {reportCard.task}
                </div>
              </div>
            </div>
            <hr className="todo-card-line" />
            <div className="todo-card-second">
              <div className="due-date">
                <div>Due Date</div>
                <div className="due-date-details">{reportCard.dueDate}</div>
              </div>
              <div>
                <button onClick={() => props.setActiveTab("insights")}>
                  <FiArrowRight size="17px" />
                </button>
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "600px" }}>
            <div className="summary-info-card">
              <div className="fw-500 fs-14 lh-20">Open Insights</div>
              <div className="fw-700 fs-16 lh-20">{openInsights}</div>
            </div>
            <div className="summary-info-card">
              <div className="fw-500 fs-14 lh-20">Open Actions</div>
              <div className="fw-700 fs-16 lh-20">{openActions}</div>
            </div>
            <div className="summary-info-card2">
              <div className="fw-500 fs-14 lh-20">Extra hours spend</div>
              <div className="fw-700 fs-16 lh-20">XX hours</div>
              <div className="fw-500 fs-14 lh-20 mt-3">€ X.XXX to bill</div>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}
