import "./Fixit.css";
import CustomCarousel from "../../components/CustomCarousel/CustomCarousel";
import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getSavedDOM, getTextDataFromStorage } from "../../helpers";
import NotificationPopup from "../NotificationPopup/NotificationPopup";
import { ProgressBar, Spinner, Button } from "react-bootstrap";
import { SelectedProjectContext } from "../../contexts";
import whiteluxi from "../../assets/white-logo-only-icon.png";
import abtest from "../../assets/abtest.png";
import { toast } from "react-toastify";

const FixIt = (props) => {
  const navigate = useNavigate();
  const [originalDOM, setOriginalDOM] = useState("");
  const [newDOM, setNewDOM] = useState("");
  const [checkHidePopup, setCheckHidePopup] = useState(false);
  const selectedProject = useContext(SelectedProjectContext);

  const [elementsToHide, setElementsToHide] = useState([]);
  const [elementsToHideString, setElementsToHideString] = useState("");

  const [checkHidePopupClassID, setCheckHidePopupClassID] = useState(false);
  const location = useLocation();
  const selectedInsight = location.state?.selectedInsight;
  const currentProject = location.state?.currentProject;

  const [elements, setElements] = useState([]);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [selectedDOM, setSelectedDOM] = useState("new");
  const [index, setIndex] = useState(0);

  const [activeTab, setActiveTab] = useState("actions");
  const [divHeight, setDivHeight] = useState(500);

  const actionTabItems = [{ name: "actions", label: "Actions" }];

  const tabData = [
    {
      title: (
        <>
          <img
            src={abtest}
            alt="abtest"
            style={{ width: "20px", height: "20px" }}
          ></img>{" "}
          Make an A/B test
        </>
      ),
      text: "Test the recommendation before implementing it.",
      buttonText: "Start A/B test",
    },
    {
      title: (
        <>
          <i className="fa-regular fa-pen-nib fs-20"></i> Ask a UX/UI Expert for
          advice
        </>
      ),
      text: "Want to know the opinion of the UX/UI expert?",
      buttonText: "Ask for advice",
    },
  ];

  function handleElementsToHideChange(e) {
    setElementsToHideString(e.target.value);
    setElementsToHide(e.target.value.split("\n"));
  }

  async function getFixedElements(elements, ruleId) {
    // Map over the elements array and fix each element
    const fixedElements = await Promise.all(
      elements.map(async (element) => {
        // Parse the HTML string into a DOM
        const parser = new DOMParser();
        const doc = parser.parseFromString(element, "text/html");

        // Replace the text of all text nodes with 'ASDASDSA'
        const walker = doc.createTreeWalker(
          doc.body,
          NodeFilter.SHOW_ELEMENT,
          null,
          false,
        );
        let node;
        while ((node = walker.nextNode())) {
          switch (ruleId) {
            case "1_2_1":
              node.style.cssText = "font-size: 16px !important;";
              break;
            case "1_3_3":
              node.style.cssText = "font-family: Arial, sans-serif !important;";
              break;
            case "3_1_1":
              node.style.cssText =
                "display: block !important; max-width: 650px !important; overflow-wrap: break-word !important; flex: 0 1 auto !important;";
              break;
            default:
              break;
          }
        }

        // Serialize the DOM back into a string
        const serializer = new XMLSerializer();
        const newElement = serializer.serializeToString(doc.body);

        return newElement;
      }),
    );

    return fixedElements;
  }
  function getOriginalDOMHelper(id) {
    return new Promise((resolve, reject) => {
      getSavedDOM(id)
        .then((data) => {
          getTextDataFromStorage(data.DOM).then((textData) => {
            let disableLinksScript = `
            <script>
                window.onload = function() {
                    let links = document.querySelectorAll('a');
                    links.forEach(link => {
                        link.addEventListener('click', (event) => {
                            event.preventDefault();
                        });
                    });
                }
            </script>
          `;
            let domWithScript = textData + disableLinksScript;
            resolve(domWithScript);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function getFixedElementsHelper() {
    return new Promise(async (resolve, reject) => {
      try {
        let elements = [
          ...selectedInsight.xpath.map((xpath, index) => ({
            xpath,
            text: selectedInsight.text[index],
            element: selectedInsight.element[index],
            checked: true,
          })),
        ];

        const fixedElements = await getFixedElements(
          elements.map((element) => element.element),
          selectedInsight.ruleId,
        );

        elements = elements.map((element, index) => ({
          ...element,
          newElement: fixedElements[index],
        }));

        resolve(elements);
      } catch (error) {
        reject(error);
      }
    });
  }

  useEffect(() => {
    if (selectedProject !== currentProject) {
      navigate("/insights/view");
    }
  }, [selectedProject]);

  // Once DOM and AI API returns, go to next step

  useEffect(() => {
    if (originalDOM && elements && newDOM && index === 0) {
      setIndex(1);
    }
  }, [originalDOM, elements, newDOM]);

  async function loadPage() {
    if (selectedInsight.DOMId) {
      const DOM = await getOriginalDOMHelper(selectedInsight.DOMId);
      const elements = await getFixedElementsHelper();

      setOriginalDOM(DOM);
      setElements(elements);

      const newDOM = updateNewDOM(DOM, elements);
      setNewDOM(newDOM);
    }
  }

  useEffect(() => {
    if (index === 1) {
      updateIframe(newDOM);
    }
  }, [index]);

  // As page first loads, get the DOM from DB and call AI fix API
  useEffect(() => {
    if (selectedInsight) {
      window.scrollTo(0, 0);
      loadPage();
    } else {
      navigate("/");
    }
  }, []);

  function updateNewDOM(DOM, elements) {
    // If the index is 1, we are on the fix it step and we can assume that the DOM is loaded

    let editedDOM = DOM;
    // Swap each element (new or original) based on if the element is checked.
    elements.forEach((e) => {
      editedDOM = editedDOM.replace(
        e.checked ? e.element : e.newElement,
        e.checked ? e.newElement : e.element,
      );
    });
    return editedDOM;
  }

  useEffect(() => {
    if (newDOM && elements && index === 1) {
      if (selectedDOM === "new") {
        updateIframe(updateNewDOM(newDOM, elements));
      } else {
        updateNewDOM(newDOM, elements);
      }
    }
  }, [elements]);

  function handleZIndexHide() {
    setCheckHidePopup(!checkHidePopup);

    var iframe = document.getElementById("content-iframe");
    if (!iframe) return; // If the iframe doesn't exist, exit the function

    var iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    if (!checkHidePopup) {
      // Reversed due to state delay
      const allElements = iframeDocument.getElementsByTagName("*");
      for (let i = 0; i < allElements.length; i++) {
        const zIndex = window
          .getComputedStyle(allElements[i])
          .getPropertyValue("z-index");
        if (zIndex !== "auto" && Number(zIndex) > 100) {
          allElements[i].style.display = "none";
          allElements[i].style.setProperty("display", "none", "important");
        }
      }
    } else {
      updateIframe(newDOM);
    }
  }

  function handleClassIDHide(classIdArray) {
    setCheckHidePopupClassID(!checkHidePopupClassID);

    var iframe = document.getElementById("content-iframe");
    if (!iframe) return; // If the iframe doesn't exist, exit the function

    var iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    if (!checkHidePopupClassID) {
      // Reversed due to state delay
      classIdArray.forEach((classId) => {
        if (typeof classId !== "string") return; // If classId is not a string, skip it

        let elements = []; // Initialize elements as an empty array
        if (classId.startsWith(".")) {
          // If it's a class
          elements = iframeDocument.getElementsByClassName(classId.slice(1));
        } else if (classId.startsWith("#")) {
          // If it's an id
          const element = iframeDocument.getElementById(classId.slice(1));
          elements = element ? [element] : [];
        }
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.display = "none";
          elements[i].style.setProperty("display", "none", "important");
        }
      });
    } else {
      updateIframe(newDOM);
    }
  }

  function updateIframe(DOM) {
    var iframe = document.getElementById("content-iframe");
    if (iframe) {
      iframe.srcdoc = DOM;
      iframe.style.width = "1920px";
      iframe.onload = function () {
        var actualHeight = iframe.contentWindow.document.body.scrollHeight;
        iframe.style.height = actualHeight + "px";
        setDivHeight(actualHeight * 0.45);
        setIframeLoading(false);
      };
    }
    setNewDOM(DOM);
  }

  function hidePopupElements() {
    return (
      <>
        <div>
          <input
            type="checkbox"
            checked={checkHidePopup}
            onChange={() => handleZIndexHide()}
            className="checkbox-input"
          />
          <label className="checkbox-label">
            Hide elements with high z-index
          </label>
        </div>

        <textarea
          rows="3"
          value={elementsToHideString}
          placeholder="Enter classes and ids one per line. Classes start with . and ids start with #."
          onChange={(e) => {
            handleElementsToHideChange(e);
          }}
        />

        <div>
          <input
            type="checkbox"
            checked={checkHidePopupClassID}
            onChange={() => handleClassIDHide(elementsToHide)}
            className="checkbox-input"
          />
          <label className="checkbox-label">
            Hide elements based on provided classes and ids
          </label>
        </div>
      </>
    );
  }

  function getRuleFixText(text, ruleId) {
    let fixText;
    let truncText = text.length > 100 ? text.slice(0, 100) + "..." : text;

    switch (ruleId) {
      case "1_2_1":
        fixText = `Text size for "${truncText}" changed to 16px`;
        break;
      case "1_3_3":
        fixText = `Serif font removed from "${truncText}"`;
        break;
      case "3_1_1":
        fixText = `Content width of "${truncText}" changed to 650px `;
        break;
      default:
        fixText = `Invalid rule`;
    }

    return fixText;
  }

  const tabItems = [
    { name: "new", label: "New version", width: "92px" },
    { name: "original", label: "Current version", width: "115px" },
  ];

  const carouselElements = [
    {
      title: "Let's create a new design that incorporates these insights",
      stepName: "Step 1",
      stepSubName: "Optimization",
      duration: 2,
      subtitle:
        "We use our smart UX/UI rules and your UI components from your html/CSS website to generate a new assumption of a better design.",
      subtitleWidth: "611px",
      content: (
        <div className="fixit-loading">
          <div>
            <div className="vhc">
              <img
                src={whiteluxi}
                style={{ width: "26.25px", height: "30px", marginTop: "335px" }}
              ></img>
            </div>
          </div>

          <div style={{ marginTop: "11px" }} className="vhc fixit-progress">
            <ProgressBar now={50}></ProgressBar>
          </div>
        </div>
      ),
    },
    {
      title: "Let's create a new design that incorporates these insights",
      stepName: "Step 2",
      stepSubName: "Test it",
      duration: 2,
      subtitle:
        "We use our intelligent UX/UI rules and your UI components from your html/CSS website to generate a new assumption for a better design.",
      subtitleWidth: "611px",
      contentWidth: "100%",
      content: (
        <div>
          <div style={{ marginBottom: "42.66px" }}>
            <div className="d-flex">
              {tabItems.map((item) => {
                return (
                  <div key={item.name}>
                    <Button className="tab-item" key={item.name}>
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedDOM(item.name);
                          updateIframe(
                            item.name === "new" && newDOM && elements
                              ? updateNewDOM(newDOM, elements)
                              : originalDOM,
                          );
                        }}
                        className={
                          selectedDOM === item.name
                            ? "fw-700 fc-blue fs-16"
                            : "fw-500 fc-black fs-16"
                        }
                      >
                        {item.label}
                      </Link>
                    </Button>
                    <div
                      className="tab-bar-insights"
                      style={{
                        width: item.width ?? "75%",
                        background:
                          selectedDOM === item.name ? "#2A00FF" : "none",
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <hr style={{ marginTop: "5px" }} />
          </div>

          <div className="d-flex" style={{ height: `${divHeight}px` }}>
            <div
              style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                display: iframeLoading ? "block" : "none",
              }}
            >
              <Spinner style={{ height: "70px", width: "70px" }} />
            </div>

            <div
              style={{
                transform: "scale(0.44)",
                transformOrigin: "0 0",
                visibility: iframeLoading ? "hidden" : "visible",
              }}
            >
              <iframe id="content-iframe"></iframe>
            </div>

            <div
              style={{
                position: "absolute",
                left: "880px",
              }}
            >
              <div className="change-details">
                <div className="fs-12 fw-900 fc-black">CHANGE DETAILS</div>
                <div
                  className="fw-16 fc-grey fw-500 lh-20"
                  style={{ marginTop: "10.33px", marginBottom: "24.89px" }}
                >
                  You will find here a list of the changes that have been made
                  on the basis of this group of insights. You can decide what
                  changes you wish to make. However, we recommend that you do
                  nothing and carry out a test to try things out, or if you're
                  convinced, incorporate the recommendations directly.
                </div>
                {elements.map((element, index) => (
                  <div key={index} style={{ marginBottom: "24.4px" }}>
                    <label className="d-flex" style={{ cursor: "pointer" }}>
                      <input
                        style={{ minWidth: "20px" }}
                        className="checkbox-input"
                        type="checkbox"
                        checked={element.checked}
                        onChange={() => {
                          const newElements = [...elements];
                          newElements[index].checked =
                            !newElements[index].checked;
                          setElements(newElements);
                        }}
                      />
                      <span
                        style={{ marginLeft: "11.8px", fontWeight: "400" }}
                        className="lh-192 fc-black"
                      >
                        {getRuleFixText(element.text, selectedInsight?.ruleId)}
                      </span>
                    </label>
                  </div>
                ))}
              </div>

              <div style={{ marginTop: "40px" }}>
                <div className="d-flex">
                  {actionTabItems.map((item) => {
                    return (
                      <div key={item.name}>
                        <Button className="tab-item" key={item.name}>
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              setActiveTab(item.name);
                            }}
                            className={
                              activeTab === item.name
                                ? "fw-700 fc-blue fs-16"
                                : "fw-500 fc-black fs-16"
                            }
                            to={item.link}
                          >
                            {item.label}
                          </Link>
                        </Button>
                        <div
                          className="tab-bar-insights"
                          style={{
                            background:
                              activeTab === item.name ? "#2A00FF" : "none",
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <hr style={{ marginTop: "5px" }} />
                {activeTab === "actions" &&
                  tabData.map((item, index) => (
                    <div key={index}>
                      <div style={{ float: "right" }}>
                        <button
                          type="button"
                          className="btn-quaternary fs-1334 fw-700"
                          onClick={() => {
                            toast.warn("This feature is not available yet.");
                          }}
                        >
                          {item.buttonText}
                        </button>
                      </div>

                      <div
                        style={{
                          marginTop: "24.33px",
                          marginBottom: "11.58px",
                        }}
                        className="fc-black fw-700 fs-18 lh-25"
                      >
                        {item.title}
                      </div>
                      <div
                        className="fc-grey fw-400 fs-16 lh-192"
                        style={{ marginLeft: "29px", marginBottom: "24.41px" }}
                      >
                        {item.text}
                      </div>
                      {index !== tabData.length - 1 && <hr />}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "3",
      stepName: "Step 3",
      stepSubName: "Result",
      duration: 2,
      subtitle: "",
      subtitleWidth: "",
      content: (
        <div>
          <button
            type="button"
            className="btn-primary"
            onClick={() => setIndex(index + 1)}
          >
            Next
          </button>
        </div>
      ),
    },
    {
      title: "Implement",
      stepName: "Step 4",
      stepSubName: "Implement",
      duration: 2,
      subtitle: "You can generate the code to share with your developers.",
      subtitleWidth: "",
      content: (
        <div>
          <button type="button" className="btn-primary" onClick={() => {}}>
            Download
          </button>
          <button type="button" className="btn-primary" onClick={() => {}}>
            Share
          </button>
        </div>
      ),
    },
  ];

  return (
    <Form>
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ transform: "translateY(-40px)" }}
      >
        <NotificationPopup setActiveMenuItem={props.setActiveMenuItem} />
      </div>
      <CustomCarousel
        elements={carouselElements}
        index={index}
        customSubHeaders
      />
    </Form>
  );
};

export default FixIt;
