import { useState, useEffect, useRef, useCallback } from "react";

export function useScrollTracking(stopScrollAtLeft = 0) {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });
  const [isReady, setIsReady] = useState(false);
  const scrollContainerRef = useRef(null);
  const planHeaderRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (scrollContainerRef?.current) {
      const { scrollLeft, scrollTop } = scrollContainerRef.current;
      setScrollPosition({ x: scrollLeft, y: scrollTop });

      if (scrollLeft <= stopScrollAtLeft) {
        setScrollPosition({
          x: 0,
          y: 0,
        });
      } else {
        setScrollPosition({
          x: stopScrollAtLeft - scrollLeft,
          y: scrollTop,
        });
      }

      // Add or remove the 'scrolling' class based on scroll position
      if (planHeaderRef?.current) {
        if (scrollContainerRef.current.scrollLeft > 0) {
          planHeaderRef.current.classList.add("scrolling");
        } else {
          planHeaderRef.current.classList.remove("scrolling");
        }
      }
    }
  }, []);

  const initializeRefs = useCallback(
    (containerElement, headerElement) => {
      if (containerElement) {
        scrollContainerRef.current = containerElement;
        setIsReady(true);
      }
    },
    [scrollContainerRef],
  );

  const resetScroll = useCallback(() => {
    setScrollPosition({ x: 0, y: 0 });
    setIsReady(false);

    if (scrollContainerRef?.current) {
      const scrollContainer = scrollContainerRef.current;
      scrollContainer.removeEventListener("scroll", handleScroll);
    }

    scrollContainerRef.current = null;
    planHeaderRef.current = null;
  }, [scrollContainerRef.current]);

  useEffect(() => {
    if (isReady && scrollContainerRef?.current) {
      const scrollContainer = scrollContainerRef.current;
      scrollContainer.addEventListener("scroll", handleScroll);

      return () => {
        !!scrollContainer &&
          scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isReady, handleScroll, scrollContainerRef?.current]);

  return {
    initializeRefs,
    planHeaderRef,
    scrollPosition,
    isReady,
    resetScroll,
  };
}

export default useScrollTracking;
