import "./Reports.css";
import ReportsTable from "../ReportsTable/ReportsTable";
import cauldron from "../../assets/cauldron.svg";
import madeByLogo from "../../assets/madeby_logo.png";
import binder from "../../assets/binder.svg";
import signature from "../../assets/signature.svg";
import lightbulb from "../../assets/lightbulb.svg";
import { SelectedProjectContext } from "../../contexts";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { getReportData, calcDaysLeft } from "../../helpers";
import React, { useState, useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";

export function Reports() {
  const selectedProject = useContext(SelectedProjectContext);
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [reportCards, setReportCards] = useState([
    {
      icon: cauldron,
      text: "No upcoming report",
      subtext: "",
    },
    {
      icon: madeByLogo,
      text: "No designer",
      subtext: "",
    },
  ]);

  /**
   * Populates the reports state with the reports of the selected project.
   * @returns {Promise<void>}
   */
  const populateReports = async () => {
    if (!selectedProject?.value) return;
    const querySnapshot = await getDocs(
      query(
        collection(db, "reports"),
        where("projectId", "==", selectedProject.value),
      ),
    );

    const reportsList = [];
    for (const reportDoc of querySnapshot.docs) {
      const user = await getDoc(doc(db, "users", reportDoc.data().designerId));
      const reportData = await getReportData(reportDoc);
      if (!reportData.submitted) continue;
      reportsList.push({
        date: reportData.date,
        insights: reportData.insights,
        id: reportData.reportId,
        projectName: reportData.projectName,
        projects: user.data().pfp,
        madeBy: user.data().pfp,
      });
    }
    setReports(reportsList);
  };

  const populateReportsAsync = async () => {
    await populateReports();
    setIsLoading(false);
  };

  /**
   * Populate report cards with data from the selected project.
   */
  useEffect(() => {
    setIsLoading(true);
    if (selectedProject.registrationDate) {
      setReportCards([
        {
          icon: cauldron,
          text: "Your report will be ready",
          subtext: `in ${calcDaysLeft(selectedProject.registrationDate)} days.`,
        },
        {
          icon: madeByLogo,
          text: "Made by Top UX/UI Designer",
          subtext: "Jeremy Goldyn",
        },
      ]);
    }
    populateReportsAsync();
  }, [selectedProject]);

  const tableHeaders = [
    { title: "Report date", icon: binder },
    { title: "Made By", icon: signature },
    { title: "Insights", icon: lightbulb },
    { title: "", icon: "" },
  ];

  const dataKeys = [
    { key: "date", width: "40%" },
    {
      key: "designerName",
      width: "21%",
      render: (value) => (
        <img
          alt=""
          src={value}
          style={{
            maxWidth: "44px",
            maxHeight: "44px",
            borderRadius: "50%",
            width: "auto",
            height: "auto",
          }}
        />
      ),
    },
    { key: "insights", width: "19%" },
    { key: "", width: "21%" },
  ];

  return (
    <div>
      <div>
        <h6 className="reports-title">Your next report</h6>
        <div
          className="d-flex"
          style={{
            marginTop: "20px",
          }}
        >
          {reportCards.map((card, i) => {
            return (
              <div
                className="report-card"
                key={i}
                style={{ background: "black" }}
              >
                <div className="mt-3 d-flex justify-content-between flex-column">
                  <img
                    alt=""
                    src={card.icon}
                    style={{
                      width: "44px",
                      height: "44px",
                      marginBottom: "10px",
                    }}
                  />
                  <div className="report-card-text">
                    {card.text}{" "}
                    <div className="report-card-subtext">{card.subtext}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <h6 className="reports-title">Previous reports</h6>
        {isLoading ? (
          <div className="spinner-parent">
            <Spinner style={{ width: "50px", height: "50px" }} />
          </div>
        ) : reports.length === 0 ? (
          <h5>
            No reports to show yet. You'll find your reports here when they are
            ready!
          </h5>
        ) : (
          <ReportsTable
            dataKeys={dataKeys}
            reports={reports}
            tableHeaders={tableHeaders}
          />
        )}
      </div>
    </div>
  );
}
