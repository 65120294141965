import "./ReportsTable.css";
import { LuMousePointer } from "react-icons/lu";
import { Link } from "react-router-dom";

function ReportsTable({
  reports,
  tableHeaders,
  dataKeys,
  showDatesBelowNumber,
}) {
  return (
    <table className="reports-table">
      <thead>
        <tr className="d-flex align-items-center reports-header">
          {tableHeaders.map((header, index) => (
            <th
              style={{
                width: dataKeys[index].width,
              }}
              key={index}
              className="ml-10 d-flex align-items-center flex-row"
            >
              {header.icon && (
                <img alt="" src={header.icon} className="reports-table-image" />
              )}
              <div style={{ fontSize: "16px" }}>{header.title}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {reports.map((report, reportIndex) => (
          <tr
            className="d-flex align-items-center reports-header-faded"
            key={reportIndex}
            style={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            {dataKeys.map((dataKey, dataIndex) => {
              return (
                dataKeys[dataIndex].key && ( //Headers
                  <td
                    style={{
                      width: dataKey.width,
                      fontSize: "16px",
                    }}
                    key={dataIndex}
                    className="ml-10 d-flex align-items-center flex-row project-name"
                  >
                    {dataKey.render ? (
                      dataKey.render(report.projects)
                    ) : (
                      <div
                        className="text-overflow-handler"
                        style={{
                          ...dataKey?.containerStyle,
                        }}
                      >
                        {dataKey.useIcon && <img src={report.madeBy} alt="" />}

                        {dataKey.key !== "insights" ? (
                          <div
                            style={{
                              color:
                                report[dataKey.key] === "In progress"
                                  ? "#0F930F"
                                  : "inherit",
                            }}
                          >
                            {report[dataKey.key]}
                          </div>
                        ) : (
                          report[dataKey.key] // # of insights count inside of a report align horizontally
                        )}

                        {dataKey?.key == "number" && showDatesBelowNumber && (
                          <p
                            className="fs-14 fw-500 fc-grey lh-189"
                            style={{ marginBottom: "0", textAlign: "center" }}
                          >
                            {report["formattedDate"]}
                          </p>
                        )}

                        {dataKey.key === "insights" && (
                          <div
                            className="hide-text-on-smaller-screens"
                            style={{ float: "right" }}
                          >
                            &nbsp; insights
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                )
              );
            })}
            <td
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginLeft: "10px",
                width: dataKeys[dataKeys.length - 1].width,
              }}
            >
              <Link
                to={
                  (!report.isDesigner ? "/report/" : "/create-report/") +
                  report.id +
                  (report.isInsight ? "#" + report.ninreport : "")
                }
              >
                <button className="report-btn d-flex align-items-center">
                  <LuMousePointer
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  {report.isInsight ? (
                    <div>View insight</div>
                  ) : (
                    <div>
                      Go&nbsp;
                      <div className="hide-text-on-smaller-screens2">
                        to&nbsp;
                        <div className="hide-text-on-smaller-screens">
                          the&nbsp;
                        </div>
                        report
                      </div>
                    </div>
                  )}
                </button>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ReportsTable;
