import "../Reports/Reports.css";
import ReportsTable from "../ReportsTable/ReportsTable";
import reworkImg from "../../assets/project-rework-vector.png";
import lightbulb from "../../assets/lightbulb.svg";
import hash from "../../assets/hash.svg";
import wrench from "../../assets/wrench.svg";
import bomb from "../../assets/bomb.svg";
import { SelectedProjectContext } from "../../contexts";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  getInsightData,
  formatDate,
  modifyDropdowns,
  removeDuplicates,
} from "../../helpers";
import React, { useState, useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

export function Insights() {
  const selectedProject = useContext(SelectedProjectContext);
  const [isLoading, setIsLoading] = useState(true);
  const [insights, setInsights] = useState([]);
  const [filteredInsights, setFilteredInsights] = useState([]);

  function strippedInsights(insights) {
    // Remove fields not shown in the table ex. filter values like report and page
    return insights.map((insight) => {
      const { id, pageName, reportId, date, ...result } = insight;
      for (const key in result) {
        if (result[key] === null || result[key] === undefined) {
          result[key] = "N/A";
        }
      }

      const month = date.split(" ")[0].substring(0, 3) + ".";
      const year = date.split(" ")[1];
      const formattedDate = `${month} ${year}`;

      return {
        ...result,
        id: reportId,
        isInsight: true,
        formattedDate: formattedDate,
      };
    });
  }

  const populateInsightsAsync = async () => {
    await populateInsights();
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    populateInsightsAsync();
  }, [selectedProject]);

  const tableHeaders = [
    { title: "Insights", icon: hash },
    { title: "Insights info", icon: lightbulb },
    { title: "Ease to fix", icon: wrench },
    { title: "Severity", icon: bomb },
    { title: "Fix status", icon: reworkImg },
    { title: "", icon: "" },
  ];

  const dataKeys = [
    { key: "number", width: "15%" },
    { key: "title", width: "25%" },
    { key: "fixEase", width: "15%" },
    { key: "severity", width: "15%" },
    { key: "fixStatus", width: "15%" },
    { key: "", width: "15%" },
  ];

  const defaultFilter = { value: "All", label: "All" };

  const [isPageMenuOpen, setIsPageMenuOpen] = useState(false);
  const [isReportMenuOpen, setIsReportMenuOpen] = useState(false);
  const [isEaseToFixMenuOpen, setIsEaseToFixMenuOpen] = useState(false);
  const [isSeverityMenuOpen, setIsSeverityMenuOpen] = useState(false);
  const [isFixStatusMenuOpen, setIsFixStatusMenuOpen] = useState(false);

  const [selectedPage, setSelectedPage] = useState(defaultFilter);
  const [selectedReport, setSelectedReport] = useState(defaultFilter);
  const [selectedEaseToFix, setSelectedEaseToFix] = useState(defaultFilter);
  const [selectedSeverity, setSelectedSeverity] = useState(defaultFilter);
  const [selectedFixStatus, setSelectedFixStatus] = useState(defaultFilter);

  const filterNames = [
    "Page",
    "Report",
    "Ease to Fix",
    "Severity",
    "Fix Status",
  ];

  const onSelect = (option, menuType) => {
    switch (menuType) {
      case "Page":
        setSelectedPage(option);
        modifyDropdowns(0, "value", option, setDropdowns);
        break;
      case "Report":
        setSelectedReport(option);
        modifyDropdowns(1, "value", option, setDropdowns);
        break;
      case "Ease to Fix":
        setSelectedEaseToFix(option);
        modifyDropdowns(2, "value", option, setDropdowns);
        break;
      case "Severity":
        setSelectedSeverity(option);
        modifyDropdowns(3, "value", option, setDropdowns);
        break;
      case "Fix Status":
        setSelectedFixStatus(option);
        modifyDropdowns(4, "value", option, setDropdowns);
        break;
      default:
        break;
    }
  };

  const onToggleMenu = (state, menuType) => {
    const index = filterNames.indexOf(menuType);
    if (index !== -1) {
      modifyDropdowns(index, "isMenuOpen", state, setDropdowns);
    }
  };

  useEffect(() => {
    // Filter the table on change of filter selection
    const filteredInsights = insights.filter((r) => {
      const pageFilter =
        selectedPage.value === "All" || r.pageName === selectedPage.value;
      const reportFilter =
        selectedReport.value === "All" || r.date === selectedReport.value;
      const easeFilter =
        selectedEaseToFix.value === "All" ||
        r.fixEase === selectedEaseToFix.value;
      const severityFilter =
        selectedSeverity.value === "All" ||
        r.severity === selectedSeverity.value;
      const statusFilter =
        selectedFixStatus.value === "All" ||
        r.fixStatus === selectedFixStatus.value;

      return (
        pageFilter &&
        reportFilter &&
        easeFilter &&
        severityFilter &&
        statusFilter
      );
    });

    setFilteredInsights(filteredInsights);
  }, [
    selectedPage.value,
    selectedReport.value,
    selectedEaseToFix.value,
    selectedSeverity.value,
    selectedFixStatus.value,
  ]);

  const [dropdowns, setDropdowns] = useState([
    {
      name: "Page",
      options: [defaultFilter],
      value: selectedPage,
      isMenuOpen: isPageMenuOpen,
      menuOpenHandler: () => setIsPageMenuOpen(true),
      menuCloseHandler: () => setIsPageMenuOpen(false),
    },
    {
      name: "Report",
      options: [defaultFilter],
      value: selectedReport,
      isMenuOpen: isReportMenuOpen,
      menuOpenHandler: () => setIsReportMenuOpen(true),
      menuCloseHandler: () => setIsReportMenuOpen(false),
    },
    {
      name: "Ease to Fix",
      options: [defaultFilter],
      value: selectedEaseToFix,
      isMenuOpen: isEaseToFixMenuOpen,
      menuOpenHandler: () => setIsEaseToFixMenuOpen(true),
      menuCloseHandler: () => setIsEaseToFixMenuOpen(false),
    },
    {
      name: "Severity",
      options: [defaultFilter],
      value: selectedSeverity,
      isMenuOpen: isSeverityMenuOpen,
      menuOpenHandler: () => setIsSeverityMenuOpen(true),
      menuCloseHandler: () => setIsSeverityMenuOpen(false),
    },
    {
      name: "Fix Status",
      options: [defaultFilter],
      value: selectedFixStatus,
      isMenuOpen: isFixStatusMenuOpen,
      menuOpenHandler: () => setIsFixStatusMenuOpen(true),
      menuCloseHandler: () => setIsFixStatusMenuOpen(false),
    },
  ]);

  const populateInsights = async () => {
    if (!selectedProject?.value) return;

    const querySnapshot = await getDocs(
      query(
        collection(db, "reports"),
        where("projectId", "==", selectedProject.value),
      ),
    );

    const promises = [];
    let reportIdx = 0;
    for (const reportDoc of querySnapshot.docs) {
      if (!reportDoc.data().submitted) continue;
      promises.push(getInsightData(reportDoc, reportIdx++));
    }

    try {
      const insightsArrays = await Promise.all(promises);
      const insightsListTemp = insightsArrays.flat();
      insightsListTemp.sort((a, b) => a.date - b.date); // Order by raw date

      const pageList = [];
      const reportList = [];
      const easeToFixList = [];
      const severityList = [];
      const fixStatusList = [];

      // Populate insight details and dropdowns that require all data
      const reportDateDropdowns = [{ value: "All", label: "All" }];

      insightsListTemp.forEach((item, index) => {
        item.fixStatus =
          item.status === "in-progress" ? "In progress" : "No action taken";
        const date = formatDate(item.date);
        reportDateDropdowns.push({ value: date, label: date });
        item.date = date;
        const insightNumber = `Insight #${item.ninreport}`;
        item.number = insightNumber;
        //Populate dropdown options
        pageList.push({
          value: item.pageName,
          label: item.pageName,
        });
        reportList.push({
          value: date,
          label: date,
        });
        easeToFixList.push({
          value: item.fixEase,
          label: item.fixEase,
        });
        severityList.push({
          value: item.severity,
          label: item.severity,
        });
        fixStatusList.push({
          value:
            item.status === "in-progress" ? "In progress" : "No action taken",
          label:
            item.status === "in-progress" ? "In progress" : "No action taken",
        });
      });

      setDropdowns((prevDropdowns) => [
        {
          ...prevDropdowns[0],
          options: [defaultFilter, ...removeDuplicates(pageList)],
        },
        {
          ...prevDropdowns[1],
          options: [defaultFilter, ...removeDuplicates(reportList)],
        },
        {
          ...prevDropdowns[2],
          options: [defaultFilter, ...removeDuplicates(easeToFixList)],
        },
        {
          ...prevDropdowns[3],
          options: [defaultFilter, ...removeDuplicates(severityList)],
        },
        {
          ...prevDropdowns[4],
          options: [defaultFilter, ...removeDuplicates(fixStatusList)],
        },
      ]);

      setInsights(insightsListTemp);
      setFilteredInsights(insightsListTemp);
    } catch (error) {}
  };

  return (
    <div>
      <div>
        <div
          className="d-flex"
          style={{
            marginTop: "20px",
          }}
        ></div>
        {isLoading ? (
          <div className="spinner-parent">
            <Spinner style={{ width: "50px", height: "50px" }} />
          </div>
        ) : insights.length === 0 ? (
          <h5>
            No insights for this project to show yet. You'll find your insights
            here when they are ready!
          </h5>
        ) : (
          <div>
            <div className="filter-section">
              <div className="filter-section-title">Filters</div>
              <div className="d-flex">
                {dropdowns.map((dropdown, i) => (
                  <div key={i} className="dropdown-filter-width-127">
                    <CustomDropdown
                      options={dropdown.options}
                      onChange={(option) => onSelect(option, dropdown.name)}
                      placeholder={dropdown.name}
                      value={dropdown.value}
                      unstyled
                      setMenuOpenState={(res) =>
                        onToggleMenu(res, dropdown.name)
                      }
                      menuOpenState={dropdown.isMenuOpen}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              {filteredInsights.length === 0 ? (
                <h5>No insights found using the selected filters.</h5>
              ) : (
                <ReportsTable
                  dataKeys={dataKeys}
                  reports={strippedInsights(filteredInsights)}
                  tableHeaders={tableHeaders}
                  showDatesBelowNumber={true}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
