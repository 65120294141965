import React from "react";

export default function GaLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="600"
      height="206.493"
      version="1.1"
      viewBox="0 0 450 154.87"
    >
      <defs>
        <g>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M21.047 0H3.312v-30.078h17.735zm-2.531-2.39v-25.282H5.844V-2.39zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.36-30.078h10.796c1.508 0 2.926.355 4.25 1.062 1.332.7 2.39 1.664 3.172 2.891.79 1.23 1.188 2.617 1.188 4.156 0 1.461-.375 2.735-1.125 3.828-.743 1.094-1.743 1.934-3 2.516v.172c1.52.5 2.754 1.371 3.703 2.61.945 1.23 1.422 2.667 1.422 4.312 0 1.625-.414 3.09-1.235 4.39a8.552 8.552 0 01-3.312 3.047A9.48 9.48 0 0114.734 0H3.36zm10.796 12.984c1.008 0 1.895-.226 2.656-.687.77-.469 1.364-1.07 1.782-1.813.426-.738.64-1.5.64-2.281 0-.758-.203-1.508-.609-2.25a5.093 5.093 0 00-1.719-1.828c-.742-.477-1.601-.719-2.578-.719h-7.39v9.578zm.797 13.688c1.008 0 1.91-.25 2.703-.75A5.11 5.11 0 0019.5-6.11c.438-.801.656-1.618.656-2.454 0-.843-.226-1.66-.672-2.453a5.226 5.226 0 00-1.89-1.937c-.813-.488-1.75-.735-2.813-.735H6.937v10.282zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M4.75-25.375c-.7 0-1.305-.25-1.813-.75-.5-.5-.75-1.102-.75-1.813 0-.718.25-1.32.75-1.812a2.53 2.53 0 011.813-.734c.727 0 1.336.246 1.828.734.488.492.734 1.094.734 1.813 0 .71-.246 1.312-.734 1.812-.492.5-1.101.75-1.828.75zm-1.766 3.953h3.563V0H2.984zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M12.188.672C10.133.672 8.296.18 6.671-.797c-1.625-.976-2.89-2.328-3.797-4.047-.906-1.726-1.36-3.672-1.36-5.828 0-2.039.426-3.937 1.282-5.687.851-1.75 2.066-3.141 3.64-4.172 1.583-1.04 3.399-1.563 5.454-1.563 2.093 0 3.91.469 5.453 1.407 1.539.937 2.722 2.234 3.547 3.89.832 1.649 1.25 3.54 1.25 5.672 0 .336-.032.7-.094 1.094H5.078c.082 1.617.473 2.984 1.172 4.11.707 1.124 1.598 1.968 2.672 2.53a7.33 7.33 0 003.39.829c2.79 0 4.899-1.286 6.329-3.86l3.03 1.469c-.898 1.71-2.14 3.074-3.733 4.094C16.343.16 14.425.67 12.188.67zm6.171-13.656c-.062-.895-.32-1.79-.765-2.688-.45-.894-1.153-1.648-2.11-2.265-.949-.614-2.148-.922-3.593-.922-1.688 0-3.121.543-4.297 1.625-1.168 1.074-1.934 2.492-2.297 4.25zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.64-21.422h3.407v3.156h.172c.582-1.062 1.52-1.96 2.812-2.703 1.29-.75 2.66-1.125 4.11-1.125 2.582 0 4.55.75 5.906 2.25 1.351 1.5 2.031 3.54 2.031 6.11V0h-3.562v-13.188c0-1.988-.48-3.43-1.438-4.328-.95-.894-2.246-1.343-3.89-1.343-1.157 0-2.188.324-3.094.968a6.61 6.61 0 00-2.125 2.547 7.529 7.529 0 00-.75 3.297V0H2.64zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.297-21.422h3.812L10.75-4.203h.094l6.719-17.219h3.734L12.562 0H8.954zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M10.422.672c-2.586 0-4.555-.754-5.906-2.266C3.16-3.102 2.484-5.219 2.484-7.937v-13.485h3.563v12.938c0 2.043.457 3.543 1.375 4.5.926.949 2.176 1.421 3.75 1.421 1.207 0 2.281-.32 3.219-.968a6.51 6.51 0 002.171-2.516c.52-1.039.782-2.133.782-3.281v-12.094h3.578V0h-3.406v-3.11h-.172c-.586 1.063-1.524 1.962-2.813 2.688-1.293.727-2.664 1.094-4.11 1.094zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M11.766.672C9.828.672 8.082.18 6.53-.797 4.977-1.773 3.754-3.125 2.86-4.844c-.898-1.726-1.343-3.68-1.343-5.86 0-2.155.445-4.1 1.343-5.843.895-1.738 2.118-3.098 3.672-4.078 1.551-.977 3.297-1.469 5.235-1.469 1.625 0 3.078.375 4.359 1.125 1.29.742 2.27 1.653 2.938 2.735h.171l-.172-2.97v-8.874h3.579V0h-3.407v-3.156h-.172c-.667 1.074-1.648 1.98-2.937 2.719C14.844.3 13.391.671 11.765.671zm.375-3.235a6.506 6.506 0 003.5-1c1.082-.675 1.953-1.628 2.609-2.859.656-1.238.984-2.664.984-4.281 0-1.625-.328-3.05-.984-4.281-.656-1.239-1.527-2.192-2.61-2.86a6.454 6.454 0 00-3.5-1.015c-1.23 0-2.39.34-3.484 1.015-1.094.668-1.969 1.625-2.625 2.875-.656 1.242-.984 2.664-.984 4.266 0 1.594.328 3.015.984 4.265.657 1.243 1.532 2.2 2.625 2.875 1.094.668 2.254 1 3.485 1zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M9.484.672C7.922.672 6.52.363 5.281-.25c-1.23-.613-2.183-1.457-2.86-2.531-.667-1.082-1-2.313-1-3.688 0-2.27.852-4.039 2.563-5.312 1.707-1.27 3.864-1.906 6.47-1.906 1.288 0 2.487.14 3.593.421 1.101.274 1.953.59 2.547.954v-1.297c0-1.594-.563-2.875-1.688-3.844-1.117-.969-2.527-1.453-4.234-1.453-1.18 0-2.277.261-3.297.781-1.023.523-1.828 1.246-2.422 2.172L2.266-17.97c.843-1.289 2.004-2.3 3.484-3.031 1.488-.727 3.129-1.094 4.922-1.094 2.906 0 5.18.766 6.828 2.297 1.656 1.524 2.484 3.602 2.484 6.235V0h-3.39v-3.063h-.172c-.617 1.032-1.543 1.915-2.781 2.641C12.41.305 11.023.672 9.484.672zm.344-3.156a6.424 6.424 0 003.36-.922 7.016 7.016 0 002.468-2.469 6.49 6.49 0 00.938-3.406c-.68-.446-1.508-.813-2.485-1.094A11.218 11.218 0 0011-10.797c-1.96 0-3.438.406-4.438 1.219-.992.812-1.484 1.867-1.484 3.156 0 1.18.445 2.133 1.344 2.86.894.718 2.031 1.078 3.406 1.078zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M10.125.672c-2.305 0-4.227-.54-5.766-1.61-1.53-1.082-2.62-2.46-3.265-4.14L4.28-6.516c.532 1.293 1.32 2.29 2.375 2.985a6.129 6.129 0 003.469 1.047c1.344 0 2.488-.27 3.438-.813C14.52-3.836 15-4.613 15-5.625c0-.895-.383-1.613-1.14-2.156-.762-.551-1.95-1.024-3.563-1.422l-2.61-.672c-1.71-.414-3.109-1.129-4.203-2.14-1.093-1.008-1.64-2.313-1.64-3.907 0-1.226.367-2.312 1.11-3.25.75-.937 1.737-1.656 2.968-2.156 1.226-.508 2.555-.766 3.984-.766 1.875 0 3.547.407 5.016 1.219 1.476.813 2.523 1.95 3.14 3.406l-3.109 1.422c-.95-1.906-2.64-2.86-5.078-2.86-1.18 0-2.21.278-3.094.829-.886.543-1.328 1.234-1.328 2.078 0 .781.305 1.418.922 1.906.625.492 1.55.89 2.781 1.203l3.11.797c2.093.531 3.671 1.324 4.734 2.375 1.07 1.055 1.61 2.336 1.61 3.844 0 1.313-.383 2.469-1.141 3.469-.75.992-1.774 1.75-3.063 2.281-1.293.531-2.719.797-4.281.797zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M17.469.672c-2.824 0-5.43-.68-7.813-2.047C7.281-2.75 5.391-4.641 3.984-7.047 2.586-9.46 1.891-12.125 1.891-15.03c0-2.914.695-5.578 2.093-7.985 1.407-2.406 3.297-4.297 5.672-5.672 2.383-1.375 4.989-2.062 7.813-2.062 2.218 0 4.297.398 6.234 1.188 1.945.78 3.55 1.886 4.813 3.312L26-23.687c-.98-1.176-2.215-2.079-3.703-2.704-1.48-.632-3.074-.953-4.781-.953-2.106 0-4.086.512-5.938 1.532-1.844 1.023-3.32 2.464-4.422 4.328C6.051-19.617 5.5-17.47 5.5-15.031c0 2.437.55 4.59 1.656 6.453 1.102 1.855 2.578 3.293 4.422 4.312a12.084 12.084 0 005.938 1.532c2.007 0 3.691-.313 5.047-.938a11.45 11.45 0 003.515-2.5c.727-.781 1.317-1.723 1.766-2.828.445-1.113.726-2.344.843-3.688H17.563V-16H32c.145.781.219 1.512.219 2.188 0 1.843-.297 3.64-.89 5.39a11.834 11.834 0 01-2.813 4.594c-2.774 3-6.453 4.5-11.047 4.5zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M12.516.672C10.41.672 8.52.164 6.844-.844a10.578 10.578 0 01-3.922-4.11c-.938-1.737-1.406-3.655-1.406-5.75 0-2.1.468-4.019 1.406-5.75a10.593 10.593 0 013.922-4.124c1.676-1.008 3.566-1.516 5.672-1.516 2.101 0 3.992.508 5.671 1.516a10.621 10.621 0 013.922 4.125c.938 1.73 1.407 3.648 1.407 5.75 0 2.094-.47 4.012-1.407 5.75a10.606 10.606 0 01-3.922 4.11C16.508.163 14.617.671 12.517.671zm0-3.235a7.218 7.218 0 003.671-.984c1.133-.656 2.047-1.598 2.735-2.828.687-1.238 1.031-2.68 1.031-4.328 0-1.656-.344-3.098-1.031-4.328-.688-1.239-1.602-2.188-2.735-2.844a7.218 7.218 0 00-3.671-.984 7.384 7.384 0 00-3.704.984c-1.148.656-2.062 1.605-2.75 2.844-.68 1.23-1.015 2.672-1.015 4.328 0 1.648.336 3.09 1.016 4.328.687 1.23 1.601 2.172 2.75 2.828a7.384 7.384 0 003.703.985zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M12.047 9.75c-2.512 0-4.59-.59-6.235-1.766-1.636-1.18-2.734-2.593-3.296-4.25l3.28-1.375c.446 1.227 1.224 2.227 2.329 3 1.102.77 2.41 1.157 3.922 1.157 2.219 0 3.937-.649 5.156-1.938 1.219-1.293 1.828-3.11 1.828-5.453v-2.406h-.172c-.699 1.094-1.687 1.984-2.968 2.672C14.617.078 13.17.42 11.547.42 9.703.422 8.016-.05 6.484-1c-1.523-.957-2.73-2.29-3.625-4-.898-1.707-1.343-3.656-1.343-5.844 0-2.144.445-4.078 1.343-5.797.895-1.726 2.102-3.066 3.625-4.015 1.532-.957 3.22-1.438 5.063-1.438 1.625 0 3.07.344 4.344 1.032 1.28.687 2.27 1.59 2.968 2.703h.172v-3.063h3.39V-.844c0 3.446-.952 6.07-2.858 7.875-1.899 1.813-4.403 2.719-7.516 2.719zm0-12.563c1.289 0 2.46-.32 3.515-.968 1.051-.645 1.891-1.567 2.516-2.766.633-1.207.953-2.64.953-4.297 0-1.676-.32-3.113-.953-4.312-.625-1.207-1.465-2.125-2.515-2.75-1.055-.633-2.227-.953-3.516-.953a6.65 6.65 0 00-3.516.968c-1.062.649-1.914 1.575-2.547 2.782-.625 1.199-.937 2.62-.937 4.265 0 1.656.312 3.094.937 4.313.633 1.218 1.485 2.148 2.547 2.781 1.063.625 2.235.938 3.516.938zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M2.64-30.078H6.22V0H2.64zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.766 0H1.062v-9.672h5.704zm-.813-.766V-8.89H1.875v8.125zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M5.61.219a5.007 5.007 0 01-2.516-.656A4.933 4.933 0 011.28-2.266C.832-3.036.61-3.89.61-4.828c0-.938.223-1.79.672-2.563a4.82 4.82 0 011.813-1.828 5.007 5.007 0 012.515-.656c.72 0 1.391.125 2.016.375s1.14.605 1.547 1.063l-.813.828a3.004 3.004 0 00-1.187-.875 4.07 4.07 0 00-1.547-.297c-.68 0-1.313.168-1.906.5-.594.324-1.07.789-1.422 1.39-.356.594-.531 1.282-.531 2.063 0 .781.175 1.476.53 2.078A3.766 3.766 0 003.72-1.36a3.907 3.907 0 001.906.485c.645 0 1.188-.098 1.625-.297a3.861 3.861 0 001.14-.812c.227-.25.415-.551.563-.907.145-.351.235-.75.266-1.187H5.64v-1.063h4.64c.051.25.078.485.078.704 0 .593-.101 1.171-.296 1.734a3.774 3.774 0 01-.891 1.469C8.273-.266 7.086.219 5.609.219zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M4.016.219a3.487 3.487 0 01-1.813-.485A3.34 3.34 0 01.938-1.594a3.802 3.802 0 01-.454-1.843c0-.676.149-1.29.454-1.844.3-.563.722-1.004 1.265-1.328a3.487 3.487 0 011.813-.485c.675 0 1.285.164 1.828.485.539.324.96.765 1.265 1.328.301.554.454 1.168.454 1.843 0 .668-.153 1.282-.454 1.844A3.395 3.395 0 015.844-.266 3.533 3.533 0 014.016.22zm0-1.047c.425 0 .82-.102 1.187-.313.363-.207.656-.507.875-.906.219-.394.328-.86.328-1.39 0-.532-.11-.993-.328-1.391a2.328 2.328 0 00-.875-.906 2.317 2.317 0 00-2.375 0 2.3 2.3 0 00-.875.906c-.219.398-.328.86-.328 1.39 0 .532.11.997.328 1.391.219.399.508.7.875.906.375.211.77.313 1.188.313zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.875 3.125c-.813 0-1.484-.188-2.016-.563-.523-.374-.87-.828-1.046-1.359L1.859.75c.145.395.395.719.75.969.352.25.774.375 1.266.375.707 0 1.254-.211 1.64-.625.395-.418.594-1 .594-1.75v-.766h-.046c-.231.344-.547.633-.954.86A2.89 2.89 0 013.72.14a3.09 3.09 0 01-1.64-.454A3.31 3.31 0 01.905-1.609C.625-2.16.484-2.785.484-3.484c0-.688.141-1.305.422-1.86.29-.562.68-.992 1.172-1.297.5-.3 1.047-.453 1.64-.453.52 0 .985.11 1.391.328.407.22.723.508.954.86h.046v-.985h1.094v6.625c0 1.102-.308 1.942-.922 2.516-.605.582-1.406.875-2.406.875zm0-4.031c.414 0 .79-.102 1.125-.313.344-.207.613-.504.813-.89.195-.383.296-.844.296-1.375 0-.54-.101-1.004-.296-1.391A2.101 2.101 0 005-5.75a2.103 2.103 0 00-1.125-.313c-.418 0-.797.106-1.14.313-.337.21-.606.508-.813.89-.2.387-.297.844-.297 1.376 0 .53.098.996.297 1.39.207.387.476.684.812.89.344.2.723.298 1.141.298zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M.844-9.672H2V0H.844zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.922.219C3.254.219 2.66.062 2.14-.25A3.29 3.29 0 01.92-1.563C.63-2.112.485-2.734.485-3.421c0-.664.133-1.281.407-1.844a3.31 3.31 0 011.171-1.328c.508-.332 1.098-.5 1.766-.5.664 0 1.242.153 1.735.453.5.293.878.704 1.14 1.235.27.531.406 1.14.406 1.828 0 .105-.007.226-.015.36H1.64c.02.523.14.964.359 1.327.227.356.516.621.86.797.35.18.718.266 1.093.266.906 0 1.586-.41 2.047-1.235l.969.47A3.525 3.525 0 015.766-.267C5.254.054 4.64.22 3.922.22zm1.984-4.39a2.275 2.275 0 00-.25-.86 1.894 1.894 0 00-.687-.735c-.305-.195-.684-.296-1.14-.296-.544 0-1.009.18-1.392.53-.374.345-.62.798-.734 1.36zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.875-9.672h1.297L8.844 0h-1.25l-.985-2.656H2.438L1.453 0H.203zm2.344 5.938L4.922-7.22l-.375-1.047H4.5l-.375 1.047-1.297 3.485zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.844-6.89h1.094v1.015H2c.188-.344.488-.629.906-.86.414-.238.852-.359 1.313-.359.832 0 1.468.242 1.906.719.438.48.656 1.133.656 1.953V0H5.625v-4.234c0-.645-.152-1.11-.453-1.391-.305-.29-.719-.438-1.25-.438-.375 0-.711.106-1 .313-.293.21-.524.484-.688.828A2.45 2.45 0 002-3.875V0H.844zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.047.219c-.5 0-.95-.102-1.344-.297a2.229 2.229 0 01-.922-.813 2.208 2.208 0 01-.328-1.187c0-.727.274-1.297.828-1.703.551-.414 1.242-.625 2.078-.625.414 0 .801.047 1.157.14.351.094.625.2.812.313v-.422c0-.508-.183-.922-.547-1.234-.355-.313-.808-.47-1.36-.47-.374 0-.73.087-1.062.25a1.928 1.928 0 00-.765.704l-.86-.656c.27-.414.641-.739 1.11-.969a3.635 3.635 0 011.578-.344c.937 0 1.672.246 2.203.735.531.492.797 1.156.797 2V0H5.328v-.984h-.047c-.199.336-.496.62-.89.859a2.666 2.666 0 01-1.344.344zm.11-1.016c.382 0 .741-.098 1.077-.297.332-.195.598-.46.797-.797.196-.332.297-.695.297-1.093a2.592 2.592 0 00-.797-.344 3.476 3.476 0 00-1-.14c-.625 0-1.101.132-1.422.39a1.265 1.265 0 00-.468 1.015c0 .376.14.684.421.922.29.23.657.344 1.094.344zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.953-.438L.11-6.89h1.235l2.172 5.125h.015L5.61-6.89h1.25L2.61 2.922H1.422zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.5.11c-.281 0-.543-.044-.781-.126a1.68 1.68 0 01-.594-.375c-.367-.343-.547-.816-.547-1.421v-4.032H.375V-6.89h1.203v-1.937h1.156v1.937h1.688v1.047H2.734v3.75c0 .375.067.656.204.844.175.2.425.297.75.297.28 0 .53-.078.75-.235v1.125a1.67 1.67 0 01-.407.126A2.45 2.45 0 013.5.108zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.531-8.156a.832.832 0 01-.593-.235.832.832 0 01-.235-.593.79.79 0 01.235-.579.832.832 0 01.593-.234.79.79 0 01.578.235.79.79 0 01.235.578c0 .23-.078.43-.235.593a.79.79 0 01-.578.235zM.953-6.891H2.11V0H.953zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.969.219A3.411 3.411 0 01.922-1.578C.629-2.128.484-2.75.484-3.438c0-.695.145-1.32.438-1.874.3-.563.719-1 1.25-1.313a3.474 3.474 0 011.797-.469c.75 0 1.375.172 1.875.516a2.87 2.87 0 011.093 1.375l-1.03.437c-.18-.425-.438-.75-.782-.968-.336-.22-.734-.329-1.203-.329-.406 0-.79.11-1.14.329a2.324 2.324 0 00-.845.921c-.21.399-.312.856-.312 1.375 0 .512.102.965.313 1.36.207.398.488.703.843.922.352.218.735.328 1.14.328.477 0 .895-.11 1.25-.328.352-.227.614-.551.782-.969l1.031.438a3.121 3.121 0 01-1.14 1.375c-.512.351-1.137.53-1.875.53zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.25.219c-.742 0-1.36-.172-1.86-.516C.899-.648.55-1.097.345-1.64l1.031-.453c.176.418.43.742.766.969.332.219.703.328 1.109.328.438 0 .805-.086 1.11-.265.3-.176.452-.426.452-.75 0-.282-.12-.508-.359-.688-.242-.176-.621-.328-1.14-.453l-.844-.219c-.555-.133-1.008-.363-1.36-.687-.343-.32-.515-.739-.515-1.25 0-.395.117-.743.36-1.047.237-.301.554-.532.952-.688a3.3 3.3 0 011.282-.25c.601 0 1.14.133 1.609.39.476.263.816.626 1.016 1.095l-1 .453c-.313-.614-.86-.922-1.641-.922-.375 0-.711.09-1 .266-.281.18-.422.402-.422.671 0 .25.098.454.297.61.195.156.492.289.89.39l1 .25c.676.18 1.188.434 1.532.766.343.336.515.746.515 1.234 0 .43-.125.805-.375 1.125-.242.313-.57.559-.984.735-.406.164-.867.25-1.375.25zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M0 0zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.094-6.89h1.234l2.125 5.546h.031L5.641-6.89h1.203L4.03 0H2.875zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.344.219C2.52.219 1.89-.02 1.454-.5 1.015-.988.796-1.672.796-2.547V-6.89h1.14v4.157c0 .668.145 1.152.438 1.453.3.304.707.453 1.219.453.383 0 .726-.098 1.031-.297.3-.207.535-.477.703-.813.164-.332.25-.687.25-1.062v-3.89h1.14V0H5.626v-1h-.047c-.187.344-.492.637-.906.875-.418.227-.86.344-1.328.344zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.844-6.89h1.094v1.109H2c.133-.375.395-.688.781-.938.383-.25.79-.375 1.219-.375.313 0 .582.047.813.14v1.25a2.196 2.196 0 00-.985-.218c-.336 0-.64.094-.922.281a2.03 2.03 0 00-.656.766c-.168.313-.25.656-.25 1.031V0H.844zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.781.219A3.118 3.118 0 012.094-.25c-.5-.32-.899-.758-1.188-1.313-.281-.55-.422-1.175-.422-1.875 0-.695.141-1.32.422-1.874.29-.563.688-1 1.188-1.313a3.118 3.118 0 011.687-.469c.52 0 .989.121 1.406.36.415.23.727.523.938.875h.063l-.063-.954v-2.859h1.156V0H6.188v-1.016h-.063c-.21.344-.523.637-.938.875a2.79 2.79 0 01-1.406.36zm.125-1.047c.406 0 .781-.102 1.125-.313.344-.218.617-.523.828-.921.22-.395.329-.852.329-1.376 0-.519-.11-.976-.329-1.374a2.406 2.406 0 00-.828-.922 2.093 2.093 0 00-2.25 0 2.324 2.324 0 00-.844.921c-.21.399-.312.856-.312 1.375 0 .512.102.97.313 1.376.207.398.488.703.843.921.352.211.727.313 1.125.313zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.5-.984l4.063-4.86H.624V-6.89h5.344v.985l-4.063 4.86h4.172V0H.5zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M4.344.219a2.77 2.77 0 01-1.407-.36C2.52-.379 2.208-.67 2-1.016h-.063V0H.845v-9.672H2v2.86l-.063.953H2c.207-.352.52-.645.938-.875a2.77 2.77 0 011.406-.36c.625 0 1.187.157 1.687.469.5.313.89.75 1.172 1.313.29.554.438 1.18.438 1.875 0 .699-.149 1.324-.438 1.874A3.292 3.292 0 016.031-.25c-.5.313-1.062.469-1.687.469zM4.219-.828c.394 0 .77-.102 1.125-.313.351-.218.633-.523.843-.921.208-.395.313-.852.313-1.376 0-.519-.105-.976-.313-1.374a2.381 2.381 0 00-.843-.922 2.093 2.093 0 00-2.25 0 2.506 2.506 0 00-.844.921c-.21.399-.313.856-.313 1.375 0 .524.102.981.313 1.376.219.398.5.703.844.921.351.211.726.313 1.125.313zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M.688-9.672h1.046v2.985H.688zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.797-6.89H1.89v1.03h.046c.208-.35.52-.644.938-.874a2.86 2.86 0 011.422-.36c.613 0 1.172.157 1.672.469.5.313.894.75 1.187 1.313.29.554.438 1.18.438 1.875 0 .699-.149 1.324-.438 1.874A3.418 3.418 0 015.97-.25c-.5.313-1.059.469-1.672.469a2.86 2.86 0 01-1.422-.36c-.418-.238-.73-.53-.938-.875h-.046l.046.954v2.984H.798zM4.172-.829c.394 0 .766-.102 1.11-.313.35-.218.632-.523.843-.921.207-.395.313-.852.313-1.376 0-.519-.106-.976-.313-1.374a2.381 2.381 0 00-.844-.922 2.032 2.032 0 00-1.11-.329c-.405 0-.78.11-1.124.329a2.506 2.506 0 00-.844.921c-.21.399-.312.856-.312 1.375 0 .524.101.981.312 1.376.219.398.5.703.844.921.344.211.719.313 1.125.313zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.64-5.844H.376V-6.89h1.266v-.734c0-.438.097-.816.296-1.14.196-.32.461-.57.797-.75a2.339 2.339 0 011.11-.266c.383 0 .722.058 1.015.172v1.125a2.743 2.743 0 00-.468-.157 1.836 1.836 0 00-.5-.062c-.305 0-.563.105-.782.312-.21.211-.312.508-.312.891v.61h1.765v1.046H2.798V0H1.64zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.984-1.688A3.166 3.166 0 015.86-.313c-.5.344-1.117.52-1.843.532l-.188.437a2.38 2.38 0 01.297-.031c.344 0 .633.117.875.36.238.238.36.546.36.921 0 .352-.133.657-.391.907-.262.257-.586.39-.969.39-.293 0-.555-.09-.781-.265a1.348 1.348 0 01-.485-.641L3.406 2c.04.133.114.25.219.344.102.094.227.14.375.14a.55.55 0 00.422-.171.565.565 0 00.172-.407.592.592 0 00-.172-.437A.537.537 0 004 1.297a.577.577 0 00-.469.234l-.61-.281.407-1.094a3.186 3.186 0 01-1.484-.625 3.364 3.364 0 01-1-1.265 3.974 3.974 0 01-.36-1.704c0-.695.145-1.32.438-1.874.3-.563.719-1 1.25-1.313a3.474 3.474 0 011.797-.469c.75 0 1.375.172 1.875.516a2.87 2.87 0 011.093 1.375l-1.03.437c-.18-.425-.438-.75-.782-.968-.336-.22-.734-.329-1.203-.329-.406 0-.79.11-1.14.329a2.324 2.324 0 00-.845.921c-.21.399-.312.856-.312 1.375 0 .512.102.965.313 1.36.207.398.488.703.843.922.352.218.735.328 1.14.328.477 0 .895-.11 1.25-.328.352-.227.614-.551.782-.969zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.922.219C3.254.219 2.66.062 2.14-.25A3.29 3.29 0 01.92-1.563C.63-2.112.485-2.734.485-3.421c0-.664.133-1.281.407-1.844a3.31 3.31 0 011.171-1.328c.508-.332 1.098-.5 1.766-.5.664 0 1.242.153 1.735.453.5.293.878.704 1.14 1.235.27.531.406 1.14.406 1.828 0 .105-.007.226-.015.36H1.64c.02.523.14.964.359 1.327.227.356.516.621.86.797.35.18.718.266 1.093.266.906 0 1.586-.41 2.047-1.235l.969.47A3.525 3.525 0 015.766-.267C5.254.054 4.64.22 3.922.22zm1.984-4.39a2.275 2.275 0 00-.25-.86 1.894 1.894 0 00-.687-.735c-.305-.195-.684-.296-1.14-.296-.544 0-1.009.18-1.392.53-.374.345-.62.798-.734 1.36zM4.031-9.938h1.25L4.328-8.22h-1zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.61.078a.839.839 0 01-.626-.25.85.85 0 01-.234-.61c0-.238.078-.44.234-.609a.854.854 0 01.625-.25c.239 0 .442.086.61.25.164.168.25.371.25.61a.831.831 0 01-.25.61.828.828 0 01-.61.25zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.188-9.672h1.265l2.39 7.016.329.953h.047l.343-.953 2.5-7.016h1.266L4.797 0H3.625zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.844-6.89h1.094v1.015H2c.195-.352.492-.645.89-.875a2.48 2.48 0 011.25-.344c.508 0 .946.121 1.313.36.375.242.645.562.813.968.238-.406.554-.726.953-.968.406-.239.875-.36 1.406-.36.79 0 1.383.242 1.781.719.406.48.61 1.133.61 1.953V0H9.875v-4.234c0-.645-.133-1.11-.39-1.391-.262-.29-.65-.438-1.157-.438a1.61 1.61 0 00-.937.297c-.274.2-.493.465-.657.797A2.525 2.525 0 006.5-3.89V0H5.36v-4.219c0-.656-.133-1.125-.391-1.406-.262-.29-.64-.438-1.14-.438-.345 0-.657.106-.938.313-.282.2-.5.469-.657.813A2.525 2.525 0 002-3.86V0H.844zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.844-9.672H2v6.078L5.234-6.89h1.47v.063L4-4.11 6.781-.047V0H5.406L3.188-3.313 2-2.125V0H.844zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.688 1.125L1.421 0a.836.836 0 01-.453-.25.781.781 0 01-.188-.531c0-.239.082-.442.25-.61a.831.831 0 01.61-.25.86.86 0 01.625.25c.164.168.25.371.25.61 0 .156-.032.304-.094.437a3.631 3.631 0 01-.281.485l-.907 1.343zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.61-3.5L.233-6.89h1.297l1.766 2.562 1.719-2.563h1.343L3.938-3.5 6.405 0H5.063L3.28-2.64 1.516 0H.172zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.844-9.672H2v2.86l-.063.937H2c.188-.352.484-.645.89-.875a2.762 2.762 0 011.344-.344c.844 0 1.485.246 1.922.735.438.492.657 1.136.657 1.937V0H5.671v-4.234c0-.625-.164-1.082-.484-1.375-.313-.301-.72-.454-1.22-.454-.366 0-.698.11-1 .329-.292.218-.527.5-.702.843A2.21 2.21 0 002-3.828V0H.844zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.25 1.844c-.656-.762-1.227-1.68-1.703-2.75a8.55 8.55 0 01-.703-3.438c0-1.218.234-2.363.703-3.437.476-1.07 1.047-1.989 1.703-2.75l.844.594c-.617.73-1.121 1.59-1.516 2.578a7.932 7.932 0 00-.594 3.015 7.99 7.99 0 00.594 3.016c.395.992.899 1.844 1.516 2.562zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.125-.063l.063-.953h-.063c-.21.344-.523.637-.938.875a2.79 2.79 0 01-1.406.36A3.118 3.118 0 012.094-.25c-.5-.32-.899-.758-1.188-1.313-.281-.55-.422-1.175-.422-1.875 0-.695.141-1.32.422-1.874.29-.563.688-1 1.188-1.313a3.118 3.118 0 011.687-.469c.52 0 .989.121 1.406.36.415.23.727.523.938.875h.063v-1.032H7.28v9.813H6.125zM3.906-.828c.406 0 .781-.102 1.125-.313.344-.218.617-.523.828-.921.22-.395.329-.852.329-1.376 0-.519-.11-.976-.329-1.374a2.406 2.406 0 00-.828-.922 2.093 2.093 0 00-2.25 0 2.324 2.324 0 00-.844.921c-.21.399-.312.856-.312 1.375 0 .512.102.97.313 1.376.207.398.488.703.843.921.352.211.727.313 1.125.313zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.266 1.234C.879.516 1.383-.336 1.78-1.328a8.067 8.067 0 00.594-3.016c0-1.031-.2-2.035-.594-3.015C1.383-8.348.88-9.207.266-9.938l.843-.593c.657.761 1.22 1.68 1.688 2.75a8.38 8.38 0 01.719 3.437c0 1.211-.243 2.356-.72 3.438-.468 1.07-1.03 1.988-1.687 2.75zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.766 0H1.062v-9.672h5.704zm-.813-.766V-8.89H1.875v8.125zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M0 0zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.64-5.844H.376V-6.89h1.266v-.734c0-.438.097-.816.296-1.14.196-.32.461-.57.797-.75a2.339 2.339 0 011.11-.266c.383 0 .722.058 1.015.172v1.125a2.743 2.743 0 00-.468-.157 1.836 1.836 0 00-.5-.062c-.305 0-.563.105-.782.312-.21.211-.312.508-.312.891v.61h3.125v-.735c0-.438.094-.816.281-1.14.195-.32.461-.57.797-.75a2.436 2.436 0 011.125-.266c.375 0 .71.058 1.016.172v1.125c-.18-.07-.34-.125-.485-.157a1.836 1.836 0 00-.5-.062 1.04 1.04 0 00-.765.312c-.211.211-.313.508-.313.891v.61h1.766v1.046H7.078V0H5.922v-5.844H2.797V0H1.64zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.5-8.156a.832.832 0 01-.594-.235.832.832 0 01-.234-.593.79.79 0 01.234-.579.832.832 0 01.594-.234.79.79 0 01.578.235.79.79 0 01.234.578c0 .23-.078.43-.234.593a.79.79 0 01-.578.235zM1.64-5.844H.376V-6.89h1.266v-.734c0-.438.097-.816.296-1.14.196-.32.461-.57.797-.75a2.339 2.339 0 011.11-.266c.383 0 .722.058 1.015.172v1.125a2.743 2.743 0 00-.468-.157 1.836 1.836 0 00-.5-.062c-.305 0-.563.105-.782.312-.21.211-.312.508-.312.891v.61h4.281V0H5.922v-5.844H2.797V0H1.64zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.734-5.844h-1.28V-6.89h1.28v-.734c0-.438.098-.816.297-1.14.196-.32.461-.57.797-.75a2.339 2.339 0 011.11-.266c.382 0 .722.058 1.015.172v1.125a4.201 4.201 0 00-.484-.157 1.758 1.758 0 00-.485-.062c-.312 0-.574.105-.78.312-.212.211-.313.508-.313.891v.61h1.75v1.046H7.89V0H6.734zM.844-6.89h1.094v1.094H2c.156-.406.426-.719.813-.937a2.565 2.565 0 011.296-.344c.188 0 .348.016.485.047v1.172a3.339 3.339 0 00-.625-.047c-.586 0-1.059.195-1.422.578-.367.387-.547.887-.547 1.5V0H.844zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M12.031 0H1.891v-17.188h10.14zm-1.453-1.375v-14.438H3.344v14.438zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.922-17.188h6.172a5.01 5.01 0 012.422.61 4.69 4.69 0 011.812 1.656c.457.7.688 1.492.688 2.375 0 .836-.215 1.563-.641 2.188-.43.617-1 1.093-1.719 1.437v.094a4.264 4.264 0 012.11 1.484c.539.711.812 1.532.812 2.469 0 .93-.234 1.766-.703 2.516a4.873 4.873 0 01-1.89 1.734A5.415 5.415 0 018.421 0h-6.5zm6.172 7.422c.57 0 1.078-.129 1.515-.39.438-.27.774-.614 1.016-1.031.238-.426.36-.864.36-1.313a2.66 2.66 0 00-.344-1.281 2.837 2.837 0 00-.985-1.047c-.418-.27-.906-.406-1.469-.406H3.954v5.468zm.453 7.829c.57 0 1.086-.145 1.547-.438.457-.29.804-.66 1.047-1.11.25-.456.375-.925.375-1.406 0-.476-.133-.945-.391-1.406a2.907 2.907 0 00-1.078-1.11c-.461-.28-.992-.421-1.594-.421h-4.5v5.89zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.953.39c-1.168 0-2.215-.28-3.14-.843A5.843 5.843 0 011.64-2.766C1.117-3.754.859-4.863.859-6.094c0-1.164.243-2.25.735-3.25a5.892 5.892 0 012.093-2.39c.907-.594 1.942-.891 3.11-.891 1.195 0 2.234.273 3.11.813a5.265 5.265 0 012.03 2.218c.47.938.704 2.016.704 3.235 0 .187-.016.398-.047.625H2.906c.04.93.258 1.714.657 2.359.406.637.914 1.117 1.53 1.438.626.312 1.27.468 1.938.468 1.602 0 2.813-.734 3.625-2.203l1.735.844a6.346 6.346 0 01-2.157 2.344c-.906.582-2 .875-3.28.875zm3.531-7.812a3.937 3.937 0 00-.437-1.531c-.25-.508-.649-.942-1.188-1.297-.543-.352-1.23-.531-2.062-.531-.961 0-1.777.312-2.453.937-.668.617-1.106 1.422-1.313 2.422zM7.172-17.656h2.234L7.703-14.61H5.922zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.516-12.234h1.937v1.796h.094c.344-.613.879-1.128 1.61-1.546a4.646 4.646 0 012.359-.641c1.468 0 2.586.43 3.359 1.281.781.856 1.172 2.024 1.172 3.5V0h-2.031v-7.531c0-1.133-.278-1.957-.829-2.469-.542-.52-1.289-.781-2.234-.781-.656 0-1.246.187-1.766.562a3.756 3.756 0 00-1.203 1.453 4.182 4.182 0 00-.437 1.875V0H1.516zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M7.063.39C5.875.39 4.804.11 3.858-.452a6.043 6.043 0 01-2.203-2.328C1.125-3.77.86-4.883.86-6.125c0-1.227.266-2.336.797-3.328a5.937 5.937 0 012.203-2.328c.946-.563 2.016-.844 3.204-.844 1.32 0 2.437.309 3.343.922a5.066 5.066 0 011.938 2.469l-1.86.765c-.304-.75-.757-1.32-1.359-1.719-.594-.394-1.32-.593-2.172-.593-.719 0-1.39.199-2.016.594-.617.398-1.117.949-1.5 1.656-.374.699-.562 1.5-.562 2.406 0 .918.188 1.73.563 2.438.382.699.882 1.246 1.5 1.64a3.76 3.76 0 002.015.578c.863 0 1.61-.195 2.234-.593.626-.395 1.086-.961 1.391-1.704L12.406-3a5.428 5.428 0 01-2 2.453c-.918.625-2.031.938-3.344.938zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.719-14.5a1.45 1.45 0 01-1.047-.422 1.428 1.428 0 01-.422-1.031c0-.414.14-.766.422-1.047a1.45 1.45 0 011.047-.422c.414 0 .758.14 1.031.422.281.281.422.633.422 1.047 0 .398-.14.742-.422 1.031a1.378 1.378 0 01-1.031.422zm-1.016 2.266H3.75V0H1.703zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.953.39c-1.168 0-2.215-.28-3.14-.843A5.843 5.843 0 011.64-2.766C1.117-3.754.859-4.863.859-6.094c0-1.164.243-2.25.735-3.25a5.892 5.892 0 012.093-2.39c.907-.594 1.942-.891 3.11-.891 1.195 0 2.234.273 3.11.813a5.265 5.265 0 012.03 2.218c.47.938.704 2.016.704 3.235 0 .187-.016.398-.047.625H2.906c.04.93.258 1.714.657 2.359.406.637.914 1.117 1.53 1.438.626.312 1.27.468 1.938.468 1.602 0 2.813-.734 3.625-2.203l1.735.844a6.346 6.346 0 01-2.157 2.344c-.906.582-2 .875-3.28.875zm3.531-7.812a3.937 3.937 0 00-.437-1.531c-.25-.508-.649-.942-1.188-1.297-.543-.352-1.23-.531-2.062-.531-.961 0-1.777.312-2.453.937-.668.617-1.106 1.422-1.313 2.422zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.89-1.75l7.22-8.64h-7v-1.844h9.5v1.75l-7.22 8.64h7.407V0H.89zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.719.39C5.613.39 4.617.11 3.734-.452c-.886-.563-1.59-1.332-2.109-2.313-.512-.988-.766-2.109-.766-3.359 0-1.227.254-2.336.766-3.328.52-.988 1.223-1.766 2.11-2.328.882-.563 1.878-.844 2.984-.844.926 0 1.754.215 2.484.64.738.419 1.3.938 1.688 1.563h.093l-.093-1.703v-5.063h2.046V0h-1.953v-1.797h-.093c-.387.606-.95 1.121-1.688 1.547-.73.426-1.558.64-2.484.64zm.218-1.859c.72 0 1.383-.187 2-.562.614-.383 1.11-.93 1.485-1.64.375-.708.562-1.524.562-2.454 0-.926-.187-1.738-.562-2.438-.375-.707-.871-1.253-1.485-1.64a3.723 3.723 0 00-2-.578c-.71 0-1.375.195-2 .578-.617.387-1.117.937-1.5 1.656-.374.711-.562 1.516-.562 2.422 0 .918.188 1.734.563 2.453.382.711.882 1.258 1.5 1.64.625.376 1.289.563 2 .563zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M1.219-17.188h1.86v5.313h-1.86zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.922-10.39H.672v-1.844h2.25v-1.329c0-.769.176-1.44.531-2.015a3.634 3.634 0 011.422-1.328 4.161 4.161 0 011.969-.469c.687 0 1.285.094 1.797.281v2a9.918 9.918 0 00-.829-.281c-.25-.07-.554-.11-.906-.11-.523 0-.976.188-1.36.563-.386.375-.577.902-.577 1.578v1.11h3.14v1.843H4.97V0H2.922zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M7.156.39c-1.199 0-2.281-.288-3.25-.859a6.132 6.132 0 01-2.25-2.36C1.125-3.815.86-4.913.86-6.124c0-1.195.266-2.29.797-3.281a6.063 6.063 0 012.25-2.344c.969-.582 2.051-.875 3.25-.875 1.196 0 2.274.293 3.235.875a5.997 5.997 0 012.234 2.344c.54.992.813 2.086.813 3.281 0 1.21-.274 2.309-.813 3.297a6.065 6.065 0 01-2.234 2.36C9.43.101 8.35.39 7.156.39zm0-1.859c.75 0 1.446-.187 2.094-.562.645-.375 1.164-.91 1.563-1.61.394-.707.593-1.535.593-2.484 0-.938-.199-1.754-.594-2.453-.398-.707-.917-1.25-1.562-1.625a4.045 4.045 0 00-2.094-.578c-.75 0-1.453.195-2.11.578-.655.375-1.183.918-1.577 1.625-.399.7-.594 1.515-.594 2.453 0 .95.195 1.777.594 2.484.394.7.922 1.235 1.578 1.61s1.36.562 2.11.562zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.516-12.234h1.937v1.968h.094c.238-.675.703-1.234 1.39-1.671.688-.438 1.41-.657 2.172-.657.551 0 1.032.078 1.438.235v2.203c-.512-.25-1.09-.375-1.735-.375-.593 0-1.14.168-1.64.5-.492.336-.887.789-1.188 1.36a3.897 3.897 0 00-.437 1.827V0H1.516zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.516-12.234h1.937v1.796h.094c.351-.624.879-1.144 1.578-1.562a4.387 4.387 0 012.25-.625c.895 0 1.672.219 2.328.656.664.43 1.145 1 1.438 1.719a4.883 4.883 0 011.703-1.719c.719-.437 1.55-.656 2.5-.656 1.406 0 2.46.43 3.172 1.281.707.856 1.062 2.024 1.062 3.5V0h-2.015v-7.531c0-1.133-.235-1.957-.704-2.469-.46-.52-1.14-.781-2.047-.781-.617 0-1.171.18-1.671.531a3.623 3.623 0 00-1.157 1.422 4.432 4.432 0 00-.421 1.922V0H9.53v-7.516c0-1.144-.234-1.972-.703-2.484-.46-.52-1.137-.781-2.031-.781-.617 0-1.172.183-1.672.547-.492.355-.875.836-1.156 1.437a4.539 4.539 0 00-.422 1.938V0H1.516zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M5.422.39c-.899 0-1.7-.18-2.406-.53-.7-.352-1.243-.837-1.625-1.454-.387-.613-.579-1.316-.579-2.11 0-1.288.489-2.3 1.47-3.03.976-.727 2.206-1.094 3.687-1.094a8.05 8.05 0 012.047.25c.632.156 1.125.34 1.468.547v-.75c0-.907-.324-1.633-.968-2.188-.637-.55-1.446-.828-2.422-.828-.668 0-1.293.152-1.875.453-.586.293-1.047.7-1.39 1.219l-1.532-1.14c.476-.739 1.14-1.317 1.984-1.735.852-.414 1.79-.625 2.813-.625 1.664 0 2.968.438 3.906 1.313.945.867 1.422 2.054 1.422 3.562V0H9.484v-1.75h-.093c-.356.594-.887 1.102-1.594 1.516-.7.414-1.492.625-2.375.625zm.187-1.812c.688 0 1.329-.172 1.922-.516a4.03 4.03 0 001.422-1.421 3.735 3.735 0 00.531-1.938 4.926 4.926 0 00-1.421-.625 6.331 6.331 0 00-1.782-.25c-1.117 0-1.96.234-2.531.703-.563.461-.844 1.059-.844 1.797 0 .68.254 1.227.766 1.64.508.407 1.156.61 1.937.61zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.219.188c-.5 0-.965-.079-1.39-.235a3.017 3.017 0 01-1.063-.656c-.637-.602-.954-1.442-.954-2.516v-7.172H.672v-1.843h2.14v-3.47h2.032v3.47h3v1.843h-3v6.672c0 .668.129 1.164.39 1.485.301.355.739.53 1.313.53.5 0 .945-.132 1.344-.405v1.984a2.724 2.724 0 01-.735.234c-.242.051-.554.079-.937.079zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M5.781.39C4.47.39 3.367.083 2.484-.53 1.61-1.156.988-1.945.625-2.906l1.828-.813c.3.742.75 1.309 1.344 1.703a3.521 3.521 0 001.984.594c.77 0 1.426-.156 1.969-.469.54-.312.813-.754.813-1.328 0-.508-.22-.922-.657-1.234-.43-.313-1.105-.578-2.031-.797l-1.484-.39c-.98-.239-1.782-.645-2.407-1.22-.617-.581-.921-1.327-.921-2.234 0-.707.207-1.328.625-1.86.425-.538.988-.952 1.687-1.233a5.976 5.976 0 012.281-.438c1.07 0 2.032.234 2.875.703.844.461 1.438 1.106 1.781 1.938l-1.765.812c-.543-1.082-1.512-1.625-2.906-1.625-.68 0-1.266.156-1.766.469-.5.312-.75.71-.75 1.187 0 .45.172.813.516 1.094.351.281.882.508 1.593.672l1.782.469c1.195.304 2.097.758 2.703 1.36.601.593.906 1.323.906 2.187 0 .75-.215 1.414-.64 1.984-.43.563-1.012 1-1.75 1.313-.743.3-1.56.453-2.454.453zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.422-12.234h1.937v1.812h.094c.375-.625.93-1.144 1.672-1.562.738-.426 1.57-.641 2.5-.641 1.102 0 2.098.281 2.984.844.895.562 1.598 1.34 2.11 2.328.508.992.765 2.101.765 3.328 0 1.25-.257 2.371-.765 3.36-.512.98-1.215 1.75-2.11 2.312C9.723.109 8.727.39 7.625.39c-.93 0-1.762-.215-2.5-.641-.742-.426-1.297-.941-1.672-1.547H3.36l.094 1.672v5.313H1.422zm6 10.765c.695 0 1.36-.187 1.984-.562.625-.383 1.117-.93 1.485-1.64.375-.708.562-1.524.562-2.454 0-.926-.187-1.738-.562-2.438-.368-.707-.86-1.253-1.485-1.64a3.75 3.75 0 00-1.984-.578c-.719 0-1.39.195-2.016.578-.617.387-1.11.933-1.484 1.64-.375.7-.563 1.512-.563 2.438 0 .93.188 1.746.563 2.453.375.711.867 1.258 1.484 1.64a3.85 3.85 0 002.016.563zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M1.516-17.188h2.03V0h-2.03zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.953.39c-1.168 0-2.215-.28-3.14-.843A5.843 5.843 0 011.64-2.766C1.117-3.754.859-4.863.859-6.094c0-1.164.243-2.25.735-3.25a5.892 5.892 0 012.093-2.39c.907-.594 1.942-.891 3.11-.891 1.195 0 2.234.273 3.11.813a5.265 5.265 0 012.03 2.218c.47.938.704 2.016.704 3.235 0 .187-.016.398-.047.625H2.906c.04.93.258 1.714.657 2.359.406.637.914 1.117 1.53 1.438.626.312 1.27.468 1.938.468 1.602 0 2.813-.734 3.625-2.203l1.735.844a6.346 6.346 0 01-2.157 2.344c-.906.582-2 .875-3.28.875zm3.531-7.812a3.937 3.937 0 00-.437-1.531c-.25-.508-.649-.942-1.188-1.297-.543-.352-1.23-.531-2.062-.531-.961 0-1.777.312-2.453.937-.668.617-1.106 1.422-1.313 2.422zM4.25-17.656h2.234L7.72-14.61H5.922zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.922-17.188h5.781c.957 0 1.844.22 2.656.657a5.054 5.054 0 011.97 1.812c.487.774.733 1.649.733 2.625 0 .98-.246 1.86-.734 2.64a5.044 5.044 0 01-1.969 1.798c-.812.43-1.699.64-2.656.64h-3.75V0H1.922zM7.75-8.953c.645 0 1.207-.149 1.688-.453.488-.301.867-.692 1.14-1.172.27-.477.406-.985.406-1.516s-.136-1.035-.406-1.515a3.306 3.306 0 00-1.14-1.172c-.481-.301-1.043-.453-1.688-.453H3.953v6.28zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M0 0zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M6.89 5.563c-1.437 0-2.624-.34-3.562-1.016-.937-.668-1.57-1.469-1.89-2.406l1.875-.797A3.54 3.54 0 004.64 3.062c.632.438 1.382.657 2.25.657 1.257 0 2.238-.371 2.937-1.11.695-.73 1.047-1.765 1.047-3.109v-1.375h-.094c-.406.625-.976 1.137-1.703 1.531-.73.383-1.558.578-2.484.578a5.367 5.367 0 01-2.89-.812c-.868-.54-1.56-1.297-2.079-2.266C1.113-3.82.859-4.938.859-6.188c0-1.238.254-2.347.766-3.328.52-.976 1.21-1.738 2.078-2.28a5.314 5.314 0 012.89-.829c.927 0 1.755.2 2.485.594.727.386 1.297.902 1.703 1.547h.094v-1.75h1.938v11.75c0 1.968-.547 3.468-1.641 4.5-1.086 1.03-2.512 1.546-4.281 1.546zm0-7.172c.727 0 1.395-.18 2-.547.602-.375 1.083-.906 1.438-1.594.363-.688.547-1.5.547-2.438 0-.957-.184-1.78-.547-2.468-.355-.696-.836-1.223-1.437-1.578a3.82 3.82 0 00-2-.547c-.743 0-1.418.187-2.032.562-.605.367-1.09.89-1.453 1.578-.355.688-.531 1.508-.531 2.454 0 .937.176 1.757.531 2.453.364.699.848 1.23 1.453 1.593.614.356 1.29.532 2.032.532zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.516-17.188h2.03v5.063l-.093 1.688h.094c.344-.626.875-1.145 1.594-1.563a4.748 4.748 0 012.39-.625c1.489 0 2.625.438 3.407 1.313.789.867 1.187 2.023 1.187 3.468V0h-2.047v-7.531c0-1.102-.281-1.922-.844-2.453-.554-.532-1.277-.797-2.171-.797-.649 0-1.243.195-1.782.578a4.156 4.156 0 00-1.265 1.5 4.067 4.067 0 00-.47 1.89V0h-2.03zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M4.625-6.219L.406-12.234H2.72l3.14 4.53 3.047-4.53h2.39L7.017-6.22 11.375 0H9L5.828-4.703 2.688 0H.312zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M5.953.39C4.484.39 3.36-.04 2.578-.905c-.773-.864-1.156-2.07-1.156-3.625v-7.703h2.031v7.39c0 1.168.266 2.024.797 2.563.531.543 1.242.812 2.14.812.688 0 1.298-.18 1.829-.547.539-.363.957-.843 1.25-1.437.289-.594.437-1.219.437-1.875v-6.906h2.047V0h-1.937v-1.781h-.11c-.336.617-.87 1.133-1.61 1.547A4.681 4.681 0 015.954.39zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.172-12.234h2.187l3.782 9.828h.046l3.844-9.828h2.14L7.172 0H5.11zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M9.984.39C8.367.39 6.88 0 5.516-.78a8.84 8.84 0 01-3.235-3.25c-.804-1.375-1.203-2.895-1.203-4.563 0-1.664.399-3.187 1.203-4.562a8.74 8.74 0 013.235-3.235c1.363-.78 2.851-1.172 4.468-1.172 1.258 0 2.446.227 3.563.672 1.113.45 2.031 1.078 2.75 1.891l-1.438 1.469a5.446 5.446 0 00-2.125-1.547 6.802 6.802 0 00-2.718-.547c-1.211 0-2.34.293-3.391.875a6.674 6.674 0 00-2.531 2.469c-.637 1.062-.953 2.293-.953 3.687 0 1.399.316 2.625.953 3.688a6.646 6.646 0 002.531 2.468c1.05.586 2.18.876 3.39.876 1.145 0 2.102-.176 2.876-.532a6.55 6.55 0 002.015-1.437 5.15 5.15 0 001-1.61 7.073 7.073 0 00.485-2.109h-6.36v-1.89h8.25c.082.449.125.867.125 1.25a9.696 9.696 0 01-.5 3.077 6.76 6.76 0 01-1.61 2.625C14.712-.468 12.61.392 9.985.392zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M12.031 0H1.891v-17.188h10.14zm-1.453-1.375v-14.438H3.344v14.438zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M11.547-14.5a1.45 1.45 0 01-1.047-.422 1.428 1.428 0 01-.422-1.031c0-.414.14-.766.422-1.047a1.45 1.45 0 011.047-.422c.414 0 .765.14 1.047.422.281.281.422.633.422 1.047 0 .398-.141.742-.422 1.031a1.426 1.426 0 01-1.047.422zm-8.625 4.11H.672v-1.844h2.25v-1.329c0-.769.176-1.44.531-2.015a3.634 3.634 0 011.422-1.328 4.161 4.161 0 011.969-.469c.687 0 1.285.094 1.797.281v2a9.918 9.918 0 00-.829-.281c-.25-.07-.554-.11-.906-.11-.523 0-.976.188-1.36.563-.386.375-.577.902-.577 1.578v1.11h7.61V0H10.53v-10.39H4.97V0H2.922zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M4.14 0H.517v-7.469H4.14zm-.28-.438V-7.03L2.468-3.734zM.796-6.968V-.5l1.36-3.234zM1-.282h2.64l-1.328-3.11zm1.313-3.797l1.328-3.11H1zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M5.734-3.063c0 .524-.152.922-.453 1.204-.304.273-.73.406-1.281.406-.543 0-.977-.176-1.297-.531-.324-.364-.484-.852-.484-1.47v-.593c0-.594.16-1.07.484-1.437.332-.364.77-.547 1.313-.547.55 0 .972.148 1.265.437.301.282.453.672.453 1.172h-.796c0-.32-.079-.55-.235-.687-.156-.145-.387-.22-.687-.22-.313 0-.559.118-.735.345-.18.23-.265.546-.265.953v.593c0 .407.086.727.265.954.176.23.422.343.735.343.3 0 .53-.066.687-.203.156-.133.234-.375.234-.719zm1.36-.671c0-.57-.133-1.098-.39-1.579a2.937 2.937 0 00-1.079-1.171 2.87 2.87 0 00-1.531-.438c-.555 0-1.063.14-1.532.422-.46.281-.824.668-1.093 1.156a3.37 3.37 0 00-.39 1.61c0 .586.128 1.12.39 1.609A2.98 2.98 0 002.562-.953c.47.281.973.422 1.516.422.55 0 1.063-.145 1.531-.438.47-.289.832-.68 1.094-1.172.258-.488.39-1.02.39-1.593zm-6.64 0c0-.688.155-1.329.468-1.922a3.556 3.556 0 011.328-1.39 3.516 3.516 0 011.844-.516c.656 0 1.265.171 1.828.515.562.336 1 .797 1.312 1.39.32.595.485 1.235.485 1.923 0 .687-.164 1.328-.485 1.921A3.557 3.557 0 015.922-.405a3.442 3.442 0 01-1.828.515A3.466 3.466 0 012.25-.406 3.652 3.652 0 01.922-1.812a4.065 4.065 0 01-.469-1.922zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M5.297 0H.844v-7.516h4.453zM4.64-.61v-6.296H1.484V-.61zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.5-1.078c1.426-1.406 2.3-2.29 2.625-2.656.313-.32.52-.582.625-.782a1.47 1.47 0 00.172-.718.987.987 0 00-.328-.735c-.211-.207-.496-.312-.86-.312-.336 0-.605.101-.812.297a1.37 1.37 0 00-.422.671L.484-5.734c.07-.25.204-.493.391-.735a2.39 2.39 0 01.766-.625c.32-.164.695-.25 1.125-.25.445 0 .847.094 1.203.282.351.187.625.437.812.75.196.312.297.664.297 1.046 0 .75-.387 1.508-1.156 2.266-.305.313-.649.664-1.031 1.047-.399.387-.696.683-.891.89v.032h3.156V0H.5zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.453.172a2.583 2.583 0 01-1.547-.484c-.449-.333-.793-.786-1.031-1.36-.242-.57-.36-1.21-.36-1.922 0-.707.118-1.344.36-1.906.238-.57.582-1.02 1.031-1.344.446-.332.961-.5 1.547-.5.594 0 1.113.168 1.563.5.445.324.789.774 1.03 1.344.24.563.36 1.2.36 1.906 0 .711-.12 1.352-.36 1.922-.241.574-.585 1.027-1.03 1.36-.45.32-.97.484-1.563.484zm0-1.078c.375 0 .695-.114.969-.344.27-.238.476-.563.625-.969.144-.406.219-.863.219-1.375 0-.508-.075-.969-.22-1.375-.148-.414-.355-.738-.624-.968a1.47 1.47 0 00-.969-.344c-.367 0-.683.117-.953.343-.273.231-.48.555-.625.97a4.31 4.31 0 00-.203 1.374c0 .512.066.969.203 1.375.145.406.352.73.625.969.27.23.586.344.953.344zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.219-5.656l-1.172.843-.578-.875 2.078-1.5h.812V0H2.22zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M4.406.172a3.9 3.9 0 01-1.968-.516A3.85 3.85 0 011-1.75a3.886 3.886 0 01-.531-2.016c0-.726.176-1.39.531-1.984a3.834 3.834 0 011.438-1.422 3.9 3.9 0 011.968-.516c.57 0 1.098.106 1.578.313.489.2.899.48 1.235.844l-.797.797c-.512-.57-1.184-.86-2.016-.86-.492 0-.949.121-1.375.36-.43.23-.765.562-1.015 1-.25.43-.375.918-.375 1.468 0 .563.125 1.063.375 1.5.257.43.597.762 1.015 1 .426.23.89.344 1.39.344.79 0 1.438-.25 1.938-.75.157-.156.286-.348.391-.578.113-.238.191-.504.234-.797H4.406v-1.015h3.64c.04.242.063.445.063.609 0 .48-.074.933-.218 1.36-.149.429-.383.808-.704 1.14a3.358 3.358 0 01-1.203.844 4.222 4.222 0 01-1.578.28zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.188.172a2.816 2.816 0 01-1.454-.375 2.63 2.63 0 01-1-1.016 2.947 2.947 0 01-.359-1.453c0-.531.117-1.015.36-1.453.238-.438.57-.773 1-1.016.425-.25.91-.375 1.452-.375a2.8 2.8 0 011.438.375c.426.25.754.594.984 1.032.239.43.36.906.36 1.437a2.99 2.99 0 01-.36 1.453c-.23.43-.558.766-.984 1.016a2.8 2.8 0 01-1.438.375zm0-1.047c.3 0 .578-.07.828-.219.25-.144.445-.351.593-.625.157-.27.235-.586.235-.953 0-.363-.078-.68-.235-.953a1.573 1.573 0 00-.593-.64 1.63 1.63 0 00-.829-.22c-.304 0-.585.075-.843.22-.25.148-.453.359-.61.64-.156.273-.234.59-.234.953 0 .367.078.684.234.953.157.274.36.48.61.625.258.149.539.219.844.219zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.078 2.438c-.656 0-1.2-.153-1.625-.454-.43-.293-.715-.652-.86-1.078L1.642.47c.113.289.296.52.546.687.258.176.555.266.891.266.5 0 .883-.149 1.156-.438.282-.293.422-.71.422-1.25V-.78h-.062a1.676 1.676 0 01-.703.61 2.177 2.177 0 01-1 .218A2.406 2.406 0 01.719-1.266C.489-1.69.375-2.18.375-2.734c0-.54.113-1.02.344-1.438.226-.426.531-.754.906-.984a2.35 2.35 0 011.266-.36 2.3 2.3 0 011 .22c.3.148.535.35.703.609h.062v-.672H5.75v5.078c0 .57-.117 1.062-.344 1.469a2.173 2.173 0 01-.937.937 3.024 3.024 0 01-1.39.313zM3.094-.97c.281 0 .539-.066.781-.203.238-.144.426-.348.563-.61.144-.269.218-.585.218-.952 0-.364-.074-.676-.218-.938a1.422 1.422 0 00-.563-.61 1.494 1.494 0 00-.781-.218c-.293 0-.559.074-.797.219-.242.136-.434.34-.578.61-.149.26-.219.573-.219.937 0 .367.07.683.219.953.144.261.336.465.578.61.238.136.504.202.797.202zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M.656-7.516h1.14V0H.657zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.11.172a2.77 2.77 0 01-1.407-.36A2.672 2.672 0 01.72-1.202a3.098 3.098 0 01-.344-1.469c0-.508.113-.984.344-1.422.226-.437.547-.781.953-1.031.406-.258.863-.39 1.375-.39.539 0 1.004.12 1.39.359.395.23.696.554.907.968.207.407.312.872.312 1.391 0 .149-.011.266-.031.36h-4.14c.05.511.234.906.546 1.187.32.273.692.406 1.11.406.343 0 .632-.078.875-.234a1.72 1.72 0 00.593-.625l.938.453a2.95 2.95 0 01-.984 1.047c-.407.25-.891.375-1.454.375zM4.53-3.281a1.58 1.58 0 00-.203-.594 1.228 1.228 0 00-.484-.484 1.434 1.434 0 00-.781-.204c-.387 0-.711.122-.97.36-.261.23-.445.539-.546.922zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M.844-8.313h1.031v9.579H.844zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M0 0zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3-7.516h1.313L7.155 0H5.875l-.688-1.938H2.126L1.422 0H.156zM4.813-3l-.844-2.297-.281-.86h-.063l-.281.86L2.5-3zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.14.172a2.897 2.897 0 01-1.437-.36A2.672 2.672 0 01.72-1.202a3.098 3.098 0 01-.344-1.469c0-.55.113-1.039.344-1.469.238-.425.566-.757.984-1 .426-.25.906-.375 1.438-.375.582 0 1.078.133 1.484.391.414.262.71.621.89 1.078L4.5-3.625c-.262-.57-.719-.86-1.375-.86-.305 0-.578.079-.828.235a1.598 1.598 0 00-.578.625c-.149.273-.219.59-.219.953 0 .356.07.668.219.938.144.273.336.484.578.64.25.149.523.219.828.219.332 0 .617-.07.86-.219a1.6 1.6 0 00.562-.656l1.016.438c-.211.46-.524.824-.938 1.093-.406.258-.902.39-1.484.39zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.61.172c-.65 0-1.141-.192-1.485-.578-.336-.395-.5-.938-.5-1.625V-5.36H1.75v3.171c0 .43.098.758.297.985.207.219.484.328.828.328.281 0 .531-.07.75-.219a1.41 1.41 0 00.5-.578c.113-.25.172-.52.172-.812V-5.36h1.125V0H4.359v-.719h-.062c-.156.25-.39.465-.703.64-.305.165-.633.25-.985.25zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.313-6.14a.76.76 0 01-.547-.22.76.76 0 01-.22-.546.72.72 0 01.22-.532.745.745 0 01.546-.234c.208 0 .383.078.532.234a.706.706 0 01.234.532c0 .21-.078.39-.234.547a.72.72 0 01-.532.218zm-.563.78h1.125V0H.75zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.656-5.36h1.078v.735h.063c.156-.258.383-.473.687-.64a2.12 2.12 0 011-.25c.645 0 1.133.195 1.47.577.331.375.5.899.5 1.563V0h-1.11v-3.234c0-.414-.11-.727-.328-.938-.211-.207-.496-.312-.86-.312a1.2 1.2 0 00-.703.218c-.21.149-.371.344-.484.594a1.803 1.803 0 00-.172.797V0H.657zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.344.172c-.387 0-.73-.074-1.031-.219a1.697 1.697 0 01-.704-.64 1.792 1.792 0 01-.25-.938c0-.375.094-.695.282-.969.195-.27.46-.472.796-.61a2.91 2.91 0 011.141-.218c.313 0 .594.031.844.094.258.055.469.121.625.203v-.281c0-.344-.133-.617-.39-.828-.25-.22-.575-.329-.97-.329-.273 0-.53.063-.78.188a1.465 1.465 0 00-.579.484l-.781-.593a2.25 2.25 0 01.906-.766 2.998 2.998 0 011.266-.266c.77 0 1.363.196 1.781.579.426.375.64.906.64 1.593V0H4.048v-.656h-.063a1.785 1.785 0 01-.671.594 1.965 1.965 0 01-.97.234zm.203-.922c.281 0 .535-.066.765-.203.227-.145.407-.332.532-.563.133-.226.203-.472.203-.734a2.084 2.084 0 00-.578-.234 2.497 2.497 0 00-.688-.094c-.437 0-.761.09-.969.265a.876.876 0 00-.312.704c0 .25.094.46.281.625.188.156.442.234.766.234zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.328-.297L.078-5.359h1.266l1.531 3.656h.047L4.406-5.36h1.25L2.36 2.266H1.141zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.86.078a2.07 2.07 0 01-.672-.11 1.154 1.154 0 01-.5-.312c-.305-.281-.454-.68-.454-1.203v-2.828H.297v-.984h.937v-1.5H2.36v1.5h1.313v.984H2.359v2.578c0 .293.055.5.172.625.114.137.297.203.547.203a.908.908 0 00.313-.047c.093-.039.195-.093.312-.156v1.094a2.167 2.167 0 01-.844.156zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.61.172c-.606 0-1.106-.133-1.5-.39a2.182 2.182 0 01-.844-1.048l1.03-.453c.126.293.302.516.532.672.227.149.488.219.781.219.301 0 .551-.05.75-.156.207-.114.313-.282.313-.5a.541.541 0 00-.266-.47c-.168-.112-.433-.21-.797-.296l-.64-.14a2.3 2.3 0 01-1.063-.547C.633-3.208.5-3.547.5-3.954c0-.313.086-.586.266-.828.187-.239.437-.422.75-.547a2.753 2.753 0 011.03-.188c.5 0 .938.102 1.313.297.375.188.645.469.813.844l-.969.453c-.219-.426-.605-.64-1.156-.64-.262 0-.485.058-.672.171-.18.106-.266.243-.266.407 0 .324.274.554.829.687l.78.188c.52.136.915.34 1.188.609.27.262.407.59.407.984 0 .336-.102.633-.297.891a1.88 1.88 0 01-.797.594 2.983 2.983 0 01-1.11.203zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M4.39.172c-.73 0-1.398-.172-2-.516A3.753 3.753 0 01.985-1.766a3.963 3.963 0 01-.515-2c0-.718.172-1.379.515-1.984a3.78 3.78 0 011.407-1.422 3.963 3.963 0 012-.516c1.125 0 2.05.415 2.78 1.235l-.812.781a2.78 2.78 0 00-.875-.687 2.38 2.38 0 00-1.078-.235c-.511 0-.98.121-1.406.36-.418.23-.75.562-1 1-.242.43-.36.918-.36 1.468 0 .563.118 1.063.36 1.5.25.43.582.762 1 1 .426.23.895.344 1.406.344.852 0 1.567-.351 2.14-1.062l.829.796c-.367.43-.805.762-1.313 1-.511.239-1.07.36-1.671.36zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.953.172c-.492 0-.933-.121-1.328-.36a2.664 2.664 0 01-.922-1.015 3.234 3.234 0 01-.328-1.469c0-.55.11-1.039.328-1.469a2.59 2.59 0 01.922-1c.395-.25.836-.375 1.328-.375.383 0 .727.086 1.031.25.301.168.532.383.688.641h.062l-.062-.75v-2.14h1.125V0H4.734v-.719h-.062c-.156.25-.387.465-.688.64-.304.165-.648.25-1.03.25zm.172-1.047c.27 0 .531-.07.781-.219.25-.156.446-.367.594-.64.156-.27.234-.582.234-.938 0-.363-.078-.68-.234-.953a1.607 1.607 0 00-.594-.625 1.457 1.457 0 00-.781-.234c-.281 0-.547.078-.797.234-.25.148-.453.355-.61.625-.148.273-.218.59-.218.953 0 .356.07.668.219.938.156.273.36.484.61.64.25.149.515.219.796.219zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M.531-7.516h1.031v2.344H.532zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.844-7.516h2.64c.457 0 .868.102 1.235.297.363.188.648.461.86.813.218.355.327.75.327 1.187 0 .5-.164.95-.484 1.344-.324.387-.73.648-1.219.781l-.016.047L6.267-.062V0H4.922l-2-2.953H2V0H.844zm2.61 3.5c.362 0 .671-.113.921-.343.25-.227.375-.52.375-.875 0-.32-.117-.602-.344-.844-.23-.238-.531-.36-.906-.36H2v2.422zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.11.172a2.77 2.77 0 01-1.407-.36A2.672 2.672 0 01.72-1.202a3.098 3.098 0 01-.344-1.469c0-.508.113-.984.344-1.422.226-.437.547-.781.953-1.031.406-.258.863-.39 1.375-.39.539 0 1.004.12 1.39.359.395.23.696.554.907.968.207.407.312.872.312 1.391 0 .149-.011.266-.031.36h-4.14c.05.511.234.906.546 1.187.32.273.692.406 1.11.406.343 0 .632-.078.875-.234a1.72 1.72 0 00.593-.625l.938.453a2.95 2.95 0 01-.984 1.047c-.407.25-.891.375-1.454.375zM4.53-3.281a1.58 1.58 0 00-.203-.594 1.228 1.228 0 00-.484-.484 1.434 1.434 0 00-.781-.204c-.387 0-.711.122-.97.36-.261.23-.445.539-.546.922zM1.75-7.766h1.297L3.53-6.39h-.953zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.266-4.375h-.97v-.984h.97v-.438c0-.562.171-1.004.515-1.328.352-.32.797-.484 1.328-.484.301 0 .579.046.829.14v1.094a3.305 3.305 0 00-.36-.125 1.45 1.45 0 00-.375-.047.783.783 0 00-.578.234c-.156.149-.234.352-.234.61v.344h1.343v.984H2.391V0H1.266zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M3.11.172a2.77 2.77 0 01-1.407-.36A2.672 2.672 0 01.72-1.202a3.098 3.098 0 01-.344-1.469c0-.508.113-.984.344-1.422.226-.437.547-.781.953-1.031.406-.258.863-.39 1.375-.39.539 0 1.004.12 1.39.359.395.23.696.554.907.968.207.407.312.872.312 1.391 0 .149-.011.266-.031.36h-4.14c.05.511.234.906.546 1.187.32.273.692.406 1.11.406.343 0 .632-.078.875-.234a1.72 1.72 0 00.593-.625l.938.453a2.95 2.95 0 01-.984 1.047c-.407.25-.891.375-1.454.375zM4.53-3.281a1.58 1.58 0 00-.203-.594 1.228 1.228 0 00-.484-.484 1.434 1.434 0 00-.781-.204c-.387 0-.711.122-.97.36-.261.23-.445.539-.546.922zM3.078-7.766H4.36L3.516-6.39h-.938zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.844-7.516h4.562v1.094H2v2.11h3.078v1.093H2v2.125h3.406V0H.844zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.078-5.36h1.25l1.5 3.907h.063L4.42-5.36h1.235L3.422 0h-1.14zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.656-5.36h1.078v.798h.063c.113-.282.316-.508.61-.688.288-.176.6-.266.937-.266.25 0 .469.04.656.11v1.172a2.12 2.12 0 00-.828-.188 1.22 1.22 0 00-.985.469c-.261.305-.39.68-.39 1.125V0H.657zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.656-5.36h1.078v.735h.063a1.83 1.83 0 01.672-.64 2 2 0 01.969-.25c.382 0 .718.09 1 .265.289.18.5.418.625.719.187-.301.43-.54.734-.719a2.162 2.162 0 011.078-.266c.613 0 1.078.188 1.39.563.32.367.485.871.485 1.516V0H7.625v-3.25c0-.82-.352-1.234-1.047-1.234-.387 0-.703.156-.953.468-.242.305-.36.688-.36 1.157V0H4.142v-3.25c0-.82-.36-1.234-1.079-1.234-.367 0-.667.156-.906.468-.242.313-.36.7-.36 1.157V0H.657zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M5.297 0H.844v-7.516h4.453zM4.64-.61v-6.296H1.484V-.61zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M0 0zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.25-1h4.938v-9.875H2.25zm6 1H1.187v-11.875H8.25zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph8-1" overflow="visible">
            <path
              stroke="none"
              d="M5.5-10L3.562-4.64h3.954L5.562-10zM1.89 0H.204l4.469-11.875h1.734L10.875 0H9.187l-1.14-3.219h-5zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph8-2" overflow="visible">
            <path
              stroke="none"
              d="M.922-8.125H2.39V-7h.062c.227-.395.582-.723 1.063-.984.488-.27.988-.407 1.5-.407 1 0 1.765.29 2.296.86.54.562.813 1.37.813 2.422V0H6.594v-5c-.032-1.332-.7-2-2-2-.606 0-1.117.25-1.532.75-.406.492-.609 1.074-.609 1.75V0H.922zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph8-3" overflow="visible">
            <path
              stroke="none"
              d="M2.047-2.484c0 .398.164.73.5 1 .332.261.726.39 1.187.39.633 0 1.204-.234 1.704-.703.507-.476.765-1.039.765-1.687-.48-.375-1.148-.563-2-.563-.617 0-1.133.152-1.547.453-.406.293-.61.664-.61 1.11zm1.969-5.907c1.125 0 2.007.305 2.656.907.656.605.984 1.433.984 2.484v5H6.203v-1.125h-.062c-.637.93-1.48 1.39-2.532 1.39C2.711.266 1.961 0 1.36-.53.766-1.062.47-1.723.47-2.516c0-.843.316-1.507.953-2 .633-.5 1.484-.75 2.547-.75.906 0 1.648.164 2.234.485v-.344c0-.531-.215-.977-.64-1.344a2.127 2.127 0 00-1.47-.562c-.855 0-1.527.36-2.015 1.078L.734-6.797C1.473-7.859 2.566-8.39 4.016-8.39zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph8-4" overflow="visible">
            <path stroke="none" d="M2.453-11.875V0H.922v-11.875zm0 0"></path>
          </symbol>
          <symbol id="glyph8-5" overflow="visible">
            <path
              stroke="none"
              d="M8.188-8.125L3.093 3.578H1.53l1.89-4.094L.064-8.125h1.656L4.14-2.281h.03l2.36-5.844zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph8-6" overflow="visible">
            <path
              stroke="none"
              d="M4.14.125c-.656 0-1.202-.203-1.64-.61-.438-.406-.664-.972-.672-1.703v-4.546H.391v-1.391h1.437v-2.484h1.516v2.484h2v1.39h-2v4.048c0 .542.101.914.312 1.109.219.187.457.281.719.281.125 0 .242-.008.36-.031.113-.031.222-.067.327-.11l.47 1.36c-.4.133-.86.203-1.391.203zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph8-7" overflow="visible">
            <path
              stroke="none"
              d="M2.906-10.922c0 .293-.11.547-.328.766-.21.21-.46.312-.75.312-.305 0-.558-.101-.766-.312a1.062 1.062 0 01-.312-.766c0-.3.102-.555.313-.765A1.04 1.04 0 011.828-12c.29 0 .54.105.75.313.219.21.328.464.328.765zm-.312 2.797V0H1.063v-8.125zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph8-8" overflow="visible">
            <path
              stroke="none"
              d="M4.64.266c-1.21 0-2.21-.41-3-1.235C.86-1.8.47-2.832.47-4.063c0-1.25.39-2.28 1.172-3.093.789-.82 1.789-1.235 3-1.235.832 0 1.554.211 2.171.625.614.418 1.079.993 1.391 1.72l-1.39.577C6.383-6.489 5.628-7 4.546-7c-.7 0-1.305.281-1.813.844-.5.562-.75 1.261-.75 2.093 0 .836.25 1.532.75 2.094.508.563 1.114.844 1.813.844 1.113 0 1.894-.508 2.344-1.531l1.359.578A3.604 3.604 0 016.86-.36c-.637.414-1.376.625-2.22.625zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph8-9" overflow="visible">
            <path
              stroke="none"
              d="M7.14-2.25c0 .71-.312 1.309-.937 1.797-.617.476-1.39.719-2.328.719-.813 0-1.531-.215-2.156-.641A3.558 3.558 0 01.39-2.063L1.75-2.64c.207.493.5.871.875 1.141.383.273.8.406 1.25.406.488 0 .895-.101 1.219-.312.332-.207.5-.457.5-.75 0-.52-.403-.899-1.203-1.14L3-3.642C1.414-4.047.625-4.812.625-5.937c0-.739.3-1.333.906-1.782.602-.445 1.375-.672 2.313-.672.719 0 1.367.172 1.953.516.582.344.988.805 1.219 1.375l-1.36.563a1.68 1.68 0 00-.765-.797 2.305 2.305 0 00-1.157-.297c-.398 0-.757.101-1.078.297-.312.199-.469.445-.469.734 0 .46.438.793 1.313 1l1.219.313C6.332-4.29 7.14-3.478 7.14-2.25zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.156 0v-6.61h6.828V0zm5.938-.906V-5.72H1.062v4.813zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M4.469-2.563L3.312-5.328 2.157-2.562zM.14 0v-.188C.398-.206.594-.3.719-.468c.133-.165.36-.618.672-1.36l2.078-4.906h.203l2.484 5.671c.164.375.297.61.39.704.102.086.274.14.516.171V0H4.517v-.188c.289-.019.476-.046.562-.078.082-.039.125-.132.125-.28 0-.052-.016-.141-.047-.266a2.26 2.26 0 00-.14-.391l-.407-.953H1.984A43.567 43.567 0 001.516-.97a1.312 1.312 0 00-.063.344c0 .18.067.297.203.36.082.042.239.07.469.078V0zm3.421-6.734zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.188-.14c.226-.032.39-.087.484-.172.094-.083.14-.274.14-.579v-2.468c0-.207-.023-.352-.062-.438-.063-.125-.195-.187-.39-.187H.28c-.031 0-.074.007-.125.015v-.172c.133-.039.453-.14.953-.296l.454-.157c.03 0 .046.012.046.032V-3.782c.301-.281.536-.473.704-.578.257-.157.523-.235.796-.235.22 0 .422.063.61.188.351.25.531.687.531 1.312v2.281c0 .243.047.415.14.516.094.106.25.156.47.156V0H2.78v-.14c.227-.032.39-.094.485-.188.093-.102.14-.32.14-.656v-2.079c0-.28-.054-.507-.156-.687-.105-.188-.293-.281-.563-.281-.199 0-.398.062-.593.187-.117.074-.258.2-.422.375v2.735c0 .23.05.386.156.468.102.086.266.125.484.125V0H.188zM2.5-4.595zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.86-2.703a5.178 5.178 0 00-.922.406c-.47.293-.704.621-.704.985 0 .304.098.523.297.656.125.094.266.14.422.14.219 0 .422-.054.61-.171.195-.125.296-.282.296-.47zM.36-.97c0-.476.238-.875.718-1.187.3-.196.895-.461 1.781-.797v-.406c0-.332-.03-.567-.093-.704-.106-.218-.336-.328-.688-.328a.936.936 0 00-.469.125c-.156.094-.234.22-.234.375 0 .032.008.094.031.188.02.094.032.156.032.187 0 .18-.063.305-.188.375a.485.485 0 01-.25.063.43.43 0 01-.344-.14.512.512 0 01-.11-.329c0-.219.141-.453.423-.703.281-.25.695-.375 1.25-.375.625 0 1.05.203 1.281.61.113.23.172.558.172.984v1.953c0 .187.015.32.047.39.039.125.129.188.265.188.07 0 .13-.008.172-.031.051-.02.14-.07.266-.156v.25c-.106.124-.219.23-.344.312a.943.943 0 01-.547.188c-.23 0-.398-.07-.5-.22a1.003 1.003 0 01-.156-.515c-.242.219-.45.383-.625.485-.305.18-.586.265-.844.265C1.125.078.88-.02.672-.219a.973.973 0 01-.313-.75zm1.874-3.656zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.203-.14c.3-.032.504-.087.61-.172.113-.094.171-.274.171-.547v-4.782c0-.207-.023-.351-.062-.437-.063-.133-.195-.203-.39-.203-.044 0-.095.008-.157.015a3.272 3.272 0 00-.188.047v-.172c.438-.113.961-.27 1.579-.468.02 0 .03.011.03.03a.401.401 0 01.016.126v5.86c0 .28.047.464.141.546.102.086.305.137.61.156V0H.203zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.14-4.484h2.063v.14c-.168.012-.289.028-.36.047-.155.043-.234.125-.234.25 0 .055.004.106.016.156l.094.204 1.14 2.53.922-2.562c.008-.02.024-.078.047-.172.02-.093.031-.156.031-.187 0-.082-.03-.14-.093-.172a.466.466 0 00-.22-.078l-.14-.016v-.14h1.328v.14a.557.557 0 00-.296.14c-.063.075-.122.18-.172.313L2.734.188c-.273.695-.53 1.203-.78 1.515-.243.32-.548.484-.923.484-.18 0-.351-.046-.515-.14C.359 1.96.28 1.817.28 1.609c0-.125.047-.23.14-.312a.558.558 0 01.376-.125c.082 0 .207.031.375.094.164.062.285.093.36.093.175 0 .363-.214.562-.64.207-.418.312-.7.312-.844 0-.031-.008-.066-.015-.11a2.672 2.672 0 01-.032-.109L.797-3.719C.68-3.969.582-4.129.5-4.203a.723.723 0 00-.36-.14zm2.329-.094zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.547-4.5v.344h-1L1.53-1.344c0 .25.024.438.078.563.07.23.223.344.454.344.113 0 .21-.024.296-.079a1.6 1.6 0 00.297-.265l.125.11-.094.14c-.18.23-.359.39-.546.484-.188.094-.371.14-.547.14-.387 0-.649-.171-.782-.515C.739-.609.704-.863.704-1.188v-2.968H.172c-.024-.008-.04-.016-.047-.016v-.047c0-.031.004-.054.016-.078.02-.02.066-.062.14-.125.227-.187.39-.336.485-.453.101-.113.336-.414.703-.906.039 0 .066.008.078.015.008 0 .016.024.016.063V-4.5zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.828-6.297c0-.144.047-.266.14-.36a.467.467 0 01.36-.155c.133 0 .254.054.36.156a.473.473 0 01.156.36.498.498 0 01-.157.359.528.528 0 01-.359.14.483.483 0 01-.36-.14.515.515 0 01-.14-.36zM.203-.14c.344-.03.555-.086.64-.172.095-.082.141-.316.141-.703v-2.328c0-.207-.011-.351-.03-.437-.056-.133-.157-.203-.313-.203a1.81 1.81 0 00-.406.094v-.157l.218-.062c.563-.188.957-.329 1.188-.422.093-.04.148-.063.171-.063.008.024.016.047.016.078v3.5c0 .375.04.61.125.704.094.093.297.152.61.171V0H.203zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.484-4.61c.407 0 .754.11 1.047.329.301.21.453.437.453.687a.446.446 0 01-.125.297c-.074.094-.187.14-.343.14a.423.423 0 01-.297-.109.731.731 0 01-.188-.359l-.062-.234a.624.624 0 00-.219-.36.75.75 0 00-.422-.11c-.375 0-.695.169-.953.5-.25.325-.375.75-.375 1.282 0 .492.14.934.422 1.328.281.387.66.578 1.14.578.344 0 .645-.109.907-.328.156-.125.328-.328.515-.61l.125.095c-.18.386-.37.695-.578.921-.398.438-.855.657-1.375.657-.5 0-.949-.196-1.344-.594C.426-.906.235-1.453.235-2.14c0-.676.211-1.255.641-1.735.426-.488.96-.734 1.61-.734zm-.218 0zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.516-1.531h.156c.082.367.187.648.312.843.227.368.563.547 1 .547.239 0 .43-.066.579-.203a.67.67 0 00.218-.515.71.71 0 00-.125-.391c-.086-.133-.23-.258-.437-.375l-.547-.313c-.399-.218-.688-.437-.875-.656a1.138 1.138 0 01-.281-.765c0-.364.129-.66.39-.891.258-.238.582-.36.969-.36.176 0 .367.032.578.094.207.063.32.094.344.094a.309.309 0 00.125-.016A.297.297 0 003-4.516h.11l.03 1.36H3c-.074-.32-.164-.567-.266-.735-.21-.332-.5-.5-.875-.5-.23 0-.414.075-.546.22a.692.692 0 00-.188.484c0 .304.223.574.672.812l.64.344c.696.375 1.047.812 1.047 1.312 0 .387-.148.703-.437.953-.281.243-.656.36-1.125.36-.2 0-.422-.032-.672-.094C1.008-.07.863-.11.812-.11a.148.148 0 00-.109.048.575.575 0 00-.062.109H.516zM1.953-4.61zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.094-.14c.258-.032.43-.094.515-.188.082-.102.125-.332.125-.688v-4.687c0-.188-.027-.32-.078-.406-.043-.082-.152-.125-.328-.125a.433.433 0 00-.11.015H.11v-.172c.133-.039.305-.086.516-.14.219-.063.363-.11.438-.14l.468-.141.016.03v3c.207-.238.39-.41.547-.515.289-.195.597-.297.922-.297.53 0 .894.219 1.093.657.102.242.157.546.157.921v2c0 .344.035.57.109.672.082.106.25.172.5.203V0H2.75v-.14c.281-.04.46-.11.547-.204.082-.094.125-.316.125-.672V-3c0-.313-.055-.563-.156-.75-.106-.195-.305-.297-.594-.297-.262 0-.512.094-.75.281-.23.18-.344.293-.344.344v2.406c0 .368.04.594.125.688.082.086.258.148.531.187V0H.094zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.594-.906c.082.23.207.402.375.515.176.106.375.157.593.157.352 0 .657-.176.907-.532.25-.351.375-.843.375-1.468 0-.594-.133-1.036-.39-1.329-.263-.3-.563-.453-.907-.453-.242 0-.461.079-.656.235-.2.148-.297.289-.297.422zM.047 2.016c.3-.024.492-.09.578-.204.082-.105.125-.277.125-.515v-4.688c0-.25-.031-.406-.094-.468-.054-.07-.164-.11-.328-.11a.433.433 0 00-.11.016H.095v-.156l.453-.157c.02 0 .348-.109.984-.328.02 0 .032.012.032.032a.084.084 0 01.015.046v.657c.219-.22.41-.38.578-.485.29-.176.586-.265.89-.265.446 0 .833.195 1.157.578.332.375.5.898.5 1.562 0 .649-.2 1.235-.594 1.766-.386.531-.875.797-1.468.797-.18 0-.336-.024-.47-.063a1.447 1.447 0 01-.577-.375V1.25c0 .332.05.535.156.61.113.07.352.117.719.14v.172H.047zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.89-4.063a.522.522 0 01-.171.407.51.51 0 01-.375.156.494.494 0 01-.39-.172.575.575 0 01-.141-.375c0-.156.05-.285.156-.39a.526.526 0 01.75 0c.113.105.172.23.172.375zM1.329.079a.498.498 0 01-.36-.156.521.521 0 01-.155-.39c0-.145.05-.27.156-.376a.486.486 0 01.375-.172.6.6 0 01.406.157.512.512 0 01.172.39.523.523 0 01-.172.375.55.55 0 01-.422.172zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path stroke="none" d="M2.234-6.766h.5L.516.172h-.5zm0 0"></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.625.125a.536.536 0 01-.39-.156.576.576 0 01-.157-.406c0-.157.05-.286.156-.391a.57.57 0 01.782 0 .512.512 0 01.171.39.558.558 0 01-.562.563zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.984.906c0 .27.149.457.454.563.312.101.664.156 1.062.156.54 0 .977-.094 1.313-.281.343-.188.515-.418.515-.688 0-.219-.137-.367-.406-.437-.18-.043-.5-.07-.969-.078-.117 0-.242-.008-.375-.016h-.375A11.622 11.622 0 001.86.078 4.888 4.888 0 011.5.016c-.031 0-.125.101-.281.312C1.062.535.984.727.984.906zM1.61-1.64a1.254 1.254 0 01-.687-.515 1.545 1.545 0 01-.25-.86c0-.375.148-.734.453-1.078.313-.343.742-.515 1.297-.515.238 0 .5.058.781.171a2.2 2.2 0 00.828.172h.297c.145-.007.25-.015.313-.015h.062v.39H3.86c.051.137.094.258.125.36.063.187.094.367.094.531 0 .375-.156.719-.469 1.031-.304.313-.714.469-1.234.469-.086 0-.234-.016-.453-.047-.094 0-.219.086-.375.25-.149.156-.219.281-.219.375 0 .106.113.184.344.235.144.03.305.046.484.046C3-.64 3.57-.594 3.875-.5c.488.156.734.484.734.984 0 .52-.293.93-.875 1.235-.574.3-1.152.453-1.734.453-.531 0-.953-.11-1.266-.328-.312-.211-.468-.434-.468-.672 0-.117.039-.23.125-.344.082-.117.242-.281.484-.5l.328-.281.063-.063a1.533 1.533 0 01-.344-.171C.797-.29.734-.407.734-.531c0-.125.051-.258.157-.406.113-.145.351-.38.718-.704zm.938-.093A.68.68 0 003-1.891c.188-.156.281-.425.281-.812 0-.313-.078-.66-.234-1.047-.156-.383-.418-.578-.781-.578-.325 0-.543.152-.657.453a1.617 1.617 0 00-.093.594c0 .398.093.758.28 1.078.188.312.438.469.75.469zM2.5-4.61zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.25-2.266c0-.644.203-1.191.61-1.64.413-.446.953-.672 1.609-.672.633 0 1.164.215 1.594.64.425.418.64.981.64 1.688 0 .648-.21 1.21-.625 1.688-.406.48-.937.718-1.594.718A2.112 2.112 0 01.891-.53C.46-.988.25-1.566.25-2.266zM2.328-4.28a.987.987 0 00-.672.25c-.324.293-.484.804-.484 1.531 0 .586.129 1.125.39 1.625.258.5.625.75 1.094.75.364 0 .645-.164.844-.5.195-.344.297-.785.297-1.328 0-.563-.133-1.086-.39-1.578-.25-.5-.61-.75-1.079-.75zm.172-.297zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.297-4.578c.457 0 .86.164 1.203.484.344.313.516.762.516 1.344H.906c.04.75.211 1.297.516 1.64.312.344.68.516 1.11.516.331 0 .613-.086.843-.265.238-.176.457-.426.656-.75l.172.046c-.125.418-.375.805-.75 1.157A1.854 1.854 0 012.11.125c-.605 0-1.074-.227-1.406-.688a2.687 2.687 0 01-.5-1.609c0-.656.192-1.219.578-1.687.383-.477.89-.72 1.516-.72zM2-4.218c-.367 0-.648.163-.844.484-.105.18-.18.402-.218.671H3c-.043-.332-.105-.578-.188-.734-.167-.281-.437-.422-.812-.422zm.234-.36zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.156-.125c.258-.02.43-.063.516-.125.125-.094.187-.285.187-.578v-2.516c0-.238-.03-.394-.093-.469-.063-.082-.168-.124-.313-.124a.806.806 0 00-.156.015.73.73 0 01-.11.031v-.171l.344-.11c.125-.039.332-.117.625-.234.29-.114.442-.172.453-.172.02 0 .032.012.032.031a.291.291 0 01.015.094v.656c.32-.289.598-.492.828-.61.239-.113.477-.171.72-.171.343 0 .612.117.812.344.101.125.187.296.25.515.238-.25.445-.43.625-.547.312-.207.629-.312.953-.312.52 0 .867.215 1.047.64.101.25.156.637.156 1.157v2.015c0 .23.05.387.156.47.102.085.285.14.547.171V0H5.547v-.14c.289-.02.476-.07.562-.157.094-.094.141-.273.141-.547v-2.11c0-.312-.04-.538-.11-.687-.117-.27-.35-.406-.702-.406-.211 0-.418.07-.625.203a3.375 3.375 0 00-.454.39v2.5c0 .274.047.477.141.61.094.137.29.211.594.219V0h-2.25v-.125c.312-.04.508-.113.594-.219.082-.113.124-.39.124-.828v-1.36c0-.5-.03-.843-.093-1.03-.106-.32-.336-.485-.688-.485-.199 0-.398.059-.594.172a1.766 1.766 0 00-.5.422v2.672c0 .242.04.414.125.515.094.094.286.141.579.141V0H.156zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M4.656-4.39c-.199.03-.343.07-.437.109-.094.043-.14.125-.14.25 0 .062.007.133.03.203.02.074.047.164.079.265l.89 2.313.875-2.11a9.86 9.86 0 00.11-.359c.05-.156.078-.27.078-.344 0-.113-.04-.19-.11-.234a1.115 1.115 0 00-.328-.094v-.14h1.234v.14a.292.292 0 00-.187.11c-.055.062-.125.21-.219.437L4.97.016c-.024.03-.04.05-.047.062-.012.008-.024.016-.031.016a.533.533 0 01-.079-.047L3.72-2.688 2.422.047c0 .008-.008.02-.016.031a.084.084 0 01-.047.016C2.316.07 2.297.054 2.297.047L.828-3.547C.672-3.94.566-4.172.516-4.234.46-4.304.359-4.36.203-4.391v-.14h1.813v.14a1.34 1.34 0 00-.36.094c-.074.031-.11.117-.11.25 0 .094.067.328.204.703.07.211.172.465.297.766l.562 1.39.875-1.89v-.063c0-.062-.039-.18-.109-.359a5.842 5.842 0 00-.188-.469.66.66 0 00-.218-.297.807.807 0 00-.36-.125v-.14h2.047zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.031-6.219v-.156a8.02 8.02 0 001-.281c.301-.102.461-.157.485-.157.007.024.015.04.015.047v2.985c.082-.156.207-.305.375-.453.301-.239.645-.36 1.032-.36.476 0 .882.203 1.218.61.344.398.516.918.516 1.562 0 .7-.219 1.293-.656 1.781C3.578-.148 3 .094 2.28.094c-.367 0-.726-.074-1.078-.219C.86-.27.687-.41.687-.547v-5.172c0-.187-.027-.316-.078-.39-.054-.082-.171-.125-.359-.125zM1.5-.719c.031.18.156.305.375.375.227.074.426.11.594.11.457 0 .797-.172 1.015-.516.22-.344.329-.758.329-1.25 0-.477-.106-.922-.313-1.328-.2-.414-.527-.625-.984-.625-.23 0-.461.074-.688.219-.219.136-.328.312-.328.53zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.047-.156c.3-.032.5-.082.594-.157.093-.082.14-.253.14-.515v-2.203c0-.32-.031-.551-.094-.688-.054-.144-.164-.219-.328-.219-.03 0-.074.008-.125.016a1.94 1.94 0 01-.172.031v-.156c.188-.062.38-.125.579-.187.195-.07.332-.125.406-.157a4.99 4.99 0 00.5-.218c.02 0 .031.011.031.03.008.013.016.044.016.095v.812c.207-.289.406-.515.593-.672.196-.164.399-.25.61-.25.164 0 .3.055.406.157a.473.473 0 01.156.359c0 .137-.043.25-.125.344a.368.368 0 01-.296.125c-.118 0-.235-.055-.36-.172-.125-.114-.226-.172-.297-.172-.117 0-.258.094-.422.281-.168.188-.25.383-.25.578v2.188c0 .281.063.48.188.594.133.105.351.156.656.156V0H.047zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.156-4.531v.14a1.57 1.57 0 00-.375.079c-.062.03-.093.105-.093.218 0 .055.003.106.015.157.02.042.047.109.078.203L2.797-1.22l.875-2.234c.039-.102.082-.211.125-.328a.921.921 0 00.062-.266.286.286 0 00-.187-.281 1.03 1.03 0 00-.297-.063v-.14h1.39v.14c-.156.024-.273.079-.343.172-.074.086-.18.297-.313.64L2.703-.061a.45.45 0 01-.062.124c-.012.02-.032.032-.063.032S2.516.07 2.484.03a.48.48 0 01-.062-.14l-1.5-3.563C.805-3.96.676-4.16.532-4.266.456-4.328.343-4.367.186-4.39v-.14zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.781-2.469l.453-1.672H2.188l-.454 1.672zm-2.703 0h1.063l.468-1.687H.547l.078-.36h1.063l.578-2.093h.593l-.562 2.093h1.047l.562-2.093H4.5l-.578 2.093h1.062l-.093.36H3.828L3.375-2.47h1.063l-.094.375H3.28L2.72 0h-.61l.594-2.094H1.641L1.078 0H.484l.563-2.094H0zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.953-6.703c.02.012.031.023.031.031v5.938c0 .25.067.414.204.484.132.063.382.102.75.11V0H1.171v-.14c.394-.02.648-.07.766-.157.125-.094.187-.285.187-.578v-4.531a.757.757 0 00-.063-.344c-.03-.082-.117-.125-.25-.125a.975.975 0 00-.328.078c-.136.043-.261.09-.375.14v-.14l1.797-.906zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M1.547-4.516v3.125c0 .22.031.399.094.532.125.242.347.359.671.359.22 0 .43-.07.641-.219.125-.07.25-.18.375-.328v-2.672c0-.258-.055-.426-.156-.5-.094-.082-.29-.129-.578-.14v-.157h1.578v3.438c0 .219.035.371.11.453.081.086.253.121.515.11v.14a3.54 3.54 0 00-.406.11 9.72 9.72 0 00-.422.124c-.086.032-.274.106-.563.22-.011 0-.023-.013-.031-.032V-.781a3.337 3.337 0 01-.594.578c-.281.2-.578.297-.89.297-.282 0-.547-.098-.797-.297C.844-.41.719-.75.719-1.22V-3.75c0-.258-.059-.43-.172-.516-.074-.05-.227-.093-.453-.125v-.125zm.953-.062zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.297-.11c1.187-1.238 1.992-2.144 2.422-2.718.426-.582.64-1.149.64-1.703 0-.477-.132-.844-.39-1.094a1.277 1.277 0 00-.922-.39c-.45 0-.813.167-1.094.5-.148.18-.293.449-.437.812l-.204-.031c.157-.75.426-1.27.813-1.563.395-.289.816-.437 1.266-.437.55 0 .992.171 1.328.515.344.344.515.766.515 1.266 0 .543-.187 1.062-.562 1.562-.367.493-1.164 1.368-2.39 2.625h2.25c.312 0 .53-.035.655-.109.125-.07.266-.254.422-.547l.125.063L4.204 0H.296zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M2.39-.188c.477 0 .805-.257.985-.78.176-.52.266-1.282.266-2.282 0-.781-.055-1.41-.157-1.89-.199-.876-.574-1.313-1.125-1.313-.554 0-.933.453-1.14 1.36-.106.48-.157 1.116-.157 1.905 0 .743.051 1.329.157 1.766.207.824.597 1.234 1.172 1.234zm-.015-6.53c.75 0 1.332.39 1.75 1.171.32.637.484 1.367.484 2.188 0 .648-.101 1.257-.296 1.828C3.925-.445 3.266.094 2.327.094c-.637 0-1.164-.29-1.578-.875C.312-1.406.094-2.25.094-3.312c0-.844.144-1.567.437-2.172.406-.82 1.02-1.235 1.844-1.235zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.75-.89c.188 0 .426.105.719.312.3.21.535.312.703.312.375 0 .703-.16.984-.484.281-.332.422-.742.422-1.234 0-.844-.45-1.446-1.344-1.813-.5-.207-.968-.312-1.406-.312H.703C.68-4.117.66-4.141.641-4.172v-.062a.14.14 0 01.03-.047L1.735-6.61h2.094c.102 0 .18-.016.235-.047.062-.04.14-.114.234-.219l.078.078-.39.89c-.012.032-.047.055-.11.063a.909.909 0 01-.188.016h-1.89l-.406.86c.53.085.921.167 1.171.25.407.136.743.34 1.016.609.238.23.414.492.531.78.125.282.188.587.188.907 0 .719-.262 1.324-.781 1.813-.512.48-1.157.718-1.938.718C1.254.11 1 .08.812.016c-.335-.102-.5-.286-.5-.547 0-.094.036-.176.11-.25.07-.07.18-.11.328-.11zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M.781-.844c.176 0 .41.094.703.281.29.18.54.266.75.266.457 0 .797-.148 1.016-.453a1.73 1.73 0 00.328-1.016c0-.343-.09-.644-.265-.906-.305-.445-.813-.672-1.532-.672h-.125c-.031 0-.074.008-.125.016l-.015-.125c.52-.188.925-.399 1.218-.64.301-.25.454-.579.454-.985 0-.352-.122-.617-.36-.797a1.226 1.226 0 00-.797-.281c-.355 0-.672.133-.953.39a3.566 3.566 0 00-.484.657l-.14-.032c.124-.488.362-.879.718-1.171.351-.301.766-.454 1.234-.454.5 0 .883.137 1.156.407a1.301 1.301 0 01.078 1.813 3.662 3.662 0 01-.593.515 2.2 2.2 0 01.75.468c.351.336.531.766.531 1.297 0 .618-.246 1.164-.734 1.641-.492.469-1.184.703-2.078.703-.399 0-.68-.058-.844-.172C.516-.219.437-.348.437-.484c0-.082.024-.16.079-.235.05-.082.14-.125.265-.125zm0 0"
            ></path>
          </symbol>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M4.484-6.61v.157L2.375.094h-.656l1.984-5.969H1.578c-.324 0-.555.055-.687.156-.137.106-.313.324-.532.657l-.156-.079c.207-.52.336-.843.39-.968.052-.125.114-.29.188-.5zm0 0"
            ></path>
          </symbol>
        </g>
      </defs>
      <g
        fill="#747474"
        fillOpacity="1"
        strokeWidth="0.835"
        transform="matrix(.8982 0 0 .8982 160.556 -.285)"
      >
        <path d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"></path>
        <path d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"></path>
        <path d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"></path>
        <path d="M225 3v65h-9.5V3z"></path>
        <path d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"></path>
        <path d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65z"></path>
      </g>
      <g
        fill="#747474"
        fillOpacity="1"
        strokeWidth="0.221"
        transform="matrix(3.54856 0 0 3.54856 -174.95 -339.18)"
      >
        <use
          width="100%"
          height="100%"
          x="81.65"
          y="66"
          transform="matrix(1.27573 0 0 1.27573 -9.954 50.463)"
          xlinkHref="#glyph8-1"
        ></use>
        <use
          width="100%"
          height="100%"
          x="92.725"
          y="66"
          transform="matrix(1.27573 0 0 1.27573 -10.404 50.463)"
          xlinkHref="#glyph8-2"
        ></use>
        <use
          width="100%"
          height="100%"
          x="101.629"
          y="66"
          transform="matrix(1.27573 0 0 1.27573 -10.404 50.463)"
          xlinkHref="#glyph8-3"
        ></use>
        <use
          width="100%"
          height="100%"
          x="110.086"
          y="66"
          transform="matrix(1.27573 0 0 1.27573 -10.48 50.463)"
          xlinkHref="#glyph8-4"
        ></use>
        <use
          width="100%"
          height="100%"
          x="113.471"
          y="66"
          transform="matrix(1.27573 0 0 1.27573 -10.629 50.463)"
          xlinkHref="#glyph8-5"
        ></use>
        <use
          width="100%"
          height="100%"
          x="121.712"
          y="66"
          transform="matrix(1.27573 0 0 1.27573 -10.629 50.463)"
          xlinkHref="#glyph8-6"
        ></use>
        <use
          width="100%"
          height="100%"
          x="127.665"
          y="66"
          transform="matrix(1.27573 0 0 1.27573 -10.854 50.463)"
          xlinkHref="#glyph8-7"
        ></use>
        <use
          width="100%"
          height="100%"
          x="131.232"
          y="66"
          transform="matrix(1.27573 0 0 1.27573 -11.454 50.463)"
          xlinkHref="#glyph8-8"
        ></use>
        <use
          width="100%"
          height="100%"
          x="139.888"
          y="66"
          transform="matrix(1.27573 0 0 1.27573 -11.454 50.463)"
          xlinkHref="#glyph8-9"
        ></use>
      </g>
      <g
        fillOpacity="1"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="0.282"
        transform="matrix(3.54856 0 0 3.54856 -174.95 -339.18)"
      >
        <path
          fill="#f8ab00"
          d="M76.058 100.69v32.108c0 3.596 2.478 5.595 5.108 5.595 2.432 0 5.108-1.703 5.108-5.595v-31.864c0-3.291-2.432-5.351-5.108-5.351-2.676 0-5.108 2.272-5.108 5.108zm0 0"
        ></path>
        <path
          fill="#e37300"
          d="M62.68 116.988v15.81c0 3.596 2.478 5.595 5.108 5.595 2.432 0 5.108-1.703 5.108-5.595v-15.567c0-3.291-2.433-5.351-5.108-5.351-2.676 0-5.108 2.273-5.108 5.108zm0 0"
        ></path>
        <path
          fill="#e37300"
          d="M59.518 133.285a5.11 5.11 0 01-5.108 5.108 5.11 5.11 0 01-5.108-5.108 5.11 5.11 0 015.108-5.108 5.11 5.11 0 015.108 5.108"
        ></path>
      </g>
    </svg>
  );
}
