import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { RiInformationLine } from "react-icons/ri";
import { useContext, useEffect, useState } from "react";
import "./Metrics.css";
import "../ClientReports/ClientReports.css";
import { UserInfoContext } from "../../contexts";
import {
  verifyGoogleAnalytics,
  fetchAnalyticsData,
  buildMetricsCardElements,
  getMetricsQuery,
  getCardOptions,
  getMetricsDescriptions,
  fetchGTMData,
  getGTMQuery,
  fillGTMCardFields,
  getMetricsUrls,
} from "../../helpers";
import { Button, Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { BsPlusLg, BsXCircle } from "react-icons/bs";
import Modal from "react-modal";
import { components } from "react-select";
import { MdCheckCircle } from "react-icons/md";
import { GiCircle } from "react-icons/gi";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import hotjar from "../../assets/hotjar.png";
import analyticsLogo from "../../assets/analytics-logo.png";
import { BuildPagesGrid } from "../../helpers";
import x from "../../assets/x.png";

export default function Metrics(props) {
  const userInfo = useContext(UserInfoContext);
  const [selectedProject2, setSelectedProject2] = useState(null);
  const [typeFilter, setTypeFilter] = useState("All");
  const [typeIsMenuOpen, setTypeIsMenuOpen] = useState(false);
  const [metricsFilter, setMetricsFilter] = useState("All");
  const [metricsIsMenuOpen, setMetricsIsMenuOpen] = useState(false);

  const [dateFilter, setDateFilter] = useState("Last month");
  const [dateIsMenuOpen, setDateIsMenuOpen] = useState(false);
  const [pageFilter, setPageFilter] = useState("All");
  const [pageMIsMenuOpen, setPageMIsMenuOpen] = useState(false); //Metrics follow page dropdown filter
  const [pageDropdownOptions, setPageDropdowOptions] = useState([]);

  const [metricsCards, setMetricsCards] = useState([]);

  const [metricsData, setMetricsData] = useState([]);
  const [metricsQuery, setMetricsQuery] = useState({});
  const [pageOptions, setPageOptions] = useState([]);

  const [filteredPagesCards, setFilteredPagesCards] = useState([]);
  const [trackerModalOpen, setTrackerModalOpen] = useState(false);
  const [pagesCards, setPagesCards] = useState([]);

  const metricsDescriptions = getMetricsDescriptions();

  const [GTMData, setGTMData] = useState({});
  const [GTMCards, setGTMCards] = useState([]);
  useEffect(() => {
    const query = getGTMQuery(GTMCards);
    if (query.name.length > 0) {
      fetchGTMData(selectedProject2.googleAnalyticsId, query.name).then(
        (data) => {
          if (data) {
            setGTMData(data);
          }
        },
      );
    }
  }, [GTMCards]);
  /**
   * Submit the new page card.
   */
  function trackPerformanceSubmit() {
    setTrackerModalOpen(false);

    const newPagesCards = selectedData
      .map((data) => {
        if (
          !pagesCards.some(
            (card) =>
              card.path === selectedPage.value &&
              card.metricType === data.value,
          )
        ) {
          setMetricsQuery((prevState) => ({
            ...prevState,
            pages: (() => {
              if (
                prevState.pages &&
                prevState.pages.includes(selectedPage.value)
              ) {
                return prevState.pages;
              }
              return [...(prevState.pages ?? []), selectedPage.value];
            })(),
            commercialMetrics: (() => {
              if (
                prevState.commercialMetrics &&
                prevState.commercialMetrics.includes(data.value)
              ) {
                return prevState.commercialMetrics;
              }
              return [...(prevState.commercialMetrics ?? []), data.value];
            })(),
          }));
          return {
            path: selectedPage.value,
            title: data.label,
            metricType: data.value,
            subtitle: selectedPage.label,
            text1: data.text1 ?? "You have",
            text2: data.text2 ?? data.label,
          };
        }
      })
      .filter((value) => !!value);

    updateDoc(doc(db, "projects", selectedProject2.value), {
      commercialMetricsCards: [...pagesCards, ...newPagesCards],
    });

    setPagesCards((prevState) => [...prevState, ...newPagesCards]);
    setFilteredPagesCards((prevState) => [...prevState, ...newPagesCards]);

    setSelectedSource(null);
    setSelectedPage(null);
    setSelectedData(null);
  }

  /**
   * Adder modal for adding a new metric or big card
   * @param submitFunc - function to be called when submitting
   * @param modalTitle - title of the modal
   * @param modalIsOpen - boolean to determine if modal is open
   * @param setModalIsOpen - function to set modalIsOpen
   * @param dataOptions - options for data dropdown
   * @returns {Element}
   */
  function adderModal({
    submitFunc,
    modalTitle,
    modalIsOpen,
    setModalIsOpen,
    dataOptions,
  }) {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Example Modal"
        className="custom-modal modal-shape"
        appElement={document.getElementById("root")}
        style={{
          overlay: {
            background: "rgba(7, 21, 43, 0.7)",
            zIndex: 10,
          },
        }}
      >
        <button
          onClick={() => {
            setSelectedData(null);
            setModalIsOpen(false);
          }}
          className="icon-button"
        >
          <BsXCircle alt="X button" size="30px" color="#1F1F24" />
        </button>
        <div className="dropdown-section">
          <h4 className="mb-3">{modalTitle}</h4>

          <CustomDropdown
            options={sourceOptions}
            onChange={(option) => onSelect(option, "source")}
            value={selectedSource}
            placeholder="Source"
            unstyled
            menuOpenState={isSourceMenuOpen}
            setMenuOpenState={setSourceIsMenuOpen}
          />
          <CustomDropdown
            options={pageOptions.map((page) => ({
              value: page,
              label: beautifyPath(page),
            }))}
            onChange={(option) => onSelect(option, "page")}
            value={selectedPage}
            placeholder="Page"
            unstyled
            menuOpenState={isPageMenuOpen}
            setMenuOpenState={setPageIsMenuOpen}
          />
          <CustomDropdown
            isMulti
            hideSelectedOptions={false}
            options={dataOptions}
            onChange={(option) => onSelect(option, "data")}
            value={selectedData}
            placeholder="Data type"
            unstyled
            closeMenuOnSelect={false}
            components={{
              MultiValue: CustomMultiValue,
              Option: checkCircle,
            }}
            menuOpenState={isDataMenuOpen}
            setMenuOpenState={setDataIsMenuOpen}
            isClearable={false}
          />
          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "31px" }}
          >
            <button
              type="button"
              className="btn-primary float-right"
              disabled={
                !selectedPage ||
                !selectedSource ||
                !selectedData ||
                selectedData.length === 0
              }
              onClick={() => submitFunc(false)}
            >
              Validate
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  const [dateOptions] = useState([
    { value: "Last month", label: "Last month" },
  ]);

  const typeOptions = [
    { value: "All", label: "All" },
    { value: "Negative", label: "Negative" },
    { value: "Positive", label: "Positive" },
    { value: "Neutral", label: "Neutral" },
  ];

  const tabItems = [
    {
      name: "Performance metrics",
      label: "Performance metrics",
    },
    {
      name: "Metrics follow",
      label: "Metrics follow",
    },
    {
      name: "Hotjar",
      label: "Hotjar",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabItems[0].name);

  const subTabItems = [
    {
      name: "Active",
      label: "Active",
    },
    {
      name: "Data Expert request",
      label: "Data Expert request",
    },
  ];

  const [activeSubTab, setActiveSubTab] = useState(subTabItems[0].name);

  useEffect(() => {
    setActiveTab("Performance metrics");
    setActiveSubTab("Active");
  }, []);

  async function deleteFollowUpCard(card) {
    const cardsAfterRemoval = metricsCards.filter((item) => item !== card);
    const projectDoc = doc(db, "projects", selectedProject2.value);
    await updateDoc(projectDoc, {
      designerMetricsCards: cardsAfterRemoval,
    }).then(() => {
      setMetricsCards(cardsAfterRemoval);
    });
  }

  function beautifyPath(path) {
    const beautified = path
      .replace("/", "")
      .replace("-", " ")
      .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));

    return beautified === "" ? "Home" : beautified;
  }

  const sourceOptions = [
    { value: "googleAnalytics", label: "Google Analytics" },
  ];

  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [metricsModalOpen, setMetricsModalOpen] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const [isPageMenuOpen, setPageIsMenuOpen] = useState(false);
  const [isSourceMenuOpen, setSourceIsMenuOpen] = useState(false);
  const [isDataMenuOpen, setDataIsMenuOpen] = useState(false);
  const [metricsLoading, setMetricsLoading] = useState(false);
  const CustomMultiValue = ({ data }) => {
    const additionalSelected = selectedData.length - 2;
    return (
      selectedData[0].value === data.value && (
        <div>
          {selectedData.slice(0, 2).map((option, index) => (
            <span key={option.value}>
              {option.label}
              {index < selectedData.length - 1 ? ", " : null}
            </span>
          ))}
          {selectedData.length > 2 ? `+ ${additionalSelected} others` : null}
        </div>
      )
    );
  };

  const onSelect = (option, menuType) => {
    switch (menuType) {
      case "page":
        setSelectedPage(option);
        break;
      case "source":
        setSelectedSource(option);
        break;
      case "data":
        setSelectedData(option);
        break;
      default:
        break;
    }
  };

  /**
   * Fetch all pages from the selected project, using verifyGoogleAnalytics
   */
  useEffect(() => {
    async function fetchPages() {
      const report = (await getDoc(doc(db, "reports", props.reportId))).data();

      const project = await getDoc(doc(db, "projects", report.projectId));

      const { name, imageLink, ...rest } = project.data();

      setSelectedProject2({
        label: name,
        value: project.id,
        image: imageLink,
        ...rest,
      });

      if (!rest?.googleAnalyticsId) return;

      const verifyGoogleAnalyticsResponse = await verifyGoogleAnalytics({
        projectId: rest.googleAnalyticsId,
      });

      // Invalid Id check
      if (
        !verifyGoogleAnalyticsResponse ||
        verifyGoogleAnalyticsResponse ===
          "No access to project, verify the account is authorized." ||
        verifyGoogleAnalyticsResponse === "Invalid Project ID"
      ) {
        setPageOptions([]);
        setPageDropdowOptions([]);
        return;
      }

      const pagesOptions = await getMetricsUrls({
        projectId: rest.googleAnalyticsId,
        rootUrl: rest.rootUrl,
        platform: "GA4",
      });

      if (rest?.otherUrls) {
        rest?.otherUrls.forEach((url) => {
          const urlObject = new URL(url);
          const pathName = urlObject.pathname;
          pagesOptions.unshift(pathName);
        });
      }

      const urlObject = new URL(rest?.rootUrl);
      const pathName = urlObject.pathname;
      pagesOptions.unshift(pathName);

      const pagesOptionsSet = [...new Set(pagesOptions)];

      setPageOptions(pagesOptionsSet);
      setPageDropdowOptions(
        [{ label: "All", value: "All" }]
          .concat({ label: "Google Tag Manager", value: "Google Tag Manager" })
          .concat(pagesOptions.map((page) => ({ label: page, value: page }))),
      );
    }

    fetchPages();
  }, []);

  /**
   * Fetch the metrics cards from the database, and then format the metrics query.
   * @returns {Promise<void>}
   */
  async function fetchMetricsCards() {
    if (selectedProject2?.value && selectedProject2?.platform === "GA4") {
      setMetricsLoading(true);

      const project = (
        await getDoc(doc(db, "projects", selectedProject2.value))
      ).data();

      const metricsCards = project?.designerMetricsCards; // designer grey cards
      const commercialCards = project?.commercialMetricsCards; // blue cards
      const GTMCards = fillGTMCardFields(project?.tagManagerCards ?? []);
      setGTMCards(GTMCards);
      setMetricsCards(metricsCards ?? []);
      setFilteredMetricsCards(metricsCards ?? []);
      setPagesCards(commercialCards ?? []);
      setFilteredPagesCards([...(commercialCards ?? []), ...(GTMCards ?? [])]);

      // Format the metrics query
      setMetricsQuery(getMetricsQuery(metricsCards, commercialCards));

      setMetricsLoading(false);
    }
  }

  useEffect(() => {
    fetchMetricsCards();
  }, [selectedProject2]);

  /**
   * Fetch the analytics data from the Google Analytics API
   * @returns {Promise<void>}
   */
  async function fetchAnalytics() {
    if (
      selectedProject2.platform === "GA4" &&
      metricsQuery &&
      metricsQuery.pages &&
      metricsQuery.pages.length > 0 &&
      ((metricsQuery.pageMetrics && metricsQuery.pageMetrics.length > 0) ||
        (metricsQuery.commercialMetrics &&
          metricsQuery.commercialMetrics.length > 0))
    ) {
      setQueryLoading(true);
      fetchAnalyticsData(selectedProject2.googleAnalyticsId, metricsQuery).then(
        (data) => {
          setMetricsData(data);
        },
      );

      setQueryLoading(false);
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchAnalytics();
    }, 1000 * 5);

    // Cleanup function otherwise interval never stops
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    fetchAnalytics();
  }, [metricsQuery]);

  const metricsFilters = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--blue-opacity10)" : "inherit",
      color: state.isSelected ? "var(--main-blue)" : "inherit",
      margin: "4px 0px",
    }),
  };

  const checkCircle = (props) => (
    <components.Option {...props}>
      {props.isSelected ? (
        <MdCheckCircle size={24} color="var(--main-blue)" />
      ) : (
        <GiCircle size={24} color="var(--main-blue)" />
      )}{" "}
      <h5
        style={{
          marginBottom: "0",
          marginLeft: "10px",
        }}
      >
        {props.data.label}
      </h5>
    </components.Option>
  );

  /**
   * Add a metric card to the project
   */
  function addMetric() {
    setMetricsModalOpen(false);

    const newMetricsCards = selectedData
      .map((data) => {
        if (
          !metricsCards.some(
            (card) =>
              card.path === selectedPage.value &&
              card.metricType === data.value,
          )
        ) {
          setMetricsQuery((prevState) => ({
            ...prevState,
            pages: (() => {
              if (
                prevState.pages &&
                prevState.pages.includes(selectedPage.value)
              ) {
                return prevState.pages;
              }
              return [...(prevState.pages ?? []), selectedPage.value];
            })(),
            pageMetrics: (() => {
              if (
                prevState.pageMetrics &&
                prevState.pageMetrics.includes(data.value)
              ) {
                return prevState.pageMetrics;
              }
              return [...(prevState.pageMetrics ?? []), data.value];
            })(),
          }));
          return {
            path: selectedPage.value,
            title: data.label,
            metricType: data.value,
            subtitle: selectedPage.label,
          };
        }
      })
      .filter((value) => !!value);

    updateDoc(doc(db, "projects", selectedProject2.value), {
      designerMetricsCards: [...metricsCards, ...newMetricsCards],
    });

    setMetricsCards((prevState) => {
      return [...prevState, ...newMetricsCards];
    });
  }

  const [filteredMetricsCards, setFilteredMetricsCards] = useState([]);

  // Reset filters on change active tab
  useEffect(() => {
    setTypeFilter("All");
    setMetricsFilter("All");
    setDateFilter("Last month");
    setPageFilter("All");
  }, [activeTab]);

  /**
   * Filter metrics cards based on type and metrics
   */
  useEffect(() => {
    activeTab === "Metrics follow"
      ? setFilteredMetricsCards(
          metricsCards
            .filter((card) => {
              let diff =
                metricsData[card.path]?.[card.metricType]?.["date_range_0"]
                  ?.data -
                metricsData[card.path]?.[card.metricType]?.["date_range_1"]
                  ?.data;
              if (typeFilter === "All") {
                return true;
              }
              if (typeFilter === "Positive") {
                return diff > 0;
              }
              if (typeFilter === "Negative") {
                return diff < 0;
              }
              if (typeFilter === "Neutral") {
                return diff === 0;
              }
            })
            .filter((card) => {
              if (metricsFilter === "All") return true;
              if (card.metricType === metricsFilter) {
                return true;
              }
            })
            .filter((card) => {
              if (pageFilter === "All") return true;
              if (card.path === pageFilter) {
                return true;
              }
            }),
        )
      : setFilteredPagesCards(
          [...pagesCards, ...GTMCards]
            .filter((card) => {
              let diff = metricsData[card.path]?.[card.metricType]
                ? metricsData[card.path][card.metricType]["date_range_0"]
                    ?.data -
                  metricsData[card.path][card.metricType]["date_range_1"]?.data
                : GTMData[card.name]?.["date_range_0"] -
                  GTMData[card.name]?.["date_range_1"];

              if (typeFilter === "All") {
                return true;
              }
              if (typeFilter === "Positive") {
                return diff > 0;
              }
              if (typeFilter === "Negative") {
                return diff < 0;
              }
              if (typeFilter === "Neutral") {
                return diff === 0 || isNaN(diff);
              }
            })
            .filter((card) => {
              if (metricsFilter === "All") return true;
              if (card.metricType === metricsFilter) {
                return true;
              }
            })
            .filter((card) => {
              if (pageFilter === "All") return true;
              if (card.path === pageFilter) {
                return true;
              }
            }),
        );
  }, [typeFilter, metricsFilter, metricsCards, dateFilter, pageFilter]);
  return (
    <div>
      <Modal
        isOpen={metricsModalOpen}
        onRequestClose={() => setMetricsModalOpen(false)}
        contentLabel="Example Modal"
        className="custom-modal modal-shape"
        appElement={document.getElementById("root")}
        style={{ overlay: { zIndex: 10 } }}
      >
        <button
          onClick={() => setMetricsModalOpen(false)}
          className="icon-button"
        >
          <BsXCircle alt="X button" size="30px" color="#1F1F24" />
        </button>
        <div className="dropdown-section">
          <h4 className="mb-3">Add Metric</h4>

          <CustomDropdown
            options={sourceOptions}
            onChange={(option) => onSelect(option, "source")}
            value={selectedSource}
            placeholder="Source"
            unstyled
            menuOpenState={isSourceMenuOpen}
            setMenuOpenState={setSourceIsMenuOpen}
          />
          <CustomDropdown
            options={pageOptions.map((page) => ({
              value: page,
              label: beautifyPath(page),
            }))}
            onChange={(option) => onSelect(option, "page")}
            value={selectedPage}
            placeholder="Page"
            unstyled
            menuOpenState={isPageMenuOpen}
            setMenuOpenState={setPageIsMenuOpen}
          />
          <CustomDropdown
            isMulti
            hideSelectedOptions={false}
            options={getCardOptions()}
            onChange={(option) => onSelect(option, "data")}
            value={selectedData}
            placeholder="Data type"
            unstyled
            closeMenuOnSelect={false}
            components={{
              MultiValue: CustomMultiValue,
              Option: checkCircle,
            }}
            menuOpenState={isDataMenuOpen}
            setMenuOpenState={setDataIsMenuOpen}
            isClearable={false}
          />
          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "31px" }}
          >
            <button
              type="button"
              className="btn-primary float-right"
              disabled={
                !selectedPage ||
                !selectedSource ||
                !selectedData ||
                selectedData.length === 0
              }
              onClick={addMetric}
            >
              Validate
            </button>
          </div>
        </div>
      </Modal>
      <div
        style={{
          marginTop: "51px",
        }}
      >
        <h4 className="fs-24">
          <b>{userInfo?.firstName ?? "User"},</b> save time 😌
          <br />
          You can directly check the analytics from here.
        </h4>
      </div>

      <div className="nav-section d-flex" style={{ marginTop: "32px" }}>
        {tabItems.map((item, i) => {
          return (
            <div key={i}>
              <Button className="todo-tab-item" key={i}>
                <div
                  onClick={() => setActiveTab(item.name)}
                  className={activeTab === item.name ? "active-tab fw-700" : ""}
                >
                  <div className="fc-black fs-16">{item.label}</div>
                </div>
              </Button>
              <div
                className="todo-tab-bar2"
                style={{
                  background: activeTab === item.name ? "black" : "none",
                  width:
                    activeTab === item.name &&
                    activeTab === "Performance metrics"
                      ? "156px"
                      : activeTab === "Metrics follow"
                        ? "104px"
                        : "46px",
                }}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="nav-line2" />

      {activeTab === "Performance metrics" ? (
        <>
          <div
            className="fs-16 fw-500 lh216 fc-grey"
            style={{ marginTop: "24px" }}
          >
            <p className="p-m0">
              “Performance metrics” are the key metrics related to client goals.
            </p>
            <p className="p-m0">
              Most of them have to be <b>configured by Data Expert.</b>
            </p>
            <p className="p-m0">
              <b>Only you can add them.</b> Be sure to well communicated with
              your client to know on what to focus.
            </p>
          </div>
          <div
            className="nav-line2"
            style={{ marginTop: "50px", marginBottom: "20px" }}
          />
          <div className="nav-section d-flex">
            {subTabItems.map((item, i) => {
              return (
                <div key={i}>
                  <Button className="todo-tab-item" key={i}>
                    <div
                      onClick={() => setActiveSubTab(item.name)}
                      className={
                        activeSubTab === item.name ? "active-tab fw-700" : ""
                      }
                    >
                      <div className="fc-black fs-16">{item.label}</div>
                    </div>
                  </Button>
                  {activeSubTab === item.name && (
                    <div
                      className="todo-tab-bar2"
                      style={{
                        background:
                          activeSubTab === item.name ? "black" : "none",
                        width:
                          activeSubTab === item.name &&
                          activeSubTab === "Active"
                            ? "46px"
                            : "155px",
                      }}
                    ></div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="nav-line2" style={{ marginTop: "5px" }} />
          {activeSubTab === "Active" && (
            <>
              <div style={{ marginTop: "50.46px", marginBottom: "10px" }}>
                <h5>Filters</h5>
              </div>
              <div style={{ display: "flex", marginBottom: "50.45px" }}>
                <div style={{ width: "127px", marginRight: "20px" }}>
                  <CustomDropdown
                    onChange={(option) => setMetricsFilter(option.value)}
                    value={{ value: metricsFilter, label: metricsFilter }}
                    placeholder={"Type"}
                    unstyled
                    styles={metricsFilters}
                    options={[
                      { value: "All", label: "All" },
                      ...getCardOptions(),
                    ]}
                    menuOpenState={metricsIsMenuOpen}
                    setMenuOpenState={setMetricsIsMenuOpen}
                  />
                </div>
                <div style={{ width: "166px", marginRight: "20px" }}>
                  <CustomDropdown
                    onChange={(option) => setDateFilter(option.value)}
                    value={{ value: dateFilter, label: dateFilter }}
                    placeholder={"Date"}
                    options={dateOptions}
                    unstyled
                    menuOpenState={dateIsMenuOpen}
                    setMenuOpenState={setDateIsMenuOpen}
                  />
                </div>
                <div style={{ width: "154px", marginRight: "20px" }}>
                  <CustomDropdown
                    onChange={(option) => setPageFilter(option.value)}
                    value={{ value: pageFilter, label: pageFilter }}
                    placeholder={"Page"}
                    options={pageDropdownOptions}
                    unstyled
                    menuOpenState={pageMIsMenuOpen}
                    setMenuOpenState={setPageMIsMenuOpen}
                  />
                </div>
                <div style={{ width: "174px" }}>
                  <CustomDropdown
                    onChange={(option) => setTypeFilter(option.value)}
                    value={{ value: typeFilter, label: typeFilter }}
                    placeholder={"Positive / Negative"}
                    options={typeOptions}
                    unstyled
                    menuOpenState={typeIsMenuOpen}
                    setMenuOpenState={setTypeIsMenuOpen}
                  />
                </div>
              </div>
              <div style={{ marginTop: "52.88px" }}>
                <BuildPagesGrid
                  metricsLoading={metricsLoading}
                  filteredPagesCards={filteredPagesCards}
                  queryLoading={queryLoading}
                  metricsData={metricsData}
                  canAddNewPage
                  setTrackerModalOpen={setTrackerModalOpen}
                  GTMData={GTMData}
                  role={"designer"}
                  cards={pagesCards}
                  setCards={setPagesCards}
                  setFilteredCards={setFilteredPagesCards}
                  pid={selectedProject2?.value}
                />
                {adderModal({
                  submitFunc: trackPerformanceSubmit,
                  modalTitle: "Track performance of a new page",
                  modalIsOpen: trackerModalOpen,
                  setModalIsOpen: setTrackerModalOpen,
                  dataOptions: getCardOptions(),
                })}
              </div>
            </>
          )}
        </>
      ) : activeTab === "Metrics follow" ? (
        <>
          <div
            className="fs-16 fw-500 lh216 fc-grey"
            style={{ marginTop: "24px" }}
          >
            <p className="p-m0">
              “Metrics follow” are the standard metrics from Analytical
              platform.
            </p>
            <p className="p-m0">
              You can add all the metrics you want to follow and play with
              filters to have responses you need.
            </p>
            <p className="p-m0">The number you see is the current value.</p>
            <p className="p-m0">
              The second number is the performance compared to the last month
            </p>
          </div>

          <div>
            <a
              target="_blank"
              href="https://analytics.google.com/"
              style={{ color: "white" }}
            >
              <button
                style={{
                  width: "218px",
                  height: "50px",
                  backgroundColor: "#F7F7F7",
                  marginTop: "32.1px",
                  border: "1px solid #D7D7D7",
                  transition: "none",
                }}
                className="d-flex align-items-center"
              >
                <img
                  src={analyticsLogo}
                  alt="analytics logo"
                  style={{
                    width: "19.92px",
                    height: "20px",
                    marginRight: "12px",
                    marginLeft: "3px",
                  }}
                />

                <div className="fw-700 fs-1334 lh-2371 fc-black">
                  Go to Google Analytics
                </div>
              </button>
            </a>
          </div>

          <div style={{ marginTop: "50.46px", marginBottom: "10px" }}>
            <h5>Filters</h5>
          </div>
          <div style={{ display: "flex", marginBottom: "50.45px" }}>
            <div style={{ width: "127px", marginRight: "20px" }}>
              <CustomDropdown
                onChange={(option) => setMetricsFilter(option.value)}
                value={{ value: metricsFilter, label: metricsFilter }}
                placeholder={"Type"}
                unstyled
                styles={metricsFilters}
                options={[{ value: "All", label: "All" }, ...getCardOptions()]}
                menuOpenState={metricsIsMenuOpen}
                setMenuOpenState={setMetricsIsMenuOpen}
              />
            </div>
            <div style={{ width: "166px", marginRight: "20px" }}>
              <CustomDropdown
                onChange={(option) => setDateFilter(option.value)}
                value={{ value: dateFilter, label: dateFilter }}
                placeholder={"Date"}
                options={dateOptions}
                unstyled
                menuOpenState={dateIsMenuOpen}
                setMenuOpenState={setDateIsMenuOpen}
              />
            </div>
            <div style={{ width: "154px", marginRight: "20px" }}>
              <CustomDropdown
                onChange={(option) => setPageFilter(option.value)}
                value={{ value: pageFilter, label: pageFilter }}
                placeholder={"Page"}
                options={pageDropdownOptions}
                unstyled
                menuOpenState={pageMIsMenuOpen}
                setMenuOpenState={setPageMIsMenuOpen}
              />
            </div>
            <div style={{ width: "174px" }}>
              <CustomDropdown
                onChange={(option) => setTypeFilter(option.value)}
                value={{ value: typeFilter, label: typeFilter }}
                placeholder={"Positive / Negative"}
                options={typeOptions}
                unstyled
                menuOpenState={typeIsMenuOpen}
                setMenuOpenState={setTypeIsMenuOpen}
              />
            </div>
          </div>
          <div className="d-flex flex-wrap">
            {metricsCards.length === 0 && metricsLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : (
              <div className="dashboard-metrics-holder">
                <div className="dashboard-metrics-card-new ">
                  <button
                    onClick={() => setMetricsModalOpen(true)}
                    className="dashboard-card-new dashboard-metrics-card d-flex align-items-center justify-content-center"
                    style={{ marginRight: 0 }}
                  >
                    <BsPlusLg
                      color="var(--main-blue)"
                      size="32px"
                      className="me-2"
                    />
                    <div>Add metrics</div>
                  </button>
                </div>
                {filteredMetricsCards.map((card) => {
                  return (
                    <div
                      className="dashboard-metrics-card"
                      key={card.title + card.subtitle + card.metricType}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>{card.title}</Tooltip>
                            }
                          >
                            <div className="title">{card.title}</div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              metricsDescriptions[card.metricType] ? (
                                <Tooltip id={`tooltip-top`}>
                                  {metricsDescriptions[card.metricType]}
                                </Tooltip>
                              ) : (
                                <></>
                              )
                            }
                          >
                            <button className="icon-button">
                              <RiInformationLine
                                alt="info icon"
                                size="16px"
                                color="#1F1F24"
                              />
                            </button>
                          </OverlayTrigger>
                        </div>
                        <button
                          className="icon-button"
                          style={{ margin: "0px" }}
                          onClick={() => deleteFollowUpCard(card)}
                        >
                          <img
                            className="delete-metric-btn"
                            src={x}
                            alt="close button"
                          />
                        </button>
                      </div>
                      <div className="subtitle">{card.subtitle}</div>
                      <div className="subtitle"></div>
                      {(() => {
                        return buildMetricsCardElements(
                          queryLoading,
                          metricsData,
                          card,
                        );
                      })()}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            className="fs-16 fw-500 lh216 fc-grey"
            style={{ marginTop: "24px" }}
          >
            <p className="p-m0">
              Go to Hotjar and analyze click, hover and scroll.
            </p>
            <p className="p-m0">
              Check related to videos to better understand correlation.
            </p>
            <p className="p-m0">
              If you have doubts, use{" "}
              <span className="styled-link">
                <a href="/">LUXIFER help.</a>
              </span>
            </p>
          </div>
          <div>
            <a
              href="https://www.hotjar.com/"
              target="_blank"
              style={{ color: "white" }}
            >
              <button
                style={{
                  width: "198px",
                  height: "50px",
                  backgroundColor: "#F7F7F7",
                  marginTop: "32px",
                  border: "1px solid #D7D7D7",
                  transition: "none",
                }}
                className="d-flex align-items-center"
              >
                <img
                  src={hotjar}
                  alt="hotjar logo"
                  style={{
                    width: "19.92px",
                    height: "20px",
                    marginRight: "12px",
                    marginLeft: "24.5px",
                  }}
                />

                <div className="fw-700 fs-1334 lh-2371 fc-black">
                  Go to Hotjar
                </div>
              </button>
            </a>
          </div>
        </>
      )}
    </div>
  );
}
