import "./CustomCalendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";

function CustomCalendar(props) {
  const [myRef, setMyRef] = useState(false);
  const closeCalendar = () => {
    myRef.setOpen(false);
  }; // Used to close calendar via button

  // Used for whether the calendar should be adaptive or not
  const [adaptiveVersion, setAdaptiveVersion] = useState(
    window.innerWidth < 1429,
  );

  window.addEventListener("resize", (e) => {
    setAdaptiveVersion(window.innerWidth < 1429);
  });

  useEffect(() => {
    if (myRef) {
      if (props.datePickerOpen) {
        myRef?.setOpen(true);
      } else {
        myRef?.setOpen(false);
      }
    }
  }, [props.datePickerOpen]);

  return (
    <div className="no-select">
      <DatePicker
        startDate={props.startDate}
        endDate={props.endDate}
        selectsRange={true}
        // Baseline customizations
        disabledKeyboardNavigation
        showPopperArrow={false}
        monthsShown={1}
        shouldCloseOnSelect={false}
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3).toUpperCase()} // SUN MON, etc
        placeholderText="Date range"
        // Control customizations
        selected={props.selected}
        onChange={props.onChange}
        customInput={props.customInput}
        updateAction={props.updateAction}
        setDatePickedTemp={props.setDatePickedTemp}
        setIsDatePickerOpen={props.setIsDatePickerOpen}
        maxDate={props.maxDate ?? null}
        onCalendarClose={() => {
          props.setIsDatePickerOpen(false);
        }}
        onCalendarOpen={() => {
          props.setIsDatePickerOpen(true);
        }}
        ref={(r) => {
          setMyRef(r);
        }}
      >
        <div className="datepicker-header">Select Date Range</div>
        <div className="datepicker-btn-container">
          <button
            className="btn-primary custom-calendar-confirm-btn"
            onClick={() => closeCalendar()}
          >
            Confirm Date
          </button>
        </div>
      </DatePicker>
    </div>
  );
}

export default CustomCalendar;
