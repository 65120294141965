import MainLayout from "../MainLayout";
import { auth, db } from "../../firebase";
import { useEffect, useState } from "react";
import { UserInfoContext } from "../../contexts";
import "./ManageNotifications.css";
import "../UxChecklist/UxChecklist.css";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { getUserInformation, getUserProjects } from "../../helpers";
import { Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { doc, updateDoc } from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function NotificationsContent({ setSavingToDb, projects }) {
  const [projectStates, setProjectStates] = useState([]);

  useEffect(() => {
    if (projects) {
      setProjectStates(
        projects.map((project) => ({
          ...project,
          notifications: project.notifications || false,
        })),
      );
    }
  }, [projects]);

  // Entire project section

  const saveProjectToDb = async (projectId, fields) => {
    setSavingToDb(true);
    const projectDoc = doc(db, "projects", projectId);
    await updateDoc(projectDoc, fields).then(() => {
      setSavingToDb(false);
    });
  };

  const handleToggleClick = async (i) => {
    await saveProjectToDb(projectStates[i]?.value, {
      notifications: !projectStates[i]?.notifications,
    });
  };

  function handleProjectStateChange(value, field, i) {
    setProjectStates((prevList) =>
      prevList.map((states, j) => {
        if (i === j) {
          return {
            ...states,
            [field]: value,
          };
        }
        return states;
      }),
    );
  }

  return (
    <div style={{ height: "100vh", display: "flex", flexGrow: 1 }}>
      <div className="profile-main-content">
        <div style={{ marginLeft: "50.36px" }}>
          <div style={{ marginTop: "35.5px" }}>
            <Link to={"/"}>
              <div
                style={{
                  width: "158px",
                  height: "24px",
                  textAlign: "center",
                }}
                className="fs-14 fw-700 fc-black"
              >
                <FiArrowLeft
                  color="#000"
                  size="24px"
                  style={{ marginRight: "10px", marginBottom: "2px" }}
                />
                Back to Dashboard
              </div>
            </Link>
            <div
              style={{ height: "24px", marginTop: "61.55px" }}
              className="fs-24 fw-700 lh-243 "
            >
              Gestions des notifications
            </div>
          </div>

          <div
            style={{
              height: "24px",
              marginTop: "68.78px",
            }}
            className="fs-18 fw-700"
          >
            Weekly summary
          </div>
          <div
            style={{
              maxWidth: "504px",
              marginTop: "12px",
            }}
          >
            <div className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023">
              <p> Receive weekly summary of events on your project.</p>
            </div>
          </div>

          <div style={{ marginTop: "68px" }}>
            {projectStates?.map((p, i) => (
              <div key={i}>
                <div
                  className="row"
                  style={{
                    margin: "0",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <img
                    src={p.image}
                    className="img-keep-ratio"
                    style={{
                      width: "38px",
                      height: "38px",
                      objectFit: "contain",
                      display: "block",
                      maxWidth: "100%",
                      padding: "0",
                      marginRight: "20.29px",
                    }}
                  />

                  <div
                    className="col fw-700 fs-18 lh-243"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "70%",
                      flexWrap: "nowrap",
                      overflow: "auto",
                      padding: "0px",
                    }}
                  >
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-top`}>{p.name}</Tooltip>}
                    >
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {p.name}
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      onClick={() => {
                        handleProjectStateChange(
                          !p.notifications,
                          "notifications",
                          i,
                        );
                        handleToggleClick(i);
                      }}
                      className={`notification-toggle align-items-center fw-700 ${
                        p.notifications ? "notification-on" : "notification-off"
                      }`}
                    >
                      <div
                        className={
                          p.notifications
                            ? "notification-toggle-circle-on"
                            : "notification-toggle-circle-off"
                        }
                      ></div>
                    </button>
                  </div>
                </div>

                <hr
                  className="row"
                  style={{
                    width: "95%",
                    marginTop: "32px",
                    marginBottom: "32px",
                  }}
                ></hr>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ManageNotifications() {
  const [userInfo, setUserInfo] = useState(null);
  const [hasUserInfo, setHasUserInfo] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [savingToDb, setSavingToDb] = useState(false);

  const [deleteProjectConfirmModalIsOpen, setDeleteProjectConfirmModalIsOpen] =
    useState(false);

  const [isGoogleAccount, setIsGoogleAccount] = useState(false);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    const fetchUserInformation = async () => {
      if (user) {
        setHasUserInfo(true);
        getUserInformation().then((info) => {
          setUserInfo(info);
          setIsGoogleAccount(info?.isGoogleAccount);
          getUserProjects(info).then((projects) => {
            const updatedProjects = projects.map((project) => ({
              ...project,
            }));
            setProjects(updatedProjects);
          });
        });
      }
    };
    if (!hasUserInfo) fetchUserInformation();
  }, [user]);

  useEffect(() => {
    if (
      !loading &&
      (!user ||
        (user && userInfo && ["designer", "reader"].includes(userInfo.role)))
    ) {
      navigate("/");
    }
  }, [loading, userInfo, user]);

  /**
   * If the page is loading, or user info is loading, the Spinning wheel will be shown.
   */
  if (loading || (user && !userInfo?.role)) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  if (user) {
    return (
      <NotificationsContent
        userInfo={userInfo}
        user={user}
        setUserInfo={setUserInfo}
        setSavingToDb={setSavingToDb}
        savingToDb={savingToDb}
        isGoogleAccount={isGoogleAccount}
        projects={projects}
        setProjects={setProjects}
      >
        <ToastContainer />
      </NotificationsContent>
    );
  }
}
