import { Button } from "react-bootstrap";
import rocketship from "../../assets/rocketship.svg";
import { UserInfoContext } from "../../contexts";
import { useContext } from "react";
import "./Information.css";

export default function Information(props) {
  const userInfo = useContext(UserInfoContext);
  return (
    <div
      style={{
        paddingRight: "50px",
      }}
    >
      <div
        className="info-greeting"
        style={{
          marginTop: "69px",
        }}
      >
        <h4 className="fw-700">Hello {userInfo?.firstName ?? "User"},</h4>
        <h5 className="fw-400">Let's start to work on your report 🔥</h5>
      </div>
      <div>
        <h6 className="fw-700 info-disclaimer">⚠️ Some reminders</h6>
        <ul className="info-ul">
          <li>
            <b>If you have hesitations</b> on how to make the UX analysis,{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="styled-link"
              href="https://www.youtube.com/watch?v=u31qwQUeGuM"
            >
              rewatch this video.
            </a>
          </li>
          <li className="fw-700">Work page by page.</li>
          <li className="fw-700">
            Follow each step of the process. You can easily go back.
          </li>
          <li className="fw-700">Don't forget to check all UX/UI rules.</li>
          <li>
            <b>Be clear, brief and complete.</b> Use simple words to explain
            your insights and recommendations.
          </li>
        </ul>
      </div>
      <Button
        className="info-btn d-flex align-items-center d-flex align-items-center"
        style={{
          height: "44px",
          marginTop: "30px",
        }}
        onClick={() => props.setActiveTab("insights")}
      >
        <img
          alt="rocketship"
          style={{
            marginRight: "10px",
          }}
          src={rocketship}
        />
        All clear, let's go
      </Button>
    </div>
  );
}
