import { useState } from "react";
import "./ClientReports.css";
import { Link, Outlet } from "react-router-dom";
import { Button } from "react-bootstrap";

export function ClientReports() {
  const tabItems = [
    {
      name: "insights",
      label: "Insights",
      link: "/reports/insights",
    },
    {
      name: "reports",
      label: "Reports",
      link: "/reports/reports",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabItems[0].name);

  return (
    <div className="client-reports-parent">
      <div className="d-flex">
        {tabItems.map((item) => {
          return (
            <div key={item.name}>
              <Button className="tab-item" key={item.name}>
                <Link
                  onClick={() => setActiveTab(item.name)}
                  className={`tab-item-text${
                    activeTab === item.name ? "-active" : ""
                  }`}
                  style={{ color: "black !important" }}
                  to={item.link}
                >
                  {item.label}
                </Link>
              </Button>
              <div
                className="tab-bar"
                style={{
                  background: activeTab === item.name ? "black" : "none",
                }}
              />
            </div>
          );
        })}
      </div>
      <hr style={{ marginBottom: "50px" }} />

      <Outlet />
    </div>
  );
}
