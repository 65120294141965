import {
  // not animated
  Annotation,
  AreaSeries,
  AreaStack,
  Axis,
  BarGroup,
  BarSeries,
  BarStack,
  GlyphSeries,
  Grid,
  LineSeries,

  // no animated equivalents
  AnnotationCircleSubject,
  AnnotationConnector,
  AnnotationLabel,
  AnnotationLineSubject,
  Tooltip,
  XYChart,
} from "@visx/xychart";

export default function getAnimatedOrUnanimatedComponents(animated?: boolean) {
  return {
    Annotation,
    AreaSeries,
    AreaStack,
    Axis,
    BarGroup,
    BarSeries,
    BarStack,
    GlyphSeries,
    Grid,
    LineSeries,
    AnnotationCircleSubject,
    AnnotationConnector,
    AnnotationLabel,
    AnnotationLineSubject,
    Tooltip,
    XYChart,
  };
}
