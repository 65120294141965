import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { Spinner } from "react-bootstrap";
import { auth } from "../../firebase";
import { getUserInformation } from "../../helpers";
import "./Preferences.css";
import "../UxChecklist/UxChecklist.css";
import "react-toastify/dist/ReactToastify.css";

// Mouses icons
import cursor_luxi_png from "../../assets/cursor_luxi.png";
import cursor_red_luxi_png from "../../assets/cursor_red_luxi.png";
import cursor_nothentity_png from "../../assets/cursor_nothentity.png";
import cursor_change_png from "../../assets/cursor_change.png";
import cursor_magic_png from "../../assets/cursor_magic.png";

import cursor_luxi_cur from "../../assets/cursor_luxi.cur";
import cursor_red_luxi_cur from "../../assets/cursor_red_luxi.cur";
import cursor_nothentity_cur from "../../assets/cursor_nothentity.cur";
import cursor_change_cur from "../../assets/cursor_change.cur";
import cursor_magic_cur from "../../assets/cursor_magic.cur";

function PreferencesContent() {
  const [selectedCursor, setSelectedCursor] = useState(null);

  useEffect(() => {
    const currentCursor = localStorage.getItem("luxiferSelectedCursor");
    if (!!currentCursor) {
      setSelectedCursor(currentCursor);
    } else {
      setSelectedCursor("cursor_luxi");
      localStorage.setItem("luxiferSelectedCursor", "cursor_luxi");
    }
  }, []);

  useEffect(() => {
    if (selectedCursor) {
      localStorage.setItem("luxiferSelectedCursor", selectedCursor);
      const cursorUrl = mousesOptions.find(
        (mouse) => mouse.key === selectedCursor,
      )?.iconPath;
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `* { cursor: url(${cursorUrl}), auto !important; }`;
      document.head.appendChild(styleElement);

      return () => {
        document.head.removeChild(styleElement);
      };
    }
  }, [selectedCursor]);

  const mousesOptions = [
    {
      key: "cursor_luxi",
      icon: cursor_luxi_png,
      iconPath: cursor_luxi_cur,
      name: "Luxi",
    },
    {
      key: "cursor_red_luxi",
      icon: cursor_red_luxi_png,
      iconPath: cursor_red_luxi_cur,
      name: "Red Luxi",
    },
    {
      key: "cursor_nothentity",
      icon: cursor_nothentity_png,
      iconPath: cursor_nothentity_cur,
      name: "Nothentity",
    },
    {
      key: "cursor_change",
      icon: cursor_change_png,
      iconPath: cursor_change_cur,
      name: "Change",
    },
    {
      key: "cursor_magic",
      icon: cursor_magic_png,
      iconPath: cursor_magic_cur,
      name: "Magic",
    },
  ];

  return (
    <div style={{ height: "100vh", display: "flex", flexGrow: 1 }}>
      <div className="preferences-main-content">
        <div style={{ marginLeft: "50.36px" }}>
          <div style={{ marginTop: "35.5px" }}>
            <Link to="/">
              <div
                style={{
                  width: "158px",
                  height: "24px",
                  textAlign: "center",
                }}
                className="fs-14 fw-700 fc-black"
              >
                <FiArrowLeft
                  color="#000"
                  size="24px"
                  style={{ marginRight: "10px", marginBottom: "2px" }}
                />
                Back to Dashboard
              </div>
            </Link>
            <div
              style={{ width: "194px", height: "24px", marginTop: "61.55px" }}
              className="fs-24 fw-700 lh-243 "
            >
              Preferences
            </div>

            <div
              style={{
                height: "24px",
                marginTop: "68.78px",
              }}
              className="fs-18 fw-700"
            >
              Mouse style
            </div>
            <div
              style={{
                maxWidth: "651.64px",
                marginTop: "12px",
                marginBottom: "32px",
              }}
            >
              <div className="fs-16 fw-500 lh-20 fc-grey lc-023">
                <p>
                  Choose your favorite mouse to play with your amazing product.
                </p>
              </div>
            </div>
            <div className="mouses-list">
              {mousesOptions?.map((mouse, mouseIndx) => (
                <div
                  key={`${mouse?.key}-${mouseIndx}`}
                  className={`mouse-item ${
                    selectedCursor == mouse?.key ? "active" : ""
                  }`}
                  onClick={() => setSelectedCursor(mouse?.key)}
                >
                  <div className="mouse-item__icon">
                    <img src={mouse?.icon} alt="mouse" />
                  </div>
                  <div className="mouse-item__name">{mouse?.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Preferences(props) {
  const [userInfo, setUserInfo] = useState(null);
  const [hasUserInfo, setHasUserInfo] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [savingToDb, setSavingToDb] = useState(false);

  useEffect(() => {
    const fetchUserInformation = async () => {
      if (user) {
        setHasUserInfo(true);
        getUserInformation().then((info) => {
          setUserInfo(info);
        });
      }
    };
    if (!hasUserInfo) fetchUserInformation();
  }, [user]);

  useEffect(() => {
    if (
      !loading &&
      (!user ||
        (user && userInfo && ["designer", "reader"].includes(userInfo.role)))
    ) {
      navigate("/");
    }
  }, [loading, userInfo, user]);

  if (loading || (user && !userInfo?.role)) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  if (user) {
    return (
      <>
        <PreferencesContent
          userInfo={userInfo}
          user={user}
          setUserInfo={props.setUserInfo}
          setSavingToDb={setSavingToDb}
          savingToDb={savingToDb}
        />
      </>
    );
  }
}
