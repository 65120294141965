import { Button } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

import "./CreateReport.css";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  getDoc,
  where,
} from "firebase/firestore";
import { verifyGoogleAnalytics, getInsightsPid } from "../../helpers";
import { Spinner } from "react-bootstrap";
import UxInsights from "../UxInsights/UxInsights";
import Information from "../Information/Information";
import Summary from "../Summary/Summary";
import Metrics from "../Metrics/Metrics";
import UxChecklist from "../UxChecklist/UxChecklist";
import { UserInfoContext } from "../../contexts";
import magnifyingGlass from "../../assets/magnifying-glass.svg";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function CreateReport({ sidebarCollapsed, ...props }) {
  const tabItems = [
    {
      name: "summary",
      label: "Summary",
      link: "/create-report/summary",
    },
    {
      name: "insights",
      label: "UX Insights",
      link: "/insights/create",
    },
    {
      name: "metrics",
      label: "Metrics",
      link: "/create-report/metrics",
    },
    {
      name: "ux-checklist",
      label: "UX Checklist",
      link: "/create-report/ux-checklist",
    },
  ];

  const [activeTab, setActiveTab] = useState("summary");

  const [pages, setPages] = useState([]);
  const [checklistPages, setChecklistPages] = useState([]);

  const [pagesOptions, setPagesOptions] = useState([]);
  const [checklistLoading, setChecklistLoading] = useState(true);
  const [projectPagesLoading, setProjectPagesLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [activeInsight, setActiveInsight] = useState("");
  const [activePage, setActivePage] = useState("");
  const [activeUxChecklist, setActiveUxChecklist] = useState("");
  const [activeUxChecklistPoint, setActiveUxChecklistPoint] = useState("");
  const [validReport, setValidReport] = useState(false);

  const { reportId } = useParams();
  const location = useLocation();

  const [projectRootUrl, setProjectRootUrl] = useState("");
  const [projectId, setProjectId] = useState("");
  const [pageName, setPageName] = useState("");
  const [insights, setInsights] = useState([]);
  const [searchAPageOpen, setSearchAPageOpen] = useState(false);

  const user = useContext(UserInfoContext);

  // Select the project id from the report doc to return project doc
  // Select the rootUrl from the project doc
  useEffect(() => {
    if (user?.role === "admin") {
      const pid = location?.state?.project?.value;
      setProjectId(pid);

      getInsightsPid(pid).then((i) => {
        const sortedInsights = i.sort((a, b) => a.num - b.num);
        setInsights(sortedInsights);
      });

      getDoc(doc(db, "projects", pid)).then(
        (p) => p?.data()?.rootUrl && setProjectRootUrl(p.data().rootUrl),
      );
    } else {
      getDoc(doc(db, "reports", reportId)).then((r) => {
        setProjectId(r.data().projectId);
        getInsightsPid(r.data().projectId).then((i) => setInsights(i));
        getDoc(doc(db, "projects", r.data().projectId)).then(
          (p) => p?.data()?.rootUrl && setProjectRootUrl(p.data().rootUrl),
        );
      });
    }
  }, [reportId]);

  const [totalImages, setTotalImages] = useState(0);
  const [loadedImages, setLoadedImages] = useState(0);

  /**
   * Check if report is valid, checking if all insights are saved and have a title.

  useEffect(() => {
    setValidReport(
      pages.some((page) => {
        return (
          page.insights.length !== 0 &&
          page.insights.every((insight) =>
            [insight.title, insight.saved].every((x) => x && x !== ""),
          )
        );
      }),
    );
  }, [pages]);   */

  /**
   * Add new insight to active page
   * @param insight - insight to add
   */
  function handleAddNewObservation(insight) {
    setActiveInsight(insight.id);
    setInsights((prevInsights) => {
      return [...prevInsights, insight];
    });
  }

  /**
   * Delete insight from active page
   * @param observationId - id of insight to delete
   */
  function handleDeleteObservation(observationId) {
    setInsights((prevInsights) => {
      return prevInsights.filter((insight) => insight.id !== observationId);
    });
  }

  /**
   * Update title of insight
   * @param title - new title
   */
  function handleObservationTitleUpdate(title) {
    setInsights((prevInsights) => {
      const newInsights = [...prevInsights];
      const insight = newInsights.find(
        (insight) => insight.id === activeInsight,
      );
      insight.title = title;
      return newInsights;
    });
  }

  /**
   * Set insight as saved
   */
  function handleSavedInsight() {
    setInsights((prevInsights) => {
      const newInsights = [...prevInsights];
      const insight = newInsights.find(
        (insight) => insight.id === activeInsight,
      );
      insight.saved = true;
      return newInsights;
    });

    setActiveInsight(null);
  }

  /**
   * Render the tab item based on the active tab
   * @returns {Element}
   */
  function renderTabItem() {
    switch (activeTab) {
      case "summary":
        return <Summary setActiveTab={setActiveTab} />;
      case "information":
        return <Information setActiveTab={setActiveTab} />;
      case "insights":
        return (
          <UxInsights
            activePage={activePage}
            handleAddNewObservation={(insight) =>
              handleAddNewObservation(insight)
            }
            handleObservationTitleUpdate={(title) =>
              handleObservationTitleUpdate(title)
            }
            handleDeleteObservation={handleDeleteObservation}
            activeInsight={activeInsight}
            handleSavedInsight={handleSavedInsight}
            setActiveInsight={setActiveInsight}
            reportId={reportId}
            validReport={validReport}
            projectRootUrl={projectRootUrl}
            projectId={projectId}
            pagesOptions={pagesOptions}
            insights={insights}
          />
        );
      case "metrics":
        return <Metrics reportId={reportId} />;
      case "ux-checklist":
        return (
          <UxChecklist
            activeUxChecklist={activeUxChecklist}
            activeUxChecklistPoint={activeUxChecklistPoint}
          />
        );
      default:
        return <Information />;
    }
  }

  /**
   * Add page to pages
   * @param option - page to add
   */
  function addPage(option) {
    // If page already part of pages, set active page to that page
    if (pages.some(([pageName]) => pageName === option.value)) {
      setActivePage({ name: option.label });
      return;
    }

    setPages((prevPages) => {
      setActivePage({ name: option.label });
      return [...prevPages, [option.label, []]];
    });
  }

  useEffect(() => {
    const pagesData = Object.entries(
      insights.reduce((pages, insight) => {
        if (!pages[insight.pageName]) {
          pages[insight.pageName] = [];
        }
        pages[insight.pageName].push(insight);
        return pages;
      }, {}),
    );

    setPages(pagesData);
  }, [insights]);

  /**
   * Get project pages from Google Analytics, using cloud function verifyGoogleAnalytics
   * @returns {Promise<void>}
   */
  async function getProjectPages() {
    const project =
      location?.state?.project ??
      (
        await getDoc(
          doc(
            db,
            "projects",
            (await getDoc(doc(db, "reports", reportId))).data().projectId,
          ),
        )
      ).data();

    // skip query to save quota
    if (!project || !project.googleAnalyticsId) {
      setPagesOptions([{ value: "/", label: "/" }]);
      setProjectPagesLoading(false);
      return;
    }

    const pages = await verifyGoogleAnalytics({
      projectId: project.googleAnalyticsId,
    });

    if (
      pages &&
      pages !== "Invalid Project ID" &&
      pages !== "No access to project, verify the account is authorized."
    ) {
      setPagesOptions(
        pages
          .filter((page) => !!page)
          .map((page) => {
            return { value: page, label: page };
          }),
      );
    } else {
      setPagesOptions([{ value: "/", label: "/" }]);
    }
    setProjectPagesLoading(false);
  }

  /**
   * Get report from firestore, and get list of pages already present in report.
   * @returns {Promise<void>}
   */
  async function getReport() {
    const insights = (
      await getDocs(query(collection(db, "reports", reportId, "insights")))
    ).docs
      .map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      })
      .sort((a, b) => a.createdAt - b.createdAt);

    const pagesLoading = [];

    for (let insight of insights) {
      const existingPage = pagesLoading.find(
        (page) => page.name === insight.pageName,
      );

      insight = { ...insight, saved: true };

      if (existingPage) {
        existingPage.insights.push(insight);
      } else {
        pagesLoading.push({ name: insight.pageName, insights: [insight] });
      }
    }

    setPages(pagesLoading);
  }

  /**
   * Fetch pages for ux checklist from firestore
   * @returns {Promise<void>}
   */
  async function getChecklistPages() {
    const checklistPagesQuerySnapshot = await getDocs(
      collection(db, "checklistPages"),
    );

    const checklistPagesTemp = [];
    for (const pageDoc of checklistPagesQuerySnapshot.docs) {
      const pointsQuerySnapshot = await getDocs(
        collection(pageDoc.ref, "points"),
      );
      const pointsTemp = [];
      for (const pointDoc of pointsQuerySnapshot.docs) {
        pointsTemp.push({
          ...pointDoc.data(),
        });
      }

      checklistPagesTemp.push({
        points: pointsTemp,
        ...pageDoc.data(),
      });
    }

    checklistPagesTemp.sort((a, b) => a.order - b.order);
    setChecklistPages(checklistPagesTemp);
    setTotalImages(checklistPagesTemp.length * 2);
  }

  /**
   * Fetch pages for ux checklist and report from firestore
   */
  useEffect(() => {
    getProjectPages();
    getChecklistPages();

    if (user?.role !== "admin") {
      getReport();
    } else {
      setActiveTab("insights");
    }
  }, []);

  /**
   * Set loading state based on projectPagesLoading and checklistLoading
   */
  useEffect(() => {
    setIsLoading(projectPagesLoading || checklistLoading);
  }, [projectPagesLoading, checklistLoading]);

  /**
   * Set active page to first page in pages, for both checlist page & report page
   */
  useEffect(() => {
    if (checklistPages && checklistPages.length !== 0 && !activeUxChecklist)
      setActiveUxChecklist(checklistPages[0]);
    if (checklistPages[0])
      setActiveUxChecklistPoint(checklistPages[0].points[0]);
  }, [checklistPages]);

  useEffect(() => {
    if (!activePage) setActivePage(pages[0]?.name ?? {});
  }, [pages]);

  // Sometimes the images get unloaded when switching active tabs
  useEffect(() => {
    // So reset and recount loaded images
    if (activeTab === "ux-checklist") {
      setChecklistLoading(true);
      setLoadedImages(0);
    }

    const imageUrls = checklistPages.flatMap((page) => [
      page.icon,
      page.iconActive,
    ]);

    const preloadImage = (src) => {
      const img = new Image();
      img.onload = () => setLoadedImages((prev) => prev + 1);
      img.src = src;
    };

    imageUrls.forEach(preloadImage);
  }, [checklistPages, activeTab]);

  useEffect(() => {
    if (totalImages !== 0 && loadedImages === totalImages) {
      setChecklistLoading(false);
    }
  }, [loadedImages]);

  return (
    <div className="d-flex flex-row">
      <Sidebar
        collapsed={activeTab !== "ux-checklist" && activeTab !== "insights"}
        collapsedWidth="0px"
        style={{
          border: "none",
          height: "100vh",
          zIndex: 2,
          position: "fixed",
          top: "0",
          left: sidebarCollapsed ? "100px" : "340px",
          background: "#F7F7F7",
        }}
        width={sidebarCollapsed ? "381px" : "250px"}
        className={`hidden-scrollbar ${
          activeTab === "ux-checklist" || activeTab === "insights"
            ? "report-sidebar"
            : ""
        }`}
      >
        <div
          style={{
            margin: "40px 0 50px 0",
            padding: "32px 32px 0 32px",
            width: "100%",
          }}
          className="d-flex align-items-center"
        >
          <h5>
            Analyse the most interesting pages or the ones requested by your
            client.
          </h5>
        </div>
        {isLoading && activeTab !== "ux-checklist" ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          <div>
            {activeTab !== "ux-checklist" && (
              <div>
                <div
                  className="d-flex justify-content-between"
                  style={{
                    borderBottom: "1px solid #D7D7D7",
                    margin: "0px 32px",
                  }}
                >
                  <div style={{ flexGrow: "1" }}>
                    <CustomDropdown
                      options={pagesOptions}
                      onChange={(option) => addPage(option)}
                      value={"myPage"}
                      placeholder="Search a page"
                      unstyled
                      menuOpenState={searchAPageOpen}
                      setMenuOpenState={setSearchAPageOpen}
                      noIcon
                    />
                  </div>
                  <img src={magnifyingGlass} alt="magnifying glass" />
                </div>
              </div>
            )}
          </div>
        )}
        {isLoading && activeTab === "ux-checklist" ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          <div className="d-flex">
            <Menu
              style={{
                width: "100%",
              }}
            >
              {activeTab === "ux-checklist" &&
                checklistPages.map((page, i) => {
                  return (
                    <MenuItem
                      key={i}
                      active={activeUxChecklist === page}
                      onClick={() => {
                        setActiveUxChecklist(page);
                        page.points.length > 0
                          ? setActiveUxChecklistPoint(page.points[0])
                          : setActiveUxChecklistPoint(null);
                      }}
                      className={`checklist-page-item ${
                        activeUxChecklist === page
                          ? "checklist-page-item-active"
                          : ""
                      }`}
                    >
                      <div className="checklist-page-container">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "20px",
                            height: "20px",
                          }}
                        >
                          <img
                            style={{
                              display: activeUxChecklist !== page ? "" : "none",
                            }}
                            src={page.icon}
                            alt="icon"
                          />
                          <img
                            style={{
                              display: activeUxChecklist === page ? "" : "none",
                            }}
                            src={page.iconActive}
                            alt="icon"
                          />
                        </div>
                        <h6>{page.title}</h6>
                      </div>
                      {page.points.length !== 0 &&
                        activeUxChecklist === page &&
                        page.points.map((point, idx) => {
                          return (
                            <Button
                              className={`subpoint${
                                activeUxChecklistPoint === point
                                  ? "-active"
                                  : ""
                              }`}
                              key={idx}
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveUxChecklistPoint(point);
                              }}
                            >
                              <h4>{point.title}</h4>
                              {Object.keys(point.subpoints).map(
                                (subpoint, i) => {
                                  return <h5 key={i}>{subpoint}</h5>;
                                },
                              )}
                            </Button>
                          );
                        })}
                    </MenuItem>
                  );
                })}
              {activeTab === "insights" &&
                pages &&
                pages.map(([pageName, pageInsights], i) => {
                  return (
                    <MenuItem
                      key={i}
                      active={activePage.name === pageName}
                      className={`report-menu-item ${
                        activePage.name === pageName ? "rmiactive" : ""
                      }`}
                      onClick={() => {
                        if (activePage.name !== pageName) {
                          setActiveInsight("");
                        }
                        setActivePage({ name: pageName });
                      }}
                    >
                      <div
                        className={`report-menu-item-container${
                          activePage.name === pageName ? "-active" : ""
                        }`}
                      >
                        <h4>{pageName}</h4>
                        {pageInsights.length === 0 ? (
                          <div>No insights yet</div>
                        ) : (
                          <div
                            style={{ marginTop: "20px" }}
                            className="d-flex flex-column"
                          >
                            {pageInsights
                              .sort((a, b) => a.num - b.num)
                              .map((insight, i) => (
                                <Button
                                  key={i}
                                  className={`report-menu-item-button ${
                                    activeInsight === insight.id
                                      ? "rmibactive"
                                      : ""
                                  }`}
                                  onClick={() => setActiveInsight(insight.id)}
                                >
                                  {`Insight ${insight?.num ?? "x"}${
                                    insight.title ? ` - ${insight.title}` : ""
                                  }`}
                                </Button>
                              ))}
                          </div>
                        )}
                      </div>
                    </MenuItem>
                  );
                })}
            </Menu>
          </div>
        )}
      </Sidebar>
      <div
        style={{ maxWidth: "960px" }}
        className={
          activeTab === "insights" || activeTab === "ux-checklist"
            ? "w-100 insights-or-checklist-dynamic-padding"
            : "w-100"
        }
      >
        {user?.role === "admin" && (
          <Link to={"/"}>
            <div
              style={{
                width: "200px",
                height: "24px",
                textAlign: "center",
                marginTop: "-50px",
                paddingBottom: "50px",
              }}
              className="fs-14 fw-700 fc-black mb-2"
            >
              <FiArrowLeft
                color="#000"
                size="24px"
                style={{ marginRight: "10px", marginBottom: "2px" }}
              />
              Back to Admin Panel
            </div>
          </Link>
        )}
        <div
          style={{
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          {tabItems.map((item) => {
            if (user?.role === "admin") {
              if (item.name === "insights") {
                return (
                  <Button
                    className={`tab-item ${
                      activeTab === item.name ? "activetab" : ""
                    }`}
                    key={item.name}
                    onClick={() => setActiveTab(item.name)}
                  >
                    <div
                      className={`tab-item-text${
                        activeTab === item.name ? "-active-blue" : ""
                      }`}
                      to={item.link}
                    >
                      <div className="fs-16">{item.label}</div>
                    </div>
                  </Button>
                );
              } else {
                return;
              }
            }
            return (
              <Button
                className={`tab-item ${
                  activeTab === item.name ? "activetab" : ""
                }`}
                key={item.name}
                onClick={() => setActiveTab(item.name)}
              >
                <div
                  className={`tab-item-text${
                    activeTab === item.name ? "-active-blue" : ""
                  }`}
                  to={item.link}
                >
                  <div className="fs-16">{item.label}</div>
                </div>
              </Button>
            );
          })}
        </div>
        {renderTabItem()}
      </div>
    </div>
  );
}
