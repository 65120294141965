import { useEffect, useState } from "react";
import { auth, db } from "../../firebase";
import { doc, setDoc, getDoc, collection } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { Row, Spinner } from "react-bootstrap";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import "./Contract.css";
import grayPfp from "../../assets/gray.PNG";
import { getIndustryTags } from "../../helpers";
import { useNavigate } from "react-router-dom";

export default function Contract() {
  const [user, loading] = useAuthState(auth);
  const [project, setProject] = useState({});
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [accepted, setAccepted] = useState(false);
  const [clientPfp, setClientPfp] = useState(grayPfp);
  const navigate = useNavigate();

  const fetchProjectInfo = async () => {
    getDoc(doc(db, "projects", projectId)).then((querySnapshot) => {
      getDoc(doc(db, "users", querySnapshot.data().clientId)).then(
        (querySnapshot) => {
          setClientPfp(querySnapshot.data().pfp ?? grayPfp);
        },
      );

      setProject(querySnapshot.data());
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (user) {
      fetchProjectInfo();
    }
  }, [user]);

  if (isLoading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }
  const industryTagChips = (options) => {
    const dictionary = getIndustryTags();

    return options?.map((option) => {
      const item = dictionary[option];
      if (item) {
        return (
          <div
            className="industry-tag-chip fw-500"
            style={{
              fontSize: "14px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5px",
            }}
          >
            {item.icon} <span>{option}</span>
          </div>
        );
      }
      return null;
    });
  };

  /**
   * Creates a new report card and navigates to dashboard.
   * @param projectId - The id of the project to create a report for.
   */
  function onNewReportCardClick(projectId) {
    const newDocRef = doc(collection(db, "reports"));
    setDoc(newDocRef, {
      createdAt: new Date(),
      designerId: user.uid,
      projectId: projectId,
      submitted: false,
    }).then(() => {
      navigate("/", { state: { projectName: project.name } });
    });
  }

  return (
    <div>
      <Link to={"/"}>
        <div
          style={{
            width: "158px",
            height: "24px",
            textAlign: "center",
          }}
          className="fs-14 fw-700 fc-black"
        >
          <FiArrowLeft
            color="#000"
            size="24px"
            style={{ marginRight: "10px", marginBottom: "2px" }}
          />
          Back to Dashboard
        </div>
      </Link>
      <div className="fs-24 fw-700 lh-243 mb-5" style={{ marginTop: "75px" }}>
        New contract
      </div>
      <div style={{ marginLeft: "10px" }}>
        <Row
          className="fs-14 lh-20 fw-500 fc-grey"
          style={{ marginBottom: "24.32px" }}
        >
          <p className="p-m0 p-p0">
            Amazing !!! You match with a new client 🥳
          </p>
          <p className="p-m0 p-p0">
            Check the project and the contract, analyze and decide if you want
            to take it or not.
          </p>
          <p className="p-m0 p-p0">You have 72 hours to take a decision.</p>
          <p className="p-m0 p-p0">
            Otherwise the project will go to another UX Designer.
          </p>
        </Row>
      </div>
      <div className="contract-container">
        <div className="fw-500 fs-14 lh-20">Client</div>
        <div className="contact-spacer">
          <img
            src={clientPfp ?? grayPfp}
            alt="Client picture"
            style={{
              height: "60px",
              width: "60px",
              borderRadius: "99px",
              marginTop: "5px",
            }}
          ></img>
        </div>
        <div className="fw-500 fs-14 lh-20">Project Name</div>
        <div className="fw-700 fs-16 lh-20 contact-spacer">{project.name}</div>
        <div className="fw-500 fs-14 lh-20">Project Type</div>
        <div className="fw-700 fs-16 lh-20 contact-spacer">
          {project.projectTags?.join(", ")}
        </div>
        <div className="fw-500 fs-14 lh-20">Industry tags</div>
        <div className="fw-700 fs-16 lh-20 contact-spacer flex-container">
          {industryTagChips(project.industryTags)}
        </div>
        <div className="fw-500 fs-14 lh-20">Report frequency</div>
        <div className="fw-700 fs-16 lh-20 contact-spacer">
          n report(s) per month
        </div>
        <div className="fw-500 fs-14 lh-20">Earning</div>
        <div className="fw-700 fs-16 lh-20 contact-spacer">
          € nnn per report
        </div>
        <div className="fw-500 fs-14 lh-20">Important conditions</div>
        <div className="fw-700 fs-16 lh-20">
          This is the legal text of LUXIFER conditions about your obligations to
          your client. Do the report before time out. Give response in max.
          48hours. Afficher les mains choses puis plus standard. Obligations:
          discussion client, etc.
        </div>
        <div style={{ marginTop: "50px", marginBottom: "32.5px" }}>
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
            className="row mb-5"
          >
            <label className="d-flex" style={{ cursor: "pointer" }}>
              <input
                type="checkbox"
                checked={accepted}
                onChange={() => setAccepted(!accepted)}
                className="checkbox-input"
              />

              <span
                style={{ marginLeft: "15.88px", fontWeight: "500" }}
                className="lh-189"
              >
                I read and I accept{" "}
                <a href="">
                  <span className="fw-700 styled-link fc-black">
                    general terms of the contract.
                  </span>
                </a>
              </span>
            </label>
          </div>
          <button
            disabled={!accepted}
            style={{ width: "195px", height: "44px" }}
            className="col btn-primary"
            onClick={() => {
              onNewReportCardClick(projectId);
            }}
          >
            Accept and continue
            <FiArrowRight style={{ marginLeft: "12px" }} />
          </button>
          <button
            style={{ width: "116px", padding: "10px", height: "44px" }}
            className="col btn-secondary mx-4 fw-700"
          >
            Decline offer
          </button>
        </div>
      </div>
    </div>
  );
}
