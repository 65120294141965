import Select, { components } from "react-select";
import "./CustomDropdown.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const CustomControl = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      {props.hasValue ? (
        <div className="floating-label">{props.selectProps.placeholder}</div>
      ) : null}
      {children}
    </components.Control>
  );
};

function CustomDropdown(props) {
  const isCustomOptions = props?.customOptions ?? false;
  const value = props?.value ?? false;

  const selectBackground = {
    ...props?.styles,
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--blue-opacity10)" : "inherit",
      color: state.isSelected ? "var(--main-blue)" : "inherit",
      margin: "4px 0px",
    }),
    menuList: (provided) => ({
      ...provided,
      minHeight: props.maxHeight,
      height: props.maxHeight,
    }),
  };

  function dropdownHasValue(value) {
    return value !== null && (!Array.isArray(value) || value.length);
  } // By default filters always have values because unselected means all is selected

  const CustomOption = ({ children, ...props }) => {
    const data = props.data;
    const separator = data?.separator ?? false;
    let classes = props.getClassNames() || "";
    classes = !!separator ? `${classes} option-separator` : classes;
    props.isSelected = !!data && data?.isSelected;
    props.isDisabled = !!data && data?.isDisabled;
    props.isFocused = !!data && data?.isFocused;

    if (!!isCustomOptions) {
      return (
        <components.Option {...props} className={classes}>
          <span className="option-wrapper">
            <span className="option-icon">{data.icon}</span>
            {data?.description ? (
              <span className="option-main">
                {children}
                <span className="option-description">{data?.description}</span>
              </span>
            ) : (
              children
            )}
          </span>
        </components.Option>
      );
    } else {
      return <components.Option {...props}>{children}</components.Option>;
    }
  };

  return (
    <div>
      <Select
        maxHeight={props.maxHeight}
        options={props.options}
        isDisabled={props.disabled}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        unstyled={props.unstyled}
        styles={props?.styles ?? selectBackground}
        classNames={{
          control: () => {
            let classes = "dropdown-control";
            if (dropdownHasValue(props.value) && !props.components?.Control) {
              classes += props.controlChips
                ? " dropdown-control-has-value-chip"
                : " dropdown-control-has-value";
            }

            classes = !!props.className
              ? (classes += ` ${props.className}`)
              : classes;

            return classes;
          },
          menu: () => "menu",
          option: () => "option",
          ...props.classNames,
        }}
        onMenuOpen={
          props.setMenuOpenState
            ? () => props.setMenuOpenState(true)
            : props.onMenuOpen
        }
        onMenuClose={
          props.setMenuOpenState
            ? () => props.setMenuOpenState(false)
            : props.onMenuClose
        }
        isMulti={props.isMulti}
        hideSelectedOptions={props.hideSelectedOptions}
        menuIsOpen={props?.menuIsOpen ?? props.menuOpenState}
        closeMenuOnSelect={props.closeMenuOnSelect}
        isClearable={props.isClearable}
        blurInputOnSelect={props.blurInputOnSelect}
        isLabelType={props.isLabelType}
        blueChevron={props.blueChevron}
        controlChips={props.controlChips}
        components={{
          Control: CustomControl,
          Option: CustomOption,
          SingleValue: (svprops) => (
            <components.SingleValue
              {...svprops}
              className={`select-selected-option ${
                props.value ? "visible" : "hidden"
              }`}
            />
          ),
          DropdownIndicator: (dropdownprops) => (
            <components.DropdownIndicator
              className={
                dropdownHasValue(props.value) &&
                !props.isLabelType &&
                !props.controlChips
                  ? "chevron-value-offset"
                  : ""
              }
              {...dropdownprops}
            >
              {props.noIcon ? (
                <div />
              ) : props.menuOpenState ? (
                <FiChevronUp
                  alt="chevron up"
                  color={props.blueChevron ? "#2A00FF" : "#707070"}
                  size="24px"
                />
              ) : (
                <FiChevronDown
                  alt="chevron down"
                  color={props.blueChevron ? "#2A00FF" : "#707070"}
                  size="24px"
                />
              )}
            </components.DropdownIndicator>
          ),
          ...props.components,
        }}
      />
    </div>
  );
}
export default CustomDropdown;
